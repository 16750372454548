import { memo, useMemo } from 'react';
import ViewportContext from './ViewportContext';

function ViewportProvider({
  children,
  frameWidth,
  frameHeight,
  regionId,
}) {

  const value = useMemo(() => {
    return {
      frameWidth,
      frameHeight,
      regionId,
    };
  }, [
    frameWidth,
    frameHeight,
    regionId,
  ]);

  return (
    <ViewportContext.Provider value={value}>
      {children}
    </ViewportContext.Provider>
  );
}

export default memo(ViewportProvider);
