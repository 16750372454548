import { reducer as modelsReducer, initialState as modelsInitialState } from '../models-store';
import { reducer as gameReducer, initialState as gameInitialState } from './slice';
import { reducer as hudReducer, initialState as hudInitialState } from '../ui/data/slice';
import { reducer as innReducer, initialState as innInitialState } from '../ui/activities/inn/data/slice';
import { reducer as combatReducer, initialState as combatInitialState } from '../ui/activities/combat';
import { reducer as conversationReducer, initialState as conversationInitialState } from '../ui/activities/conversation';
import { reducer as tradeReducer, initialState as tradeInitialState } from '../ui/activities/trade';
import { reducer as chronicleReducer, initialState as chronicleInitialState } from '../ui/chronicle';
import { reducer as phaserReducer, initialState as phaserInitialState } from '../world/data/slice';
import { AppState, AppStatus, StandardAction } from './types';

const LOAD_GAME = 'LOAD_GAME';
const NEW_GAME = 'NEW_GAME';
const UNLOAD_GAME = 'UNLOAD_GAME';

export function loadGame(payload: AppState) {
  return {
    type: LOAD_GAME,
    payload,
  };
}

export function newGame() {
  return {
    type: NEW_GAME,
  };
}

export function unloadGame() {
  return {
    type: UNLOAD_GAME,
  };
}

export const initialState: AppState = {
  status: AppStatus.UNLOADED,
  chronicle: chronicleInitialState,
  combat: combatInitialState,
  conversation: conversationInitialState,
  game: gameInitialState,
  hud: hudInitialState,
  inn: innInitialState,
  models: modelsInitialState,
  phaser: phaserInitialState,
  trade: tradeInitialState,
};

const reducer = (state: AppState, action: StandardAction<any>) => {
  switch(action.type) {
    case LOAD_GAME:
      return {
        ...action.payload,
        status: AppStatus.LOADED,
      };
    case UNLOAD_GAME:
      return initialState;
    default:
      return {
        status: state.status,
        chronicle: chronicleReducer(state.chronicle, action),
        combat: combatReducer(state.combat, action),
        conversation: conversationReducer(state.conversation, action),
        game: gameReducer(state.game, action),
        hud: hudReducer(state.hud, action),
        inn: innReducer(state.inn, action),
        models: modelsReducer(state.models, action),
        phaser: phaserReducer(state.phaser, action),
        trade: tradeReducer(state.trade, action),
      };
  }
};

export default reducer;
