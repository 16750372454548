import { updateActivity } from '../../../../data/slice';
import { createCharacterSkillsSelector } from '../../../../characters/skills/data/selectors';
import { rest } from '../../../../characters/status/data/thunks';
import { createRegionCharactersByLocationSelector, createRegionFeaturesByLocationSelector, createRegionItemsByLocationSelector, createRegionPathfindingGraphSelector } from '../../../../world/data/selectors';
import { advanceTime } from '../../../../saves/data/thunks';
import { isWalkableArea } from '../../../../data/utils';
import { createItemWithChildren } from '../../../../content-utils/itemUtils';
import { addModel, updateModel } from '../../../../models-store';
import { PartyModes } from '../../../../data/types';
import { getCampfirePosition } from './utils';
import { ActivityTypes } from '../../data/types';
import { endActivity } from '../../data/thunks';
import { addMessage } from '../../../chronicle';
import { sleep } from '../../../../data/utils';
import { playerChangePartyMode } from '../../../../player/data/thunks';
import { partyCatchupWalk } from '../../../../characters/movement/data/thunks';

export function partyCamp({ watcherId, duration }) {
  return async (dispatch, getState) => {
    const { partyCharacterIds, activeCharacterId } = getState().game;

    partyCharacterIds.forEach(characterId => {
      dispatch(camp({ characterId, isWatcher: watcherId === characterId }));
    });

    for (let i = 1; i <= duration; i++) {
      dispatch(advanceTime());
      await sleep(2500);
    }

    dispatch(breakCamp({ characterId: activeCharacterId }));
  };
}

export function camp({ characterId, isWatcher = false }) {
  return async (dispatch, getState) => {
    const { survival } = createCharacterSkillsSelector(characterId)(getState());

    let quality = 5 + survival;
    if (isWatcher) {
      quality = survival;
    }

    dispatch(rest({
      characterId,
      healthQuality: quality,
      enduranceQuality: quality * 2,
    }));
  };
}

function isValidCampSite(x, y, region, itemsByLocation, featuresByLocation, charactersByLocation) {
  for (let dx = -1; dx < 2; dx++) {
    for (let dy = -1; dy < 2; dy++) {
      const areaX = x + dx;
      const areaY = y + dy;
      const areaItems = itemsByLocation[`${areaX}&${areaY}`];
      const areaFeatures = featuresByLocation[`${areaX}&${areaY}`];
      const areaCharacters = charactersByLocation[`${areaX}&${areaY}`];

      const walkable = isWalkableArea(areaItems, areaFeatures, areaCharacters, region, areaX, areaY, { includeCharacters: false });
      if (!walkable) {
        return false;
      }

      if (dx === 0 && dy === 0) {
        // There can't be anything directly where we want to put our fire.  That's weird.
        if ((areaItems && areaItems.length > 0) || (areaFeatures && areaFeatures.length > 0)) {
          return false;
        }
      }
    }
  }
  return true;
}


export function partyTakePositions() {
  return async (dispatch, getState) => {
    const { activeCharacterId } = getState().game;
    const playerCharacter = getState().models.characters[activeCharacterId];
    const region = getState().models.regions[playerCharacter.regionId];
    const graph = createRegionPathfindingGraphSelector(region.id)(getState());
    const { partyCharacterIds } = getState().game;

    const remainingCharacterIds = [...partyCharacterIds];

    while (remainingCharacterIds.length > 0) {
      for (let i = 0; i < remainingCharacterIds.length; i++) {
        const partyCharacterId = remainingCharacterIds[i];
        if (partyCharacterId === activeCharacterId) {
          remainingCharacterIds.splice(i, 1);
        } else {
          const complete = await dispatch(partyCatchupWalk({ characterId: partyCharacterId, existingGraph: graph }));

          if (complete) {
            remainingCharacterIds.splice(i, 1);
          }
        }
      }
      await sleep(250);
    }
  };
}

export function setUpCamp({ characterId }) {
  return async (dispatch, getState) => {

    const character = getState().models.characters[characterId];

    const { x, y } = getCampfirePosition(character);

    const region = getState().models.regions[character.regionId];
    const itemsByLocation = createRegionItemsByLocationSelector(region.id)(getState());
    const featuresByLocation = createRegionFeaturesByLocationSelector(region.id)(getState());
    const charactersByLocation = createRegionCharactersByLocationSelector(region.id)(getState());

    if (isValidCampSite(x, y, region, itemsByLocation, featuresByLocation, charactersByLocation)) {
      const items = createItemWithChildren({
        templateId: 'campfire',
        regionId: character.regionId,
        x,
        y,
        status: 'lit',
      });
      const campfire = Object.values(items)[0]; // There's only one.

      dispatch(addModel({ modelType: 'items', model: campfire }));
      dispatch(playerChangePartyMode({
        partyMode: PartyModes.CAMPING,
        characterId,
      }));

      dispatch(updateActivity({ activity: ActivityTypes.CAMP }));
      dispatch(partyTakePositions());
    } else {
      dispatch(addMessage({ message: `This isn't a good campsite. There's something in the way.` }));
    }
  };
}

export function breakCamp({ characterId }) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];

    const { x, y } = getCampfirePosition(character);

    const itemsByLocation = createRegionItemsByLocationSelector(character.regionId)(getState());

    dispatch(endActivity());
    dispatch(playerChangePartyMode({
      partyMode: PartyModes.ALL,
      characterId,
    }));

    const areaItems = itemsByLocation[`${x}&${y}`];

    areaItems.forEach(item => {
      if (item.templateId === 'campfire') {
        dispatch(updateModel({ modelType: 'items', model: {
          id: item.id,
          status: 'doused',
        }}));
      }
    });
  };
}
