import { memo, useMemo } from 'react';
import classNames from 'classnames';

import { useActiveRegion, useCurrentActivity, useGame, useMainViewpoint, useWindowSize } from '../data/hooks';
import { logRender } from '../data/utils';
import ViewportInputOverlay from '../world/ViewportInputOverlay';
import { useViewportMouseHandlers } from '../player/data/hooks';
import { getCutscene } from '../content-utils/cutsceneUtils';
import { TimeModes } from '../data/types';

import Activities from './activities/Activities';
import { Floater, Panel, FullBleed, Fader } from './generic';
import Chronicle from './chronicle/Chronicle';
import GeneralButtons from './GeneralButtons';
import OpenContainers from './containers/OpenContainers';
import OpenSheets from './sheet/OpenSheets';
import GrabbedItemPanel from './GrabbedItemPanel';
import { ActivityTypes } from './activities/data/types';
import ConversationPanel from './ConversationPanel';
import InGameMenu from './menus/InGameMenu';
import { Trade } from './activities/trade';
import Party from './Party';
import Defeated from './Defeated';
import Inn from './activities/inn/Inn';
import DateTime from './DateTime';
import Camp from './activities/camp/Camp';
import Narrative from './Narrative';
import PlaceIcon from './PlaceIcon';
import { useHud } from './data/hooks';

function Hud() {
  logRender('Hud');
  const activity = useCurrentActivity();
  const {
    defeated,
    activeCutsceneId,
    activeCutsceneStep,
    timeMode,
  } = useGame();
  const {
    showChronicle,
    showMenu,
    grabbedItemId,
  } = useHud();

  const viewpoint = useMainViewpoint();

  const {
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  } = useViewportMouseHandlers();

  const region = useActiveRegion();
  const { width, height } = useWindowSize();

  const viewportStyle = useMemo(() => {
    return {
      width,
      height,
    };
  }, [width, height]);

  const isCutscene = timeMode === TimeModes.CUTSCENE;

  if (showMenu) {
    return (
      <FullBleed
        className={classNames('layer-1 overflow-hidden invisible')}
      >
        <Floater x={width / 2} y={height / 2} alignX="center" alignY="center">
          <InGameMenu />
        </Floater>
      </FullBleed>
    );
  }

  if (isCutscene) {
    const cutscene = getCutscene(activeCutsceneId);
    const { steps } = cutscene;
    const currentStep = steps[activeCutsceneStep];
    if (currentStep.action === 'narrative') {
      return (
        <FullBleed
          className={classNames('layer-1 overflow-hidden invisible')}
        >
          <Floater x={width / 2} y={height / 2} alignX="center" alignY="center">
            <Narrative narrativeId={currentStep.narrativeId} />
          </Floater>
        </FullBleed>
      );
    }
  }

  if (defeated) {
    return (
      <Fader ready>
        <Defeated />
      </Fader>
    );
  }

  return (
    <div className="position-relative">
      <ViewportInputOverlay
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        style={viewportStyle}
        className="position-absolute overflow-hidden"
        viewpoint={viewpoint}
      />

      <FullBleed
        className={classNames('layer-1 overflow-hidden invisible')}
      >
        <Fader ready={!isCutscene} mount>
          <GeneralButtons />
        </Fader>

        <Floater x={width - 8} y={8} alignX="right">
          <div className="d-flex flex-column vgap-2 align-items-stretch justify-content-stretch">
            <Panel className="p-2"><DateTime /></Panel>
            <Panel className="p-2">
              <div className="d-flex justify-content-center align-items-center">
                <PlaceIcon
                  className="mr-2"
                  iconId={region.placeIconId}
                  scale={1.5}
                />
                <div>{region.name}</div>
              </div>
            </Panel>
          </div>
        </Floater>

        {showChronicle && (
          <Chronicle className="flex-grow-1" />
        )}
        {activity && (
          <Activities />
        )}
        {activity === ActivityTypes.CONVERSATION && (
          <ConversationPanel />
        )}
        {activity === ActivityTypes.TRADE && (
          <Trade />
        )}
        {activity === ActivityTypes.INN && (
          <Inn />
        )}
        {activity === ActivityTypes.CAMP && (
          <Camp />
        )}
        <OpenSheets />
        <OpenContainers />
        {grabbedItemId !== null && (
          <GrabbedItemPanel />
        )}
        <Fader ready={!isCutscene} mount>
          <Party />
        </Fader>
      </FullBleed>
    </div>
  );
}

export default memo(Hud);
