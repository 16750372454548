import {
  endCharacterTurn,
} from '../../characters/data/thunks';
import { addMessage } from '../../ui/chronicle';
import {
  createRegionCharactersByLocationSelector,
  createRegionItemsByLocationSelector,
} from '../../world/data/selectors';
import {
  activeCharacterSelector,
  createContainerItemsSelector,
  leaderCharacerSelector,
} from '../../data/selectors';
import {
  updateActiveCharacter,
  updatePartyMode,
  updateWalkDirections
} from '../../data/slice';
import {
  addOpenContainer,
  removeOpenContainer,
  updateGrabbedItem,
} from '../../ui/data/slice';
import { REST } from '../../ui/character/constants';
import { createModelSelector } from '../../models-store/selectors';
import { startConversation } from '../../ui/activities/conversation';
import { hydrateItem } from '../../data/contentUtils';
import {
  addModelsById,
  removeModel,
  updateModel,
  updateModels
} from '../../models-store';
import { startActivity } from '../../ui/activities/data/thunks';
import { ActivityTypes } from '../../ui/activities/data/types';
import { getDistance, sleep } from '../../data/utils';
import {
  createCharacterAttackRangeSelector,
  createIsAmmoAvailableSelector
} from '../../characters/combat/data/selectors';
import { createItemWithChildren } from '../../content-utils/itemUtils';
import { PartyModes, TimeModes } from '../../data/types';
import { createCharacterInventorySelector } from '../../characters/items/data/selectors';
import { attackCharacter } from '../../characters/combat/data/thunks';
import { loadActiveRegion, unloadActiveRegion } from '../../world/data/thunks';
import { destroyInputDirector, createInputDirector, isInputWalking } from '../../engine/directors/input/director';

export function playerAttack({ characterId, x, y }) {
  return async (dispatch, getState) => {
    const attacker = createModelSelector('characters', characterId)(getState());
    const charactersByLocation = createRegionCharactersByLocationSelector(attacker.regionId)(getState());

    const areaCharacters = charactersByLocation[`${x}&${y}`];
    if (areaCharacters && areaCharacters.length > 0) {
      const attackedCharacter = areaCharacters[0];

      const dx = attacker.x - attackedCharacter.x;
      const dy = attacker.y - attackedCharacter.y;
      const distance = getDistance(dx, dy);
      const range = createCharacterAttackRangeSelector(attacker.id)(getState());

      if (distance > range) {
        dispatch(addMessage({ message: `${attackedCharacter.name} is out of range.` }));
        return;
      }

      const isAmmoAvailable = createIsAmmoAvailableSelector(attacker.id)(getState());
      if (!isAmmoAvailable) {
        dispatch(addMessage({ message: `${attacker.name} has no ammo.` }));
        return;
      }

      // The actions in attackCharacter take time.  We should wait for them to complete.
      await dispatch(attackCharacter({
        attackerId: attacker.id,
        defenderId: attackedCharacter.id,
      }));

      dispatch(endCharacterTurn({ actingCharacterId: characterId }));
    }
  };
}

export function playerLook({ characterId, x, y }) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];

    const region = getState().models.regions[character.regionId];
    const itemsByLocation = createRegionItemsByLocationSelector(character.regionId)(getState());
    const charactersByLocation = createRegionCharactersByLocationSelector(character.regionId)(getState());

    const areaItems = itemsByLocation[`${x}&${y}`];
    const areaCharacters = charactersByLocation[`${x}&${y}`];
    const area = region.map.areas[`${x}&${y}`];
    const groundTerrain = region.map.terrains[area.ground];

    let message = `${character.name} sees `;

    let needsOn = false;
    if (areaCharacters && areaCharacters.length > 0) {
      message += `${areaCharacters[0].name}`;
      if (areaItems && areaItems.length > 0) {
        message += ` standing over `;
      }
      needsOn = true;
    }
    if (areaItems && areaItems.length > 0) {
      for (let i = 0; i < areaItems.length; i++) {
        const item = areaItems[i];
        if (i === 0) {
          message += `${item.determiner} ${item.name}`;
        } else if (i === areaItems.length - 1) {
          message += `, and ${item.determiner} ${item.name}`;
        } else {
          message += `, ${item.determiner} ${item.name}`;
        }
      }
      // const itemText = areaItems.length === 1 ? 'item' : 'items';
      // message += `${areaItems.length} ${itemText} on `;
      needsOn = true;
    }
    if (needsOn) {
      message += ' on';
    }
    message += ` the ${groundTerrain.type}.`;
    dispatch(addMessage({ message }));
  };
}

export function playerTalk({ characterId, x, y }) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];
    const charactersByLocation = createRegionCharactersByLocationSelector(character.regionId)(getState());
    const areaCharacters = charactersByLocation[`${x}&${y}`];
    if (areaCharacters && areaCharacters.length > 0) {
      const talkCharacter = areaCharacters[0];

      if (talkCharacter.conversationId !== null) {
        dispatch(startConversation({ characterId: talkCharacter.id }));
        dispatch(startActivity({ activity: ActivityTypes.CONVERSATION }));
      }
    }
  };
}

export function openContainer({ containerItemId }) {
  return async (dispatch, getState) => {
    dispatch(addOpenContainer({ containerItemId }));
    dispatch(updateModel({
      modelType: 'items',
      model: {
        id: containerItemId,
        status: 'open',
      }
    }));
  };
}

export function closeContainer({ containerItemId }) {
  return async (dispatch, getState) => {
    dispatch(updateModel({
      modelType: 'items',
      model: {
        id: containerItemId,
        status: 'closed',
      }
    }));
    dispatch(removeOpenContainer({
      containerItemId
    }));
  };
}

export function combineStacks({ destinationItemId, sourceItemId }) {
  return async (dispatch, getState) => {
    const destinationItem = hydrateItem(getState().models.items[destinationItemId]);
    const sourceItem = hydrateItem(getState().models.items[sourceItemId]);

    const availableSpace = destinationItem.stackSize - destinationItem.quantity;
    if (availableSpace >= sourceItem.quantity) {
      // keep dest, remove source
      dispatch(updateModel({
        modelType: 'items',
        model: {
          id: destinationItemId,
          quantity: destinationItem.quantity + sourceItem.quantity,
        }
      }));
      dispatch(removeModel({
        modelType: 'items',
        id: sourceItemId,
      }));
    } else {
      // Keep both, move as many as we can.
      dispatch(updateModels({
        modelType: 'items',
        models: [
          {
            id: destinationItemId,
            quantity: destinationItem.quantity + availableSpace,
          },
          {
            id: sourceItemId,
            quantity: sourceItem.quantity - availableSpace,
          },
        ]
      }));
    }
  };
}

export function splitStack({ itemId, splitCount }) {
  return async (dispatch, getState) => {
    const item = getState().models.items[itemId];

    dispatch(updateModel({ modelType: 'items', model: {
      id: item.id,
      quantity: item.quantity - splitCount,
    }}));

    const {
      characterId,
      containerItemId,
      regionId,
      status,
      templateId,
      x,
      y,
    } = item;

    let order = 0;
    if (characterId !== null) {
      // get char inventory items so we can find out the next order index
      const items = createCharacterInventorySelector(characterId)(getState());
      order = items.length;
    } else if (containerItemId !== null) {
      // get container items so we can find out the next order index
      const items = createContainerItemsSelector(containerItemId)(getState());
      order = items.length;
    }

    const splitItems = createItemWithChildren({
      templateId,
      regionId,
      characterId,
      containerItemId,
      slot: null,
      order,
      quantity: splitCount,
      status,
      x,
      y,
    });

    dispatch(addModelsById({ modelType: 'items', models: splitItems }));
  };
}

/**
 * Returns the top door in the list of items.
 */
function getDoor(items) {
  const doors = items.filter(item => item.door);
  return doors[doors.length - 1];
}

export function playerUseKey({ characterId, keyItemId, x, y }) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];
    const keyItem = getState().models.items[keyItemId];

    if (Math.abs(character.x - x) <= 1 && Math.abs(character.y - y) <= 1) {
      const itemsByLocation = createRegionItemsByLocationSelector(character.regionId)(getState());
      const areaItems = itemsByLocation[`${x}&${y}`];

      if (areaItems && areaItems.length > 0) {
        areaItems.forEach(areaItem => {
          if (areaItem.keyId === keyItem.keyId) {
            if (areaItem.status === 'locked') {
              dispatch(updateModel({
                modelType: 'items',
                model: {
                  id: areaItem.id,
                  status: 'closed',
                }
              }));
              dispatch(addMessage({ message: `${character.name} unlocks the ${areaItem.name} using ${keyItem.uniqueName}.` }));
            } else {
              dispatch(closeContainer({
                containerItemId: areaItem.id,
              }));
              dispatch(updateModel({
                modelType: 'items',
                model: {
                  id: areaItem.id,
                  status: 'locked',
                }
              }));
              dispatch(addMessage({ message: `${character.name} locks the ${areaItem.name} using ${keyItem.uniqueName}.` }));
            }
          }
        });
      }
    }
  };
}

export function operateDoor({ characterId, doorItemId, x, y }) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];
    const item = hydrateItem(getState().models.items[doorItemId]);
    const itemsByLocation = createRegionItemsByLocationSelector(character.regionId)(getState());

    let otherSectionY = y;
    if (item.doorSection === 'bottom') {
      otherSectionY = y - 1;
    } else if (item.doorSection === 'top') {
      otherSectionY = y + 1;
    }

    const otherSectionAreaItems = itemsByLocation[`${x}&${otherSectionY}`];
    const otherHalf = getDoor(otherSectionAreaItems);

    const nextStatus = item.status === 'closed' ? 'open' : 'closed';

    const itemNextWalkable = (item.doorSection === 'bottom' && nextStatus === 'open') || item.doorSection === 'top';
    const otherHalfWalkable = (otherHalf.doorSection === 'bottom' && nextStatus === 'open') || otherHalf.doorSection === 'top';

    dispatch(updateModels({
      modelType: 'items',
      models: [
        {
          id: item.id,
          status: nextStatus,
          walkable: itemNextWalkable
        },
        {
          id: otherHalf.id,
          status: nextStatus,
          walkable: otherHalfWalkable
        }
      ]
    }));
  };
}

export function operateContainer({ containerItemId }) {
  return async (dispatch, getState) => {
    const item = hydrateItem(getState().models.items[containerItemId]);
    if (item.status === 'closed') {
      dispatch(openContainer({ containerItemId: item.id }));
    } else if (item.status === 'open') {
      dispatch(closeContainer({ containerItemId: item.id }));
    } else if (item.status === 'locked') {
      dispatch(addMessage({ message: `The ${item.name} is locked.` }));
    }
  };
}

export function playerUse({ characterId, x, y }) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];

    if (Math.abs(character.x - x) <= 1 && Math.abs(character.y - y) <= 1) {
      const itemsByLocation = createRegionItemsByLocationSelector(character.regionId)(getState());
      const areaItems = itemsByLocation[`${x}&${y}`];

      if (areaItems && areaItems.length > 0) {
        const topItemId = areaItems[areaItems.length - 1].id;

        const item = hydrateItem(getState().models.items[topItemId]);
        // Using a container should happen on mouse up.
        if (item.door) {
          dispatch(operateDoor({ characterId, doorItemId: item.id, x, y }));
        } else if (item.container) {
          dispatch(operateContainer({ containerItemId: item.id }));
        } else if (item.moveable) {
          dispatch(updateGrabbedItem({ itemId: item.id }));
        }
      }
    }
  };
}

export function playerWalk({ characterId, walkNorth, walkSouth, walkWest, walkEast }) {
  return async (dispatch, getState) => {
    dispatch(updateWalkDirections({
      walkNorth,
      walkSouth,
      walkWest,
      walkEast
    }));

    const moving = (
      getState().game.walkNorth ||
      getState().game.walkSouth ||
      getState().game.walkWest ||
      getState().game.walkEast
    );

    if (getState().game.timeMode === TimeModes.NORMAL) {
      if (!isInputWalking() && moving) {
        createInputDirector();
      } else if (isInputWalking() && !moving) {
        destroyInputDirector();

        await sleep(Math.floor(Math.random() * 250) + 500);
        dispatch(updateModel({
          modelType: 'characters',
          model: {
            id: characterId,
            pose: REST,
          },
        }));
      }
    } else if (getState().game.actingCharacterId === characterId && moving) {
      // This means the game is in a paused or combat state, it's the character's turn,
      // and they're moving.
      createInputDirector({ listen: false });
      await sleep(250);
      dispatch(endCharacterTurn({ actingCharacterId: characterId }));
    }
  };
}

export function playerChangePartyMode({ partyMode, characterId }) {
  return async (dispatch, getState) => {

    const activeCharacter = activeCharacterSelector(getState());
    const leaderCharacter = leaderCharacerSelector(getState());

    const nextActiveCharacterId = partyMode === PartyModes.ALL ? leaderCharacter.id : characterId;
    const nextActiveCharacter = getState().models.characters[nextActiveCharacterId];

    const transferRegion = nextActiveCharacter.regionId !== activeCharacter.regionId;

    if (transferRegion) {
      await dispatch(unloadActiveRegion());
    }

    dispatch(updateActiveCharacter({ characterId: nextActiveCharacter.id }));
    dispatch(updatePartyMode({ partyMode }));

    if (transferRegion) {
      await dispatch(loadActiveRegion({ regionId: nextActiveCharacter.regionId }));
    }
  };
}
