/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { PhaserState, PhaserStatuses } from './types';

export const initialState: PhaserState = {
  gameStatus: PhaserStatuses.UNLOADED,
  regionStatus: PhaserStatuses.UNLOADED,
};

const slice = createSlice({
  name: 'phaser',
  initialState,
  reducers: {
    updateGameStatus: (state: PhaserState, action) => {
      state.gameStatus = action.payload.gameStatus || state.gameStatus;
    },
    updateRegionStatus: (state: PhaserState, action) => {
      state.regionStatus = action.payload.regionStatus || state.regionStatus;
    }
  },
});

export const {
  updateGameStatus,
  updateRegionStatus,
} = slice.actions;

export const { reducer } = slice;
