import { useDispatch } from 'react-redux';
import { loadNewGame } from '../../saves/data/thunks';
import { Button } from '../generic';

export default function NewButton({ story }) {
  const dispatch = useDispatch();

  const handleNew = () => {
    dispatch(loadNewGame(story));
  };

  return (
    <Button className="mb-2" style={{ width: 160 }} onClick={handleNew}>New</Button>
  );
}
