import { hydrateItem } from '../../../data/contentUtils';
import { createContainerItemsSelector } from '../../../data/selectors';
import { sleep } from '../../../data/utils';
import { updateModels } from '../../../models-store';
import { addMessage } from '../../../ui/chronicle';
import { clearGrabbedItem, updateGrabbedItem } from '../../../ui/data/slice';
import { createCharacterInventorySelector } from './selectors';

export function pickUpItem({ characterId, itemId }) {
  return async (dispatch, getState) => {
    const item = hydrateItem(getState().models.items[itemId]);
    const character = getState().models.characters[characterId];
    const inventoryItems = createCharacterInventorySelector(characterId)(getState());

    if (item.allowances.inventory) {
      dispatch(addMessage({ message: `${character.name} picks up ${item.determiner} ${item.name}.` }));
      dispatch(updateModels({
        modelType: 'items',
        models: [
          // move the equipped item out of the slot and into inventory at the last index
          {
            id: item.id,
            characterId,
            containerItemId: null,
            regionId: null,
            x: null,
            y: null,
            slot: null,
            order: inventoryItems.length,
          },
        ],
      }));
    } else {
      dispatch(addMessage({ message: `${character.name} cannot pick up the ${item.name}.` }));
    }
  };
}

export function addItemToContainer({ containerItemId, itemId }) {
  return async (dispatch, getState) => {
    const item = hydrateItem(getState().models.items[itemId]);

    const containerItems = createContainerItemsSelector(containerItemId)(getState());
    if (item.characterId) {
      dispatch(removeItemFromInventory({ itemId }));
    }

    // Move to where it's supposed to be.
    dispatch(updateModels({
      modelType: 'items',
      models: [
        // move the equipped item out of the slot and into inventory at the last index
        {
          id: item.id,
          characterId: null,
          containerItemId,
          regionId: null,
          x: null,
          y: null,
          slot: null,
          order: containerItems.length,
        },
      ],
    }));

  };
}

export function replaceEquippedItem({ characterId, slot, equippedItemId, replacementItemId }) {
  return async (dispatch, getState) => {
    const equippedItem = getState().models.items[equippedItemId];
    const replacementItem = getState().models.items[replacementItemId];

    dispatch(clearGrabbedItem());
    dispatch(updateModels({
      modelType: 'items',
      models: [
        // move this one to the inventory where the replacementItem was.
        {
          id: equippedItem.id,
          order: replacementItem.order,
          slot: null,
        },
        // move this one to the slot
        {
          id: replacementItem.id,
          slot,
          order: null,
          regionId: null,
          x: null,
          y: null,
          containerItemId: null,
          characterId,
        }
      ],
    }));
    dispatch(updateGrabbedItem({ itemId: equippedItemId }));
  };
}

export function removeItemFromInventory({ itemId }) {
  return async (dispatch, getState) => {
    const item = hydrateItem(getState().models.items[itemId]);
    let updatedInventoryItems = [];
    if (item.characterId) {
      const holdingCharacter = getState().models.characters[item.characterId];
      const inventoryItems = createCharacterInventorySelector(holdingCharacter.id)(getState());

      // Filter the item from inventory - it may not be in there, but whatever.
      updatedInventoryItems = inventoryItems
        .filter((inventoryItem) => inventoryItem.id !== item.id)
        .map((inventoryItem, index) => {
          return {
            id: inventoryItem.id,
            order: index,
          };
        });
    }

    dispatch(updateModels({
      modelType: 'items',
      models: [
        {
          id: item.id,
          slot: null,
          order: null,
          characterId: null,
          containerItemId: null,
          regionId: null,
          x: null,
          y: null,
        },
        ...updatedInventoryItems
      ],
    }));
  };
}

export function dropItem({ itemId, characterId, regionId, x, y }) {
  return async (dispatch, getState) => {
    const item = hydrateItem(getState().models.items[itemId]);

    dispatch(removeItemFromInventory({ itemId }));

    // Let the above store updates finish before we change the item position to throw it.
    await sleep(1);

    const droppingCharacter = getState().models.characters[characterId];
    dispatch(updateModels({
      modelType: 'items',
      models: [
        {
          id: item.id,
          regionId,
          x: droppingCharacter.x,
          y: droppingCharacter.y,
        },
      ],
    }));

    await sleep(1);
    // Move to where it's supposed to be.
    dispatch(updateModels({
      modelType: 'items',
      models: [
        {
          id: item.id,
          regionId,
          x,
          y,
        },
      ],
    }));
  };
}

export function equipItem({ characterId, slot, itemId }) {
  return async (dispatch, getState) => {
    const item = getState().models.items[itemId];

    const inventoryItems = createCharacterInventorySelector(characterId)(getState());
    // Re-assigns order variables to all the items based on their index in the filtered list.
    const updatedInventoryItems = inventoryItems
      .filter((inventoryItem) => inventoryItem.id !== item.id)
      .map((inventoryItem, index) => {
        return {
          id: inventoryItem.id,
          order: index,
        };
      });

    dispatch(clearGrabbedItem());
    dispatch(updateModels({
      modelType: 'items',
      models: [
        // move this one to the slot
        {
          id: item.id,
          slot,
          order: null,
          regionId: null,
          x: null,
          y: null,
          containerItemId: null,
          characterId,
        },
        ...updatedInventoryItems
      ],
    }));
  };
}

export function unequipItem({ characterId, equippedItemId }) {
  return async (dispatch, getState) => {
    const equippedItem = getState().models.items[equippedItemId];
    const inventoryItems = createCharacterInventorySelector(characterId)(getState());
    // Re-assigns order variables to all the items by bumping them all up by 1 to make room at 0
    // for the newly unequipped item.
    const updatedInventoryItems = inventoryItems
      .map((item, index) => {
        return {
          id: item.id,
          order: index + 1,
        };
      });
    dispatch(updateModels({
      modelType: 'items',
      models: [
        // move the equipped item out of the slot and into inventory at index 0
        {
          id: equippedItem.id,
          slot: null,
          order: 0,
        },
        ...updatedInventoryItems
      ],
    }));
    dispatch(updateGrabbedItem({ itemId: equippedItemId }));
  };
}

export function transferItem({
  giverCharacterId,
  receiverCharacterId,
  itemId,
}) {
  return async (dispatch, getState) => {
    const item = getState().models.items[itemId];
    const giverInventoryItems = createCharacterInventorySelector(giverCharacterId)(getState());
    const receiverInventoryItems = createCharacterInventorySelector(receiverCharacterId)(getState());

    // Re-assigns order variables to all the items by bumping them all up by 1 to make room at 0
    // for the newly unequipped item.
    const updatedGiverInventoryItems = giverInventoryItems.filter((giverItem) => giverItem.id !== item.id)
      .map((giverItem, index) => {
        return {
          id: giverItem.id,
          order: index,
        };
      });
    const updatedReceiverInventoryItems = receiverInventoryItems.map((receiverItem, index) => {
      return {
        id: receiverItem.id,
        order: index + 1,
      };
    });

    dispatch(updateModels({
      modelType: 'items',
      models: [
        {
          id: item.id,
          order: 0,
          characterId: receiverCharacterId,
        },
        ...updatedGiverInventoryItems,
        ...updatedReceiverInventoryItems,
      ]
    }));
  };
}

export function giveItemsWithTemplateIds({ giverCharacterId, receiverCharacterId, templateIds }) {
  return async (dispatch, getState) => {
    templateIds.forEach(templateId => {
      dispatch(giveItemWithTemplateId({
        giverCharacterId, receiverCharacterId, templateId
      }));
    });
  };
}

export function giveItemWithTemplateId({ giverCharacterId, receiverCharacterId, templateId }) {
  return async (dispatch, getState) => {
    const giverInventoryItems = createCharacterInventorySelector(giverCharacterId)(getState());
    let item = null;
    giverInventoryItems.forEach(inventoryItem => {
      if (inventoryItem.templateId === templateId) {
        item = inventoryItem;
      }
    });

    if (item !== null) {
      dispatch(transferItem({
        giverCharacterId,
        receiverCharacterId,
        itemId: item.id,
      }));
    }
  };
}

export function purchaseItem({
  buyerCharacterId,
  sellerCharacterId,
  itemId,
}) {
  return async (dispatch, getState) => {
    const item = getState().models.items[itemId];
    const buyer = getState().models.characters[buyerCharacterId];
    const seller = getState().models.characters[sellerCharacterId];

    const buyerPrice = buyer.buys[item.templateId];
    const sellerPrice = seller.sells[item.templateId];

    // NPCs are buyers with prices, NPCs are sellers with prices - we take the NPC's price.
    const price = buyerPrice !== undefined ? buyerPrice : sellerPrice;

    if (buyer.gold < price) {
      // We can't afford this, bail.
      return;
    }

    dispatch(transferItem({
      giverCharacterId: sellerCharacterId,
      receiverCharacterId: buyerCharacterId,
      itemId,
    }));

    dispatch(updateModels({
      modelType: 'characters',
      models: [
        {
          id: buyer.id,
          gold: buyer.gold - price,
        },
        {
          id: seller.id,
          gold: seller.gold + price,
        }
      ]
    }));
  };
}
