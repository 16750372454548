import classNames from 'classnames';

import Character from '../character/Character';
import { SOUTH, REST } from '../character/constants';
import { useCharacter, useClickAttributes } from '../../data/hooks';

export default function Portrait({
  direction = SOUTH,
  className,
  characterId,
  scale = 4,
  onClick = null
}) {
  const character = useCharacter(characterId);

  const _onClick = onClick || (() => {});
  const clickAttributes = useClickAttributes(_onClick);

  const props = {};
  if (onClick) {
    props.role = 'button';
  }

  return (
    <div
      className={classNames(
        'border rounded overflow-hidden bg-white flex-grow-0 flex-shrink-0',
        className
      )}
      {...clickAttributes}
      {...props}
    >
      <Character
        portrait
        scale={scale}
        character={character}
        direction={direction}
        pose={REST}
      />
    </div>
  );
}
