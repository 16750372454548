import Phaser from 'phaser';

import createSelectorListener from '../../engine/createSelectorListener';
import { createRegionFeaturesSelector } from '../data/selectors';
import FeatureSprite from '../sprites/FeatureSprite';

export default class Features extends Phaser.GameObjects.Group {

  constructor(region, elevationLayers, ...args) {
    super(...args);

    this.region = region;
    this.elevationLayers = elevationLayers;
    this.handleFeaturesChanged = this.handleFeaturesChanged.bind(this);
    this.currentFeaturesById = {};

    const { remove, data } = createSelectorListener(
      createRegionFeaturesSelector(this.region.id),
      this.handleFeaturesChanged
    );
    this.handleFeaturesChanged(data);

    this.removeModelListener = remove;
  }

  destroy() {
    this.removeModelListener();
  }

  handleFeaturesChanged(features) {
    const nextCurrentFeaturesById = {};
    const remainingCurrentFeaturesById = { ...this.currentFeaturesById };
    const newFeaturesData = [];

    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      if (remainingCurrentFeaturesById[feature.id] !== undefined) {
        // Pass the sprite to the new list
        nextCurrentFeaturesById[feature.id] = remainingCurrentFeaturesById[feature.id];
        // Delete it in the old list so we can filter down to what's left, which should be retired
        delete remainingCurrentFeaturesById[feature.id];
      } else {
        newFeaturesData.push(feature);
      }
    }

    const retiredFeatures = Object.values(remainingCurrentFeaturesById);

    newFeaturesData.forEach(feature => {
      // create a new FeatureSprite
      const atlas = 'features';
      let frame = feature.templateId;
      if (feature.status) {
        frame += `@${feature.status}`;
      }

      const featureSprite = new FeatureSprite(feature.id, this.scene, 0, 0, atlas, frame);
      nextCurrentFeaturesById[feature.id] = featureSprite;

      const elevation = feature.elevation;
      if (this.elevationLayers[elevation] === undefined) {
        this.elevationLayers[elevation] = this.scene.add.layer();
        this.elevationLayers[elevation].setDepth(elevation);
      }
      this.add(featureSprite);
      // So this is the depth _within_ the layer.  We want features to be below other entities on
      // their elevation.
      featureSprite.setDepth(0);
      this.elevationLayers[elevation].add(featureSprite);
    });

    retiredFeatures.forEach(retiredFeature => {
      // destroy existing feature sprite
      retiredFeature.destroy();
    });

    this.currentFeaturesById = nextCurrentFeaturesById;
  }
}
