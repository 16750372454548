import { getStore } from '../../../data/configureStore';
import { createInterval } from '../../../data/renderLoopUtils';
import { activeCharacterSelector } from '../../../data/selectors';
import { createRegionCharactersSelector } from '../../../world/data/selectors';

import { createListeners, destroyListeners } from './listeners';
import { performMovement } from './performers/movement';

let characterLiveControls = {};

export function createLiveDirector() {

  createListeners();

  const activeCharacter = activeCharacterSelector(getStore().getState());
  const regionCharacters = createRegionCharactersSelector(activeCharacter.regionId)(getStore().getState());

  characterLiveControls = {};

  regionCharacters.forEach(character => {
    characterLiveControls[character.id] = createLivePerformer(character);
    characterLiveControls[character.id].play();
  });
}

function createLivePerformer(character) {
  let defaultDelay = 150;
  return createInterval(defaultDelay, (controls) => {
    const {
      activeConversationCharacterId,
    } = getStore().getState().conversation;
    const inConversation = activeConversationCharacterId === character.id;

    if (inConversation) {
      return;
    }

    performMovement(character, controls);
  });
}

export function destroyLiveDirector() {
  Object.values(characterLiveControls).forEach(controls => {
    controls.abort();
  });
  destroyListeners();
}
