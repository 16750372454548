import featuresImage from '../../spritesheets/features.png';

const featureTemplates = [
  {
    id: 'peach-nw',
    name: 'Peach Tree',
    image: {
      src: featuresImage,
      position: { x: 6, y: 0 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'peach-n',
    name: 'Peach Tree',
    image: {
      src: featuresImage,
      position: { x: 7, y: 0 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'peach-ne',
    name: 'Peach Tree',
    image: {
      src: featuresImage,
      position: { x: 8, y: 0 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'peach-w',
    name: 'Peach Tree',
    image: {
      src: featuresImage,
      position: { x: 6, y: 1 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'peach-center',
    name: 'Peach Tree',
    image: {
      src: featuresImage,
      position: { x: 7, y: 1 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'peach-e',
    name: 'Peach Tree',
    image: {
      src: featuresImage,
      position: { x: 8, y: 1 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'peach-sw',
    name: 'Peach Tree',
    image: {
      src: featuresImage,
      position: { x: 6, y: 2 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'peach-s',
    name: 'Peach Tree',
    image: {
      src: featuresImage,
      position: { x: 7, y: 2 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'peach-se',
    name: 'Peach Tree',
    image: {
      src: featuresImage,
      position: { x: 8, y: 2 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'peach-trunk',
    name: 'Peach Tree',
    image: {
      src: featuresImage,
      position: { x: 7, y: 3 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
];

export default featureTemplates;
