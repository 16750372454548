/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ChronicleState } from './types';

export const initialState: ChronicleState = {
  messages: [],
};

const slice = createSlice({
  name: 'chronicle',
  initialState,
  reducers: {
    addMessage: (state: ChronicleState, action) => {
      const { payload } = action;
      const { message } = payload;

      state.messages = [
        ...state.messages,
        {
          id: uuidv4(),
          message,
        }
      ];
    },
    clearMessages: (state: ChronicleState, action) => {
      state.messages = [];
    },
  },
});

export const {
  addMessage,
  clearMessages,
} = slice.actions;

export const { reducer } = slice;
