import classNames from 'classnames';
import ActivityIcon from '../generic/ActivityIcon';

export default function Statistic({ className, statId, children }) {
  return (
    <div className={classNames(className, 'd-flex gap-1 align-items-center justify-content-start')}>
      <ActivityIcon activityId={statId} scale={2} />
      <div className="d-flex justify-content-end flex-grow-0">
        {children}
      </div>
    </div>
  );
}
