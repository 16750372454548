import { changeConversationTopic } from './slice';
import { trade } from '../../trade';
import { endConversation } from '..';
import { talkToInnkeeper } from '../../inn/data/thunks';
import { endActivity, startActivity } from '../../data/thunks';
import { CombatStatuses, startCombat } from '../../combat';
import { addPartyMember, removePartyMember, updateActingCharacter } from '../../../../data/slice';
import { ActivityTypes } from '../../data/types';
import { updateModel } from '../../../../models-store';
import { updateRelationship } from '../../../../characters/relationships/data/thunks';
import { updateMovement } from '../../../../characters/movement/data/thunks';
import { giveItemsWithTemplateIds } from '../../../../characters/items/data/thunks';
import { getCutscene } from '../../../../content-utils/cutsceneUtils';
import { cutsceneStepComplete } from '../../../../engine/directors/cutscene/stepComplete';

export function selectConversationChoice({ choice }) {
  return async (dispatch, getState) => {
    const { action } = choice;

    switch(action) {
      case 'TRADE':
        dispatch(performTradeActionChoice());
        break;
      case 'INN':
        dispatch(performInnActionChoice());
        break;
      case 'END':
        dispatch(performEndActionChoice());
        break;
      case 'COMBAT':
        dispatch(performCombatActionChoice());
        break;
      case 'JOIN_PARTY':
        dispatch(performJoinPartyActionChoice({ choice }));
        break;
      case 'LEAVE_PARTY':
        dispatch(performLeavePartyActionChoice({ choice }));
        break;
      case 'ADD_STORY_MILESTONE':
        dispatch(performAddStoryMilestoneActionChoice({ choice }));
        break;
      case 'UPDATE_RELATIONSHIP':
        dispatch(performUpdateRelationshipActionChoice({ choice }));
        break;
      case 'UPDATE_MOVEMENT':
        dispatch(performUpdateMovementActionChoice({ choice }));
        break;
      case 'GIVE_PLAYER_ITEMS':
        dispatch(performGivePlayerItemsActionChoice({ choice }));
        break;
      case 'TAKE_PLAYER_ITEMS':
        dispatch(performTakePlayerItemsActionChoice({ choice }));
        break;
      case 'LEARN_NAME':
        dispatch(performLearnNameActionChoice({ choice }));
        break;
      case 'LINK':
        dispatch(changeConversationTopic({ linkedTopicId: choice.linkedTopicId }));
        return;
      default:
        console.error(`Unexpected conversation action type: ${action}`);
    }
  };
}

export function performTradeActionChoice() {
  return async (dispatch, getState) => {
    const {
      activeConversationCharacterId,
    } = getState().conversation;
    dispatch(trade({ tradePartnerId: activeConversationCharacterId }));
    dispatch(endConversation());
  };
}

export function performInnActionChoice() {
  return async (dispatch, getState) => {
    const {
      activeConversationCharacterId,
    } = getState().conversation;
    dispatch(talkToInnkeeper({ innkeeperId: activeConversationCharacterId }));
    dispatch(endConversation());
  };
}

export function performEndActionChoice() {
  return async (dispatch, getState) => {
    dispatch(endActivity());
    dispatch(endConversation());

    const { activeCutsceneId, activeCutsceneStep } = getState().game;

    if (activeCutsceneId !== null && activeCutsceneStep !== null) {
      const cutscene = getCutscene(activeCutsceneId);
      const step = cutscene.steps[activeCutsceneStep];
      if (step.action === 'conversation') {
        dispatch(cutsceneStepComplete());
      }
    }
  };
}

export function performCombatActionChoice() {
  return async (dispatch, getState) => {
    const {
      activeCharacterId
    } = getState().game;
    const {
      activeConversationCharacterId,
    } = getState().conversation;
    dispatch(startCombat({
      opponentId: activeConversationCharacterId,
      distance: 1,
      status: CombatStatuses.FIGHTING,
    }));
    dispatch(updateActingCharacter({ characterId: activeCharacterId }));
    dispatch(startActivity({ activity: ActivityTypes.COMBAT }));
    dispatch(endConversation());
  };
}

export function performJoinPartyActionChoice({ choice }) {
  return async (dispatch, getState) => {
    const {
      linkedTopicId
    } = choice;
    const {
      partyCharacterIds
    } = getState().game;
    const {
      activeConversationCharacterId
    } = getState().conversation;

    const nextPosition = partyCharacterIds.length;

    dispatch(updateModel({
      modelType: 'characters',
      model: {
        id: activeConversationCharacterId,
        movement: {
          type: 'PARTY',
          position: nextPosition,
        }
      }
    }));
    dispatch(addPartyMember({ characterId: activeConversationCharacterId }));

    dispatch(changeConversationTopic({ linkedTopicId }));
  };
}

export function performLeavePartyActionChoice({ choice }) {
  return async (dispatch, getState) => {
    const {
      linkedTopicId
    } = choice;
    const {
      activeConversationCharacterId
    } = getState().conversation;

    dispatch(updateModel({
      modelType: 'characters',
      model: {
        id: activeConversationCharacterId,
        movement: {
          type: 'STATIONARY',
          position: null,
        }
      }
    }));
    dispatch(removePartyMember({ characterId: activeConversationCharacterId }));

    dispatch(changeConversationTopic({ linkedTopicId }));
  };
}

export function performAddStoryMilestoneActionChoice({ choice }) {
  return async (dispatch, getState) => {
    const { milestone, linkedTopicId } = choice;
    const { activeStoryId } = getState().game;
    const story = getState().models.stories[activeStoryId];

    dispatch(updateModel({
      modelType: 'stories',
      model: {
        milestones: [
          ...story.milestones,
          milestone,
        ],
      }
    }));

    dispatch(changeConversationTopic({ linkedTopicId }));
  };
}

export function performUpdateRelationshipActionChoice({ choice }) {
  return async (dispatch, getState) => {
    const { levelChange, conversationStatus, linkedTopicId } = choice;
    const {
      activeCharacterId
    } = getState().game;
    const {
      activeConversationCharacterId
    } = getState().conversation;
    const activeConversationCharacter = getState().models.characters[activeConversationCharacterId];

    const relationship = activeConversationCharacter.relationships[activeCharacterId] || {
      conversationStatus: 'NEUTRAL',
      level: 0,
      knownName: false,
    };

    // We're updating the relationship on the CHARACTER to say what they think of the PLAYER.
    dispatch(updateRelationship({
      characterId: activeConversationCharacterId,
      relatedCharacterId: activeCharacterId,
      conversationStatus,
      level: relationship.level + levelChange,
    }));

    dispatch(changeConversationTopic({ linkedTopicId }));
  };
}

export function performUpdateMovementActionChoice({ choice }) {
  return async (dispatch, getState) => {
    const {
      movement,
      linkedTopicId
    } = choice;
    const {
      activeConversationCharacterId
    } = getState().conversation;

    dispatch(updateMovement({
      characterId: activeConversationCharacterId,
      movement,
    }));

    dispatch(changeConversationTopic({ linkedTopicId }));
  };
}
export function performGivePlayerItemsActionChoice({ choice }) {
  return async (dispatch, getState) => {
    const { templateIds, linkedTopicId } = choice;
    const {
      activeCharacterId
    } = getState().game;
    const {
      activeConversationCharacterId
    } = getState().conversation;

    dispatch(giveItemsWithTemplateIds({
      giverCharacterId: activeConversationCharacterId,
      receiverCharacterId: activeCharacterId,
      templateIds
    }));

    dispatch(changeConversationTopic({ linkedTopicId }));
  };
}
export function performTakePlayerItemsActionChoice({ choice }) {
  return async (dispatch, getState) => {
    const { templateIds, linkedTopicId } = choice;
    const {
      activeCharacterId
    } = getState().game;
    const {
      activeConversationCharacterId
    } = getState().conversation;

    dispatch(giveItemsWithTemplateIds({
      giverCharacterId: activeCharacterId,
      receiverCharacterId: activeConversationCharacterId,
      templateIds
    }));

    dispatch(changeConversationTopic({ linkedTopicId }));
  };
}
export function performLearnNameActionChoice({ choice }) {
  return async (dispatch, getState) => {
    const {
      linkedTopicId
    } = choice;
    const {
      activeCharacterId
    } = getState().game;
    const {
      activeConversationCharacterId
    } = getState().conversation;

    // We're updating the relationship on the PLAYER to say they know this CHARACTER's name.
    dispatch(updateRelationship({
      characterId: activeCharacterId,
      relatedCharacterId: activeConversationCharacterId,
      knownName: true,
    }));

    dispatch(changeConversationTopic({ linkedTopicId }));
  };
}
