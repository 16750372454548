import { useSelector, useDispatch } from 'react-redux';
import Markdown from 'react-markdown';
import classNames from 'classnames';
import { useCallback } from 'react';

import { getConversation } from '../../../content-utils/conversationUtils';
import { Fader } from '../../generic';
import ConversationChoice from './ConversationChoice';
import { createFilteredConversationChoicesSelector } from './data/selectors';
import { selectConversationChoice } from './data/thunks';
import { useGame } from '../../../data/hooks';

export default function Conversation({ conversationId, children, className }) {
  const dispatch = useDispatch();
  const {
    activeCharacterId,
  } = useGame();
  const {
    activeConversationTopicId,
    activeConversationCharacterId,
  } = useSelector(state => state.conversation);

  const conversation = getConversation(conversationId);
  const conversationTopic = conversation[activeConversationTopicId];
  const filteredConversationChoices = useSelector(createFilteredConversationChoicesSelector(
    activeConversationCharacterId,
    activeCharacterId,
    conversationTopic.choices
  ));

  const handleChoiceClick = useCallback((choice) => {
    dispatch(selectConversationChoice({ choice }));
  }, [dispatch]);

  return (
    <section className={classNames('conversation', className)}>
      <Fader speed="0.25s" ready key={activeConversationTopicId}>
        <Markdown>{conversationTopic.content}</Markdown>
        <Fader delay={0.5} ready key={activeConversationTopicId}>
          <ol>
            {filteredConversationChoices.map(choice => {
              return (
                <ConversationChoice
                  key={`${JSON.stringify(choice)}`}
                  choice={choice}
                  onChoiceClick={handleChoiceClick}
                />
              );
            })}
            {children}
          </ol>
        </Fader>
      </Fader>
    </section>
  );
}
