import { transferItem } from '../../../../characters/items/data/thunks';
import { activeCharacterSelector } from '../../../../data/selectors';
import { updateModels } from '../../../../models-store';
import { createModelSelector } from '../../../../models-store/selectors';

import { endActivity, startActivity } from '../../data/thunks';
import { ActivityTypes } from '../../data/types';

import { buyPriceSelector, sellPriceSelector } from './selectors';
import { clearTradeItems, clearTradePartnerId, updateTradePartnerId } from './slice';

export function trade({ tradePartnerId }) {
  return async (dispatch, getState) => {
    dispatch(updateTradePartnerId({ tradePartnerId }));
    dispatch(startActivity({ activity: ActivityTypes.TRADE }));
  };
}

export function stopTrading() {
  return async (dispatch, getState) => {
    dispatch(endActivity({ activity: ActivityTypes.TRADE }));
    dispatch(clearTradeItems());
    dispatch(clearTradePartnerId());
  };
}

export function completeTrade() {
  return async (dispatch, getState) => {
    let sellPrice = sellPriceSelector(getState());
    let buyPrice = buyPriceSelector(getState());

    const activeCharacter = activeCharacterSelector(getState());
    const {
      tradePartnerId, sellItemIds, buyItemIds
    } = getState().trade;

    const tradePartner = createModelSelector('characters', tradePartnerId)(getState());
    const cost = buyPrice - sellPrice;

    if (cost > 0) {
      if(activeCharacter.gold < cost) {
        // We can't afford this, bail!
        return;
      }
    } else if (cost < 0) {
      if(tradePartner.gold < Math.abs(cost)) {
        // Our trade partner can't afford this, bail!
        return;
      }
    }

    dispatch(updateModels({
      modelType: 'characters',
      models: [
        {
          id: tradePartner.id,
          gold: tradePartner.gold + cost, // cost is negative if the partner is spending money.
        },
        {
          id: activeCharacter.id,
          gold: activeCharacter.gold - cost, // The cost is positive if we're spending money.
        }
      ]
    }));

    sellItemIds.forEach(itemId => {
      dispatch(transferItem({
        giverCharacterId: activeCharacter.id,
        receiverCharacterId: tradePartnerId,
        itemId,
      }));
    });

    buyItemIds.forEach(itemId => {
      dispatch(transferItem({
        giverCharacterId: tradePartnerId,
        receiverCharacterId: activeCharacter.id,
        itemId,
      }));
    });

    dispatch(clearTradeItems());
  };
}
