/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  BulkModelIdsAction,
  BulkModelsAction,
  Model,
  ModelAction,
  ModelIdAction,
  ModelIdsAction,
  ModelsAction,
  ModelsPayload,
  ModelsState,
  ModelTypeAction
} from './types';

export const initialState: ModelsState = {};

const slice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    addModel: (state: ModelsState, action: ModelAction) => {
      const { payload } = action;
      const { modelType, model } = payload;

      if (state[modelType] === undefined) {
        state[modelType] = {};
      }

      state[modelType][model.id] = model;
    },
    addModels: (state: ModelsState, action: ModelsAction) => {
      const { payload } = action;
      const { modelType, models } = payload;

      if (state[modelType] === undefined) {
        state[modelType] = {};
      }

      models.forEach((model) => { state[modelType][model.id] = model });
    },
    addModelsById: (state: ModelsState, action: ModelsAction) => {
      const { payload } = action;
      const {
        modelType,
        models
      } : ModelsPayload = payload;

      if (state[modelType] === undefined) {
        state[modelType] = {};
      }

      Object.values(models).forEach((model: Model) => { state[modelType][model.id] = model });
    },
    addBulkModels: (state: ModelsState, action: BulkModelsAction) => {
      const { payload } = action;
      const { modelsByModelType } = payload;

      const modelTypes = Object.keys(modelsByModelType);
      modelTypes.forEach((modelType) => {
        if (state[modelType] === undefined) {
          state[modelType] = {};
        }
        const models = modelsByModelType[modelType];
        models.forEach((model) => { state[modelType][model.id] = model });
      });
    },
    updateModel: (state: ModelsState, action: ModelAction) => {
      const { payload } = action;
      const { modelType, model } = payload;

      if (state[modelType] === undefined) {
        state[modelType] = {};
      }

      state[modelType][model.id] = { ...state[modelType][model.id], ...model };
    },
    updateModels: (state: ModelsState, action: ModelsAction) => {
      const { payload } = action;
      const { modelType, models } = payload;

      if (state[modelType] === undefined) {
        state[modelType] = {};
      }

      models.forEach((model) => { state[modelType][model.id] = { ...state[modelType][model.id], ...model } });
    },
    updateModelsById: (state: ModelsState, action: ModelsAction) => {
      const { payload } = action;
      const { modelType, models } = payload;

      if (state[modelType] === undefined) {
        state[modelType] = {};
      }

      Object.values(models).forEach((model) => {
        state[modelType][model.id] = {
          ...state[modelType][model.id],
          ...model
        };
      });
    },
    removeModel: (state: ModelsState, action: ModelIdAction) => {
      const { payload } = action;
      const { modelType, id } = payload;

      if (state[modelType] === undefined) {
        state[modelType] = {};
      }

      delete state[modelType][id];
    },
    removeModels: (state: ModelsState, action: ModelIdsAction) => {
      const { payload } = action;
      const { modelType, ids } = payload;

      if (state[modelType] === undefined) {
        state[modelType] = {};
      }

      ids.forEach((id) => { delete state[modelType][id] });
    },
    removeBulkModels: (state: ModelsState, action: BulkModelIdsAction) => {
      const { payload } = action;
      const { modelIdsByModelType } = payload;

      const modelTypes = Object.keys(modelIdsByModelType);
      modelTypes.forEach((modelType) => {
        if (state[modelType] === undefined) {
          state[modelType] = {};
        }
        const modelIds = modelIdsByModelType[modelType];
        modelIds.forEach((id) => { delete state[modelType][id] });
      });
    },
    truncateModelType: (state: ModelsState, action: ModelTypeAction) => {
      const { payload } = action;
      const { modelType } = payload;

      state[modelType] = {};
    },
  },
});

export const {
  addModel,
  addModels,
  addModelsById,
  addBulkModels,
  updateModel,
  updateModels,
  updateModelsById,
  removeModel,
  removeModels,
  removeBulkModels,
  truncateModelType,
} = slice.actions;

export const { reducer } = slice;
