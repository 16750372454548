import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { useContainerItems, useGrabbedItemId, useItem } from '../../data/hooks';
import { updateGrabbedItem } from '../data/slice';
import { closeContainer } from '../../player/data/thunks';
import { DraggablePanel, Well, Grid } from '../generic';
import ContainerItem from './ContainerItem';
import ActivityIconButton from '../generic/ActivityIconButton';

export default function ContainerContents({ containerItemId }) {
  const grabbedItemId = useGrabbedItemId();
  const panelId = `container-${containerItemId}`;

  const container = useItem(containerItemId);
  const items = useContainerItems(containerItemId);

  const partialRowCount = (4 - (items.length % 4)) + 8;

  for (let i = 0; i < partialRowCount; i++) {
    items.push({
      id: `empty-${i + items.length}`,
      empty: true
    });
  }

  const dispatch = useDispatch();

  const handleContainerClose = (event) => {
    dispatch(closeContainer({ containerItemId }));
  };

  const handleUse = () => {
    dispatch(updateGrabbedItem({
      itemId: containerItemId
    }));
    dispatch(closeContainer({ containerItemId }));
  };

  const headerItems = [];
  if (container.allowances.inventory) {
    headerItems.push(
      <ActivityIconButton key="use" activityId="use" onClick={handleUse} scale={1} className="p-2" />
    );
  }

  return (
    <DraggablePanel
      id={panelId}
      title={container.name}
      onClose={handleContainerClose}
      headerItems={headerItems}
    >
      <Well
        className={classNames(
          'overflow-y-auto w-container',
          { 'cursor-pointer': grabbedItemId !== null && grabbedItemId !== containerItemId }
        )}
        style={{ height: 200 }}
      >
        <Grid>
          {items.map((item) => {
            return (
              <ContainerItem
                key={item.id}
                containerItemId={containerItemId}
                item={item}
                isGrabbed={grabbedItemId === item.id}
              />
            );
          })}
        </Grid>
      </Well>
    </DraggablePanel>
  );
}
