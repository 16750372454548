import { hydrateFeature } from '../../data/contentUtils';
import GameSprite from './GameSprite';

export default class FeatureSprite extends GameSprite {
  constructor(featureId, ...args) {
    super(featureId, 'features', ...args);

    this.targetX = null;
    this.targetY = null;
    this.originalX = null;
    this.originalY = null;

    this.initialize();
  }

  setTargetPosition(x, y) {
    // Maybe limit how far away this target position can be, but do it in the thunk where we assign the new location.  That way characters are limited in how far they can throw.
    this.targetX = x + 8;
    this.targetY = y + 8;
    this.originalX = this.x;
    this.originalY = this.y;
  }

  setModelFrame() {
    const feature = hydrateFeature(this.model);
    let frame = feature.templateId;
    if (feature.status) {
      frame += `@${feature.status}`;
    }
    this.setFrame(frame);
  }

  setModelDepth() {
    const nextDepth = this.model.y + 0.5; // This 0.5 ensures that features are always shown above other entities at this elevation and at this 'y' value.
    if (nextDepth !== this.depth) {
      this.setDepth(nextDepth);
    }
  }
}
