import characterData from './characters';
import conversations from './conversations';
import cutscenes from './cutscenes';
import narratives from './narratives';
import regionData from './regions';

import assets from '../assets';

const { tilesets, templates, spritesheets, splash } = assets;

// TODO: This structure has "story" under "story".  That's silly.
const story = {
  characterData,
  conversations,
  cutscenes,
  narratives,
  regionData,
  tilesets,
  templates,
  spritesheets,
  story: {
    id: 'myth',
    title: 'Myth',
    startCutsceneId: 'introduction',
    milestones: [],
    splash: {
      wander: true,
      background: splash,
    },
  }
};

export default story;
