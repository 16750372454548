import { useDispatch } from 'react-redux';

import { updateShowSettingsMenu } from '../data/slice';
import { Button } from '../generic';

export default function SettingsButton() {
  const dispatch = useDispatch();

  const handleSettings = () => {
    dispatch(updateShowSettingsMenu({ showSettingsMenu: true }));
  };

  return (
    <Button style={{ width: 160 }} className="mb-2" onClick={handleSettings}>Settings</Button>
  );
}
