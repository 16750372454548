import { getStore } from '../../data/configureStore';
import { updateActingCharacter, updateActiveCutsceneId, updateActiveCutsceneStep, updateTimeMode } from '../../data/slice';
import { TimeModes } from '../../data/types';
import { createCutsceneDirector } from '../directors/cutscene/director';
import { destroyLiveDirector } from '../directors/live/director';
import { destroySequenceDirector } from '../directors/sequence/director';

export function playCutscene(cutsceneId) {
  destroyLiveDirector();
  destroySequenceDirector();

  const { dispatch } = getStore();

  dispatch(updateActiveCutsceneStep({ cutsceneStep: 0 }));
  dispatch(updateActiveCutsceneId({ cutsceneId }));
  dispatch(updateActingCharacter({ characterId: null }));
  dispatch(updateTimeMode({ timeMode: TimeModes.CUTSCENE }));

  createCutsceneDirector();
}
