const cutscenes = {
  introduction: {
    id: 'introduction',
    steps: [
      {
        action: 'viewpoint',
        characterId: 'horace',
      },
      {
        action: 'narrative',
        narrativeId: 'introduction',
      },
      {
        action: 'movement',
        characterId: 'horace',
        destination: {
          x: 41,
          y: 22,
        }
      },
      {
        action: 'conversation',
        conversationPartnerId: 'horace',
        conversationId: 'horaceIntroduction',
      },
      {
        action: 'viewpoint',
        characterId: null,
      },
    ]
  }
};

export default cutscenes;
