import Phaser from 'phaser';
import memoize from 'memoize-one';

import createSelectorListener from '../../engine/createSelectorListener';
import { gameSelector, mainViewportCharacterIdSelector } from '../../data/selectors';
import { createModelSelector } from '../../models-store/selectors';
import { calculateFinalViewpoint } from '../data/utils';
import { AREA_SIZE } from '../data/constants';
import { getWall } from '../../content-utils/terrainUtils';

export default class Walls extends Phaser.GameObjects.Group {
  constructor(region, elevationLayers, ...args) {
    super(...args);

    this.layerName = 'walls';
    this.atlas = 'walls';

    this.region = region;
    this.elevationLayers = elevationLayers;
    this.handleStateChanged = this.handleStateChanged.bind(this);

    this.initialize();
  }

  initialize() {
    const { remove, data } = createSelectorListener(
      state => state,
      this.handleStateChanged
    );

    this.removeStateListener = remove;
    this.state = data;

    this.areaSpritesByLocation = {};

    this.handleStateChanged(this.state);

    this.updateTerrains = memoize(this.updateTerrains);
  }

  destroy() {
    this.removeStateListener();
    super.destroy();
  }

  handleStateChanged(state) {
    this.state = state;

    this.checkState(state);
  }

  checkState(state) {
    if (this.scene.cameras.main) {
      const { zoom } = gameSelector(state);

      const viewpointCharacterId = mainViewportCharacterIdSelector(state);
      const viewpointCharacter = createModelSelector('characters', viewpointCharacterId)(state);
      const cameraWidth = this.scene.cameras.main.width;
      const cameraHeight = this.scene.cameras.main.height;
      this.updateTerrains(zoom, cameraWidth, cameraHeight, this.region.map.areas, viewpointCharacter);
    }
  }

  updateTerrains(zoom, cameraWidth, cameraHeight, areas, viewpointCharacter) {
    const radiusX = Math.ceil(cameraWidth / 2 / zoom / AREA_SIZE) + 4;
    const radiusY = Math.ceil(cameraHeight / 2 / zoom / AREA_SIZE) + 4;
    const finalViewpoint = calculateFinalViewpoint(
      viewpointCharacter,
      zoom,
      cameraWidth,
      cameraHeight,
      this.region.map.width * AREA_SIZE,
      this.region.map.height * AREA_SIZE,
      this.region,
    );

    const minX = finalViewpoint.x - radiusX;
    const maxX = finalViewpoint.x + radiusX;
    const minY = finalViewpoint.y - radiusY;
    const maxY = finalViewpoint.y + radiusY;

    for (let x = minX; x <= maxX; x++) {
      for (let y = minY; y <= maxY; y++) {

        let area = areas[`${x}&${y}`];
        if (area !== undefined && area[this.layerName] !== null) {
          let areaSprite = this.areaSpritesByLocation[`${x}&${y}`];

          if (areaSprite === undefined) {
            areaSprite = new Phaser.GameObjects.Image(this.scene,
              (x * AREA_SIZE),
              (y * AREA_SIZE),
              this.atlas,
              area[this.layerName]
            );

            const wall = getWall(area[this.layerName]);
            const elevation = wall.elevation;
            if (this.elevationLayers[elevation] === undefined) {
              this.elevationLayers[elevation] = this.scene.add.layer();
              this.elevationLayers[elevation].setDepth(elevation);
            }

            this.add(areaSprite);
            this.elevationLayers[elevation].add(areaSprite);
          }

          this.areaSpritesByLocation[`${x}&${y}`] = areaSprite;
        }
      }
    }
  }
}
