import { useEffect, useRef, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isTouchDevice from 'is-touch-device';

import { useModel, useModels } from '../models-store';
import { HOUR_LENGTH } from '../world/data/constants';
import { advanceTime } from '../saves/data/thunks';
import { pause, play } from '../engine/controls';

import { hydrateFeature, hydrateItem } from './contentUtils';
import { activeCharacterSelector, activeRegionSelector, createContainerItemsSelector, gameSelector, grabbedItemIdSelector, leaderCharacerSelector, mainViewportCharacterIdSelector } from './selectors';
import { AppStatus, TimeModes } from './types';

export function useStatus() {
  const status = useSelector(state => state.status);

  return {
    isLoaded: status === AppStatus.LOADED,
    isUnloaded: status === AppStatus.UNLOADED,
  };
}

export function useCurrentActivity() {
  const { activity } = useSelector(gameSelector);
  return activity;
}

export function useItem(id) {
  const item = useModel('items', id);
  return hydrateItem(item);
}

export function useItems(ids) {
  const items = useModels('items', ids);
  return items.map(item => hydrateItem(item));
}

export function useCharacter(id) {
  return useModel('characters', id);
}

export function useFeature(id) {
  const feature = useModel('features', id);
  return hydrateFeature(feature);
}

export function useRegion(id) {
  return useModel('regions', id);
}

export function useStory(id) {
  const story = useModel('stories', id);
  if (story === null) {
    throw new Error(`useStory(${id}) returned null.  Did you try to use it before a story was loaded?`);
  }
  return useModel('stories', id);
}

export function useActiveCharacter() {
  return useSelector(activeCharacterSelector);
}

export function useLeaderCharacter() {
  return useSelector(leaderCharacerSelector);
}

export function useActiveRegion() {
  return useSelector(activeRegionSelector);
}

export function useGame() {
  return useSelector(gameSelector);
}

export function useGrabbedItemId() {
  return useSelector(grabbedItemIdSelector);
}

// https://usehooks.com/useWindowSize/
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    function handleOrientationChange() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleOrientationChange);

    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      window.addEventListener('orientationchange', handleOrientationChange);
    };
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export function useElementSize() {
  const observerRef = useRef();
  const ref = useRef();

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  useEffect(() => {
    observerRef.current = new ResizeObserver(() => {
      if (ref.current) {
        setDimensions({
          width: ref.current.clientWidth,
          height: ref.current.clientHeight
        });
        setOffset({
          x: ref.current.offsetLeft,
          y: ref.current.offsetTop,
        });
      }
    });
    if (ref.current) {
      observerRef.current.observe(ref.current);
    }
  }, []);

  return useMemo(
    () => ([ref, dimensions.width, dimensions.height, offset.x, offset.y]),
    [ref, dimensions, offset]
  );
}

export function useMainViewpointCharacterId() {
  return useSelector(mainViewportCharacterIdSelector);
}

export function useMainViewpoint() {
  const viewpointCharacterId = useMainViewpointCharacterId();

  const viewpointCharacter = useCharacter(viewpointCharacterId);

  const viewpoint = useMemo(() => {
    return {
      x: viewpointCharacter.x,
      y: viewpointCharacter.y,
    };
  }, [viewpointCharacter.x, viewpointCharacter.y]);

  return viewpoint;
}

export function useContainerItems(containerItemId) {
  const items = useSelector(createContainerItemsSelector(containerItemId));

  return items.map(item => hydrateItem(item));
}

export function useClickAttributes(onClick) {
  const hasTouch = isTouchDevice();
  const clickHandler = useCallback((event) => {
    if (!hasTouch) {
      onClick(event);
    }
  }, [onClick, hasTouch]);

  const touchEndHandler = useCallback((event) => {
    if (hasTouch) {
      onClick(event);
    }
  }, [onClick, hasTouch]);

  return {
    onClick: clickHandler,
    onTouchEnd: touchEndHandler,
  };
}

export function useTimeAdvancement() {
  const { timeMode } = useGame();
  const dispatch = useDispatch();
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (timeMode === TimeModes.NORMAL && intervalId === null) {
      const id = setInterval(() => {
        dispatch(advanceTime());
      }, HOUR_LENGTH);
      setIntervalId(id);
    } else if (timeMode !== TimeModes.NORMAL && intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [intervalId, timeMode, dispatch]);
}

export function useDocumentVisibility() {
  const {
    timeMode,
  } = useGame();
  const [pausedOnHide, setPausedOnHide] = useState(false);

  const handleVisibilityChange = useCallback((event) => {
    if (document.visibilityState === 'hidden') {
      if (timeMode === TimeModes.NORMAL) {
        pause();
        setPausedOnHide(true);
      }
    } else {
      if (pausedOnHide) {
        play();
        setPausedOnHide(false);
      }
    }
  }, [timeMode, pausedOnHide]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);
}
