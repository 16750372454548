import { updateActingCharacter } from '../../data/slice';
import { sleep } from '../../data/utils';
import { activeRegionSelector } from '../../data/selectors';
import { PartyModes } from '../../data/types';
import { combatTactics } from '../combat/data/thunks';
import { partyCatchupWalk, wander } from '../movement/data/thunks';
import { createCharacterIdsByInitiativeSelector } from '../combat/data/selectors';
import { updateModel } from '../../models-store';
import { WEST, EAST, NORTH, SOUTH, REST } from '../../ui/character/constants';
import { MovementTypes } from '../../content-utils/types';
import { COMBAT_TURN_DELAY, PARTY_CATCHUP_DELAY } from '../../world/data/constants';
import { createHostileEnvironmentSelector } from '../../world/data/selectors';

export function endCharacterTurn({ actingCharacterId }) {
  return async (dispatch, getState) => {
    const activeRegion = activeRegionSelector(getState());

    const characterIds = createCharacterIdsByInitiativeSelector(activeRegion.id)(getState());

    const actingCharacterIndex = characterIds.indexOf(actingCharacterId);

    // Re-arrange the characterIds array to start with the character AFTER the acting character.
    // Then at the end of the array, include all the elements from the array that were BEFORE the
    // acting character, ending in the acting character.
    // C = acting
    // [A, B, C, D, E, F] => [D, E, F, A, B, C]
    const shiftedCharacterIds = [
      ...characterIds.slice(actingCharacterIndex + 1),
      ...characterIds.slice(0, actingCharacterIndex + 1)
    ];
    const nextCharacterId = shiftedCharacterIds[0];

    dispatch(updateActingCharacter({ characterId: nextCharacterId }));
  };
}

export function takeCharacterTurn({ characterId }) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];
    // If we're supposed to do something (either our turn or NORMAL time)
    // Then figure out what we should be doing.
    const isHostileEnvironment = createHostileEnvironmentSelector(character.regionId, characterId)(getState());
    const {
      partyCharacterIds,
      partyMode,
      activeCharacterId,
    } = getState().game;

    const activeCharacter = getState().models.characters[activeCharacterId];

    let delay = 0;

    const {
      activeConversationCharacterId,
    } = getState().conversation;

    const inConversation = activeConversationCharacterId === characterId;

    if (isHostileEnvironment) {
      // Wait a second on each combat action.
      delay = COMBAT_TURN_DELAY;
      dispatch(combatTactics({ characterId: characterId }));
    } else if (inConversation) {
      let direction = character.direction;

      const dx = Math.abs(activeCharacter.x - character.x);
      const dy = Math.abs(activeCharacter.y - character.y);

      if (dx > dy) {
        if (activeCharacter.x < character.x) {
          direction = WEST;
        } else if (activeCharacter.x > character.x) {
          direction = EAST;
        }
      } else {
        if (activeCharacter.y < character.y) {
          direction = NORTH;
        } else if (activeCharacter.y > character.y) {
          direction = SOUTH;
        }
      }

      dispatch(updateModel({
        modelType: 'characters',
        model: {
          id: characterId,
          direction,
          pose: REST,
        },
      }));
    } else if (character.movement.type === MovementTypes.WANDER) {
      delay = character.movement.delay;
      dispatch(wander({ characterId }));
    } else if (partyCharacterIds.includes(characterId) && partyMode !== PartyModes.SOLO) {
      delay = PARTY_CATCHUP_DELAY;
      dispatch(partyCatchupWalk({ characterId }));
    }

    await sleep(delay);
    dispatch(endCharacterTurn({ actingCharacterId: characterId }));
  };
}
