import { useSelector } from 'react-redux';

import { ProgressBar } from '../generic';
import { createCharacterStatusSelector } from '../../characters/status/data/selectors';
import { useCharacter } from '../../data/hooks';

export default function EnduranceBar({ characterId, className = null, barOnly = false, height = 14 }) {
  const character = useCharacter(characterId);
  const { endurance, maxEndurance } = character;
  const { enduranceStatus } = useSelector(createCharacterStatusSelector(characterId));

  return (
    <div className={className}>
      <ProgressBar height={height} min={0} max={maxEndurance} current={endurance} className="w-100" barClassName="bg-cha" />
      {!barOnly && (
        <div className="d-flex justify-content-between">
          <div className="small">{enduranceStatus}</div>
          <div className="small">{`${endurance} / ${maxEndurance}`}</div>
        </div>
      )}
    </div>
  );
}
