import classNames from 'classnames';

import { useCharacterInventory } from '../../../characters/data/hooks';
import { Grid, Well } from '../../generic';
import ContainerItem from '../../containers/ContainerItem';
import { useHud } from '../../data/hooks';

export default function Inventory({ characterId }) {
  const items = useCharacterInventory(characterId);

  const partialRowCount = (4 - (items.length % 4)) + 8;

  for (let i = 0; i < partialRowCount; i++) {
    items.push({
      id: `empty-${i + items.length}`,
      empty: true
    });
  }

  const {
    grabbedItemId,
  } = useHud();

  return (
    <Well
      className={classNames(
        'overflow-y-auto w-container',
        { 'cursor-pointer': grabbedItemId !== null }
      )}
      style={{ height: 200 }}
    >
      <Grid>
        {items.map((item) => {
          return (
            <ContainerItem
              key={item.id}
              characterId={characterId}
              item={item}
              isGrabbed={grabbedItemId === item.id}
            />
          );
        })}
      </Grid>
    </Well>
  );
}
