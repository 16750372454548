import { v4 as uuidv4 } from 'uuid';

const allowancesMap = {
  primary: {
    mainHand: true,
    inventory: true,
  },
  secondary: {
    mainHand: true,
    offHand: true,
    inventory: true,
  },
  offHand: {
    offHand: true,
    inventory: true,
  },
  head: {
    head: true,
    inventory: true,
  },
  chest: {
    chest: true,
    inventory: true,
  },
  legs: {
    legs: true,
    inventory: true,
  },
  waist: {
    waist: true,
    inventory: true,
  },
  feet: {
    feet: true,
    inventory: true,
  },
  hands: {
    hands: true,
    inventory: true,
  },
  material: {
    inventory:true,
  },
  inventory: {
    inventory: true,
  },
  none: {}
};

// This is not exported because we should be using createItemWithChildren everywhere.
function createItem({
  templateId,
  regionId = null,
  characterId = null,
  containerItemId = null,
  uniqueName = null,
  slot = null,
  order = null,
  quantity = 1,
  status = null,
  contents = [],
  keyId = null,
  x = null,
  y = null,
  ...properties
}) {
  assertItemTemplate(templateId);

  if (characterId && slot === null && order === null) {
    throw new Error('createItem: order is required for inventory items.');
  }

  if (characterId && slot !== null && order !== null) {
    throw new Error('createItem: order must be null for equipped items.');
  }

  if (regionId && (slot !== null || order !== null)) {
    if (slot !== null) {
      throw new Error('createItem: slot must be null in regions.');
    }
    if (order === null) {
      throw new Error('createItem: order must set in regions.');
    }
  }

  const itemTemplate = getItemTemplate(templateId);

  // A container inside a container, or on a character, will not have a status set.  So default it to closed.
  let finalStatus = status;
  if (finalStatus === null) {
    if (itemTemplate.container) {
      finalStatus = 'closed';
    }
  }

  const id = uuidv4();

  let children = [];
  if (contents) {
    for (let i = 0; i < contents.length; i++) {
      const childData = contents[i];
      if (typeof childData === 'object') {
        const {
          templateId: childId,
          children: grandChildren,
        } = childData;
        const child = createItem({
          templateId: childId,
          containerItemId: id,
          order: i,
          quantity: childData.quantity || 1,
          uniqueName: childData.uniqueName || null,
          contents: grandChildren,
        });
        children.push(child);
        children = [...children, ...child.children];
        delete child.children; // We no longer need this.
      } else {
        const child = createItem({
          templateId: childData,
          containerItemId: id,
          order: i,
        });
        children.push(child);
        children = [...children, ...child.children];
      }
    }
  }

  return {
    ...properties,
    id,
    regionId,
    x,
    y,
    status: finalStatus,
    children,
    characterId,
    containerItemId,
    templateId,
    slot,
    order,
    quantity,
    uniqueName,
    keyId,
  };
}

export function createItemWithChildren(props) {
  const items = {};
  const item = createItem(props);
  items[item.id] = item;
  item.children.forEach(child => {
    items[child.id] = child;
  });
  delete item.children; // We now no longer need this.
  return items;
}

function createItemTemplate({
  id,
  name,
  image,
  damage = 0,
  defense = 0,
  range = 1,
  value = 0,
  container = false,
  lockable = false,
  door = false,
  doorSection = null,
  moveable = true,
  walkable = true,
  status = null,
  atlas = 'items',
  determiner = 'a', // English determiners. For singular objects, usually 'a' or 'an'.  Plurals can be things like 'a pair of' or 'some'.  a club. an apple. some raspberries. a pair of gloves.
  stackSize = 1,
  ammoTemplateIds = null,
  light = null,
  allowances = 'none'
}) {
  if (!id) {
    throw new Error('createItemTemplate: id is a required field.');
  }

  if (!name) {
    throw new Error('createItemTemplate: name is a required field.');
  }

  if (!image) {
    throw new Error('createItemTemplate: image is a required field.');
  }

  const finalAllowances = {
    head: false,
    back: false,
    chest: false,
    offHand: false,
    legs: false,
    neck: false,
    waist: false,
    mainHand: false,
    hands: false,
    feet: false,
    material: false,
    inventory: false,
    ...allowancesMap[allowances],
  };

  return {
    id,
    name,
    damage,
    defense,
    range,
    value,
    image,
    determiner,
    container,
    lockable,
    door,
    doorSection,
    moveable,
    walkable,
    status,
    atlas,
    stackSize,
    ammoTemplateIds,
    light,
    allowances: finalAllowances,
  };
}

export function createItemTemplates(dataList) {
  const templates = {};

  dataList.forEach(data => {
    const item = createItemTemplate(data);

    templates[item.id] = item;

  });
  return templates;
}

let itemTemplates = {};

function assertItemTemplate(id) {
  const template = itemTemplates[id];
  if (template === undefined) {
    throw new Error(`Item template ${id} does not exist!`);
  }
}

export function getItemTemplate(id) {
  assertItemTemplate(id);
  return itemTemplates[id];
}

export function setItemTemplates(dataList) {
  itemTemplates = createItemTemplates(dataList);
}

/*
image: {
  statuses: {
    closed: {
      src: itemsImage,
      position: { x: 4, y: 6 },
    },
    open: {
      src: itemsImage,
      position: { x: 4, y: 7 },
    },
    locked: {
      src: itemsImage,
      position: { x: 4, y: 8 },
    }
  }
},
image: {
    quantities: {
      one: {
        src: itemsImage,
        position: { x: 2, y: 4 },
      },
      many: {
        src: itemsImage,
        position: { x: 2, y: 5 },
      }
    }
  },
}
*/
export function generateItemTemplateAtlas() {
  const tileSize = 16;
  const margin = 1;
  const spacing = 2;
  const frames = [];

  const frameDefaults = {
    rotated: false,
    trimmed: false,
    spriteSourceSize: { x: 0, y: 0, w: tileSize, h: tileSize },
    sourceSize: { w: tileSize, h: tileSize },
    pivot: { x: 0, y: 0 }
  };

  Object.values(itemTemplates).filter(itemTemplate => itemTemplate.atlas === 'items').forEach(itemTemplate => {
    if (itemTemplate.image.statuses) {
      Object.entries(itemTemplate.image.statuses).forEach(([key, value]) => {
        const { position } = value;
        frames.push({
          filename: `${itemTemplate.id}@${key}`,
          frame: {
            x: margin + (position.x * tileSize) + (position.x * spacing),
            y: margin + (position.y * tileSize) + (position.y * spacing),
            w: tileSize,
            h: tileSize
          },
          ...frameDefaults,
        });
      });
    } else if (itemTemplate.image.quantities) {
      Object.entries(itemTemplate.image.quantities).forEach(([key, value]) => {
        const { position } = value;
        frames.push({
          filename: `${itemTemplate.id}@${key}`,
          frame: {
            x: margin + (position.x * tileSize) + (position.x * spacing),
            y: margin + (position.y * tileSize) + (position.y * spacing),
            w: tileSize,
            h: tileSize
          },
          ...frameDefaults,
        });
      });
    } else {
      const { position } = itemTemplate.image;
      frames.push({
        filename: itemTemplate.id,
        frame: {
          x: margin + (position.x * tileSize) + (position.x * spacing),
          y: margin + (position.y * tileSize) + (position.y * spacing),
          w: tileSize,
          h: tileSize
        },
        ...frameDefaults,
      });
    }
  });

  return { frames };
}
