import activityIcons from './activity-icons.png';
import animalsLarge from './animals-large.png';
import animalsSmall from './animals-small.png';
import characterChests from './character-chests.png';
import characterEyesIrises from './character-eyes-irises.png';
import characterEyesWhites from './character-eyes-whites.png';
import characterFeet from './character-feet.png';
import characterHairstyles from './character-hairstyles.png';
import characterLegs from './character-legs.png';
import characterSkins from './character-skins.png';
import characterWaist from './character-waist.png';
import effects from './effects.png';
import features from './features.png';
import items from './items.png';
import placeIcons from './place-icons.png';
import terrains from './terrains.png';
import walls from './walls.png';

const spritesheets = {
  activityIcons,
  animalsLarge,
  animalsSmall,
  characterChests,
  characterEyesIrises,
  characterEyesWhites,
  characterFeet,
  characterHairstyles,
  characterLegs,
  characterSkins,
  characterWaist,
  effects,
  features,
  items,
  placeIcons,
  terrains,
  walls,
};

export default spritesheets;
