import Character from '../../character/Character';
import { SOUTH, REST } from '../../character/constants';
import { useCharacterEquipment } from '../../../characters/data/hooks';
import Statistics from '../Statistics';
import EquipmentSlot from './EquipmentSlot';

export default function Equipment({ character }) {
  const items = useCharacterEquipment(character.id);
  return (
    <section>
      <Statistics character={character} className="mb-3" />
      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between gap-2">
          <EquipmentSlot characterId={character.id} slot="back" name="Back" item={items.back} />
          <EquipmentSlot characterId={character.id} slot="head" name="Head" item={items.head} />
          <EquipmentSlot characterId={character.id} slot="neck" name="Neck" item={items.neck} />
        </div>
        <div className="d-flex gap-2">
          <div className="d-flex flex-column flex-grow-0">
            <EquipmentSlot characterId={character.id} slot="chest" name="Chest" item={items.chest} />
            <EquipmentSlot characterId={character.id} slot="mainHand" name="Main" item={items.mainHand} />
          </div>
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <Character scale={4} character={character} direction={SOUTH} pose={REST} />
          </div>
          <div className="d-flex flex-column flex-grow-0">
            <EquipmentSlot characterId={character.id} slot="hands" name="Hands" item={items.hands} />
            <EquipmentSlot characterId={character.id} slot="offHand" name="Off" item={items.offHand} />
          </div>
        </div>
        <div className="d-flex justify-content-between gap-2">
          <EquipmentSlot characterId={character.id} slot="legs" name="Legs" item={items.legs} />
          <EquipmentSlot characterId={character.id} slot="feet" name="Feet" item={items.feet} />
          <EquipmentSlot characterId={character.id} slot="waist" name="Waist" item={items.waist} />
        </div>
      </div>
    </section>
  );
}
