import { useSelector } from 'react-redux';
import { createCharacterEquipmentBySlotSelector, createCharacterInventorySelector, createCharacterItemsSelector } from '../items/data/selectors';
import { partyGoldSelector, partySelector } from '../party/data/selectors';
import { createCharacterSkillsSelector } from '../skills/data/selectors';
import { createCharacterCombatStatsSelector } from '../stats/data/selectors';

export function useCombatStats(characterId) {
  return useSelector(createCharacterCombatStatsSelector(characterId));
}

export function useCharacterItems(characterId) {
  return useSelector(createCharacterItemsSelector(characterId));
}

export function useCharacterSkills(characterId) {
  return useSelector(createCharacterSkillsSelector(characterId));
}

export function useCharacterInventory(characterId) {
  return useSelector(createCharacterInventorySelector(characterId));
}

export function useCharacterEquipment(characterId) {
  return useSelector(createCharacterEquipmentBySlotSelector(characterId));
}

export function usePartyGold() {
  return useSelector(partyGoldSelector);
}

export function useParty() {
  return useSelector(partySelector);
}
