import { createSelector } from 'reselect';
import memoize from 'memoize-one';

import { hydrateFeature, hydrateItem } from '../../data/contentUtils';
import { Graph } from '../../data/astar';
import {
  hasOnlyWalkableCharacters,
  hasOnlyWalkableFeatures,
  hasOnlyWalkableItems,
  isWalkableTerrain
} from '../../data/utils';
import { activeCharacterSelector, activeRegionSelector } from '../../data/selectors';

export function createRegionItemsSelector(regionId) {
  return createSelector(
    state => state.models.items,
    (itemsById) => {
      const items = [];
      Object.values(itemsById).forEach(item => {
        if (item.regionId === regionId) {
          items.push(hydrateItem(item));
        }
      });
      return items.sort((a, b) => a.order - b.order);
    }
  );
}

export function createRegionFeaturesSelector(regionId) {
  return createSelector(
    state => state.models.features,
    (featuresById) => {
      const features = [];
      Object.values(featuresById).forEach(feature => {
        if (feature.regionId === regionId) {
          features.push(hydrateFeature(feature));
        }
      });
      return features.sort((a, b) => a.order - b.order);
    }
  );
}

export const createRegionItemsByLocationSelector = memoize((regionId) => {
  return createSelector(
    createRegionItemsSelector(regionId),
    (items) => {
      const itemsByLocation = {};
      items.forEach(item => {
        if (itemsByLocation[`${item.x}&${item.y}`] === undefined) {
          itemsByLocation[`${item.x}&${item.y}`] = [];
        }
        itemsByLocation[`${item.x}&${item.y}`].push(item);
      });
      return itemsByLocation;
    }
  );
});

export const createRegionFeaturesByLocationSelector = memoize((regionId) => {
  return createSelector(
    createRegionFeaturesSelector(regionId),
    (features) => {
      const featuresByLocation = {};

      features.forEach(feature => {
        if (featuresByLocation[`${feature.x}&${feature.y}`] === undefined) {
          featuresByLocation[`${feature.x}&${feature.y}`] = [];
        }
        featuresByLocation[`${feature.x}&${feature.y}`].push(feature);
      });
      return featuresByLocation;
    }
  );
});

export const createRegionCharactersByLocationSelector = memoize((regionId) => {
  return createSelector(
    createRegionCharactersSelector(regionId),
    (characters) => {
      const charactersByLocation = {};

      characters.forEach(character => {
        if (charactersByLocation[`${character.x}&${character.y}`] === undefined) {
          charactersByLocation[`${character.x}&${character.y}`] = [];
        }
        charactersByLocation[`${character.x}&${character.y}`].push(character);
      });
      return charactersByLocation;
    }
  );
});

function selectRegionCharacters(regionId, charactersById) {
  const characters = [];
  Object.values(charactersById).forEach((character) => {
    if (character.regionId === regionId) {
      characters.push(character);
    }
  });
  return characters;
}

export const activeRegionCharactersSelector = createSelector(
  activeRegionSelector,
  state => state.models.characters,
  (activeRegion, charactersById) => selectRegionCharacters(activeRegion.id, charactersById)
);


export function createRegionCharactersSelector(regionId) {
  return createSelector(
    state => state.models.characters,
    (charactersById) => selectRegionCharacters(regionId, charactersById)
  );
}

export const createRegionAreasGrid = memoize((regionId) => {
  return createSelector(
    state => state.models.regions[regionId],
    (region) => {
      const astarGrid = [];
      for (let rx = 0; rx < region.map.width; rx++) {
        for (let ry = 0; ry < region.map.height; ry++) {
          if (astarGrid[rx] === undefined) {
            astarGrid[rx] = [];
          }

          const walkable = isWalkableTerrain(region, rx, ry);

          astarGrid[rx][ry] = walkable ? 1 : 0;
        }
      }

      return astarGrid;
    }
  );
});

export const createRegionPathfindingGraphSelector = memoize((regionId) => {
  return createSelector(
    state => state.models.regions,
    createRegionItemsByLocationSelector(regionId),
    createRegionFeaturesByLocationSelector(regionId),
    createRegionCharactersByLocationSelector(regionId),
    createRegionAreasGrid(regionId),
    (regionsById, itemsByLocation, featuresByLocation, charactersByLocation, regionAreasGrid) => {
      const region = regionsById[regionId];
      const graph =  new Graph(regionAreasGrid, { diagonal: true });

      Object.entries(itemsByLocation).forEach(([location, areaItems]) => {
        const walkable = hasOnlyWalkableItems(areaItems);
        const [x, y] = location.split('&').map(element => Number.parseInt(element));
        if (!walkable) {
          graph.grid[x][y].weight = 0;
        }
      });

      Object.entries(featuresByLocation).forEach(([location, areaFeatures]) => {
        const walkable = hasOnlyWalkableFeatures(areaFeatures);
        const [x, y] = location.split('&').map(element => Number.parseInt(element));
        if (!walkable) {
          // Some features can be placed outside the bounds for aesthetic reasons.  Ignore them
          // for this.
          if (x >= 0 && x < region.map.width && y >= 0 && y < region.map.height) {
            graph.grid[x][y].weight = 0;
          }
        }
      });

      Object.entries(charactersByLocation).forEach(([location, areaCharacters]) => {
        const walkable = hasOnlyWalkableCharacters(areaCharacters);
        const [x, y] = location.split('&').map(element => Number.parseInt(element));
        if (!walkable) {
          graph.grid[x][y].weight = 0;
        }
      });

      return graph;
    });
});

export const createRegionLightsSelector = memoize((regionId) => {
  return createSelector(
    createRegionItemsSelector(regionId),
    createRegionFeaturesSelector(regionId),
    (regionItems, regionFeatures) => {
      const itemLights = regionItems.filter(item => item.light !== null);
      const featureLights = regionFeatures.filter(feature => feature.light !== null);
      return [ ...itemLights, ...featureLights ];
    }
  );
});

function selectHostileEnvironment(characters, characterId) {
  for (let i = 0; i < characters.length; i++) {
    const character = characters[i];
    if (character.id === characterId) {
      // this is the character we're evaluating, so look at all of their relationships for hostility.  We need to do this because we call this selector for NPCs as well, who have their own relationships.
      const relationshipCharacterIds = Object.keys(character.relationships);
      for (let j = 0; j < relationshipCharacterIds.length; j++) {
        const relationshipCharacterId = relationshipCharacterIds[j];
        const relationship = character.relationships[relationshipCharacterId];
        if (relationship.level < 0) {
          return true;
        }
      }
    }
    const relationship = character.relationships[characterId] || {};
    if (relationship.level < 0) {
      return true;
    }
  }

  return false;
}

export const activeCharacterHostileEnvironmentSelector = createSelector(
  activeCharacterSelector,
  activeRegionCharactersSelector,
  (activeCharacter, characters) => selectHostileEnvironment(characters, activeCharacter.id)
);

export function createHostileEnvironmentSelector(regionId, characterId) {
  return createSelector(
    createRegionCharactersSelector(regionId),
    (characters) => selectHostileEnvironment(characters, characterId)
  );
}
