import { useSelector } from 'react-redux';
import { useCharacter } from '../../../../data/hooks';
import {
  buyPriceSelector,
  createCharacterSellItemsSelector,
  createCharacterShopItemsSelector,
  sellPriceSelector
} from './selectors';

export function useTradePartner() {
  const {
    tradePartnerId,
  } = useSelector(state => state.trade);
  const items = useShopItems(tradePartnerId);
  const tradePartner = useCharacter(tradePartnerId);

  return {
    tradePartner,
    items
  };
}

export function useShopItems(characterId) {
  return useSelector(createCharacterShopItemsSelector(characterId));
}

export function useSellItems(characterId, buyerId) {
  return useSelector(createCharacterSellItemsSelector(characterId, buyerId));
}

export function useTrade() {
  return useSelector(state => state.trade);
}

export function useBuyPrice() {
  return useSelector(buyPriceSelector);
}

export function useSellPrice() {
  return useSelector(sellPriceSelector);
}
