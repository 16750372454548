import Draggable from 'react-draggable';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import { addModel, updateModel, updateModels, useModel } from '../../models-store';
import ActivityIconButton from './ActivityIconButton';

import Panel from './Panel';
import { useElementSize, useWindowSize } from '../../data/hooks';

export default function DraggablePanel({
  id,
  children,
  className,
  style,
  onClose,
  onMinimize,
  onMaximize,
  minimized,
  closeStyle,
  title = '',
  panelClassName = null,
  headerItems = null,
}) {
  const dispatch = useDispatch();
  const { width, height } = useWindowSize();
  const [ref, elementWidth, elementHeight] = useElementSize();

  const panel = useModel('panels', id);
  const panelsById = useSelector(state => state.models.panels);
  const panels = panelsById ? Object.values(panelsById) : [];
  const panelCount = panelsById ? panels.length : 0;

  useEffect(() => {
    if (!panel) {
      dispatch(addModel({ modelType: 'panels', model: {
        id,
        x: (panelCount * 16) + 8,
        y: (panelCount * 16) + 8,
        order: panelCount,
      }}));
    }
  }, [id, panel, dispatch, panelCount]);

  const handleDragStop = (event, data) => {
    const { x, y } = data;

    dispatch(updateModel({ modelType: 'panels', model: {
      id,
      x,
      y,
    }}));
  };

  const handleDragStart = () => {
    dispatch(updateModels({
      modelType: 'panels',
      models: panels.map(panel => {
        if (panel.id === id) {
          return {
            id: panel.id,
            order: panelCount,
          };
        }
        return {
          id: panel.id,
          order: panel.order - 1,
        };
      })
    }));
  };

  const defaultX = panel ? panel.x : ((panelCount * 16) + 8);
  const defaultY = panel ? panel.y : ((panelCount * 16) + 8);

  return (
    <Draggable
      defaultPosition={{ x: defaultX, y: defaultY }}
      handle=".handle"
      bounds={{
        left: 8,
        top: 8,
        right: width - (elementWidth + 8),
        bottom: height - (elementHeight + 8),
      }}
      onStop={handleDragStop}
      onStart={handleDragStart}
    >
      <div
        ref={ref}
        style={{
          ...style,
          zIndex: 100 + (panel ? panel.order : 0),
        }}
        className={classNames(className, 'visible position-absolute d-flex flex-column')}
      >
        <Panel className={classNames(
          'd-flex flex-column',
        )}>
          <div className="d-flex justify-content-between align-items-center handle cursor-pointer bg-frosted-400 py-1 pl-3 pr-2 border-bottom">
            <div>{title}</div>
            <div className="d-flex">
              <div className="mr-1">
                {headerItems}
              </div>
              {onMinimize && !minimized && (
                <ActivityIconButton
                  scale={1.5}
                  activityId="minimize"
                  className="btn-link"
                  style={{ right: 2, top: 2, ...closeStyle }}
                  onClick={onMinimize}
                />
              )}
              {onMaximize && minimized && (
                <ActivityIconButton
                  scale={1.5}
                  activityId="maximize"
                  className="btn-link"
                  style={{ right: 2, top: 2, ...closeStyle }}
                  onClick={onMaximize}
                />
              )}
              {onClose && (
                <ActivityIconButton
                  scale={1.5}
                  activityId="close"
                  className="btn-link"
                  style={{ right: 2, top: 2, ...closeStyle }}
                  onClick={onClose}
                />
              )}
            </div>
          </div>
          <div className={panelClassName || 'p-3'}>
            {children}
          </div>
        </Panel>
      </div>
    </Draggable>
  );
}
