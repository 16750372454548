import Game from '../Game';
import { useStatus } from '../data/hooks';
import MainMenu from '../ui/menus/MainMenu';
import { logRender } from '../data/utils';

export default function AppContent({ story }) {
  const { isUnloaded } = useStatus();

  logRender('AppContent');

  if (isUnloaded) {
    return (
      <MainMenu story={story} />
    );
  }

  return (
    <Game />
  );
}
