import { useEffect, useState } from 'react';

export default function Fader({
  children,
  className,
  style,
  ready = false,
  mount = false,
  delay = 0,
  speed = '0.5s',
}) {
  const [show, setShow] = useState(false);
  const [mounted, setMounted] = useState(!mount);
  useEffect(() => {
    let timeoutId = null;
    if (ready) {
      timeoutId = setTimeout(() => {
        setMounted(true);
      }, delay * 1000);
      setShow(true);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [ready, delay]);

  return (
    <div
      style={{
        transition: `opacity ${speed} linear`,
        transitionDelay: `${delay}s`,
        opacity: show ? 1 : 0,
        ...style,
      }}
      className={className}
    >
      {mounted && children}
    </div>
  );
}
