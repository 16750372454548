import { useDispatch } from 'react-redux';
import { ActivityTypes } from '../activities/data/types';

import { useGame } from '../../data/hooks';
import ActivityIconButton from '../generic/ActivityIconButton';
import { setUpCamp } from '../activities/camp/data/thunks';
import { useHud } from '../data/hooks';

export default function CampButton() {
  const dispatch = useDispatch();
  const {
    viewportActivity,
  } = useHud();
  const {
    activeCharacterId
  } = useGame();

  const handleCamp = () => {
    dispatch(setUpCamp({ characterId: activeCharacterId }));
  };

  return (
    <ActivityIconButton
      activityId="camp"
      scale={2}
      onClick={handleCamp}
      selected={viewportActivity === ActivityTypes.CAMP}
    />
  );
}
