import Phaser from 'phaser';
import memoize from 'memoize-one';

import { AREA_SIZE } from '../data/constants';
import { SOUTH, EAST, WEST, NORTH, UNCONSCIOUS } from '../../ui/character/constants';
import { parseColorInt } from '../data/utils';
import { createModelSelector } from '../../models-store/selectors';
import createSelectorListener from '../../engine/createSelectorListener';

export default class CharacterLowerContainer extends Phaser.GameObjects.Container {

  constructor(modelId, modelType, elevationLayers, ...args) {
    super(...args);

    this.modelId = modelId;
    this.modelType = modelType;
    this.elevationLayers = elevationLayers;

    this.updateCharacterPosition = memoize(this.updateCharacterPosition);
    this.updateCharacterDirection = memoize(this.updateCharacterDirection);
    // This is important since we're calling the function from createSelectorListener
    this.handleModelChanged = this.handleModelChanged.bind(this);

    this.targetX = null;
    this.targetY = null;

    this.elevation = 1;
    const { remove, data } = createSelectorListener(
      createModelSelector(modelType, modelId),
      this.handleModelChanged
    );

    this.removeModelListener = remove;
    this.model = data;

    this.initialize();
    this.handleModelChanged(data);
  }

  initialize() {
    const {
      skin, hairstyle, chest, waist, legs, feet
    } = this.model.body;

    const poseDirection = `${this.model.pose}@${this.model.direction}`;
    this.setDepth(1);

    if (skin) {
      const atlas = skin.atlas || 'skins';
      this.skin = new Phaser.GameObjects.Image(this.scene, 0, 0, atlas, `${skin.style}@${poseDirection}@${this.elevation}`);
      this.skin.setTint(parseColorInt(skin.tint));
      this.add(this.skin);
    }

    if (chest) {
      this.chest = new Phaser.GameObjects.Image(this.scene, 0, 0, 'chests', `${chest.style}@${poseDirection}@${this.elevation}`);
      this.chest.setTint(parseColorInt(chest.tint));
      this.add(this.chest);
    }

    if (waist) {
      this.waist = new Phaser.GameObjects.Image(this.scene, 0, 0, 'waist', `${waist.style}@${poseDirection}@${this.elevation}`);
      this.waist.setTint(parseColorInt(waist.tint));
      this.add(this.waist);
    }

    if (feet) {
      this.feet = new Phaser.GameObjects.Image(this.scene, 0, 0, 'feet', `${feet.style}@${poseDirection}@${this.elevation}`);
      this.feet.setTint(parseColorInt(feet.tint));
      this.add(this.feet);
    }

    if (legs) {
      this.legs = new Phaser.GameObjects.Image(this.scene, 0, 0, 'legs', `${legs.style}@${poseDirection}@${this.elevation}`);
      this.legs.setTint(parseColorInt(legs.tint));
      this.add(this.legs);
    }

    if (hairstyle) {
      this.hairstyle = new Phaser.GameObjects.Image(this.scene, 0, 0, 'hairstyles', `${hairstyle.style}@${poseDirection}@${this.elevation}`);
      this.hairstyle.setTint(parseColorInt(hairstyle.tint));
      this.add(this.hairstyle);
    }

    if (this.elevationLayers[this.elevation] === undefined) {
      this.elevationLayers[this.elevation] = this.scene.add.layer();
      this.elevationLayers[this.elevation].setDepth(this.elevation);
    }
    this.elevationLayers[this.elevation].add(this);

    this.initializePosition();
  }

  initializePosition() {
    this.setPosition(this.model.x * AREA_SIZE, (this.model.y * AREA_SIZE) + 8);
  }

  destroy() {
    // This is super important - cleaning up the subscription releases the store's reference to our
    // subscription listener, which lets this sprite be garbage collected.
    this.removeModelListener();
    super.destroy();
  }

  handleModelChanged(model) {
    this.model = model;
    this.setModelFrame();
    this.setModelDepth();
    this.checkCharacterPosition();
    this.checkCharacterDirection();
  }

  checkCharacterPosition() {
    const { x, y } = this.model;
    if (this.x !== x * AREA_SIZE || this.y !== (y * AREA_SIZE) + 8) {
      this.updateCharacterPosition(x * AREA_SIZE, (y * AREA_SIZE) + 8);
    }
  }

  checkCharacterDirection() {
    const { direction } = this.model;
    this.updateCharacterDirection(direction);
  }

  updateCharacterPosition(x, y) {
    this.setTargetPosition(x, y);
  }

  /**
   * This is about modifying the position of 'large' characters
   * so that they move in a more natural way, keeping their head in
   * place and their bodies moving around that.
   */
  updateCharacterDirection(direction) {
    const { images } = this.model;
    if (images.spriteWidth === 32 && images.spriteHeight === 32) {
      let dx = 0;
      let dy = 0;
      if (direction === SOUTH) {
        dx = 0;
        dy = 0;
      } else if (direction === EAST) {
        dx = -16;
        dy = 0;
      } else if (direction === WEST) {
        dx = 0;
        dy = 0;
      } else if (direction === NORTH) {
        dx = 0;
        dy = 16;
      }

      if (this.skin) {
        this.skin.setPosition(dx, dy);
      }
      if (this.hairstyle) {
        this.hairstyle.setPosition(dx, dy);
      }
      if (this.chest) {
        this.chest.setPosition(dx, dy);
      }
      if (this.waist) {
        this.waist.setPosition(dx, dy);
      }
      if (this.legs) {
        this.legs.setPosition(dx, dy);
      }
      if (this.feet) {
        this.feet.setPosition(dx, dy);
      }
    }
  }

  setTargetPosition(x, y) {
    this.targetX = x;
    this.targetY = y;
  }

  setModelFrame() {
    const { pose, direction, health } = this.model;
    const { skin, hairstyle, chest, waist, legs, feet } = this.model.body;
    let finalPose = pose;
    let finalDirection = direction;

    if (health === 0) {
      finalPose = UNCONSCIOUS;
      finalDirection = SOUTH;
    }

    const poseDirection = `${finalPose}@${finalDirection}`;

    if (this.skin) {
      this.skin.setFrame(`${skin.style}@${poseDirection}@${this.elevation}`);
    }
    if (this.hairstyle) {
      this.hairstyle.setFrame(`${hairstyle.style}@${poseDirection}@${this.elevation}`);
    }
    if (this.chest) {
      this.chest.setFrame(`${chest.style}@${poseDirection}@${this.elevation}`);
    }
    if (this.waist) {
      this.waist.setFrame(`${waist.style}@${poseDirection}@${this.elevation}`);
    }
    if (this.legs) {
      this.legs.setFrame(`${legs.style}@${poseDirection}@${this.elevation}`);
    }
    if (this.feet) {
      this.feet.setFrame(`${feet.style}@${poseDirection}@${this.elevation}`);
    }
  }

  setModelDepth() {
    const nextDepth = this.model.y;
    if (nextDepth !== this.depth) {
      this.setDepth(nextDepth);
    }
  }

  preUpdate(time, delta) {
    this.updatePosition(time, delta);
  }

  updatePosition(time, delta) {
    const percentage = delta / 150;

    const distance = AREA_SIZE * percentage;

    if (this.targetX !== null) {
      if (this.targetX !== this.x) {
        const dx = Math.max(-1, Math.min(1, this.targetX - this.x));
        let nextX = this.x + (dx * distance);
        if (dx > 0) {
          nextX = Math.ceil(Math.min(nextX, this.targetX));
        } else {
          nextX = Math.floor(Math.max(nextX, this.targetX));
        }
        this.setX(nextX);
      } else {
        this.targetX = null;
      }
    }

    if (this.targetY !== null) {
      if (this.targetY !== this.y) {
        const dy = Math.max(-1, Math.min(1, this.targetY - this.y));
        let nextY = this.y + (dy * distance);
        if (dy > 0) {
          nextY = Math.ceil(Math.min(nextY, this.targetY));
        } else {
          nextY = Math.floor(Math.max(nextY, this.targetY));
        }
        this.setY(nextY);
      } else {
        this.targetY = null;
      }
    }
  }
}
