import classNames from 'classnames';
import { useElementSize } from '../../data/hooks';

export default function Floater({
  y = null,
  x = null,
  alignY = 'top',
  alignX = 'left',
  children,
  className,
}) {
  const [ref, elementWidth, elementHeight] = useElementSize();

  let finalY = y;
  if (alignY === 'center') {
    finalY -= (elementHeight / 2);
  } else if (alignY === 'bottom') {
    finalY -= elementHeight;
  }

  let finalX = x;
  if (alignX === 'center') {
    finalX -= (elementWidth / 2);
  } else if (alignX === 'right') {
    finalX -= elementWidth;
  }

  return (
    <div
      ref={ref}
      className={classNames('layer-1 visible position-absolute', className)}
      style={{
        transform: `translate(${finalX}px, ${finalY}px)`,
        transition: 'right 0.25s ease-in-out, left 0.25s ease-in-out, top 0.25s ease-in-out, bottom 0.25s ease-in-out'
      }}
    >
      {children}
    </div>
  );
}
