import { Item } from '../data/types';
import { RawTiledMap } from './tiled/types';

export interface Terrain {
  id: number | string,
  type: string,
  image: Icon,
  [key: string]: string | number | boolean | null | Icon,
}

export interface RegionMap {
  height: number,
  width: number,
  terrains: ModelsById<Terrain>,
  walls: ModelsById<Terrain>,
  areas: ModelsByLocation<TerrainIdsByAreaLayer>,
  items: Array<Item>,
  features: Array<Item>,
  links: ModelsByLocation<RegionLink>,
  roofs: ModelsByLocation<TerrainIdsByAreaLayer>,
}

export interface PreyMap {
  [characterTemplateId: string]: number,
}

export interface GatherablesMap {
  [itemTemplateId: string]: number,
}

export interface Region {
  id: string,
  name: string,
  placeIconId: string,
  activities: Array<string>,
  prey: PreyMap,
  gatherables: GatherablesMap,
  wilderness: boolean,
  map: RegionMap,
}

export interface NormalizedItem {
  id: string,
  templateId: string,
  x: number,
  y: number,
  status?: string,
  contents?: Array<string>,
}

export interface Feature {
  id: string,
  type: string,
  templateId: string,
  x: number,
  y: number,
  status?: string,
  contents?: Array<string>,
}

export interface Position {
  x: number,
  y: number,
}

export interface Icon {
  src: string,
  position: Position,
}

export interface RegionData {
  id: string,
  name: string,
  placeIconId: string,
  activities?: Array<string>,
  prey?: PreyMap,
  gatherables?: GatherablesMap,
  wilderness: boolean,
  rawMap: RawTiledMap,
}

export interface ModelsByLocation<Type> {
  [location: string]: Type,
}

export interface ModelsById<Type> {
  [id: string]: Type,
}

export interface TerrainIdsByAreaLayer {
  [layerId: string]: number | null
}

export interface RegionLink {
  destinationId: string,
  destinationX: number,
  destinationY: number,
}

export interface Story {
  id: string,
  title: string,
  milestones: Array<string>,
}

export interface Character {
  templateId: string,
  uniqueId: string,
  name: string,
  regionId: string,
  conversationId?: string,
  active?: boolean,
  x: number,
  y: number,
  movement: MovementParty | MovementStationary | MovementWander,
  stats?: Stats,
  skills?: Skills,
  body: HumanBody | AnimalBody,
  gold?: number,
  items?: Array<string | ItemTemplate>,
  slots?: Slots,
  inn?: Inn,
  buys?: Buys,
  sells?: Sells,
  materials?: Materials,
}

export enum MovementTypes {
  WANDER = 'WANDER',
  FLEE = 'FLEE',
  FOLLOW = 'FOLLOW',
  PARTY = 'PARTY',
  PATROL = 'PATROL',
  STATIONARY = 'STATIONARY',
}

export interface Movement {
  type: MovementTypes,
}

export interface MovementStationary extends Movement {
  type: MovementTypes.STATIONARY,
}

export interface MovementWander extends Movement {
  type: MovementTypes.WANDER,
  radius: number,
  center: Position,
  delay: number,
}

export interface MovementParty extends Movement {
  type: MovementTypes.PARTY,
  position: number,
}

export interface MovementWaystop extends Location {
  delay: number,
}

export interface MovementPatrol extends Movement {
  type: MovementTypes.PATROL,
  destinations: Array<MovementWaystop>
}

// This is used for movement that follows a particular character around but isn't otherwise in that character's party.
export interface MovementFollow extends Movement {
  type: MovementTypes.FOLLOW,
  leaderCharacterId: string,
  minDistance: number, // The minimum distance they'll try to stay from the leader.
  maxDistance: number, // The maximum distance they'll try to stay from the leader.
}

export interface MovementFlee extends Movement {
  type: MovementTypes.FLEE,
  scaryCharacterIds: Array<string>,
  minDistance: number, // How far away from the character they'll try to get.
}

export interface Stats {
  agi?: number,
  cha?: number,
  int?: number,
  str?: number,
}

export interface Skills {
  gathering?: number,
  hunting?: number,
  running?: number,
  survival?: number,
}

export interface HumanBody {
  skin: BodyPart,
  hairstyle: BodyPart,
  eyesIrises: BodyPart,
  eyesWhites: BodyPart,
  chest: BodyPart,
  waist: BodyPart,
  legs: BodyPart,
  feet: BodyPart,
}

export interface AnimalBody {
  skin: BodyPart,
}

export interface BodyPart {
  atlas?: string,
  style: string,
  tint: string,
}

export interface ItemTemplate {
  templateId: string,
  keyId?: string,
  uniqueName?: string,
  quantity?: number,
  contents?: Array<string | ItemTemplate>,
}

export interface Slots {
  back?: string | ItemTemplate,
  head?: string | ItemTemplate,
  neck?: string | ItemTemplate,
  chest?: string | ItemTemplate,
  mainHand?: string | ItemTemplate,
  hands?: string | ItemTemplate,
  offHand?: string | ItemTemplate,
  legs?: string | ItemTemplate,
  feet?: string | ItemTemplate,
  waist?: string | ItemTemplate,
}

export interface Inn {
  price: number,
  quality: number,
}

export interface Buys {
  [templateId: string]: number,
}

export interface Sells {
  [templateId: string]: number,
}

export interface Materials {
  [templateId: string]: number,
}

export interface Narrative {
  id: string,
  content: Array<string>
}

export interface Narratives {
  [narrativeId: string]: Narrative
}
