import Phaser from 'phaser';
import memoize from 'memoize-one';

import createSelectorListener from '../../engine/createSelectorListener';
import { gameSelector, mainViewportCharacterIdSelector } from '../../data/selectors';
import { createModelSelector } from '../../models-store/selectors';
import { calculateFinalViewpoint } from '../data/utils';
import { AREA_SIZE } from '../data/constants';
import { getWall } from '../../content-utils/terrainUtils';

export default class Roofs extends Phaser.GameObjects.Group {
  constructor(region, elevationLayers, ...args) {
    super(...args);

    this.atlas = 'walls';

    this.region = region;
    this.elevationLayers = elevationLayers;
    this.handleStateChanged = this.handleStateChanged.bind(this);

    this.initialize();
  }

  initialize() {
    const { remove, data } = createSelectorListener(
      state => state,
      this.handleStateChanged
    );

    this.removeStateListener = remove;
    this.state = data;

    this.areaSpritesByLayerByLocation = {};
    this.roofVisibilities = {};

    this.handleStateChanged(this.state);

    this.updateTerrains = memoize(this.updateTerrains);
    this.updateRoofVisibility = memoize(this.updateRoofVisibility);
  }

  destroy() {
    this.removeStateListener();
    super.destroy();
  }

  handleStateChanged(state) {
    this.state = state;

    this.checkState(state);
  }

  checkState(state) {
    if (this.scene.cameras.main) {
      const { zoom } = gameSelector(state);

      const viewpointCharacterId = mainViewportCharacterIdSelector(state);
      const viewpointCharacter = createModelSelector('characters', viewpointCharacterId)(state);
      const cameraWidth = this.scene.cameras.main.width;
      const cameraHeight = this.scene.cameras.main.height;
      const area = this.region.map.areas[`${viewpointCharacter.x}&${viewpointCharacter.y}`];

      this.updateTerrains(zoom, cameraWidth, cameraHeight, this.region.map.roofs, viewpointCharacter, area.roofId);
      this.updateRoofVisibility(area.roofId);
    }
  }

  updateRoofVisibility(areaRoofId) {
    Object.entries(this.roofVisibilities).forEach(([roofId, { visible, group }]) => {
      if (areaRoofId === roofId) {
        this.roofVisibilities[roofId].visible = false;
        this.scene.tweens.add({
          targets: group.children.getArray(),
          duration: 100,
          alpha: 0,
          onComplete: (tween) => {
            tween.remove();
          }
        });
      } else if (!visible) {
        this.roofVisibilities[roofId].visible = true;
        this.scene.tweens.add({
          targets: group.children.getArray(),
          duration: 100,
          alpha: 1,
          onComplete: (tween) => {
            tween.remove();
          }
        });
      }
    });
  }

  updateTerrains(zoom, cameraWidth, cameraHeight, roofsByLocation, viewpointCharacter, areaRoofId) {
    const radiusX = Math.ceil(cameraWidth / 2 / zoom / AREA_SIZE) + 4;
    const radiusY = Math.ceil(cameraHeight / 2 / zoom / AREA_SIZE) + 4;
    const finalViewpoint = calculateFinalViewpoint(
      viewpointCharacter,
      zoom,
      cameraWidth,
      cameraHeight,
      this.region.map.width * AREA_SIZE,
      this.region.map.height * AREA_SIZE,
      this.region,
    );

    const minX = finalViewpoint.x - radiusX;
    const maxX = finalViewpoint.x + radiusX;
    const minY = finalViewpoint.y - radiusY;
    const maxY = finalViewpoint.y + radiusY;

    for (let x = minX; x <= maxX; x++) {
      for (let y = minY; y <= maxY; y++) {

        let roofs = roofsByLocation[`${x}&${y}`];
        if (roofs !== undefined) {

          const roofIds = Object.keys(roofs);
          for (let i = 0; i < roofIds.length; i++) {
            const roofId = roofIds[i];

            if (roofs[roofId] !== null) {
              if (this.areaSpritesByLayerByLocation[roofId] === undefined) {
                this.areaSpritesByLayerByLocation[roofId] = {};

                this.roofVisibilities[roofId] = {
                  visible: roofId !== areaRoofId,
                  group: new Phaser.GameObjects.Group(this.scene),
                };
              }
              let areaSprite = this.areaSpritesByLayerByLocation[roofId][`${x}&${y}`];
              const group = this.roofVisibilities[roofId].group;

              if (areaSprite === undefined) {
                areaSprite = new Phaser.GameObjects.Image(this.scene,
                  (x * AREA_SIZE),
                  (y * AREA_SIZE),
                  this.atlas,
                  roofs[roofId]
                );
                const alpha = roofId === areaRoofId ? 0 : 1;
                areaSprite.setAlpha(alpha);

                const wall = getWall(roofs[roofId]);
                const elevation = wall.elevation;
                if (this.elevationLayers[elevation] === undefined) {
                  this.elevationLayers[elevation] = this.scene.add.layer();
                  this.elevationLayers[elevation].setDepth(elevation);
                }

                this.add(areaSprite);
                group.add(areaSprite);
                this.elevationLayers[elevation].add(areaSprite);
              }


              this.areaSpritesByLayerByLocation[roofId][`${x}&${y}`] = areaSprite;
            }
          }
        }
      }
    }
  }
}
