import { useDispatch } from 'react-redux';
import { updateShowSettingsMenu } from './data/slice';
import { Button, Panel } from './generic';

export default function SettingsMenu() {
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(updateShowSettingsMenu({ showSettingsMenu: false }));
  };

  return (
    <Panel className="pt-5 pr-4 pl-4 d-flex flex-column justify-content-center align-items-center">
      <h1 className="mb-4 d1">Settings</h1>

      <Button style={{ width: 160 }} onClick={handleBack}>Back</Button>
    </Panel>
  );
}
