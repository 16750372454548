import { memo, useCallback, useContext, useEffect, useRef } from 'react';
import { useGame } from '../data/hooks';
import { calculateMouseLocation, calculateTouchLocation } from '../data/utils';
import MousePositionContext from './MousePositionContext';
import { AREA_SIZE } from '../world/data/constants';
import { useHud } from '../ui/data/hooks';

function MoveListener() {
  const {
    viewportActivity,
  } = useHud();
  const {
    zoom
  } = useGame();
  const { setDragPosition } = useContext(MousePositionContext);
  const locationRef = useRef({ x: null, y: null });

  const handleTouchMove = useCallback((event) => {
    const location = calculateTouchLocation(event, AREA_SIZE, zoom);
    if (locationRef.current.x !== location.x || locationRef.current.y !== location.y) {
      locationRef.current = location;
      setDragPosition({ x: location.clientX, y: location.clientY });
    }
  }, [setDragPosition, zoom]);

  const handleMouseMove = useCallback((event) => {
    const location = calculateMouseLocation(event, AREA_SIZE);
    if (viewportActivity !== 'walk' || (locationRef.current.x !== location.x || locationRef.current.y !== location.y)) {
      locationRef.current = location;
      setDragPosition({ x: location.clientX, y: location.clientY });
    }
  }, [viewportActivity, setDragPosition]);

  useEffect(() => {
    // We attach these listeners to the document here because we can then listen to them
    // regardless of what element the mouse is on top of.  If we attach them to an element in this
    // file, such as the FullBleed at the top of the tree, we need to remove 'invisible' which then
    // causes the FullBleed to block all mouse events to anything underneath it.  Super-bummer.
    // It's _possible_ that we could avoid this if the game were a child of this full-bleed, but
    // attaching it to the document is working just fine.
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('touchmove', handleTouchMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [handleMouseMove, handleTouchMove]);

  return null;
}

export default memo(MoveListener);
