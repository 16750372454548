import { createContext } from 'react';

const MousePositionContext = createContext({
  x: null,
  y: null,
  setDragPosition: null,
  setIsMouseDown: null,
  isMouseDown: false,
});

export default MousePositionContext;
