import { astar } from '../../../data/astar';
import { isWalkableArea } from '../../../data/utils';
import { PartyModes } from '../../../data/types';
import { sleep } from '../../../data/utils';
import { updateModel } from '../../../models-store';
import { SOUTH, WEST, EAST, NORTH, WALK_1, WALK_2, REST } from '../../../ui/character/constants';
import {
  createRegionCharactersByLocationSelector,
  createRegionFeaturesByLocationSelector,
  createRegionItemsByLocationSelector,
  createRegionPathfindingGraphSelector
} from '../../../world/data/selectors';
import { createPartyMemberPreferredPositionSelector } from '../../party/data/selectors';
import { decreaseEndurance } from '../../status/data/thunks';
import { loadActiveRegion, unloadActiveRegion } from '../../../world/data/thunks';

export function travel({ characterId, regionId, x = 0, y = 0 }) {
  return async (dispatch, getState) => {
    dispatch(decreaseEndurance({ characterId, amount: 1 }));
    dispatch(updateModel({ modelType: 'characters', model: {
      id: characterId,
      regionId,
      x,
      y,
    }}));
  };
}


export function activeCharacterTravel({ regionId, destinationRegionId, x, y }) {
  return async (dispatch, getState) => {
    const { activeCharacterId } = getState().game;
    const character = getState().models.characters[activeCharacterId];

    await dispatch(unloadActiveRegion());

    dispatch(travel({
      characterId: character.id,
      regionId: destinationRegionId,
      x,
      y,
    }));

    await dispatch(loadActiveRegion({ regionId: destinationRegionId }));
  };
}

export function partyTravel({ regionId, destinationRegionId, x, y }) {
  return async (dispatch, getState) => {
    const { partyCharacterIds } = getState().game;

    await dispatch(unloadActiveRegion());

    partyCharacterIds.forEach((partyCharacterId) => {
      const partyCharacter = getState().models.characters[partyCharacterId];
      if (partyCharacter.regionId === regionId) {
        dispatch(travel({
          characterId: partyCharacterId,
          regionId: destinationRegionId,
          x,
          y,
        }));
      }
    });

    await dispatch(loadActiveRegion({ regionId: destinationRegionId }));
  };
}

function calculateNextDirection(dx, dy, preferredDirection) {
  if (preferredDirection) {
    return preferredDirection;
  } else {
    if (dx < 0) {
      return WEST;
    } else if (dx > 0) {
      return EAST;
    } else if (dy < 0) {
      return NORTH;
    }
  }
  return SOUTH;
}

function calculateNextPose(pose, lastStep) {
  let nextPose = WALK_1;
  let nextLastStep = lastStep;
  if (pose === REST && lastStep === WALK_1) {
    nextPose = WALK_2;
    nextLastStep = REST;
  } else if (pose === REST && lastStep === WALK_2) {
    nextPose = WALK_1;
    nextLastStep = REST;
  } else if (pose === WALK_1) {
    nextPose = REST;
    nextLastStep = WALK_1;
  }  else if (pose === WALK_2) {
    nextPose = REST;
    nextLastStep = WALK_2;
  }
  return { nextPose, nextLastStep };
}

export function walk({ characterId, dx, dy, preferredDirection = null, canTravel = false }) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];

    const nextDirection = calculateNextDirection(dx, dy, preferredDirection);
    const { nextPose, nextLastStep } = calculateNextPose(character.pose, character.lastStep);

    const nextX = character.x + dx;
    const nextY = character.y + dy;

    const region = getState().models.regions[character.regionId];

    const itemsByLocation = createRegionItemsByLocationSelector(region.id)(getState());
    const featuresByLocation = createRegionFeaturesByLocationSelector(region.id)(getState());
    const charactersByLocation = createRegionCharactersByLocationSelector(region.id)(getState());

    const areaItems = itemsByLocation[`${nextX}&${nextY}`];
    const areaFeatures = featuresByLocation[`${nextX}&${nextY}`];
    const areaCharacters = charactersByLocation[`${nextX}&${nextY}`];

    const walkable = isWalkableArea(areaItems, areaFeatures, areaCharacters, region, nextX, nextY);

    const link = region.map.links[`${nextX}&${nextY}`];

    const walkProps = {};
    if (walkable || (canTravel && link)) {

      walkProps.x = nextX;
      walkProps.y = nextY;
      // TODO: We should set the pose even if we're not actually moving/the wall wasn't actually
      // walkable
      walkProps.pose = nextPose;
      walkProps.lastStep = nextLastStep;
    } else {
      walkProps.pose = REST;
      walkProps.lastStep = WALK_1;
    }

    if (dx !== 0 || dy !== 0) {
      dispatch(updateModel({
        modelType: 'characters',
        model: {
          id: characterId,
          direction: nextDirection,
          ...walkProps,
        },
      }));
    }

    const { activeCharacterId, partyMode } = getState().game;

    if (canTravel && link) {
      if (characterId === activeCharacterId) {
        if (partyMode === PartyModes.ALL) {
          dispatch(partyTravel({
            regionId: region.id,
            destinationRegionId: link.destinationId,
            x: link.destinationX,
            y: link.destinationY
          }));
        } else if (partyMode === PartyModes.SOLO) {
          dispatch(activeCharacterTravel({
            regionId: region.id,
            destinationRegionId: link.destinationId,
            x: link.destinationX,
            y: link.destinationY
          }));
        }
      } else {
        dispatch(travel({
          characterId: character.id,
          regionId: link.destinationId,
          x: link.destinationX,
          y: link.destinationY
        }));
      }
    }
  };
}

export function walkPath({
  characterId,
  x,
  y,
  existingGraph = null,
  doneCallback = null
}) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];
    const region = getState().models.regions[character.regionId];

    let graph = existingGraph;
    if (graph === null) {
      graph = createRegionPathfindingGraphSelector(region.id)(getState());
    }

    const start = graph.grid[character.x][character.y];
    const end = graph.grid[x][y];
    const result = astar.search(graph, start, end, { closest: true });

    if (result.length > 0) {
      let nextX = result[0].x;
      let nextY = result[0].y;

      const dx = nextX - character.x;
      const dy = nextY - character.y;
      dispatch(walk({ characterId, dx, dy }));

      if (existingGraph) {
        // This line is only correct if we assume the character belonged at its old location.
        graph.grid[character.x][character.y].weight = 1;
        graph.grid[nextX][nextY].weight = 0;
      }
    } else if (doneCallback) {
      doneCallback();
    }
  };
}

export function wander({ characterId }) {
  return async (dispatch, getState) => {
    const dx = Math.floor(Math.random() * 3) - 1;
    const dy = Math.floor(Math.random() * 3) - 1;

    dispatch(walk({ characterId: characterId, dx, dy }));
  };
}

export function partyCatchupWalk({ characterId, existingGraph = null }) {
  return async (dispatch, getState) => {
    const { activeCharacterId, partyMode } = getState().game;

    const partyCharacter = getState().models.characters[characterId];
    const activeCharacter = getState().models.characters[activeCharacterId];

    const { x, y, direction } = createPartyMemberPreferredPositionSelector(characterId)(getState());

    // If a party member is in a different region, just ignore them and let them stay where they are.
    // TODO: This should probably be done before this function so that we don't even call it on
    // characters that aren't in the region.
    if (partyCharacter.regionId !== activeCharacter.regionId) {
      return true;
    }

    if (x !== partyCharacter.x || y !== partyCharacter.y) {
      if (partyMode !== PartyModes.SOLO && characterId !== activeCharacterId) {
        dispatch(walkPath({
          characterId,
          x,
          y,
          existingGraph,
        }));
      }
    } else if (partyCharacter.direction !== direction) {
      // Give it a rest for a moment before updating the direction.  Makes it seem more natural.
      await sleep(Math.floor(Math.random() * 500) + 500);
      dispatch(updateModel({
        modelType: 'characters',
        model: {
          id: characterId,
          direction: direction,
        },
      }));
    } else if (partyCharacter.pose !== REST) {
      // Give it a rest for a moment before updating the pose.  Makes it seem more natural.
      await sleep(Math.floor(Math.random() * 500) + 500);
      dispatch(updateModel({
        modelType: 'characters',
        model: {
          id: characterId,
          pose: REST,
        },
      }));
    } else if (partyMode === PartyModes.CAMPING) {
      return true;
    }
    return false;
  };
}

export function updateMovement({ characterId, movement }) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];

    // TODO: this thunk should validate that `movement` is a shape it expects.  Should this file be a .ts file so we can use the types?

    dispatch(updateModel({
      modelType: 'characters',
      model: {
        id: characterId,
        movement: {
          ...character.movement,
          ...movement,
        }
      }
    }));
  };
}
