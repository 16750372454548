import Gather from './gather/Gather';

import { useCurrentActivity } from '../../data/hooks';
import { Combat } from './combat';
import { DraggablePanel } from '../generic';

import { ActivityTypes } from './data/types';

// These are not ALL the valid activities - just the one that are still displayed through this
// class.
const validActivities = [
  ActivityTypes.COMBAT,
  ActivityTypes.GATHER,
];

export default function Activities() {
  const activity = useCurrentActivity();

  if (validActivities.indexOf(activity) < 0) {
    return null;
  }

  return (
    <DraggablePanel
      id="activities"
      title="Activity"
    >
      {activity === ActivityTypes.COMBAT && (
        <Combat />
      )}
      {activity === ActivityTypes.GATHER && (
        <Gather />
      )}
    </DraggablePanel>
  );
}
