import hairstylesImage from '../spritesheets/character-hairstyles.png';
import chestsImage from '../spritesheets/character-chests.png';
import waistsImage from '../spritesheets/character-waist.png';
import legsImage from '../spritesheets/character-legs.png';
import feetImage from '../spritesheets/character-feet.png';
import skinsImage from '../spritesheets/character-skins.png';
import eyesIrisesImage from '../spritesheets/character-eyes-irises.png';
import eyesWhitesImage from '../spritesheets/character-eyes-whites.png';
import animalsSmallImage from '../spritesheets/animals-small.png';
import animalsLargeImage from '../spritesheets/animals-large.png';

const humanImages = {
  spriteWidth: 16,
  spriteHeight: 32,
  skins: {
    src: skinsImage,
    width: 360,
    height: 576,
    spriteWidth: 16,
    spriteHeight: 32,
    atlasFunction: 'characterSkins',
  },
  feet: {
    src: feetImage,
    width: 288,
    height: 216,
    spriteWidth: 16,
    spriteHeight: 32,
    atlasFunction: 'characterFeet',
  },
  legs: {
    src: legsImage,
    width: 288,
    height: 288,
    spriteWidth: 16,
    spriteHeight: 32,
    atlasFunction: 'characterLegs',
  },
  waists: {
    src: waistsImage,
    width: 288,
    height: 288,
    spriteWidth: 16,
    spriteHeight: 32,
    atlasFunction: 'characterWaist',
  },
  chests: {
    src: chestsImage,
    width: 288,
    height: 288,
    spriteWidth: 16,
    spriteHeight: 32,
    atlasFunction: 'characterChests',
  },
  hairstyles: {
    src: hairstylesImage,
    width: 288,
    height: 576,
    spriteWidth: 16,
    spriteHeight: 32,
    atlasFunction: 'characterHairstyles',
  },
  eyesIrises: {
    src: eyesIrisesImage,
    width: 288,
    height: 288,
    spriteWidth: 16,
    spriteHeight: 32,
    atlasFunction: 'characterEyesIrises',
  },
  eyesWhites: {
    src: eyesWhitesImage,
    width: 288,
    height: 288,
    spriteWidth: 16,
    spriteHeight: 32,
    atlasFunction: 'characterEyesWhites',
  }
};

const animalsSmallImages = {
  spriteWidth: 16,
  spriteHeight: 16,
  skins: {
    src: animalsSmallImage,
    width: 288,
    height: 288,
    spriteWidth: 16,
    spriteHeight: 16,
    atlasFunction: 'animalsSmall',
  }
};

const animalsLargeImages = {
  spriteWidth: 32,
  spriteHeight: 32,
  skins: {
    src: animalsLargeImage,
    width: 288,
    height: 288,
    spriteWidth: 32,
    spriteHeight: 32,
    atlasFunction: 'animalsLarge',
  }
};

const characterTemplates = [
  {
    id: 'rogue',
    name: 'Rogue',
    images: humanImages,
    agi: 4,
    cha: 3,
    int: 2,
    str: 2,
    running: 3,
    maxHealth: 15,
    flee: 0.5,
  },
  {
    id: 'warrior',
    name: 'Warrior',
    images: humanImages,
    agi: 1,
    cha: 2,
    int: 3,
    str: 4,
    maxHealth: 20,
    flee: 0.3,
  },
  {
    id: 'bandit',
    name: 'Bandit',
    images: humanImages,
    agi: 2,
    cha: 1,
    int: 1,
    str: 2,
    maxHealth: 20,
    flee: 0.4,
  },
  {
    id: 'thief',
    name: 'Thief',
    images: humanImages,
    agi: 3,
    cha: 2,
    int: 2,
    str: 1,
    running: 3,
    maxHealth: 15,
    flee: 0.5,
  },
  {
    id: 'strongarm',
    name: 'Strongarm',
    images: humanImages,
    agi: 1,
    cha: 1,
    int: 1,
    str: 4,
    maxHealth: 25,
    flee: 0.1,
  },
  {
    id: 'deer',
    name: 'Deer',
    images: animalsLargeImages,
    agi: 4,
    cha: 1,
    int: 1,
    str: 2,
    maxHealth: 15,
    damage: 4,
    defense: 2,
    flee: 0.8,
    running: 3,
    materials: {
      'deer-hide': 0.9,
      'antler': 0.1,
      'venison': 1,
    },
  },
  {
    id: 'boar',
    name: 'Boar',
    images: animalsLargeImages,
    agi: 2,
    cha: 1,
    int: 1,
    str: 3,
    maxHealth: 25,
    damage: 7,
    defense: 1,
    flee: 0.2,
    materials: {
      'tusk': 0.5,
      'pork': 1,
    },
  },
  {
    id: 'rabbit',
    name: 'Rabbit',
    images: animalsSmallImages,
    agi: 4,
    cha: 2,
    int: 1,
    str: 1,
    maxHealth: 10,
    damage: 1,
    defense: 1,
    flee: 0.9,
    running: 3,
    materials: {
      'rabbit-pelt': 0.9,
      'rabbit-meat': 1,
    }
  },
  {
    id: 'raccoon',
    name: 'Raccoon',
    images: animalsSmallImages,
    agi: 2,
    cha: 1,
    int: 2,
    str: 1,
    maxHealth: 10,
    damage: 1,
    defense: 1,
    running: 2,
    flee: 0.4,
  },
  {
    id: 'wolf',
    name: 'Wolf',
    images: animalsLargeImages,
    agi: 3,
    cha: 3,
    int: 1,
    str: 3,
    maxHealth: 25,
    damage: 10,
    defense: 4,
    flee: 0.2,
    running: 2,
    materials: {
      'wolf-pelt': 0.9,
    }
  },
  {
    id: 'cat',
    name: 'Cat',
    images: animalsSmallImages,
    agi: 3,
    cha: 3,
    int: 1,
    str: 1,
    maxHealth: 10,
    damage: 1,
    defense: 2,
    flee: 0.2,
    running: 2,
  }
];

export default characterTemplates;
