import { useDispatch } from 'react-redux';
import { useItem, useWindowSize } from '../data/hooks';
import { Floater, Panel } from './generic';
import Item from './Item';
import ItemQuantity from './ItemQuantity';
import { splitStack } from '../player/data/thunks';
import ActivityIconButton from './generic/ActivityIconButton';
import { useHud } from './data/hooks';

export default function GrabbedItemPanel() {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const {
    grabbedItemId,
  } = useHud();

  const item = useItem(grabbedItemId);

  const handleMinusOne = () => {
    dispatch(splitStack({ itemId: grabbedItemId, splitCount: 1 }));
  };

  const handleMinusFive = () => {
    dispatch(splitStack({ itemId: grabbedItemId, splitCount: 5 }));
  };

  const handleMinusHalf = () => {
    dispatch(splitStack({ itemId: grabbedItemId, splitCount: Math.floor(item.quantity / 2) }));
  };

  return (
    <Floater x={width - 200} y={8} alignX="right">
      <Panel className="overflow-visible p-2 d-flex gap-2 align-items-center">
        <Item scale={3} item={item} />
        <div style={{ minWidth: 48 }}>{item.uniqueName || item.name}</div>
        {item.quantity > 1 && (
          <>
            <ActivityIconButton activityId="minusOne" scale={2} className="position-absolute p-1" style={{ bottom: -52, left: -8 }} onClick={handleMinusOne} />
            <ActivityIconButton activityId="minusFive" scale={2} className="position-absolute p-1" style={{ bottom: -52, left: 42 }} onClick={handleMinusFive} />
            <ActivityIconButton activityId="minusHalf" scale={2} className="position-absolute p-1" style={{ bottom: -52, right: -16 }} onClick={handleMinusHalf} />
            <ItemQuantity quantity={item.quantity} />
          </>
        )}
      </Panel>
    </Floater>
  );
}
