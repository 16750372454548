import { useHud } from '../data/hooks';
import ContainerContents from './ContainerContents';

export default function OpenContainers() {
  const {
    openContainers
  } = useHud();

  return openContainers.map(containerItemId => {
    return (
      <ContainerContents key={containerItemId} containerItemId={containerItemId} />
    );
  });
}
