import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from './rootReducer';

const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false, // These two middleware are only used in development and are slowing down redux cause our state is huge.
      serializableCheck: false,
    }),
  ],
  preloadedState: {
    status: 'UNLOADED',
  }
});

export function getStore() {
  return store;
}

export default store;
