import { useDispatch } from 'react-redux';
import { useHud } from '../data/hooks';

import { updateViewportActivity } from '../data/slice';
import ActivityIconButton from '../generic/ActivityIconButton';

export default function WalkButton() {
  const dispatch = useDispatch();
  const {
    viewportActivity,
  } = useHud();

  const handleWalk = () => {
    dispatch(updateViewportActivity({ activity: 'walk' }));
  };

  return (
    <ActivityIconButton
      activityId="walk"
      scale={2}
      onClick={handleWalk}
      selected={viewportActivity === 'walk'}
    />
  );
}
