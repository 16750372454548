import { useSelector } from 'react-redux';

import { Conversation } from './activities/conversation';
import { useCharacter, useGame } from '../data/hooks';
import { DraggablePanel } from './generic';
import ActivityIcon, { ActivityIconTypes } from './generic/ActivityIcon';
import Character from './character/Character';
import { REST, SOUTH } from './character/constants';
import { getCutscene } from '../content-utils/cutsceneUtils';

export default function ConversationPanel() {
  const {
    activeConversationCharacterId,
  } = useSelector(state => state.conversation);

  const conversationPartner = useCharacter(activeConversationCharacterId);

  const {
    activeCutsceneId,
    activeCutsceneStep,
  } = useGame();

  let conversationId = conversationPartner.conversationId;
  if (activeCutsceneId !== null) {
    const cutscene = getCutscene(activeCutsceneId);
    const step = cutscene.steps[activeCutsceneStep];

    if (step.action === 'conversation') {
      conversationId = step.conversationId;
    }
  }

  const title = (
    <div className="d-flex align-items-center">
      <ActivityIcon activityId={ActivityIconTypes.TALK} scale={2} className="mr-1" />
      <span>{conversationPartner.name}</span>
    </div>
  );

  return (
    <DraggablePanel
      id="activities"
      title={title}
      style={{
        width: 600,
      }}
      panelClassName="d-flex p-3"
    >
      <Character
        portrait={true}
        scale={4}
        character={conversationPartner}
        direction={SOUTH}
        pose={REST}
        className="mr-3 border rounded-2 bg-parchment-300"
      />
      <Conversation conversationId={conversationId} />
    </DraggablePanel>
  );
}
