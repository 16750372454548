import itemsImage from '../../spritesheets/items.png';

const itemTemplates = [
  {
    id: 'campfire',
    name: 'Campfire',
    status: 'lit',
    image: {
      statuses: {
        lit: {
          src: itemsImage,
          position: { x: 12, y: 7 }
        },
        doused: {
          src: itemsImage,
          position: { x: 12, y: 8 }
        },
        cold: {
          src: itemsImage,
          position: { x: 12, y: 9 }
        }
      }
    },
    atlas: 'items',
    moveable: false,
    walkable: true,
    light: {
      lit: { rgb: 0xffa500, intensity: 8 },
      doused: { rgb: 0xff0000, intensity: 4 },
      cold: { rgb: 0x000000, intensity: 0 }
    },
  },
];

export default itemTemplates;
