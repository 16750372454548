import { useDispatch } from 'react-redux';
import { useHud } from '../data/hooks';

import { updateViewportActivity } from '../data/slice';
import ActivityIconButton from '../generic/ActivityIconButton';

export default function UseButton() {
  const dispatch = useDispatch();
  const {
    viewportActivity,
  } = useHud();

  const handleUse = () => {
    dispatch(updateViewportActivity({ activity: 'use' }));
  };

  return (
    <ActivityIconButton
      activityId="use"
      scale={2}
      onClick={handleUse}
      selected={viewportActivity === 'use'}
    />
  );
}
