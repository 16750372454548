import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { useCharacter } from '../../data/hooks';
import { removeOpenSheet } from '../data/slice';
import { DraggablePanel } from '../generic';
import ActivityIconButton from '../generic/ActivityIconButton';

import Statistics from './Statistics';
import Skills from './Skills';
import HealthBar from './HealthBar';
import EnduranceBar from './EnduranceBar';
import Portrait from './Portrait';
import Equipment from './equipment/Equipment';
import Abilities from './abilities/Abilities';
import Inventory from './inventory/Inventory';
import { useOpenSheet } from '../data/hooks';

export default function Sheet({ characterId }) {
  const panelId = `sheet-${characterId}`;
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState('stats');
  const character = useCharacter(characterId);
  const { minimized } = useOpenSheet(characterId);

  const handleClose = () => {
    dispatch(removeOpenSheet({ characterId }));
  };

  return (
    <DraggablePanel
      id={panelId}
      className="d-flex"
      title={character.name}
      onClose={handleClose}
    >
      <div className="d-flex flex-column w-container">
        {!minimized && (
          <div className="d-flex justify-content-between align-items-center mb-3 gap-2">
            <ActivityIconButton
              activityId="stats"
              onClick={() => setActiveKey('stats')}
              selected={activeKey === 'stats'}
            />
            <ActivityIconButton
              activityId="equipment"
              onClick={() => setActiveKey('equipment')}
              selected={activeKey === 'equipment'}
            />
            <ActivityIconButton
              activityId={activeKey === 'inventory' ? 'backpackOpen' : 'backpack'}
              selected={activeKey === 'inventory'}
              onClick={() => setActiveKey('inventory')}
            />
          </div>
        )}
        <div className="flex-grow-1">
          {activeKey === 'stats' && (
            <>
              <div className={classNames({ 'mb-3': !minimized })}>
                <div className="d-flex gap-2 align-items-start">
                  <Portrait characterId={character.id} />
                  <div className="flex-grow-1">
                    <HealthBar characterId={character.id} />
                    <EnduranceBar characterId={character.id} />
                  </div>
                </div>
              </div>
              {!minimized && (
                <>
                  <Statistics character={character} className="mb-3" />
                  <div className="d-flex justify-content-between gap-3">
                    <Abilities character={character} className="flex-grow-1" />
                    <div className="border-left"></div>
                    <Skills character={character}  className="flex-grow-1" />
                  </div>
                </>
              )}
            </>
          )}
          {activeKey === 'equipment' && (
            <div className={classNames('d-flex flex-column')}>
              <Equipment character={character} />
            </div>
          )}
          {activeKey === 'inventory' && (
            <Inventory characterId={character.id} />
          )}
        </div>
      </div>
    </DraggablePanel>
  );
}
