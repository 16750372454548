
// Directions
export const SOUTH = 's';
export const EAST = 'e';
export const WEST = 'w';
export const NORTH = 'n';

// Poses
export const REST = 'rest';
export const BLINK = 'blink';
export const WALK_1 = 'walk1';
export const WALK_2 = 'walk2';
export const UNCONSCIOUS = 'unconscious';
