import { useDispatch } from 'react-redux';

import { useHasSave } from '../../saves/data/hooks';

import { updateShowMenu } from '../data/slice';
import { useHud } from '../data/hooks';
import { Button, Panel } from '../generic';
import SettingsMenu from '../SettingsMenu';

import LoadButton from './LoadButton';
import QuitButton from './QuitButton';
import SaveButton from './SaveButton';
import SettingsButton from './SettingsButton';
import { useGame, useStory } from '../../data/hooks';

export default function InGameMenu() {
  const dispatch = useDispatch();
  const hasSave = useHasSave();
  const { showSettingsMenu } = useHud();
  const { activeStoryId } = useGame();
  const { title } = useStory(activeStoryId);

  const handleMenuClose = () => {
    dispatch(updateShowMenu({ showMenu: false }));
  };

  if (showSettingsMenu) {
    return (
      <SettingsMenu />
    );
  }

  return (
    <Panel className="p-5 d-flex flex-column justify-content-center align-items-center">
      <h1 className="mb-4 d1">{title}</h1>

      <SaveButton className="mb-2" />

      {hasSave && (
        <LoadButton className="mb-2" />
      )}
      <SettingsButton className="mb-2" />
      <QuitButton className="mb-5" />

      <Button style={{ width: 160 }} onClick={handleMenuClose}>Back</Button>
    </Panel>
  );
}
