import Phaser from 'phaser';

import createSelectorListener from '../../engine/createSelectorListener';
import { createRegionItemsSelector } from '../data/selectors';
import ItemSprite from '../sprites/ItemSprite';

export default class Items extends Phaser.GameObjects.Group {

  constructor(region, elevationLayers, ...args) {
    super(...args);

    this.region = region;
    this.elevationLayers = elevationLayers;
    this.handleItemsChanged = this.handleItemsChanged.bind(this);
    this.currentItemsById = {};

    const { remove, data } = createSelectorListener(
      createRegionItemsSelector(this.region.id),
      this.handleItemsChanged
    );
    this.handleItemsChanged(data);

    this.removeModelListener = remove;
  }

  destroy() {
    this.removeModelListener();
  }

  handleItemsChanged(items) {
    const nextCurrentItemsById = {};
    const remainingCurrentItemsById = { ...this.currentItemsById };
    const newItemsData = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (remainingCurrentItemsById[item.id] !== undefined) {
        // Pass the sprite to the new list
        nextCurrentItemsById[item.id] = remainingCurrentItemsById[item.id];
        // Delete it in the old list so we can filter down to what's left, which should be retired
        delete remainingCurrentItemsById[item.id];
      } else {
        newItemsData.push(item);
      }
    }

    const retiredItems = Object.values(remainingCurrentItemsById);

    newItemsData.forEach(item => {
      // create a new ItemSprite
      const atlas = 'items';
      let frame = item.templateId;
      if (item.status) {
        frame += `@${item.status}`;
      } else if (item.image.quantities) {
        frame += `@${item.quantity > 1 ? 'many' : 'one'}`;
      }

      const itemSprite = new ItemSprite(item.id, this.scene, 0, 0, atlas, frame);
      nextCurrentItemsById[item.id] = itemSprite;

      const elevation = item.elevation;
      if (this.elevationLayers[elevation] === undefined) {
        this.elevationLayers[elevation] = this.scene.add.layer();
        this.elevationLayers[elevation].setDepth(elevation);
      }

      this.add(itemSprite);
      this.elevationLayers[elevation].add(itemSprite);
      // So this is the depth _within_ the layer.  We want items to be below other entities on
      // their elevation.
      itemSprite.setDepth(0);
    });

    retiredItems.forEach(retiredItem => {
      // destroy existing item sprite
      retiredItem.destroy();
    });

    this.currentItemsById = nextCurrentItemsById;
  }

  preUpdate(time, delta) {
    this.children.each((child) => {
      child.preUpdate(time, delta);
    });
  }
}
