import { createSelector } from '@reduxjs/toolkit';

import { validSkills } from '../../../content-utils/characterUtils';

export function createCharacterSkillsSelector(characterId) {
  return createSelector(
    state => state.models.characters[characterId],
    (character) => {
      const skills = {};

      validSkills.forEach(validSkill => {
        skills[validSkill] = Math.floor(character[validSkill]);
      });

      return skills;
    }
  );
}
