import { createSelector } from '@reduxjs/toolkit';

import { hydrateItem } from '../../../data/contentUtils';

export function createCharacterItemsSelector(characterId) {
  return createSelector(
    state => state.models.items,
    (itemsById) => {
      const items = [];
      Object.values(itemsById).forEach(item => {
        if (item.characterId === characterId) {
          items.push(hydrateItem(item));
        }
      });
      return items;
    }
  );
}

export function createCharacterInventorySelector(characterId) {
  return createSelector(
    state => state.models.items,
    (itemsById) => {
      const items = [];
      Object.values(itemsById).forEach(item => {
        if (item.characterId === characterId && item.slot === null) {
          items.push(hydrateItem(item));
        }
      });
      return items.sort((a, b) => a.order - b.order);
    }
  );
}

export function createCharacterEquipmentBySlotSelector(characterId) {
  return createSelector(
    state => state.models.items,
    (itemsById) => {
      const items = {};
      Object.values(itemsById).forEach(item => {
        if (item.characterId === characterId && item.slot !== null) {
          items[item.slot] = hydrateItem(item);
        }
      });
      return items;
    }
  );
}

export function createCharacterEquipmentSlotSelector(characterId, slot) {
  return createSelector(
    state => state.models.items,
    (itemsById) => {
      let slotItem = null;
      Object.values(itemsById).forEach(item => {
        if (item.characterId === characterId && item.slot === slot) {
          slotItem = hydrateItem(item);
        }
      });
      return slotItem;
    }
  );
}
