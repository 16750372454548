import { EAST, WEST, NORTH, SOUTH } from '../../../character/constants';

export function getCampfirePosition(character) {
  const { x, y, direction } = character;
  if (direction === WEST) {
    return {
      x: x - 1,
      y,
    };
  } else if (character.direction === EAST) {
    return {
      x: x + 1,
      y,
    };
  } else if (character.direction === NORTH) {
    return {
      x,
      y: y - 1,
    };
  } else if (character.direction === SOUTH) {
    return {
      x,
      y: y + 1,
    };
  }
  return {
    x, y
  };
}
