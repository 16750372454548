import { getStore } from '../../../data/configureStore';
import { createInterval } from '../../../data/renderLoopUtils';
import { updateWalkDirections } from '../../../data/slice';
import { performMovementInput } from './performers/movement';

let inputControls = null;

export function isInputWalking() {
  return inputControls !== null;
}

export function createInputDirector(options = {}) {
  const listen = options.listen !== undefined ? options.listen : true;

  if (listen) {
    inputControls = createInterval(150, performMovementInput);
  }
  // Run it once immediately.
  performMovementInput();

  if (listen) {
    inputControls.play();
  }
}

export function destroyInputDirector() {
  if (inputControls !== null) {
    inputControls.abort();
    // Since we forcibly stopped the loop, clean up our walk directions.
    inputControls = null;
  }

  getStore().dispatch(updateWalkDirections({
    walkNorth: false,
    walkSouth: false,
    walkWest: false,
    walkEast: false
  }));
}
