import {
  removeModel,
  removeModels,
} from '../../../../models-store';
import {
  updateActingCharacter,
} from '../../../../data/slice';
import { resetCombat, updateCombatStatus, updateDistance, updateMessages } from './slice';
import { sleep } from '../../../../data/utils';
import { endActivity } from '../../data/thunks';
import { CombatStatuses } from './types';
import { createCharacterItemsSelector } from '../../../../characters/items/data/selectors';
import { attackCharacter } from '../../../../characters/combat/data/thunks';

export function flee() {
  return async (dispatch, getState) => {
    dispatch(endCombat());
  };
}

export function leave() {
  return async (dispatch, getState) => {
    dispatch(endCombat());
  };
}

export function endCombat() {
  return async (dispatch, getState) => {
    const opponentId = getState().combat.opponentId;
    const opponentItems = createCharacterItemsSelector(opponentId)(getState());

    dispatch(resetCombat());
    dispatch(removeModels({
      modelType: 'items',
      ids: opponentItems.map(item => item.id)
    }));
    dispatch(removeModel({ modelType: 'characters', id: opponentId }));
    dispatch(endActivity());
  };
}

export function attack({ activeCharacterId, opponentId }) {
  return async (dispatch, getState) => {
    dispatch(attackCharacter({ attackerId: activeCharacterId, defenderId: opponentId }));

    // Check for victory
    const opponent = getState().models.characters[opponentId];
    if (opponent.health === 0) {
      dispatch(win({ opponentId }));
      return;
    }

    // No victory, continue!
    dispatch(updateActingCharacter({ characterId: opponentId }));

    await sleep(2000);
    dispatch(attackCharacter({ attackerId: opponentId, defenderId: activeCharacterId }));

    // No defeat, continue!
    dispatch(updateActingCharacter({ characterId: activeCharacterId }));
  };
}

export function strike({ opponentId, characterId, ambush }) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];

    const bonusDamage = ambush ? character.hunting : 0;

    dispatch(attackCharacter({
      attackerId: characterId,
      defenderId: opponentId,
      bonusDamage,
    }));

    // Check for victory
    const opponent = getState().models.characters[opponentId];
    if (opponent.health === 0) {
      dispatch(win({ opponentId }));
      return;
    }

    // TODO: This is whether they WANT to flee, also check whether they're good enough to.
    const fleePercentage = Math.min(
      1,
      opponent.flee + (
        (opponent.maxHealth - opponent.health) / opponent.maxHealth
      )
    );

    if (Math.random() < fleePercentage) {
      dispatch(updateCombatStatus({ status: CombatStatuses.CHASING }));

      // Try to run away, opponent!
      const { distance } = getState().combat;

      if ((distance + 1) < 5) {
        dispatch(updateDistance({ distance: distance + 1 }));
      } else {
        dispatch(updateCombatStatus({ status: CombatStatuses.OPPONENT_ESCAPED }));
        return;
      }
    } else {
      dispatch(updateCombatStatus({ status: CombatStatuses.FIGHTING }));
    }
  };
}

export function chase({ opponentId, characterId }) {
  return async (dispatch, getState) => {
    const { distance } = getState().combat;

    const opponent = getState().models.characters[opponentId];
    const character = getState().models.characters[characterId];

    if (Math.random() < (
      opponent.running / (opponent.running + character.hunting)
    )) {
      // successfully ran away!
      if ((distance + 1) < 5) {
        dispatch(updateDistance({ distance: distance + 1 }));
      } else {
        dispatch(updateCombatStatus({ status: CombatStatuses.OPPONENT_ESCAPED }));
        return;
      }
    } else {
      // successfully caught up!
      dispatch(updateDistance({ distance: distance - 1 }));
    }
  };
}

export function win({ opponentId }) {
  return async (dispatch, getState) => {
    const opponent = getState().models.characters[opponentId];
    dispatch(updateMessages({
      message: `You have defeated ${opponent.name}!`
    }));

    dispatch(updateCombatStatus({ status: CombatStatuses.VICTORY }));
  };
}

export function stalk() {
  return async (dispatch, getState) => {
    const { distance } = getState().combat;
    dispatch(updateDistance({ distance: distance - 1 }));
  };
}
