import { getStore } from '../../data/configureStore';
import { updateActingCharacter, updateActiveCutsceneId, updateActiveCutsceneStep, updateTimeMode } from '../../data/slice';
import { TimeModes } from '../../data/types';
import { destroyCutsceneDirector } from '../directors/cutscene/director';
import { createLiveDirector } from '../directors/live/director';
import { destroySequenceDirector } from '../directors/sequence/director';

export function play() {
  // If we've hit play, the only performers we can clean up are the 'pause' ones - this should never happen if we're in the middle of a cutscene.
  destroySequenceDirector();
  destroyCutsceneDirector();

  const { dispatch } = getStore();

  dispatch(updateTimeMode({ timeMode: TimeModes.NORMAL }));
  dispatch(updateActiveCutsceneId({ cutsceneId: null }));
  dispatch(updateActiveCutsceneStep({ cutsceneStep: null }));
  dispatch(updateActingCharacter({ characterId: null }));
  createLiveDirector();
}
