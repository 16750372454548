let terrains = {};
let walls = {};

export function setTerrains(terrainsById) {
  terrains = terrainsById;
}

export function setWalls(wallsById) {
  walls = wallsById;
}

function assertTerrain(id) {
  const template = terrains[id];
  if (template === undefined) {
    throw new Error(`Terrain ${id} does not exist!`);
  }
}

function assertWall(id) {
  const template = walls[id];
  if (template === undefined) {
    throw new Error(`Wall ${id} does not exist!`);
  }
}

export function getTerrain(id) {
  assertTerrain(id);
  return terrains[id];
}

export function getWall(id) {
  assertWall(id);
  return walls[id];
}

function generateAtlas(modelsById) {
  const tileSize = 16;
  const margin = 1;
  const spacing = 2;
  const frames = [];

  const frameDefaults = {
    rotated: false,
    trimmed: false,
    spriteSourceSize: { x: 0, y: 0, w: tileSize, h: tileSize },
    sourceSize: { w: tileSize, h: tileSize },
    pivot: { x: 0, y: 0 }
  };

  Object.values(modelsById).forEach(model => {
    const { position } = model.image;
    frames.push({
      filename: `${model.id}`,
      frame: {
        x: margin + (position.x * tileSize) + (position.x * spacing),
        y: margin + (position.y * tileSize) + (position.y * spacing),
        w: tileSize,
        h: tileSize
      },
      ...frameDefaults,
    });
  });

  return { frames };
}

export function generateWallsAtlas() {
  return generateAtlas(walls);
}

export function generateTerrainAtlas() {
  return generateAtlas(terrains);
}
