import { useSelector } from 'react-redux';
import { createModelSelector, createModelsSelector } from './selectors';

export function useModel(modelType, modelId) {
  return useSelector(createModelSelector(modelType, modelId));
}

export function useModels(modelType, modelIds) {
  return useSelector(createModelsSelector(modelType, modelIds));
}
