import { createItemWithChildren } from '../../../../content-utils/itemUtils';
import { addModels, removeModels } from '../../../../models-store';
import { endActivity, startActivity } from '../../data/thunks';
import { gatherItemsSelector } from './selectors';
import { ActivityTypes } from '../../data/types';
import { learnSkill } from '../../../../characters/skills/data/thunks';
import { createCharacterSkillsSelector } from '../../../../characters/skills/data/selectors';
import { decreaseEndurance } from '../../../../characters/status/data/thunks';

export function startGathering({ characterId, regionId }) {
  return async (dispatch, getState) => {
    const region = getState().models.regions[regionId];
    const { gathering } = createCharacterSkillsSelector(characterId)(getState());

    const gatherableItems = [];
    const gatherables = region.gatherables;

    Object.entries(gatherables).forEach(([gatherableTemplateId, probability], index) => {

      // Each point in gathering increases the probability by 1%.
      const gatheringBonusPercentage = (gathering / 100);
      const shouldGenerateGatherable = Math.random() < (probability + gatheringBonusPercentage);

      if (shouldGenerateGatherable) {
        const createdItems = createItemWithChildren({
          templateId: gatherableTemplateId,
          containerItemId: 'gather-container',
          order: index,
        });
        Object.values(createdItems).forEach(createdItem => {
          gatherableItems.push(createdItem);
        });
      }
    });

    if (gatherableItems.length > 0) {
      dispatch(addModels({ modelType: 'items', models: gatherableItems }));
    }

    dispatch(learnSkill({
      characterId,
      skill: 'gathering',
      experience: 1
    }));
    dispatch(decreaseEndurance({ characterId, amount: 2 }));
    dispatch(startActivity({ activity: ActivityTypes.GATHER }));
  };
}

export function endGathering() {
  return async (dispatch, getState) => {
    const gatherables = gatherItemsSelector(getState());
    const gatherableIds = gatherables.map(gatherable => gatherable.id);

    dispatch(removeModels({ modelType: 'items', ids: gatherableIds }));
    dispatch(endActivity());
  };
}
