import { v4 as uuidv4 } from 'uuid';
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CombatState } from './types';

export const initialState: CombatState = {
  opponentId: null,
  distance: null,
  status: null,
  messages: [],
};

const slice = createSlice({
  name: 'combat',
  initialState,
  reducers: {
    startCombat: (state: CombatState, action) => {
      const { payload } = action;
      const {
        opponentId,
        distance,
        status
      } = payload;

      state.status = status;
      state.opponentId = opponentId;
      state.distance = distance;
    },
    updateCombatStatus: (state: CombatState, { payload }) => {
      const { status } = payload;
      state.status = status;
    },
    updateDistance: (state: CombatState, { payload }) => {
      const { distance } = payload;

      state.distance = distance;
    },
    resetCombat: (state: CombatState) => {
      state.opponentId = null;
      state.status = null;
      state.distance = null;
      state.messages = [];
    },
    updateMessages: (state: CombatState, { payload }) => {
      const { message } = payload;
      state.messages = [...state.messages, {
        id: uuidv4(),
        message,
      }];
    },
  },
});

export const {
  // Combat
  startCombat,
  updateCombatStatus,
  resetCombat,
  updateDistance,
  updateMessages,
} = slice.actions;

export const { reducer } = slice;
