export default function ItemQuantity({ quantity, style = {}}) {
  return (
    <div
      className="position-absolute border border-primary bg-white rounded-circle xsmall"
      style={{
        right: -7,
        bottom: -7,
        width: 16,
        height: 16,
        textAlign: 'center',
        lineHeight: 1.7,
        ...style,
      }}
    >
      {quantity}
    </div>
  );
}
