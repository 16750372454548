import { updateModel } from '../../../models-store';

export function updateRelationship({
  characterId,
  relatedCharacterId,
  conversationStatus = null,
  level = null,
  knownName = null,
  talkDistance = null
}) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];
    // Relationships are not pre-defined, since it'd be N^2 connections.  So we create a default
    // one for relationships that don't exist.
    const relationship = {
      conversationStatus: 'NEUTRAL',
      level: 0,
      knownName: false,
      // Override defaults with whatever's in this relationship, if it exists.
      ...(character.relationships[relatedCharacterId] || {})
    };

    if (level !== null) {
      relationship.level = level;
    }

    if (conversationStatus !== null) {
      relationship.conversationStatus = conversationStatus;
    }

    if (knownName !== null) {
      relationship.knownName = knownName;
    }

    if (talkDistance !== null) {
      relationship.talkDistance = talkDistance;
    }

    dispatch(updateModel({
      modelType: 'characters',
      model: {
        id: characterId,
        relationships: {
          ...character.relationships,
          [relatedCharacterId]: relationship,
        },
      },
    }));
  };
}
