import Phaser from 'phaser';
import { getAngle } from '../../data/utils';

const THROW_VELOCITY = 500; // 400 pixels per second

export default class ProjectileSprite extends Phaser.GameObjects.Image {
  constructor(...args) {
    super(...args);

    this.targetX = null;
    this.targetY = null;
    this.originalX = null;
    this.originalY = null;
  }

  setTargetPosition(x, y, completeCallback) {
    // Maybe limit how far away this target position can be, but do it in the thunk where we assign the new location.  That way characters are limited in how far they can throw.
    this.targetX = x + 8;
    this.targetY = y + 8;
    this.originalX = this.x;
    this.originalY = this.y;
    this.completeCallback = completeCallback;
  }

  preUpdate(time, delta) {
    // The partial velocity for this delta
    const dV = delta / 1000 * THROW_VELOCITY;

    if (this.targetX !== null && this.targetY !== null) {
      const dx = this.targetX - this.originalX;
      const dy = this.targetY - this.originalY;
      const magnitudeX = Math.abs(dx);
      const magnitudeY = Math.abs(dy);

      const normalX = dx / (magnitudeX + magnitudeY);
      const normalY = dy / (magnitudeX + magnitudeY);

      const nextX = this.x + (normalX * dV);
      const nextY = this.y + (normalY * dV);

      const effectAngle = (getAngle(dx, dy) + 225) % 360;

      // See if the next X or Y is 'beyond' the target.  If so, stop.
      const stop = (
        (this.targetX < this.x && nextX < this.targetX) ||
        (this.targetX > this.x && nextX > this.targetX) ||
        (this.targetY < this.y && nextY < this.targetY) ||
        (this.targetY > this.y && nextY > this.targetY)
      );

      this.setPosition(
        nextX,
        nextY,
      );

      this.setAngle(effectAngle);
      this.setOrigin(0.5, 0.5);

      if (stop) {
        this.setPosition(this.targetX - 8, this.targetY - 8);
        this.targetX = null;
        this.targetY = null;
        this.originalX = null;
        this.originalY = null;
        // this.setRotation(0);
        this.setOrigin(0, 0);
        this.completeCallback();
        this.completeCallback = null;

        // This effect is OVAH!
        super.destroy();
      }
    }
  }
}
