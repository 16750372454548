import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { stopTrading } from './data/thunks';
import {
  useActiveCharacter,
  useClickAttributes
} from '../../../data/hooks';
import { Button, DraggablePanel, Well } from '../../generic';
import ActivityIcon from '../../generic/ActivityIcon';
import ItemTile from '../../ItemTile';
import Statistic from '../../sheet/Statistic';
import {
  useBuyPrice,
  useSellItems,
  useSellPrice,
  useTrade,
  useTradePartner
} from './data/hooks';
import {
  addBuyItem,
  addSellItem,
  removeBuyItem,
  removeSellItem
} from './data/slice';
import { completeTrade } from './data/thunks';

export default function Trade() {
  const dispatch = useDispatch();
  const {
    tradePartner,
    items
  } = useTradePartner();

  const activeCharacter = useActiveCharacter();
  const { gold } = activeCharacter;
  const { gold: partnerGold } = tradePartner;
  const sellItems = useSellItems(activeCharacter.id, tradePartner.id);

  const buyPrice = useBuyPrice();
  const sellPrice = useSellPrice();

  const { sellItemIds, buyItemIds } = useTrade();

  const handleEnd = () => {
    dispatch(stopTrading());
  };

  const handleTrade = () => {
    dispatch(completeTrade());
  };

  const handleSelectShopItem = (item) => {
    if (buyItemIds.includes(item.id)) {
      dispatch(removeBuyItem({ itemId: item.id }));
    } else {
      dispatch(addBuyItem({ itemId: item.id }));
    }
  };

  const handleSelectSellItem = (item) => {
    if (sellItemIds.includes(item.id)) {
      dispatch(removeSellItem({ itemId: item.id }));
    } else {
      dispatch(addSellItem({ itemId: item.id }));
    }
  };

  const goldChange = sellPrice - buyPrice;

  const remainingGold = gold + goldChange;
  const partnerRemainingGold = partnerGold - goldChange;
  const tooExpensive = remainingGold < 0 || partnerRemainingGold < 0;

  const willLoseGold = goldChange < 0;
  const willGainGold = goldChange > 0;

  return (
    <DraggablePanel
      id="trade"
      title={`Trade with ${tradePartner.name}`}
      onClose={handleEnd}
    >
      <div className="d-flex align-items-center justify-content-between">
        <Statistic
          className="mb-1"
          statId="gold"
          name="Gold"
        >
          <span>{partnerGold}</span>
        </Statistic>
        <div className="d-flex">
          {willLoseGold ? (
            <ActivityIcon activityId="goldLost" scale={2} />
          ) : (
            <ActivityIcon activityId="" scale={2} />
          )}
          <div>
            <span
              className={classNames('px-1')}>{goldChange < 0 ? '-' : '+'}</span>
            <span>{Math.abs(goldChange)}</span>
            {tooExpensive && (
              <span>Too expensive!</span>
            )}
          </div>
          {willGainGold && (
            <ActivityIcon activityId="goldGained" scale={2} />
          )}
        </div>
        <Statistic
          className="mb-1"
          statId="gold"
          name="Gold"
        >
          <span>{gold}</span>
        </Statistic>

      </div>
      <div className="d-flex gap-2 mb-3" style={{ width: 376 }}>
        <Well className="w-50">
          {items.length === 0 && (
            <p>There's nothing left for you to purchase.</p>
          )}

          {items.length > 0 && (
            <>
              <p>Buy</p>
              <div
                className={classNames(
                  'd-flex flex-wrap justify-content-start align-items-start',
                  'gap-2 grid-gap-2',
                )}
              >
                {items.map((item) => {
                  const price = tradePartner.sells[item.templateId];
                  return (
                    <ShopItem
                      key={item.id}
                      item={item}
                      price={price}
                      selected={buyItemIds.includes(item.id)}
                      disabled={price > remainingGold}
                      onClick={handleSelectShopItem}
                    />
                  );
                })}
              </div>
            </>
          )}
        </Well>
        <Well className="w-50">
          {sellItems.length === 0 && (
            <p>There's nothing left for you to sell.</p>
          )}

          {sellItems.length > 0 && (
            <>
              <p>Sell</p>
              <div
                className={classNames(
                  'd-flex flex-wrap justify-content-start align-items-start',
                  'gap-2 grid-gap-2',
                )}
              >
                {sellItems.map((item) => {
                  const price = tradePartner.buys[item.templateId];
                  return (
                    <SellItem
                      key={item.id}
                      item={item}
                      price={price}
                      selected={sellItemIds.includes(item.id)}
                      disabled={price > partnerRemainingGold}
                      onClick={handleSelectSellItem}
                    />
                  );
                })}
              </div>
            </>
          )}
        </Well>
      </div>
      <div className="d-flex justify-content-between">
        <Button onClick={handleTrade}>Trade</Button>
        <Button onClick={handleEnd}>Leave Shop</Button>
      </div>
    </DraggablePanel>
  );
}

function SellItem({ item, price, onClick, disabled, selected }) {
  const clickAttributes = useClickAttributes(() => {
    onClick(item);
  });

  return (
    <ItemTile
      item={item}
      scale={2}
      className={classNames(
        'bg-parchment-100',
        { 'bg-parchment-500': selected },
        { 'border-dashed': disabled }
      )}
      {...clickAttributes}
    />
  );
}

function ShopItem({ item, price, onClick, disabled, selected }) {
  const clickAttributes = useClickAttributes(() => {
    onClick(item);
  });

  return (
    <ItemTile
      item={item}
      scale={2}
      className={classNames(
        'bg-parchment-100',
        { 'bg-parchment-500': selected },
        { 'border-dashed': disabled }
      )}
      {...clickAttributes}
    />
  );
}
