import { useMemo } from 'react';
import { atlasFunctionLookup } from '../../../content-utils/characterUtils';

import { SOUTH, REST } from '../constants';

export default function BodyPart({
  className,
  scale = 1,
  image,
  style = {},
  direction = SOUTH,
  pose = REST,
  portrait,
  bodyPart,
}) {
  const atlasLookup = useMemo(() => {
    const atlasMap = {};
    const atlas = atlasFunctionLookup[image.atlasFunction]({ scale });
    atlas.frames.forEach(frame => {
      atlasMap[frame.filename] = frame;
    });
    return atlasMap;
  }, [image, scale]);

  const upperBodyElevation = 2;
  const upperBodyFrame = atlasLookup[`${bodyPart.style}@${pose}@${direction}@${upperBodyElevation}`].frame; // theThing.frame; //

  const lowerBodyElevation = 1;
  const lowerBodyFrame = atlasLookup[`${bodyPart.style}@${pose}@${direction}@${lowerBodyElevation}`].frame; // atlasLookup[tempLowerFrame].frame; //

  const upperPositionX = -upperBodyFrame.x;
  const upperPositionY = -upperBodyFrame.y;
  const lowerPositionX = -lowerBodyFrame.x;
  const lowerPositionY = -lowerBodyFrame.y;

  const backgroundWidth = image.width * scale;
  const backgroundHeight = image.height * scale;

  const fullHeight = upperBodyFrame.h + lowerBodyFrame.h;
  const fullWidth = upperBodyFrame.w;
  const finalHeight = portrait ? upperBodyFrame.h : fullHeight;
  const finalWidth = portrait ? upperBodyFrame.w : fullWidth;

  return (
    <div
      className={className}
      style={{
        width: `${finalWidth}px`,
        height: `${finalHeight}px`,
        ...style,
      }}
    >
      <div
        className="position-absolute"
        style={{
          top: 0,
          width: `${upperBodyFrame.w}px`,
          height: `${upperBodyFrame.h}px`,
          backgroundImage: `url('${image.src}')`,
          WebkitMaskImage: `url('${image.src}')`,
          backgroundSize: `${backgroundWidth}px ${backgroundHeight}px`,
          WebkitMaskSize: `${backgroundWidth}px ${backgroundHeight}px`,
          backgroundPosition: `${upperPositionX}px ${upperPositionY}px`,
          WebkitMaskPosition:  `${upperPositionX}px ${upperPositionY}px`,
          backgroundColor: bodyPart.tint ? bodyPart.tint : null,
          backgroundBlendMode: 'multiply',
        }}
      />
      <div
        className="position-absolute"
        style={{
          width: `${lowerBodyFrame.w}px`,
          height: `${lowerBodyFrame.h}px`,
          top: `${upperBodyFrame.h}px`,
          backgroundImage: `url('${image.src}')`,
          WebkitMaskImage: `url('${image.src}')`,
          backgroundSize: `${backgroundWidth}px ${backgroundHeight}px`,
          WebkitMaskSize: `${backgroundWidth}px ${backgroundHeight}px`,
          backgroundPosition: `${lowerPositionX}px ${lowerPositionY}px`,
          WebkitMaskPosition:  `${lowerPositionX}px ${lowerPositionY}px`,
          backgroundColor: bodyPart.tint ? bodyPart.tint : null,
          backgroundBlendMode: 'multiply',
        }}
      />
    </div>
  );
}
