import featuresImage from '../../spritesheets/features.png';

const featureTemplates = [
  {
    id: 'big-wooden-counter-nw',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 7, y: 11 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-counter-n',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 5, y: 11 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-counter-ne',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 6, y: 11 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-counter-w',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 7, y: 12 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-counter',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 5, y: 12 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-counter-e',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 6, y: 12 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-counter-sw',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 7, y: 13 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-counter-s',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 5, y: 13 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-counter-se',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 6, y: 13 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'h-wooden-counter-w',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 4, y: 14 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'h-wooden-counter',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 5, y: 14 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'h-wooden-counter-e',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 6, y: 14 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'v-wooden-counter-n',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 7, y: 11 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'v-wooden-counter',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 7, y: 12 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'v-wooden-counter-s',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 7, y: 13 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'small-wooden-counter',
    name: 'Wooden Counter',
    image: {
      src: featuresImage,
      position: { x: 7, y: 14 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'wooden-counter-side-w',
    name: 'Wooden Counter Side',
    image: {
      src: featuresImage,
      position: { x: 4, y: 15 }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'wooden-counter-side',
    name: 'Wooden Counter Side',
    image: {
      src: featuresImage,
      position: { x: 5, y: 15 }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'wooden-counter-side-e',
    name: 'Wooden Counter Side',
    image: {
      src: featuresImage,
      position: { x: 6, y: 15 }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'small-wooden-counter-side',
    name: 'Wooden Counter Side',
    image: {
      src: featuresImage,
      position: { x: 7, y: 15 }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },

  // Counter Passthroughs
  {
    id: 'counter-passthrough-v-closed-n',
    name: 'Counter Passthrough',
    status: 'closed',
    door: true,
    doorSection: 'top',
    image: {
      statuses: {
        closed: {
          src: featuresImage,
          position: { x: 8, y: 11 },
        },
        open: {
          src: featuresImage,
          position: { x: 9, y: 11 }
        },
      }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'counter-passthrough-v-closed-s',
    name: 'Counter Passthrough',
    status: 'closed',
    door: true,
    doorSection: 'bottom',
    image: {
      statuses: {
        closed: {
          src: featuresImage,
          position: { x: 8, y: 12 },
        },
        open: {
          src: featuresImage,
          position: { x: 9, y: 12 }
        },
      }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },

  {
    id: 'counter-passthrough-h-closed-n',
    name: 'Counter Passthrough',
    status: 'closed',
    door: true,
    doorSection: 'top',
    image: {
      statuses: {
        closed: {
          src: featuresImage,
          position: { x: 8, y: 13 },
        },
        open: {
          src: featuresImage,
          position: { x: 9, y: 13 }
        },
      }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'counter-passthrough-h-closed-s',
    name: 'Counter Passthrough',
    status: 'closed',
    door: true,
    doorSection: 'bottom',
    image: {
      statuses: {
        closed: {
          src: featuresImage,
          position: { x: 8, y: 14 },
        },
        open: {
          src: featuresImage,
          position: { x: 9, y: 14 }
        },
      }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },

];

export default featureTemplates;
