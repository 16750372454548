import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { usePartyGold } from '../characters/data/hooks';
import { useGame, useLeaderCharacter } from '../data/hooks';
import { PartyModes } from '../data/types';
import { DraggablePanel } from './generic';
import Statistic from './sheet/Statistic';
import ActivityIconButton from './generic/ActivityIconButton';
import SheetButton from './buttons/SheetButton';
import { playerChangePartyMode } from '../player/data/thunks';

export default function Party() {
  const dispatch = useDispatch();
  const { partyCharacterIds } = useGame();

  const [minimized, setMinimized] = useState(false);

  const leaderCharacter = useLeaderCharacter();
  const handlePartyModeAll = useCallback(() => {
    dispatch(playerChangePartyMode({
      partyMode: PartyModes.ALL,
      characterId: leaderCharacter.id
    }));
  }, [dispatch, leaderCharacter.id]);

  const partyGold = usePartyGold();

  return (
    <DraggablePanel
      id="party"
      className="d-flex"
      closeStyle={{
        right: 30,
        top: 2,
      }}
      panelClassName="p-2"
      title="Party"
      onMinimize={() => setMinimized(true)}
      onMaximize={() => setMinimized(false)}
      minimized={minimized}
    >
      <div className="d-flex gap-5" style={{
        width: 160,
      }}>
        <div className="d-flex flex-column flex-grow-1 vgap-1">
          {!minimized && partyCharacterIds.map(characterId => {
            return (
              <SheetButton key={characterId} characterId={characterId} />
            );
          })}
          <div className={classNames(
            'p-1 d-flex justify-content-between align-items-center',
            { 'pt-2 mt-1 border-top': !minimized }
          )}>
            <ActivityIconButton
              activityId="partyModeAll"
              onClick={handlePartyModeAll}
              className="p-2"
              scale={1}
            />
            <Statistic
              className="mb-1"
              statId="gold"
              name="Gold"
            >
              <span>{partyGold}</span>
            </Statistic>
          </div>
        </div>
      </div>
    </DraggablePanel>
  );
}
