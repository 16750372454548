import memoize from 'memoize-one';

import { activeCharacterSelector } from '../../../data/selectors';
import { createRegionPathfindingGraphSelector } from '../../../world/data/selectors';
import createSelectorListener from '../../createSelectorListener';

import { getStore } from '../../../data/configureStore';
import { PartyModes, TimeModes } from '../../../data/types';
import { partyCatchupWalk } from '../../../characters/movement/data/thunks';

let removeActiveCharacterStateListener = null;

export function createListeners() {
  const activeCharacterResult = createSelectorListener(activeCharacterSelector, handleActiveCharacterChanged);

  removeActiveCharacterStateListener = activeCharacterResult.remove;
}

export function destroyListeners() {
  if (removeActiveCharacterStateListener !== null) {
    removeActiveCharacterStateListener();
  }
}

// We do a catchup walk here because when the game is live, we want to ensure that all
// the characters in the party walk IMMEDIATELY when the player moves the active character.
// Otherwise it looks dumb.
const handleActiveCharacterChanged = memoize((activeCharacter) => {
  const { timeMode, partyMode } = getStore().getState().game;
  if (timeMode === TimeModes.NORMAL && partyMode === PartyModes.ALL) {
    const playerCharacter = getStore().getState().models.characters[activeCharacter.id];
    const region = getStore().getState().models.regions[playerCharacter.regionId];
    const graph = createRegionPathfindingGraphSelector(region.id)(getStore().getState());
    const { partyCharacterIds } = getStore().getState().game;
    partyCharacterIds.forEach((partyCharacterId) => {
      if (partyCharacterId === activeCharacter.id) {
        return;
      }

      getStore().dispatch(partyCatchupWalk({
        characterId: partyCharacterId,
        existingGraph: graph
      }));
    });
  }
});
