import { memo, useMemo, useState } from 'react';
import MousePositionContext from './MousePositionContext';

function MousePositionProvider({ children }) {

  const [dragPosition, setDragPosition] = useState({ x: null, y: null });
  const [isMouseDown, setIsMouseDown] = useState(false);

  const value = useMemo(() => {
    return {
      ...dragPosition,
      setDragPosition,
      isMouseDown,
      setIsMouseDown,
    };
  }, [
    dragPosition,
    setDragPosition,
    isMouseDown,
    setIsMouseDown,
  ]);

  return (
    <MousePositionContext.Provider value={value}>
      {children}
    </MousePositionContext.Provider>
  );
}

export default memo(MousePositionProvider);
