import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useActiveCharacter } from '../data/hooks';

export default function ShakeIt({ children }) {
  const activeCharacter = useActiveCharacter();
  const { health } = activeCharacter;

  const [lastHealth, setLastHealth] = useState(health);
  const [damaged, setDamaged] = useState(false);
  useEffect(() => {

    if (health < lastHealth) {
      setLastHealth(health);
      setDamaged(true);
      setTimeout(() => {
        setDamaged(false);
      }, 1000);
    }
  }, [health, lastHealth]);

  return (
    <div className={classNames(
      { 'shake': damaged },
    )}>
      {children}
    </div>
  );
}
