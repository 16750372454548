import { walkPath } from '../../../../characters/movement/data/thunks';
import { getCutscene } from '../../../../content-utils/cutsceneUtils';
import { getStore } from '../../../../data/configureStore';
import { createInterval } from '../../../../data/renderLoopUtils';
import { cutsceneStepComplete } from '../stepComplete';

let cutsceneMovementControls = {};

export function performMovementStep({ characterId }) {
  const character = getStore().getState().models.characters[characterId];

  if (character === null) {
    throw new Error(`Could not find character with id ${characterId}`);
  }

  let defaultDelay = 150;
  cutsceneMovementControls[character.id] = createInterval(defaultDelay, ({ setDelay, abort }) => {
    const {
      activeCutsceneId,
      activeCutsceneStep,
    } = getStore().getState().game;

    const cutscene = getCutscene(activeCutsceneId);
    const step = cutscene.steps[activeCutsceneStep];

    if (step.action !== 'movement') {
      throw new Error('Expected movement step');
    }

    const { x, y } = step.destination;

    getStore().dispatch(walkPath({
      characterId: character.id,
      x,
      y,
      doneCallback: () => {
        abort();
        getStore().dispatch(cutsceneStepComplete());
      }
    }));
    setDelay(150);
  });
  cutsceneMovementControls[character.id].play();
}
