import townMap from '../assets/tiled/maps/town/town.json';
import forestMap from '../assets/tiled/maps/forest/forest.json';
import gladeMap from '../assets/tiled/maps/glade/glade.json';
import { PlaceIconTypes } from '../platform/ui/PlaceIcon';
import { RegionData } from '../platform/content-utils/types';

const regions:Array<RegionData> = [
  {
    id: 'forest',
    name: 'The Forest',
    placeIconId: PlaceIconTypes.ROAD,
    wilderness: true,
    rawMap: forestMap,
    activities: ['hunt', 'gather', 'camp'],
    prey: {
      'deer': 0.6,
      'rabbit': 0.15,
      'boar': 0.15,
      'wolf': 0.1,
    },
    gatherables: {
      'raspberries': 0.15,
      'blueberries': 0.15,
      'apple': 1.0,
      'onions': 0.3,
      'garlic': 0.05,
      'mushrooms': 0.4,
      'carrot': 0.05,
      'truffles': 0.01,
    },
  },
  {
    id: 'town',
    name: 'The Town',
    placeIconId: PlaceIconTypes.TOWN,
    rawMap: townMap,
    wilderness: true,
  },
  {
    id: 'glade',
    name: 'Glade',
    placeIconId: PlaceIconTypes.ROAD,
    wilderness: true,
    rawMap: gladeMap,
  }
];

export default regions;
