import classNames from 'classnames';

export default function Well({ children, className, style, ...props }) {

  return (
    <div className={classNames('p-3 rounded-2 border-box border border-inset bg-parchment-300', className)} style={style} {...props}>
      {children}
    </div>
  );
}
