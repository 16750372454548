export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function getAngle(dx, dy) {
  var angle = Math.atan2(dy, dx);
  var degrees = 180 * angle / Math.PI;
  return (360 + Math.round(degrees)) % 360;
}

const renderDebug = false;

export function logRender(message) {
  if (renderDebug) {
    console.info(`${message} rendered`);
  }
}

export function getDistance(dx, dy, round = true) {
  if (round) {
    return Math.floor(Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2)));
  }
  return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
}

export const calculateMouseLocation = (event, areaSize) => {
  const _event = event.nativeEvent ? event.nativeEvent : event;
  const offsetX = _event.offsetX;
  const offsetY = _event.offsetY;

  // Find how many tiles over the click was.  That's pixels divided by area size.
  const x = Math.floor(offsetX / areaSize);
  const y = Math.floor(offsetY / areaSize);

  return { x, y, clickX: offsetX, clickY: offsetY, clientX: _event.clientX, clientY: _event.clientY };
};

export const calculateTouchLocation = (event, areaSize, zoom) => {
  const _event = event.nativeEvent ? event.nativeEvent : event;
  const touch = _event.changedTouches[0];
  const rect = _event.target.getBoundingClientRect();

  const offsetX = (touch.clientX - rect.left) / zoom;
  const offsetY = (touch.clientY - rect.top) / zoom;

  // Find how many tiles over the click was.  That's pixels divided by area size.
  const x = Math.floor(offsetX / areaSize);
  const y = Math.floor(offsetY / areaSize);

  return {
    x,
    y,
    clickX: offsetX,
    clickY: offsetY,
    clientX: touch.clientX,
    clientY: touch.clientY
  };
};

export function isWalkableTerrain(region, x, y) {
  const area = region.map.areas[`${x}&${y}`];

  // If this area doesn't exist or is out of bounds, it's not walkable!
  if (area === undefined) {
    return false;
  }
  const ground = (area && region.map.terrains[area.ground]) || { walkable: false };
  const walls = (area && region.map.walls[area.walls]) || { walkable: true };

  const walkable = (
    ground.walkable &&
    walls.walkable
  );

  return walkable;
}

export function hasOnlyWalkableItems(areaItems) {
  let walkable = true;
  if (areaItems && areaItems.length > 0) {
    for (let i = 0; i < areaItems.length; i++) {
      const areaItem = areaItems[i];
      if (!areaItem.walkable) {
        walkable = false;
        break;
      }
    }
  }
  return walkable;
}

export function hasOnlyWalkableFeatures(areaFeatures) {
  let walkable = true;
  if (areaFeatures && areaFeatures.length > 0) {
    for (let i = 0; i < areaFeatures.length; i++) {
      const areaFeature = areaFeatures[i];
      if (!areaFeature.walkable) {
        walkable = false;
        break;
      }
    }
  }
  return walkable;
}

export function hasOnlyWalkableCharacters(areaCharacters) {
  let walkable = true;
  if (areaCharacters && areaCharacters.length > 0) {
    // TODO: Some day this could be smarter.
    walkable = false;
  }
  return walkable;
}

export function isWalkableArea(areaItems, areaFeatures, areaCharacters, region, x, y, options = {}) {
  const includeCharacters = options.includeCharacters !== undefined ? options.includeCharacters : true;
  const area = region.map.areas[`${x}&${y}`];
  const ground = (area && region.map.terrains[area.ground]) || { walkable: false };
  const walls = (area && region.map.walls[area.walls]) || { walkable: true };
  const onlyWalkableItems = hasOnlyWalkableItems(areaItems);
  const onlyWalkableFeatures = hasOnlyWalkableFeatures(areaFeatures);
  let onlyWalkableCharacters = true;
  if (includeCharacters) {
    onlyWalkableCharacters = hasOnlyWalkableCharacters(areaCharacters);
  }

  const walkable = (
    ground.walkable &&
    walls.walkable &&
    onlyWalkableItems &&
    onlyWalkableFeatures &&
    onlyWalkableCharacters
  );

  return walkable;
}
