import classNames from 'classnames';

export default function ProgressBar({ color, min, max, current, className, barClassName, style, height = 14 }) {
  const percentFull = Math.floor(current / (max - min) * 100);

  return (
    <div
      style={{
        height,
        ...style
      }}
      className={classNames(
        'bg-white shadow-1 border border-primary border-box',
        className
      )}
    >
      <div
        className={classNames(barClassName)}
        style={{
          width: `${percentFull}%`,
          height: height - 4,
          transition: 'width 0.5s ease-in-out',
        }}
      ></div>

    </div>
  );
}
