import Phaser from 'phaser';
import memoize from 'memoize-one';

import createSelectorListener from '../../engine/createSelectorListener';
import { gameSelector, mainViewportCharacterIdSelector } from '../../data/selectors';
import { createRegionLightsSelector } from '../data/selectors';
import { createModelSelector } from '../../models-store/selectors';
import { AREA_SIZE } from '../data/constants';
import { calculateFinalViewpoint } from '../data/utils';
import { getDistance } from '../../data/utils';

const hourAlpha = [
  1.0,
  1.0,
  1.0, // 2am
  0.9,
  0.8, // 4am
  0.6,
  0.4, // 6am
  0.2,
  0.1, // 8am
  0,
  0, // 10am
  0,
  0, // noon
  0,
  0, // 2pm
  0,
  0.1, // 4pm
  0.2,
  0.4, // 6pm
  0.6,
  0.8, // 8pm
  0.9, // 9pm
  1.0, // 10pm
  1.0, // 11pm
];

export default class Lights extends Phaser.GameObjects.Container {

  constructor(region, ...args) {
    super(...args);

    this.region = region;
    this.handleStateChanged = this.handleStateChanged.bind(this);

    this.initialize();
  }

  initialize() {

    const { remove, data } = createSelectorListener(
      state => state,
      this.handleStateChanged
    );

    this.removeStateListener = remove;
    this.state = data;

    this.shadowsByLocation = {};

    this.handleStateChanged(this.state);

    this.updateLights = memoize(this.updateLights);
  }

  destroy() {
    this.removeStateListener();
    super.destroy();
  }

  handleStateChanged(state) {
    this.state = state;

    this.checkLights(state);
  }

  checkLights(state) {
    if (this.scene.cameras.main) {
      const { hour, zoom } = gameSelector(state);
      const lights = createRegionLightsSelector(this.region.id)(state);
      const viewpointCharacterId = mainViewportCharacterIdSelector(state);
      const viewpointCharacter = createModelSelector('characters', viewpointCharacterId)(state);
      const cameraWidth = this.scene.cameras.main.width;
      const cameraHeight = this.scene.cameras.main.height;
      this.updateLights(zoom, hour, cameraWidth, cameraHeight, lights, viewpointCharacter);
    }
  }

  // cameraWidth and cameraHeight are passed in to trip the memoization whenever the viewport changes size.
  updateLights(zoom, hour, cameraWidth, cameraHeight, lights, viewpointCharacter) {
    // We add and subtract 1 to make sure we're creating darkness over the whole viewport with no
    // gaps around the edges.
    const radiusX = Math.ceil(cameraWidth / 2 / zoom / AREA_SIZE) + 4;
    const radiusY = Math.ceil(cameraHeight / 2 / zoom / AREA_SIZE) + 4;
    const finalViewpoint = calculateFinalViewpoint(
      viewpointCharacter,
      zoom,
      cameraWidth,
      cameraHeight,
      this.region.map.width * AREA_SIZE,
      this.region.map.height * AREA_SIZE,
      this.region,
    );

    const minX = finalViewpoint.x - radiusX;
    const maxX = finalViewpoint.x + radiusX;
    const minY = finalViewpoint.y - radiusY;
    const maxY = finalViewpoint.y + radiusY;

    const additionalIntensity = (4 - (hourAlpha[hour] * 4));

    const characterLightIntensity = 4 + additionalIntensity;

    for (let x = minX; x <= maxX; x++) {
      for (let y = minY; y <= maxY; y++) {
        const distance = getDistance(viewpointCharacter.x - x, viewpointCharacter.y - y - 1);

        let remainingIntensity = Math.max(0, characterLightIntensity - distance);

        let lightIntensity = 0;
        for (let i = 0; i < lights.length; i++) {
          const light = lights[i];
          const lightDistance = getDistance(light.x - x, light.y - y);
          const intensity = light.light[light.status].intensity + additionalIntensity;
          lightIntensity += Math.max(0, intensity - lightDistance);
        }

        let shadow = this.shadowsByLocation[`${x}&${y}`];
        const alpha = Math.max(0, hourAlpha[hour] - (remainingIntensity / 10) - (lightIntensity / 10));

        if (shadow === undefined) {
          shadow = new Phaser.GameObjects.Rectangle(this.scene,
            (x * AREA_SIZE) + 8,
            (y * AREA_SIZE) + 8,
            AREA_SIZE,
            AREA_SIZE,
            0x000000,
            alpha,
          );
          this.add(shadow);
          shadow.setDepth((this.region.map.height * AREA_SIZE * 2) + 3);
        }

        this.shadowsByLocation[`${x}&${y}`] = shadow;
        if (alpha !== shadow.fillAlpha) {
          this.scene.tweens.add({
            targets: shadow,
            duration: 250,
            fillAlpha: alpha,
          });
        }

      }
    }
  }
}
