import meleeWeapons from './itemTemplates/meleeWeapons';
import rangedWeapons from './itemTemplates/rangedWeapons';
import leatherArmors from './itemTemplates/leatherArmors';
import steelArmors from './itemTemplates/steelArmors';
import foods from './itemTemplates/foods';
import materials from './itemTemplates/materials';
import containers from './itemTemplates/containers';
import doors from './itemTemplates/doors';
import campsite from './itemTemplates/campsite';

const itemTemplates = [
  ...meleeWeapons,
  ...rangedWeapons,
  ...leatherArmors,
  ...steelArmors,
  ...foods,
  ...materials,
  ...containers,
  ...doors,
  ...campsite,
];

export default itemTemplates;
