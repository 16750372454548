import classNames from 'classnames';

import { logRender } from '../data/utils';

export default function Item({ scale = 1, item, style, className }) {
  logRender('Item');

  let image = item.image;
  if (item.image.statuses) {
    image = item.image.statuses[item.status];
  } else if (item.image.quantities) {
    image = item.image.quantities[item.quantity > 1 ? 'many' : 'one'];
  }
  const x = ((image.position.x * 16) + 1 + (image.position.x * 2)) * -1 * scale;
  const y = ((image.position.y * 16) + 1 + (image.position.y * 2)) * -1 * scale;
  const size = 16 * scale;
  const backgroundSize = 288 * scale;

  return (
    <div
      className={classNames(className, 'pixelated')}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundImage: `url('${image.src}')`,
        backgroundSize: `${backgroundSize}px ${backgroundSize}px`,
        backgroundPosition: `${x}px ${y}px`,
        ...style,
      }}
    />
  );
}
