import featuresImage from '../../spritesheets/features.png';

const featureTemplates = [
  {
    id: 'big-wooden-table-nw',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 0, y: 11 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-table-n',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 1, y: 11 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-table-ne',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 2, y: 11 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-table-w',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 0, y: 12 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-table',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 1, y: 12 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-table-e',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 2, y: 12 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-table-sw',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 0, y: 13 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-table-s',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 1, y: 13 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'big-wooden-table-se',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 2, y: 13 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'h-wooden-table-w',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 0, y: 14 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'h-wooden-table',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 1, y: 14 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'h-wooden-table-e',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 2, y: 14 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'v-wooden-table-n',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 3, y: 11 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'v-wooden-table',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 3, y: 12 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'v-wooden-table-s',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 3, y: 13 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'small-wooden-table',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 3, y: 14 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'wooden-table-leg-w',
    name: 'Wooden Table Leg',
    image: {
      src: featuresImage,
      position: { x: 0, y: 15 }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'wooden-table-bottom',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 1, y: 15 }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'wooden-table-leg-e',
    name: 'Wooden Table',
    image: {
      src: featuresImage,
      position: { x: 2, y: 15 }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'small-wooden-table-legs',
    name: 'Wooden Table Legs',
    image: {
      src: featuresImage,
      position: { x: 3, y: 15 }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
];

export default featureTemplates;
