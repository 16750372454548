import { CHESTS, EYES, FEET, HAIRSTYLES, LEGS, SKINS, WAIST } from '../platform/content-utils/characterUtils';
import { Character, MovementTypes } from '../platform/content-utils/types';

const characters: Array<Character> = [
  {
    templateId: 'rogue',
    uniqueId: 'ani',
    name: 'Ani',
    regionId: 'town',
    active: true,
    x: 41,
    y: 20,
    movement: {
      type: MovementTypes.PARTY,
      position: 0,
    },
    stats: {
      agi: 5,
    },
    skills: {
      survival: 5,
    },
    body: {
      skin: { style: SKINS.ADULT_HUMAN, tint: SKINS.COLORS.WHITE },
      hairstyle: { style: HAIRSTYLES.SIDE_BRAID, tint: HAIRSTYLES.COLORS.GINGER },
      eyesIrises: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.LIGHT_GREEN },
      eyesWhites: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.WHITE },
      chest: { style: CHESTS.FEMININE.PLAIN_SHIRT, tint: CHESTS.COLORS.GREEN },
      waist: { style: WAIST.SLIM_BELT, tint: WAIST.COLORS.LIGHT_LEATHER },
      legs: { style: LEGS.PANTS, tint: LEGS.COLORS.DENIM },
      feet: { style: FEET.BOOTS, tint: FEET.COLORS.LIGHT_LEATHER },
    },
    // body: {
    //   skin: { atlas: 'animalsLarge', style: 'wolf@plain', tint: '#ffffff' }
    // },
    // body: {
    //   skin: { atlas: 'animalsSmall', style: 'cat@plain', tint: '#ffffff' }
    // },
    gold: 120,
    items: [
      { templateId: 'key', keyId: 'aniChest', uniqueName: `Ani's Key` },
      'apple',
      'short-sword',
      'leather-helmet',
      'hand-axe',
      'dagger',
      'long-sword',
      'leather-boots',
      'arrow',
      { templateId: 'arrow', quantity: 20 },
      { templateId: 'arrow', quantity: 5 },
      { templateId: 'arrow', quantity: 5 },
      { templateId: 'arrow', quantity: 5 },
      { templateId: 'bag', contents: [
        'short-sword',
        'apple',
        'quarterstaff',
        'belt',
        { templateId: 'bolt',  'quantity': 23 }
      ]}
    ],
    slots: {
      mainHand: 'long-bow',
      chest: 'leather-chest',
      waist: 'belt',
      legs: 'steel-leggings',
      offHand: { templateId: 'arrow', quantity: 15 },
    },
  },
  {
    templateId: 'warrior',
    uniqueId: 'horace',
    name: 'Horace',
    regionId: 'town',
    x: 47,
    y: 39,
    movement: {
      type: MovementTypes.PARTY,
      position: 1,
    },
    conversationId: 'horace',
    body: {
      skin: { style: SKINS.ADULT_HUMAN, tint: SKINS.COLORS.BEIGE },
      hairstyle: { style: HAIRSTYLES.SHORT_SLICK, tint: HAIRSTYLES.COLORS.BROWN },
      eyesIrises: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.GRAY },
      eyesWhites: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.WHITE },
      chest: { style: CHESTS.MASCULINE.PLAIN_SHIRT, tint: CHESTS.COLORS.RED },
      waist: { style: WAIST.LEATHER_BELT, tint: WAIST.COLORS.LIGHT_LEATHER },
      legs: { style: LEGS.LONG_SKIRT, tint: LEGS.COLORS.LINEN },
      feet: { style: FEET.BOOTS, tint: FEET.COLORS.LIGHT_LEATHER },
    },
    gold: 50,
    inn: {
      price: 10,
      quality: 10,
    },
    buys: {
      venison: 4,
      pork: 4,
      'rabbit-meat': 1,
    }
  },
  {
    templateId: 'warrior',
    uniqueId: 'mue',
    name: 'Mue',
    regionId: 'town',
    x: 35,
    y: 7,
    movement: {
      type: MovementTypes.PARTY,
      position: 2,
    },
    conversationId: 'mue',
    body: {
      skin: { style: SKINS.ADULT_HUMAN, tint: SKINS.COLORS.DARK_BROWN },
      hairstyle: { style: HAIRSTYLES.SHORT, tint: HAIRSTYLES.COLORS.BLACK },
      eyesIrises: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.DARK_BLUE },
      eyesWhites: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.WHITE },
      chest: { style: CHESTS.MASCULINE.PLAIN_SHIRT, tint: CHESTS.COLORS.BROWN },
      waist: { style: WAIST.LEATHER_BELT, tint: WAIST.COLORS.LIGHT_LEATHER },
      legs: { style: LEGS.PANTS, tint: LEGS.COLORS.FOREST_GREEN },
      feet: { style: FEET.BOOTS, tint: FEET.COLORS.LIGHT_LEATHER },
    },
    gold: 300,
    items: [
      'raspberries',
      'garlic',

    ],
    slots: {
      mainHand: 'battle-axe',
      waist: 'belt',
      legs: 'leather-leggings',
      chest: 'leather-chest',
    },
    materials: {
      'deer-hide': 0.9,
      'horn': 0.9,
    }
  },
  {
    templateId: 'warrior',
    uniqueId: 'dao',
    name: 'Dao',
    regionId: 'town',
    x: 35,
    y: 25,
    movement: {
      type: MovementTypes.WANDER,
      radius: 10,
      center: { x: 20, y: 30 },
      delay: 1000,
    },
    conversationId: 'dao',
    body: {
      skin: { style: SKINS.ADULT_HUMAN, tint: SKINS.COLORS.BROWN },
      hairstyle: { style: HAIRSTYLES.SHORT, tint: HAIRSTYLES.COLORS.BLACK },
      eyesIrises: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.LIGHT_GREEN },
      eyesWhites: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.WHITE },
      chest: { style: CHESTS.MASCULINE.PLAIN_SHIRT, tint: CHESTS.COLORS.GREEN },
      waist: { style: WAIST.LEATHER_BELT, tint: WAIST.COLORS.LIGHT_LEATHER },
      legs: { style: LEGS.PANTS, tint: LEGS.COLORS.DENIM },
      feet: { style: FEET.BOOTS, tint: FEET.COLORS.LIGHT_LEATHER },
    },
    gold: 250,
    items: [
      'battle-axe',
      'halberd',
      'dagger',
      'dagger',
      'steel-buckler',
      { templateId: 'arrow', quantity: 15 },
    ],
    slots: {
      mainHand: 'war-hammer',
    },
    buys: {
      'short-sword': 8,
      dagger: 1,
      halberd: 8,
      hide: 6,
      'rabbit-pelt': 2,
      tusk: 10,
      'wolf-pelt': 50,
      arrow: 1,
    },
    sells: {
      halberd: 10,
      dagger: 2,
      'steel-buckler': 16,
      arrow: 1,
    }
  },
  {
    templateId: 'raccoon',
    uniqueId: 'tim',
    name: 'Tim',
    regionId: 'forest',
    x: 80,
    y: 25,
    conversationId: 'tim',
    movement: {
      type: MovementTypes.WANDER,
      radius: 10,
      center: { x: 20, y: 30 },
      delay: 1000,
    },
    body: {
      skin: { style: SKINS.ADULT_HUMAN, tint: SKINS.COLORS.WHITE },
      hairstyle: { style: HAIRSTYLES.SIDE_BRAID, tint: HAIRSTYLES.COLORS.BROWN },
      eyesIrises: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.LIGHT_GREEN },
      eyesWhites: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.WHITE },
      chest: { style: CHESTS.FEMININE.PLAIN_SHIRT, tint: CHESTS.COLORS.GREEN },
      waist: { style: WAIST.LEATHER_BELT, tint: WAIST.COLORS.LIGHT_LEATHER },
      legs: { style: LEGS.PANTS, tint: LEGS.COLORS.DENIM },
      feet: { style: FEET.BOOTS, tint: FEET.COLORS.LIGHT_LEATHER },
    },
  },
  // {
  //   templateId: 'wolf',
  //   uniqueId: 'wolfy',
  //   regionId: 'town',
  //   name: 'Wolfy',
  //   x: 55,
  //   y: 45,
  //   movement: {
  //     type: MovementTypes.WANDER,
  //     radius: 10,
  //     center: { x: 20, y: 30 },
  //     delay: 1000,
  //   },
  //   body: {
  //     skin: { atlas: 'animalsLarge', style: 'wolf@plain', tint: '#ffffff' }
  //   }
  // },
  {
    templateId: 'strongarm',
    uniqueId: 'aen',
    name: 'Aen',
    regionId: 'town',
    x: 38,
    y: 23,
    conversationId: 'aen',
    movement: {
      type: MovementTypes.STATIONARY,
    },
    body: {
      skin: { style: SKINS.ADULT_HUMAN, tint: SKINS.COLORS.BROWN },
      hairstyle: { style: HAIRSTYLES.TIGHT_BRAID, tint: HAIRSTYLES.COLORS.BLACK },
      eyesIrises: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.LIGHT_GREEN },
      eyesWhites: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.WHITE },
      chest: { style: CHESTS.FEMININE.PLAIN_SHIRT, tint: CHESTS.COLORS.RED },
      waist: { style: WAIST.LEATHER_BELT, tint: WAIST.COLORS.LIGHT_LEATHER },
      legs: { style: LEGS.PANTS, tint: LEGS.COLORS.DENIM },
      feet: { style: FEET.BOOTS, tint: FEET.COLORS.LIGHT_LEATHER },
    },
    items: [
      'halberd'
    ],
    slots: {
      mainHand: 'battle-axe',
      waist: 'belt',
      legs: 'leather-leggings',
      chest: 'leather-chest',
    },
  }
];

export default characters;
