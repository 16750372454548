let fps;
const times = [];

export function displayFps(element) {
  window.requestAnimationFrame((now) => {
    while (times.length > 0 && times[0] <= now - 1000) {
      times.shift();
    }
    times.push(now);
    fps = times.length;

    // eslint-disable-next-line no-param-reassign
    element.innerHTML = fps;
    displayFps(element);
  });
}

export function createInterval(newDelay, callback, options = {}) {
  let lastTime = performance.now();
  let proceed = true;
  let active = options.active !== undefined ? options.active : false;
  let delay = newDelay;

  const controls = {
    abort: () => {
      proceed = false;
      // Also shut down the callback processing, since it'll be called one last time.
      active = false;
    },
    pause: () => {
      active = false;
    },
    play: () => {
      active = true;
      interval();
    },
    setDelay: (nextDelay) => {
      delay = nextDelay;
    }
  };

  function interval() {
    window.requestAnimationFrame((now) => {
      if (active && (now - lastTime) > delay) {
        lastTime = now;
        callback(controls);
      }

      if (active && proceed) {
        interval(delay);
      }
    });
  }

  if (active) {
    interval();
  }

  return controls;
}
