import { createSelector } from '@reduxjs/toolkit';

export function createModelsSelector(modelType, modelIds) {
  return (state) => {
    const models = [];
    if (state.models[modelType] !== undefined) {
      modelIds.forEach((modelId) => {
        if (state.models[modelType][modelId] !== undefined) {
          models.push(state.models[modelType][modelId]);
        }
      });
    }
    return models;
  };
}

export function createModelSelector(modelType, modelId) {
  return createSelector(
    () => modelType,
    () => modelId,
    state => state.models,
    (modelType, modelId, models) => {

      if (models[modelType] === undefined) {
        return null;
      }
      if (models[modelType][modelId] === undefined) {
        return null;
      }
      return models[modelType][modelId];
    }
  );
}
