export const AREA_SIZE = 16;

export const HOUR_LENGTH = 30000;

export const NORMAL_SPEED = 1;
export const FAST_SPEED = 12; // Speed for camping.

export const COMBAT_TURN_DELAY = 1000;

export const PARTY_CATCHUP_DELAY = 250;
