import { useDispatch } from 'react-redux';
import { useHud } from '../data/hooks';

import { updateViewportActivity } from '../data/slice';
import ActivityIconButton from '../generic/ActivityIconButton';

export default function AttackButton() {
  const dispatch = useDispatch();
  const {
    viewportActivity,
  } = useHud();

  const handleAttack = () => {
    dispatch(updateViewportActivity({ activity: 'attack' }));
  };

  return (
    <ActivityIconButton
      activityId="combat"
      scale={2}
      onClick={handleAttack}
      selected={viewportActivity === 'attack'}
    />
  );
}
