import featuresImage from '../../spritesheets/features.png';

const featureTemplates = [
  {
    id: 'forge-chimney-top-w',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 13, y: 2 },
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'forge-chimney-top-center',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 14, y: 2 },
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'forge-chimney-top-e',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 15, y: 2 },
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'forge-chimney-w',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 13, y: 3 },
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'forge-chimney-center',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 14, y: 3 },
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'forge-chimney-e',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 15, y: 3 },
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'forge-chimney-base-w',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 13, y: 4 },
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'forge-chimney-base-center',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 14, y: 4 },
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'forge-chimney-base-e',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 15, y: 4 },
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'forge-arch-w',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 13, y: 5 },
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'forge-arch-center',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 14, y: 5 },
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'forge-arch-e',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 15, y: 5 },
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'forge-shelf-w',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 13, y: 6 },
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'forge-shelf-center',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 14, y: 6 },
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'forge-shelf-e',
    name: 'Forge',
    image: {
      src: featuresImage,
      position: { x: 15, y: 6 },
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  }
];



export default featureTemplates;


/*





*/
