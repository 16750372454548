import { CombatState } from '../ui/activities/combat';
import { ConversationState } from '../ui/activities/conversation';
import { InnState } from '../ui/activities/inn/data/types';
import { TradeState } from '../ui/activities/trade';
import { ChronicleState } from '../ui/chronicle';
import { HudState } from '../ui/data/types';
import { ModelsState } from '../models-store';
import { PhaserState } from '../world/data/types';

export enum AppStatus {
  LOADED = 'LOADED',
  UNLOADED = 'UNLOADED',
}

export interface AppState {
  status: AppStatus.LOADED | AppStatus.UNLOADED,
  chronicle: ChronicleState,
  combat: CombatState,
  conversation: ConversationState,
  game: GameState,
  hud: HudState,
  inn: InnState,
  models: ModelsState,
  phaser: PhaserState,
  trade: TradeState,
}

export interface StandardAction<Type> {
  type: string,
  payload: Type,
}

export interface Item {
  id: string,
  templateId: string,
  name: string,
  regionId: string,
  x: number | null,
  y: number | null,
  status: string | null,
  characterId: string | null,
  containerItemId: string | null,
  slot: string | null,
  order: number,
  quantity: number,
  contents: any,
  uniqueName: string | null,
  keyId: string | null,
}

export interface Feature {
  id: string,
  templateId: string,
  name: string,
  regionId: string,
  x: number | null,
  y: number | null,
  status: string | null,
  order: number,
  contents: any,
  uniqueName: string | null,
  keyId: string | null,
}


export interface ItemImage {
  src: string,
  position: { x: number, y: number }
}

export interface FeatureImage {
  src: string,
  position: { x: number, y: number }
}

export interface ItemStatusesImage {
  statuses: {
    closed?: ItemImage,
    open?: ItemImage,
    locked?: ItemImage,
    lit?: ItemImage,
    doused?: ItemImage,
    cold?: ItemImage,
  }
}

export interface FeatureStatusesImage {
  statuses: {
    closed?: FeatureImage,
    open?: FeatureImage,
    locked?: FeatureImage,
    lit?: FeatureImage,
    doused?: FeatureImage,
    cold?: FeatureImage,
  }
}

export interface ItemQuantitiesImage {
  quantities: {
    one?: ItemImage,
    many?: ItemImage,
  }
}

export interface FeatureQuantitiesImage {
  quantities: {
    one?: FeatureImage,
    many?: FeatureImage,
  }
}


export interface Light {
  radius: number,
  rgb: number,
  intensity: number,
}

export interface ItemLight {
  lit?: Light,
  doused?: Light
}

export interface FeatureLight {
  lit?: Light,
  doused?: Light
}

export interface HydratedItem extends Item {
  image: ItemImage | ItemStatusesImage | ItemQuantitiesImage,
  damage: number,
  defense: number,
  value: number,
  range: number,
  allowances: string,
  door?: boolean,
  doorSection: 'top' | 'bottom',
  elevation: number,
  moveable: boolean,
  walkable: boolean,
  lockable: boolean,
  container: boolean,
  atlas: 'items',
  light: ItemLight | null,
  determiner: string,
}

export interface HydratedFeature extends Feature {
  image: FeatureImage | FeatureStatusesImage | FeatureQuantitiesImage,
  door?: boolean,
  doorSection: 'top' | 'bottom',
  elevation: number,
  moveable: boolean,
  walkable: boolean,
  lockable: boolean,
  container: boolean,
  atlas: 'features',
  light: FeatureLight | null,
  determiner: string,
}

export interface SelectedInventoryItem {
  characterId: string,
  itemId: string,
  index: number,
}

export interface Location {
  x: number,
  y: number,
}

export enum TimeModes {
  NORMAL = 'NORMAL',
  PAUSED = 'PAUSED',
  CUTSCENE = 'CUTSCENE',
}

export enum PartyModes {
  ALL = 'ALL',
  SOLO = 'SOLO',
  CAMPING = 'CAMPING',
}

export interface OpenSheet {
  characterId: string,
  minimized: boolean,
}

export interface GameState {
  activeStoryId: string | null,
  activeCharacterId: string | null,
  actingCharacterId: string | null,
  activeCutsceneId: string | null,
  activeCutsceneStep: number | null,
  overrideViewpointCharacterId: string | null,
  partyCharacterIds: Array<string>,
  partyMode: PartyModes,
  activity: string | null,
  year: number,
  day: number,
  hour: number,
  defeated: boolean,
  walkNorth: boolean,
  walkSouth: boolean,
  walkEast: boolean,
  walkWest: boolean,
  timeMode: TimeModes,
  zoom: number,
}
