import { v4 as uuidv4 } from 'uuid';

import { SOUTH, REST } from '../ui/character/constants';
import { createItemWithChildren } from './itemUtils';

export const SKINS = {
  ADULT_HUMAN: 'adultHuman@plain',
  COLORS: {
    // Stick to generic color words when adding more shades, qualified with "light" or "dark" if the word is already in use and you want a shade between.
    WHITE: '#ffd7b3',
    BEIGE: '#e19d52',
    BROWN: '#89411f',
    DARK_BROWN: '#3f2511',
  }
};

export const HAIRSTYLES = {
  SIDE_BRAID: 'sideBraid@plain',
  SHORT_SLICK: 'shortSlick@plain',
  SHORT: 'short@plain',
  TIGHT_BRAID: 'tightBraid@plain',
  COLORS: {
    GINGER: '#e09a4b',
    BROWN: '#553333',
    BLACK: '#222222',
  }
};

export const EYES = {
  ADULT_HUMAN: 'adultHuman@plain',
  COLORS: {
    GRAY: '#666666',
    LIGHT_GREEN: '#54bbb7',
    DARK_BLUE: '#000044',
    WHITE: '#ffffff'
  }
};

export const CHESTS = {
  FEMININE: {
    PLAIN_SHIRT: 'femininePlainShirt@plain',
  },
  MASCULINE: {
    PLAIN_SHIRT: 'masculinePlainShirt@plain',
  },
  COLORS: {
    GREEN: '#00ff00',
    RED: '#ff0000',
    BROWN: '#264f69',
  }
};

export const WAIST = {
  LEATHER_BELT: 'leatherBelt@plain',
  SLIM_BELT: 'slimBelt@plain',
  COLORS: {
    LIGHT_LEATHER: '#f1a550',
  }
};

export const LEGS = {
  PANTS: 'pants@plain',
  LONG_SKIRT: 'longSkirt@plain',
  COLORS: {
    DENIM: '#3f4874',
    LINEN: '#6c6058',
    FOREST_GREEN: '#3d6957',
  }
};

export const FEET = {
  BOOTS: 'boots@plain',
  COLORS: {
    LIGHT_LEATHER: '#f1a550',
  }
};

export const DEFAULT_CHARACTER_BODY = {
  skin: { style: SKINS.ADULT_HUMAN, tint: SKINS.COLORS.WHITE },
  hairstyle: { style: HAIRSTYLES.SIDE_BRAID, tint: HAIRSTYLES.COLORS.GINGER },
  eyesIrises: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.LIGHT_GREEN },
  eyesWhites: { style: EYES.ADULT_HUMAN, tint: EYES.COLORS.WHITE },
  chest: { style: CHESTS.FEMININE.PLAIN_SHIRT, tint: CHESTS.COLORS.GREEN },
  waist: { style: WAIST.LEATHER_BELT, tint: WAIST.COLORS.LIGHT_LEATHER },
  legs: { style: LEGS.PANTS, tint: LEGS.COLORS.DENIM },
  feet: { style: FEET.BOOTS, tint: FEET.COLORS.LIGHT_LEATHER },
};

export const validSkills = [
  'gathering',
  'hunting',
  'running',
  'survival',
];

export const validStats = [
  'agi',
  'cha',
  'int',
  'str'
];

export function createCharacters(dataList) {
  let activeCharacterId = null;
  const characters = {};
  let items = {};
  const partyMembers = [];
  dataList.forEach(data => {
    const [character, characterItems, active] = createCharacter(data);
    if (active) {
      activeCharacterId = character.id;
    }

    if (character.movement.type === 'PARTY') {
      partyMembers.push(character);
    }

    characters[character.id] = character;

    items = {
      ...items,
      ...characterItems,
    };
  });

  const partyCharacterIds = partyMembers.sort((a, b) => {
    return a.movement.position - b.movement.position;
  }).map(character => character.id);

  return {
    activeCharacterId,
    partyCharacterIds,
    characters,
    items,
  };
}

let characterIdsIntegrityCheck = {};

export function resetCharacterIdsIntegrityCheck() {
  characterIdsIntegrityCheck = {};
}

/**
 * Creates a new character from a character template
 */
export function createCharacter({
  templateId,
  uniqueId,
  name,
  regionId,
  x = 0,
  y = 0,
  active = false,
  direction = SOUTH,
  pose = REST,
  lastStep = 'walk2',
  health,
  endurance,
  conversationId = null,
  gold = 0,
  inn = null,
  items = [],
  slots = {},
  sells = {},
  buys = {},
  relationships = {},
  skills = {},
  stats = {},
  movement = {
    type: 'STATIONARY',
  },
  body = DEFAULT_CHARACTER_BODY
}) {
  if (!templateId) {
    throw new Error('createCharacter: template is a required field');
  }

  if (!name) {
    throw new Error('createCharacter: name is a required field');
  }

  if (!regionId) {
    throw new Error('createCharacter: regionId is a required field');
  }

  if (inn !== null) {
    if (!Number.isInteger(inn.price) || inn.price < 0) {
      throw new Error('createCharacter: inn.price must be 0 or a positive integer.');
    }
    if (!Number.isInteger(inn.quality) || inn.quality < 1) {
      throw new Error('createCharacter: inn.quality must be a positive integer.');
    }
  }

  const template = getCharacterTemplate(templateId);

  const unexpectedSkills = Object.keys(skills).filter(skill => !validSkills.includes(skill));
  if (unexpectedSkills.length > 0) {
    throw new Error(`createCharacter: unexpected skills: ${unexpectedSkills.join(' ')}`);
  }

  const unexpectedStats = Object.keys(stats).filter(stat => !validStats.includes(stat));
  if (unexpectedStats.length > 0) {
    throw new Error(`createCharacter: unexpected stats: ${unexpectedStats.join(' ')}`);
  }

  let finalHealth = health || template.maxHealth;
  let finalEndurance = endurance || template.maxEndurance;

  const id = uniqueId ? uniqueId : uuidv4();

  if (characterIdsIntegrityCheck[id] !== undefined) {
    throw new Error(`createCharacter: character id ${id} already exists`);
  }

  characterIdsIntegrityCheck[id] = true;

  let finalItems = {};
  items.forEach((data, index) => {
    let templateId = data;
    let quantity = 1;
    let uniqueName = null;
    let contents = [];
    let keyId = null;
    if (typeof data === 'object') {
      templateId = data.templateId;
      quantity = data.quantity;
      uniqueName = data.uniqueName;
      contents = data.contents;
      keyId = data.keyId;
    }
    const createdItems = createItemWithChildren({
      templateId,
      characterId: id,
      quantity,
      uniqueName,
      order: index,
      contents,
      keyId,
    });
    finalItems = { ...finalItems, ...createdItems };
  });
  Object.entries(slots).forEach(([slot, data]) => {
    let templateId = data;
    let quantity = 1;
    let uniqueName = null;
    let contents = [];
    let keyId = null;
    if (typeof data === 'object') {
      templateId = data.templateId;
      quantity = data.quantity;
      contents = data.contents;
      uniqueName = data.uniqueName;
      keyId = data.keyId;
    }
    const createdItems = createItemWithChildren({
      templateId,
      characterId: id,
      slot,
      quantity,
      uniqueName,
      contents,
      keyId
    });
    finalItems = { ...finalItems, ...createdItems };
  });

  const character = {
    ...template,
    id,
    templateId: template.id,
    uniqueId,
    name,
    active,
    regionId,
    x,
    y,
    direction,
    pose,
    lastStep,
    conversationId,
    health: finalHealth,
    endurance: finalEndurance,
    gold,
    inn,
    buys,
    sells,
    movement,
    body,
    relationships,
    ...stats,
    ...skills,
  };
  return [
    character,
    finalItems,
    active,
  ];
}

let characterTemplates = {};

/**
 * Creates a new character template which includes the physical characteristics of a new type of
 * character.  Think base classes like rogue, warrior, generic characters like guards and tavern
 * workers, or monsters and animals.
 */
function createCharacterTemplate(
  {
    id,
    name,
    images,
    agi = 1,
    cha = 1,
    int = 1,
    str = 1,
    gathering = 1,
    hunting = 1,
    running = 1,
    survival = 1,
    maxHealth = 10,
    maxEndurance = 30,
    damage = 2,
    defense = 0,
    flee = 0.0,
    materials = {},
  }) {

  if (!id) {
    throw new Error ('createCharacterTemplate: id is a required field');
  }

  if (!name) {
    throw new Error ('createCharacterTemplate: name is a required field');
  }

  if (!images) {
    throw new Error ('createCharacterTemplate: images is a required field');
  }

  return {
    id,
    name,
    images,
    agi,
    cha,
    int,
    str,
    gathering,
    hunting,
    running,
    survival,
    maxHealth,
    maxEndurance,
    damage,
    defense,
    flee,
    materials,
  };
}

function createCharacterTemplates(dataList) {
  const templates = {};
  dataList.forEach(data => {
    const template = createCharacterTemplate(data);
    templates[template.id] = template;
  });
  return templates;
}

function assertCharacterTemplate(id) {
  const template = characterTemplates[id];
  if (template === undefined) {
    throw new Error(`Character template ${id} does not exist!`);
  }
}

export function getCharacterTemplate(id) {
  assertCharacterTemplate(id);
  return characterTemplates[id];
}

export function setCharacterTemplates(data) {
  characterTemplates = createCharacterTemplates(data);
}

export function generateSpritesheetAtlas(styles, colors, poses, directions, options = {}) {
  // console.log(options);

  const scale = options.scale || 1;
  const height = options.height || 16;
  const width = options.width || 16;
  const elevations = options.elevations || 1;
  const scaledHeight = height * scale * elevations;
  const scaledWidth = width * scale;
  const margin = 1 * scale;
  const spacing = 2 * scale;
  const frames = [];

  const frameDefaults = {
    rotated: false,
    trimmed: false,
    spriteSourceSize: { x: 0, y: 0, w: width, h: height },
    sourceSize: { w: width, h: height },
    pivot: { x: 0, y: 0.5 }
  };

  /*
  const styles = ['adultHuman'];
  const colors = ['plain']; // "plain" here means a gray-scale adult human sprite.
  const poses = ['rest', 'walk1', 'walk2', 'unconscious'];
  const directions = ['s', 'e', 'w', 'n'];
  */

  styles.forEach((style, styleIndex) => {
    const styleSpacing = ((4 * scaledHeight) + (4 * (spacing * 2))) * styleIndex;
    colors.forEach((color, colorIndex) => {
      const colorSpacing = ((4 * scaledWidth) + (4 * spacing)) * colorIndex;
      poses.forEach((pose, poseIndex) => {
        const poseSpacing = (poseIndex * scaledHeight) + (poseIndex * (spacing * 2));
        directions.forEach((direction, directionIndex) => {
          const directionSpacing = (directionIndex * scaledWidth) + (directionIndex * spacing);
          for (let elevation = 0; elevation < elevations; elevation++) {
            const elevationHeight = scaledHeight / elevations;
            const elevationBackwardsLevel = ((elevations - 1) - elevation);
            const elevationOffsetY = (elevationBackwardsLevel * elevationHeight) + (spacing * elevationBackwardsLevel);
            const elevationIdentifier = `@${elevation + 1}`;
            frames.push({
              filename: `${style}@${color}@${pose}@${direction}${elevationIdentifier}`,
              frame: {
                x: margin + colorSpacing + directionSpacing,
                y: margin + styleSpacing + poseSpacing + elevationOffsetY,
                w: scaledWidth,
                h: elevationHeight
              },
              ...frameDefaults,
            });
          }
        });
      });
    });
  });


  return {
    frames,
  };
}

export function generateCharacterHairstylesAtlas(options = {}) {
  const scale = options.scale || 1;
  const styles = ['sideBraid', 'short', 'shortSlick', 'tightBraid'];
  const colors = ['plain'];
  const poses = ['rest', 'walk1', 'walk2', 'unconscious'];
  const directions = ['s', 'e', 'w', 'n'];

  return generateSpritesheetAtlas(styles, colors, poses, directions, {
    scale,
    elevations: 2,
  });
}

export function generateCharacterChestsAtlas(options = {}) {
  const scale = options.scale || 1;
  const styles = ['femininePlainShirt', 'masculinePlainShirt'];
  const colors = ['plain'];
  const poses = ['rest', 'walk1', 'walk2', 'unconscious'];
  const directions = ['s', 'e', 'w', 'n'];

  return generateSpritesheetAtlas(styles, colors, poses, directions, {
    scale,
    elevations: 2,
  });
}

export function generateCharacterWaistAtlas(options = {}) {
  const scale = options.scale || 1;
  const styles = ['leatherBelt', 'slimBelt'];
  const colors = ['plain'];
  const poses = ['rest', 'walk1', 'walk2', 'unconscious'];
  const directions = ['s', 'e', 'w', 'n'];

  return generateSpritesheetAtlas(styles, colors, poses, directions, {
    scale,
    elevations: 2,
  });
}

export function generateCharacterLegsAtlas(options = {}) {
  const scale = options.scale || 1;
  const styles = ['pants', 'longSkirt'];
  const colors = ['plain'];
  const poses = ['rest', 'walk1', 'walk2', 'unconscious'];
  const directions = ['s', 'e', 'w', 'n'];

  return generateSpritesheetAtlas(styles, colors, poses, directions, {
    scale,
    elevations: 2,
  });
}

export function generateCharacterFeetAtlas(options = {}) {
  const scale = options.scale || 1;
  const styles = ['boots'];
  const colors = ['plain'];
  const poses = ['rest', 'walk1', 'walk2', 'unconscious'];
  const directions = ['s', 'e', 'w', 'n'];

  return generateSpritesheetAtlas(styles, colors, poses, directions, {
    scale,
    elevations: 2,
  });
}

export function generateCharacterEyesIrisesAtlas(options = {}) {
  const scale = options.scale || 1;
  const styles = ['adultHuman'];
  const colors = ['plain'];
  const poses = ['rest', 'walk1', 'walk2', 'unconscious'];
  const directions = ['s', 'e', 'w', 'n'];

  return generateSpritesheetAtlas(styles, colors, poses, directions, {
    scale,
    elevations: 2,
  });
}

export function generateCharacterEyesWhitesAtlas(options = {}) {
  const scale = options.scale || 1;
  const styles = ['adultHuman'];
  const colors = ['plain'];
  const poses = ['rest', 'walk1', 'walk2', 'unconscious'];
  const directions = ['s', 'e', 'w', 'n'];

  return generateSpritesheetAtlas(styles, colors, poses, directions, {
    scale,
    elevations: 2,
  });
}

export function generateCharacterSkinsAtlas(options = {}) {
  const scale = options.scale || 1;
  const styles = ['adultHuman'];
  const colors = ['plain']; // "plain" here means a gray-scale adult human sprite.
  const poses = ['rest', 'walk1', 'walk2', 'unconscious'];
  const directions = ['s', 'e', 'w', 'n'];

  return generateSpritesheetAtlas(styles, colors, poses, directions, {
    scale,
    elevations: 2,
  });
}

export function generateAnimalsSmallAtlas(options = {}) {
  const scale = options.scale || 1;
  const styles = ['cat', 'rat'];
  const colors = ['plain']; // "plain" here means we have no colors of animals yet.
  const poses = ['rest', 'walk1', 'walk2', 'unconscious'];
  const directions = ['s', 'e', 'w', 'n'];

  return generateSpritesheetAtlas(styles, colors, poses, directions, {
    scale
  });
}

export function generateAnimalsLargeAtlas(options = {}) {
  const scale = options.scale || 1;
  const styles = ['wolf'];
  const colors = ['plain']; // "plain" here means we have no colors of animals yet.
  const poses = ['rest', 'walk1', 'walk2', 'unconscious'];
  const directions = ['s', 'e', 'w', 'n'];

  return generateSpritesheetAtlas(styles, colors, poses, directions, {
    height: 32,
    width: 32,
    scale
  });
}

export const atlasFunctionLookup = {
  animalsLarge: generateAnimalsLargeAtlas,
  animalsSmall: generateAnimalsSmallAtlas,
  characterChests: generateCharacterChestsAtlas,
  characterEyesIrises: generateCharacterEyesIrisesAtlas,
  characterEyesWhites: generateCharacterEyesWhitesAtlas,
  characterFeet: generateCharacterFeetAtlas,
  characterHairstyles: generateCharacterHairstylesAtlas,
  characterLegs: generateCharacterLegsAtlas,
  characterSkins: generateCharacterSkinsAtlas,
  characterWaist: generateCharacterWaistAtlas
};
