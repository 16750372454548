import terrainsTileset from './tiled/tilesets/terrains/terrains.json';
import itemsTileset from './tiled/tilesets/items/items.json';
import wallsTileset from './tiled/tilesets/walls/walls.json';
import featuresTileset from './tiled/tilesets/features/features.json';

import spritesheets from './spritesheets';
import templates from './templates';

import splash from './tiled/maps/splash/splash.png';

// Tilesets belong to Tiled, so we don't really have a "tilesets" subdirectory
// we can put these in.  Maybe in the future if we post-process the tiled tilesets
// Into whatever we need in the game we could put THAT in there, but for now I'm
// trying to keep code out of the source directories, i.e., aseprite and tiled.
const tilesets = {
  terrains: terrainsTileset,
  items: itemsTileset,
  walls: wallsTileset,
  features: featuresTileset,
};

const assets = {
  tilesets,
  spritesheets,
  templates,
  splash,
};

export default assets;
