import { createSelector } from 'reselect';
import { hydrateItem } from '../../../../data/contentUtils';

export const gatherItemsSelector = createSelector(
  state => state.models.items,
  (itemsById) => {
    const items = [];
    Object.values(itemsById).forEach(item => {
      if (item.containerItemId === 'gather-container') {
        items.push(hydrateItem(item));
      }
    });
    return items.sort((a, b) => a.order - b.order);
  }
);
