import classNames from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';

import { useGame, useRegion } from '../data/hooks';
import { calculateMouseLocation, calculateTouchLocation, logRender } from '../data/utils';
import MousePositionContext from '../player/MousePositionContext';
import { useHud } from '../ui/data/hooks';
import { AREA_SIZE } from './data/constants';
import { calculateTranslation, calculateFinalViewpoint } from './data/utils';
import ViewportContext from './ViewportContext';

export default function ViewportInputOverlay({
  viewpoint,
  className,
  style,
  onClick = null,
  onMouseDown = null,
  onMouseUp = null,
  onMouseMove = null,
}) {
  logRender('ViewportInputOverlay');
  const dimensionsRef = useRef(null);
  const {
    viewportActivity
  } = useHud();
  const {
    zoom
  } = useGame();

  // So the idea will be that each viewport needs to be wrapped in a ViewportProvider specific to its needs.  The closest provider wins.
  const { regionId, frameWidth, frameHeight } = useContext(ViewportContext);
  const region = useRegion(regionId);

  const mapHeight = region.map.height * AREA_SIZE;
  const mapWidth = region.map.width * AREA_SIZE;

  const [animateTransform, setAnimateTransform] = useState(false);

  const { isMouseDown, setIsMouseDown } = useContext(MousePositionContext);

  const locationRef = useRef({ x: null, y: null });

  useEffect(() => {
    // wait a moment
    const timeoutId = setTimeout(() => setAnimateTransform(true), 1);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleClick = (event) => {
    if (onClick !== null) {
      const location = calculateMouseLocation(event, AREA_SIZE);
      onClick(location);
    }
  };

  const handleMouseDown = (event) => {
    if (event.buttons === 1 && onMouseDown !== null) {
      const location = calculateMouseLocation(event, AREA_SIZE);
      setIsMouseDown(true);
      onMouseDown(location);
    }
  };

  const handleMouseUp = (event) => {
    if (event.button === 0 && onMouseUp !== null) {
      const location = calculateMouseLocation(event, AREA_SIZE);
      setIsMouseDown(false);
      onMouseUp(location);
    }
  };

  const handleTouchStart = (event) => {
    if (onMouseDown !== null) {
      const location = calculateTouchLocation(event, AREA_SIZE, zoom);
      setIsMouseDown(true);
      onMouseDown(location);
    }
  };

  const handleTouchEnd = (event) => {
    event.preventDefault();
    if (onMouseUp !== null) {
      const location = calculateTouchLocation(event, AREA_SIZE, zoom);
      setIsMouseDown(false);
      onMouseUp(location);
    }
  };

  const handleTouchMove = (event) => {
    if (isMouseDown && onMouseMove !== null) {
      const location = calculateTouchLocation(event, AREA_SIZE, zoom);
      if (locationRef.current.x !== location.x || locationRef.current.y !== location.y) {
        locationRef.current = location;
        onMouseMove(location);
      }
    }
  };

  const handleMouseMove = (event) => {
    if (isMouseDown && onMouseMove !== null) {
      const location = calculateMouseLocation(event, AREA_SIZE);
      if (viewportActivity !== 'walk' || (locationRef.current.x !== location.x || locationRef.current.y !== location.y)) {
        locationRef.current = location;
        onMouseMove(location);
      }
    }
  };

  const finalViewpoint = calculateFinalViewpoint(
    viewpoint,
    zoom,
    frameWidth,
    frameHeight,
    mapWidth,
    mapHeight,
    region,
  );
  const translation = calculateTranslation(finalViewpoint, zoom, AREA_SIZE, frameWidth, frameHeight);
  // This logic is responsible for turning off the transform transition when the frame itself is changing its size.  This keeps the viewpoint perfectly in the center of the frame, rather than
  // having it take a moment to catch up when the dimensions start shifting.
  let animateTransition = true;
  const lastDimensions = dimensionsRef.current;

  if (lastDimensions === null
    || frameWidth !== lastDimensions.frameWidth
    || frameHeight !== lastDimensions.frameHeight
  ) {
    animateTransition = false;
    // Only set the lastViewpoint if something changed.
  }
  dimensionsRef.current = { frameWidth, frameHeight };

  return (
    <div
      className={classNames(
        'border-box',
        className,
      )}
      style={style}
    >
      <div
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchCancel={(event) => {
          // Just... turn this off, not sure when it could happen.
          event.preventDefault();
        }}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
        onContextMenu={(event) => {
          // Turn off context menu clicks.
          event.preventDefault();
        }}
        className="position-relative"
        style={{
          transform: `translate(${translation.x}px, ${translation.y}px) scale(${zoom}, ${zoom})`,
          transition: (animateTransition && animateTransform) ? 'transform 0.15s linear, opacity 0.3s linear' : null,
          transformOrigin: 'left top',
          height: mapHeight,
          width: mapWidth,
          touchAction: 'none',
        }}
      />
    </div>
  );
}
