/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { StandardAction } from '../../../../data/types';
import { ItemIdPayload, TradePartnerIdPayload, TradeState } from './types';

export const initialState: TradeState = {
  tradePartnerId: null,
  sellItemIds: [],
  buyItemIds: [],
};

const slice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    updateTradePartnerId: (state: TradeState, action: StandardAction<TradePartnerIdPayload>) => {
      state.tradePartnerId = action.payload.tradePartnerId;
    },
    clearTradePartnerId: (state: TradeState) => {
      state.tradePartnerId = null;
    },
    addSellItem: (state: TradeState, action: StandardAction<ItemIdPayload>) => {
      state.sellItemIds = [...state.sellItemIds.filter(id => id !== action.payload.itemId), action.payload.itemId];
    },
    removeSellItem: (state: TradeState, action: StandardAction<ItemIdPayload>) => {
      state.sellItemIds = state.sellItemIds.filter(id => id !== action.payload.itemId);
    },
    addBuyItem: (state: TradeState, action: StandardAction<ItemIdPayload>) => {
      state.buyItemIds = [...state.buyItemIds.filter(id => id !== action.payload.itemId), action.payload.itemId];
    },
    removeBuyItem: (state: TradeState, action: StandardAction<ItemIdPayload>) => {
      state.buyItemIds = state.buyItemIds.filter(id => id !== action.payload.itemId);
    },
    clearTradeItems: (state: TradeState) => {
      state.sellItemIds = [];
      state.buyItemIds = [];
    }
  },
});

export const {
  updateTradePartnerId,
  clearTradePartnerId,
  addSellItem,
  removeSellItem,
  addBuyItem,
  removeBuyItem,
  clearTradeItems,
} = slice.actions;

export const { reducer } = slice;
