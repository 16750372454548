import featuresImage from '../../spritesheets/features.png';

const featureTemplates = [
  {
    id: 'pine-nw',
    name: 'Pine Tree',
    image: {
      src: featuresImage,
      position: { x: 0, y: 0 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'pine-n',
    name: 'Pine Tree',
    image: {
      src: featuresImage,
      position: { x: 1, y: 0 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'pine-ne',
    name: 'Pine Tree',
    image: {
      src: featuresImage,
      position: { x: 2, y: 0 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'pine-w',
    name: 'Pine Tree',
    image: {
      src: featuresImage,
      position: { x: 0, y: 1 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'pine-center',
    name: 'Pine Tree',
    image: {
      src: featuresImage,
      position: { x: 1, y: 1 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'pine-e',
    name: 'Pine Tree',
    image: {
      src: featuresImage,
      position: { x: 2, y: 1 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'pine-sw',
    name: 'Pine Tree',
    image: {
      src: featuresImage,
      position: { x: 0, y: 2 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'pine-s',
    name: 'Pine Tree',
    image: {
      src: featuresImage,
      position: { x: 1, y: 2 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'pine-se',
    name: 'Pine Tree',
    image: {
      src: featuresImage,
      position: { x: 2, y: 2 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'pine-trunk',
    name: 'Pine Tree',
    image: {
      src: featuresImage,
      position: { x: 1, y: 3 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
];

export default featureTemplates;
