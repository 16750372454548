let templates = {};

function assertTemplate(id) {
  const template = templates[id];
  if (template === undefined) {
    throw new Error(`Template ${id} does not exist!`);
  }
}

export function getTemplate(id) {
  assertTemplate(id);

  return templates[id];
}

export function setTemplates(data) {
  templates = data;
}
