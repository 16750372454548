import { v4 as uuidv4 } from 'uuid';

// This is not exported because we should be using createFeatureWithChildren everywhere.
function createFeature({
  templateId,
  regionId = null,
  containerItemId = null,
  uniqueName = null,
  order = null,
  status = null,
  contents = [],
  keyId = null,
  x = null,
  y = null,
  ...properties
}) {
  assertFeatureTemplate(templateId);

  const featureTemplate = getFeatureTemplate(templateId);

  // A container inside a container, or on a character, will not have a status set.  So default it to closed.
  let finalStatus = status;
  if (finalStatus === null) {
    if (featureTemplate.container) {
      finalStatus = 'closed';
    }
  }

  const id = uuidv4();

  // TODO: These children must be items.  Therefore we need to
  // return them separately, kind of like how a character creates items.
  let children = [];
  if (contents) {
    for (let i = 0; i < contents.length; i++) {
      const childData = contents[i];
      if (typeof childData === 'object') {
        const {
          templateId: childId,
          children: grandChildren,
        } = childData;
        const child = createFeature({
          templateId: childId,
          containerItemId: id,
          order: i,
          uniqueName: childData.uniqueName || null,
          contents: grandChildren,
        });
        children.push(child);
        children = [...children, ...child.children];
        delete child.children; // We no longer need this.
      } else {
        const child = createFeature({
          templateId: childData,
          containerItemId: id,
          order: i,
        });
        children.push(child);
        children = [...children, ...child.children];
      }
    }
  }

  return {
    ...properties,
    id,
    regionId,
    x,
    y,
    status: finalStatus,
    children,
    containerItemId,
    templateId,
    order,
    uniqueName,
    keyId,
  };
}

export function createFeatureWithChildren(props) {
  const features = {};
  const feature = createFeature(props);
  features[feature.id] = feature;
  feature.children.forEach(child => {
    features[child.id] = child;
  });
  delete feature.children; // We now no longer need this.
  return features;
}

function createFeatureTemplate({
  id,
  name,
  image,
  container = false,
  lockable = false,
  door = false,
  doorSection = null,
  moveable = true,
  walkable = true,
  status = null,
  atlas = 'features',
  determiner = 'a', // English determiners. For singular objects, usually 'a' or 'an'.  Plurals can be things like 'a pair of' or 'some'.  a club. an apple. some raspberries. a pair of gloves.
  light = null,
}) {
  if (!id) {
    throw new Error('createFeatureTemplate: id is a required field.');
  }

  if (!name) {
    throw new Error('createFeatureTemplate: name is a required field.');
  }

  if (!image) {
    throw new Error('createFeatureTemplate: image is a required field.');
  }

  return {
    id,
    name,
    image,
    determiner,
    container,
    lockable,
    door,
    doorSection,
    moveable,
    walkable,
    status,
    atlas,
    light,
  };
}

export function createFeatureTemplates(dataList) {
  const templates = {};

  dataList.forEach(data => {
    const feature = createFeatureTemplate(data);

    templates[feature.id] = feature;

  });
  return templates;
}

let featureTemplates = {};

function assertFeatureTemplate(id) {
  const template = featureTemplates[id];
  if (template === undefined) {
    throw new Error(`Feature template ${id} does not exist!`);
  }
}

export function getFeatureTemplate(id) {
  assertFeatureTemplate(id);
  return featureTemplates[id];
}

export function setFeatureTemplates(dataList) {
  featureTemplates = createFeatureTemplates(dataList);
}


export function generateFeatureAtlas() {
  const tileSize = 16;
  const margin = 1;
  const spacing = 2;
  const frames = [];

  const frameDefaults = {
    rotated: false,
    trimmed: false,
    spriteSourceSize: { x: 0, y: 0, w: tileSize, h: tileSize },
    sourceSize: { w: tileSize, h: tileSize },
    pivot: { x: 0, y: 0 }
  };

  Object.values(featureTemplates).forEach(featureTemplate => {
    if (featureTemplate.image.statuses) {
      Object.entries(featureTemplate.image.statuses).forEach(([key, value]) => {
        const { position } = value;
        frames.push({
          filename: `${featureTemplate.id}@${key}`,
          frame: {
            x: margin + (position.x * tileSize) + (position.x * spacing),
            y: margin + (position.y * tileSize) + (position.y * spacing),
            w: tileSize,
            h: tileSize
          },
          ...frameDefaults,
        });
      });
    } else {
      const { position } = featureTemplate.image;
      frames.push({
        filename: featureTemplate.id,
        frame: {
          x: margin + (position.x * tileSize) + (position.x * spacing),
          y: margin + (position.y * tileSize) + (position.y * spacing),
          w: tileSize,
          h: tileSize
        },
        ...frameDefaults,
      });
    }
  });

  return { frames };
}
