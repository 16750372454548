import { Fader, FullBleed, Panel } from '../generic';

import { useHasSave } from '../../saves/data/hooks';
import Splash from './Splash';
import NewButton from './NewButton';
import LoadButton from './LoadButton';

export default function MainMenu({ story }) {
  const hasSave = useHasSave();

  return (
    <FullBleed
      className="bg-parchment-700"
    >
      <Fader ready speed="1s" className="position-absolute">
        <Splash story={story} />
      </Fader>
      <FullBleed
        as="main"
        className="d-flex flex-column justify-content-center align-items-center layer-1"
      >
        <Fader ready delay={1}>
          <Panel className="pb-5 pt-5 pr-4 pl-4 d-flex flex-column justify-content-center align-items-center">
            <h1 className="mb-4 d1">{story.story.title}</h1>
            <Fader ready delay={1.5}>
              <NewButton story={story} />
            </Fader>
            <Fader ready={hasSave} delay={2}>
              <LoadButton />
            </Fader>
          </Panel>
        </Fader>
      </FullBleed>
    </FullBleed>
  );
}
