import localforage from 'localforage';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, FullBleed } from './generic';
import { loadSavedGame, quitGame } from '../saves/data/thunks';

export default function Defeated() {
  const [hasSave, setHasSave] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    localforage.length().then((length) => {
      if (length > 0) {
        setHasSave(true);
      }
    });
  }, []);

  const handleLoad = () => {
    dispatch(loadSavedGame());
  };

  const handleQuit = () => {
    dispatch(quitGame());
  };

  return (
    <FullBleed
      as="main"
      className="d-flex layer-1 flex-column justify-content-center align-items-center text-white bg-primary-frosted"
    >
      <h1 className="mb-4">You have been defeated.</h1>
      <p>Everyone laments.</p>
      {hasSave && (
        <Button style={{ width: 160 }} className="mb-2 btn-dark" onClick={handleLoad}>Reload</Button>
      )}
      <Button style={{ width: 160 }} className="btn-dark" onClick={handleQuit}>Quit</Button>
    </FullBleed>
  );
}
