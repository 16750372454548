import { partyCatchupWalk, wander } from '../../../../characters/movement/data/thunks';
import { MovementTypes } from '../../../../content-utils/types';
import { getStore } from '../../../../data/configureStore';
import { PartyModes } from '../../../../data/types';
import { isInputWalking } from '../../input/director';

export function performMovement(character, { setDelay }) {
  const {
    partyMode,
    activeCharacterId
  } = getStore().getState().game;

  let defaultDelay = 150;
  switch (character.movement.type) {
    case MovementTypes.WANDER:
      setDelay(character.movement.delay);
      getStore().dispatch(wander({ characterId: character.id }));

      break;
    case MovementTypes.PARTY:
      setDelay(defaultDelay);
      if (partyMode === PartyModes.ALL && !isInputWalking() && character.id !== activeCharacterId ) {
        getStore().dispatch(partyCatchupWalk({ characterId: character.id }));
      }

      break;
    case MovementTypes.STATIONARY:

      break;
    default:
  }
}
