
import itemsImage from '../../spritesheets/items.png';

const itemTemplates = [
  {
    id: 'wooden-door-top',
    name: 'Wooden Door',
    status: 'closed',
    container: false,
    lockable: true,
    walkable: true,
    moveable: false,
    elevation: 2,
    door: true,
    doorSection: 'top',
    image: {
      statuses: {
        closed: {
          src: itemsImage,
          position: { x: 14, y: 0 },
        },
        open: {
          src: itemsImage,
          position: { x: 14, y: 2 },
        },
        locked: {
          src: itemsImage,
          position: { x: 14, y: 4 },
        }
      }
    },
    value: 20,
  },
  {
    id: 'wooden-door-bottom',
    name: 'Wooden Door',
    status: 'closed',
    container: false,
    lockable: true,
    walkable: false,
    moveable: false,
    door: true,
    doorSection: 'bottom',
    image: {
      statuses: {
        closed: {
          src: itemsImage,
          position: { x: 14, y: 1 },
        },
        open: {
          src: itemsImage,
          position: { x: 14, y: 3 },
        },
        locked: {
          src: itemsImage,
          position: { x: 14, y: 5 },
        }
      }
    },
    value: 20,
  },

];

export default itemTemplates;
