import { getStore } from '../../../../data/configureStore';
import { startConversation } from '../../../../ui/activities/conversation';
import { startActivity } from '../../../../ui/activities/data/thunks';
import { ActivityTypes } from '../../../../ui/activities/data/types';

export function performConversationStep(step) {
  const { dispatch } = getStore();
  dispatch(startConversation({ characterId: step.conversationPartnerId }));
  dispatch(startActivity({ activity: ActivityTypes.CONVERSATION }));
}
