import ShakeIt from './ui/ShakeIt';
import { Fader } from './ui/generic';
import Hud from './ui/Hud';
import {
  useActiveRegion,
  useDocumentVisibility,
  useTimeAdvancement,
  useWindowSize,
} from './data/hooks';
import { logRender } from './data/utils';
import ViewportProvider from './world/ViewportProvider';
import MousePositionProvider from './player/MousePositionProvider';
import MoveListener from './player/MoveListener';

export default function Game() {
  logRender('Game');

  const { width, height } = useWindowSize();
  const region = useActiveRegion();

  useTimeAdvancement();
  useDocumentVisibility();

  return (
    <ViewportProvider
      frameWidth={width}
      frameHeight={height}
      regionId={region.id}
    >
      <MousePositionProvider>
        <ShakeIt>
          <Fader ready>
            <MoveListener />
            <Hud />
          </Fader>
        </ShakeIt>
      </MousePositionProvider>
    </ViewportProvider>
  );
}
