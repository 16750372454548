import { endJourneyTheHardWay } from '../../../data/slice';
import { updateModel } from '../../../models-store';

export function decreaseEndurance({ characterId, amount }) {
  return async (dispatch, getState) => {
    const { endurance, health } = getState().models.characters[characterId];

    let newEndurance = endurance;
    let newHealth = health;
    if (endurance > 0) {
      newEndurance -= amount;
    } else if (health > 1) {
      newHealth -= amount;
    } else {
      dispatch(endJourneyTheHardWay());
    }

    dispatch(updateModel({ modelType: 'characters', model: {
      id: characterId,
      endurance: newEndurance,
      health: newHealth,
    }}));
  };
}

export function rest({ characterId, healthQuality, enduranceQuality }) {
  return async (dispatch, getState) => {
    const character = getState().models.characters[characterId];

    dispatch(updateModel({
      modelType: 'characters',
      model: {
        id: characterId,
        health: Math.min(character.maxHealth, character.health + healthQuality),
        endurance: Math.min(character.maxEndurance, character.endurance + enduranceQuality),
      },
    }));
  };
}
