
import itemsImage from '../../spritesheets/items.png';

const itemTemplates = [
  // SWORD COLUMN
  {
    id: 'short-sword',
    name: 'Short Sword',
    image: {
      src: itemsImage,
      position: { x: 0, y: 0 },
    },
    damage: 8,
    defense: 2,
    value: 15,
    allowances: 'primary',
  },
  {
    id: 'dagger',
    name: 'Dagger',
    image: {
      src: itemsImage,
      position: { x: 0, y: 1 },
    },
    damage: 3,
    defense: 1,
    value: 8,
    allowances: 'secondary',
  },
  {
    id: 'long-sword',
    name: 'Long Sword',
    image: {
      src: itemsImage,
      position: { x: 0, y: 2 },
    },
    damage: 10,
    defense: 1,
    value: 20,
    allowances: 'primary',
  },
  {
    id: 'rapier',
    name: 'Rapier',
    image: {
      src: itemsImage,
      position: { x: 0, y: 3 },
    },
    damage: 5,
    defense: 1,
    value: 14,
    allowances: 'primary',
  },
  {
    id: 'stiletto',
    name: 'Stiletto',
    image: {
      src: itemsImage,
      position: { x: 0, y: 4 },
    },
    damage: 3,
    defense: 1,
    value: 9,
    allowances: 'secondary',
  },
  {
    id: 'knife',
    name: 'Knife',
    image: {
      src: itemsImage,
      position: { x: 0, y: 5 },
    },
    damage: 2,
    defense: 1,
    value: 6,
    allowances: 'secondary',
  },
  {
    id: 'battle-axe',
    name: 'Battle Axe',
    image: {
      src: itemsImage,
      position: { x: 0, y: 6 },
    },
    damage: 9,
    defense: 1,
    value: 25,
    allowances: 'primary',
  },
  {
    id: 'throwing-axe',
    name: 'Throwing Axe',
    image: {
      src: itemsImage,
      position: { x: 0, y: 7 },
    },
    damage: 5,
    defense: 1,
    value: 10,
    allowances: 'primary',
  },
  {
    id: 'hand-axe',
    name: 'Hand Axe',
    image: {
      src: itemsImage,
      position: { x: 0, y: 8 },
    },
    damage: 5,
    defense: 1,
    value: 12,
    allowances: 'secondary',
  },
  {
    id: 'halberd',
    name: 'Halberd',
    image: {
      src: itemsImage,
      position: { x: 0, y: 9 },
    },
    damage: 12,
    defense: 1,
    value: 30,
    allowances: 'primary',
  },
  {
    id: 'spear',
    name: 'Spear',
    image: {
      src: itemsImage,
      position: { x: 0, y: 10 },
    },
    damage: 8,
    defense: 1,
    value: 12,
    allowances: 'primary',
  },
  {
    id: 'club',
    name: 'Club',
    image: {
      src: itemsImage,
      position: { x: 0, y: 11 },
    },
    damage: 5,
    defense: 1,
    value: 4,
    allowances: 'primary',
  },
  {
    id: 'mace',
    name: 'Mace',
    image: {
      src: itemsImage,
      position: { x: 0, y: 12 },
    },
    damage: 8,
    defense: 1,
    value: 15,
    allowances: 'primary',
  },
  {
    id: 'maul',
    name: 'Maul',
    image: {
      src: itemsImage,
      position: { x: 0, y: 13 },
    },
    damage: 9,
    defense: 1,
    value: 30,
    allowances: 'primary',
  },
  {
    id: 'war-hammer',
    name: 'War Hammer',
    image: {
      src: itemsImage,
      position: { x: 0, y: 14 },
    },
    damage: 12,
    defense: 1,
    value: 18,
    allowances: 'primary',
  },
  {
    id: 'quarterstaff',
    name: 'Quarterstaff',
    image: {
      src: itemsImage,
      position: { x: 0, y: 15 },
    },
    damage: 8,
    defense: 1,
    value: 7,
    allowances: 'primary',
  },
];

export default itemTemplates;
