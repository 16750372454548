import memoize from 'memoize-one';

import { getCutscene } from '../../../content-utils/cutsceneUtils';
import { getStore } from '../../../data/configureStore';
import createSelectorListener from '../../createSelectorListener';
import { performConversationStep } from './performers/conversation';
import { performMovementStep } from './performers/movement';
import { performNarrativeStep } from './performers/narrative';
import { performViewpointStep } from './performers/viewpoint';

let removeStateListener = null;

export function createCutsceneDirector() {
  const {
    remove,
  } = createSelectorListener(state => {
    return `${state.game.activeCutsceneId}${state.game.activeCutsceneStep}`;
  }, handleCutsceneChanged);

  const { activeCutsceneId, activeCutsceneStep } = getStore().getState().game;

  // Call it once to set up the initial state.
  handleCutsceneChanged(`${activeCutsceneId}${activeCutsceneStep}`);

  removeStateListener = remove;
}

export function destroyCutsceneDirector() {
  if (removeStateListener !== null) {
    removeStateListener();
  }
}

const handleCutsceneChanged = memoize((uniqueStepIdUnused) => {
  const { activeCutsceneId, activeCutsceneStep } = getStore().getState().game;

  if (activeCutsceneId === null) {
    return;
  }

  const cutscene = getCutscene(activeCutsceneId);
  const step = cutscene.steps[activeCutsceneStep];

  switch (step.action) {
    case 'movement':
      performMovementStep(step);
      break;
    case 'viewpoint':
      performViewpointStep(step);
      break;
    case 'narrative':
      performNarrativeStep(step);
      break;
    case 'conversation':
      performConversationStep(step);
      break;
    default:
      throw new Error(`Unknown cutscene action: ${step.action}`);
  }
});
