import { createSelector } from '@reduxjs/toolkit';

import { createCharacterEquipmentBySlotSelector } from '../../items/data/selectors';

export function createCharacterCombatStatsSelector(characterId) {
  const characterEquipmentBySlotSelector = createCharacterEquipmentBySlotSelector(characterId);
  return createSelector(
    state => state.models.characters[characterId],
    characterEquipmentBySlotSelector,
    (character, equipment) => {
      let damage = character.damage;
      let defense = character.defense;
      Object.values(equipment).forEach(item => {
        if (item) {
          damage += item.damage;
          defense += item.defense;
        }
      });

      return {
        damage,
        defense
      };
    }
  );
}
