import memoize from 'memoize-one';

import { getStore } from '../data/configureStore';
import { TimeModes } from '../data/types';
import { closeContainer } from '../player/data/thunks';

import createSelectorListener from './createSelectorListener';
import { destroyInputDirector } from './directors/input/director';
import { pause, play } from './controls';
import { activeCharacterSelector } from '../data/selectors';
import { addMessage } from '../ui/chronicle';
import { activeCharacterHostileEnvironmentSelector } from '../world/data/selectors';

let removeActiveCharacterListener = null;
let removeTimeModeListener = null;
let removeActiveCutsceneIdListener = null;
let removeHostileEnvironmentStateListener = null;

export function createListeners() {
  const activeCharacterResult = createSelectorListener(activeCharacterSelector, handleActiveCharacterChanged);
  const timeModeResult = createSelectorListener(state => state.game, handleTimeModeChanged);
  const cutsceneResult = createSelectorListener(state => state.game.activeCutsceneId, handleCutsceneChanged);
  const hostileEnvironmentResult = createSelectorListener(activeCharacterHostileEnvironmentSelector, handleHostileEnvironmentChanged);

  removeActiveCharacterListener = activeCharacterResult.remove;
  removeTimeModeListener = timeModeResult.remove;
  removeActiveCutsceneIdListener = cutsceneResult.remove;
  removeHostileEnvironmentStateListener = hostileEnvironmentResult.remove;
}

export function destroyListeners() {
  removeActiveCharacterListener();
  removeTimeModeListener();
  removeActiveCutsceneIdListener();
  removeHostileEnvironmentStateListener();
}

const handleActiveCharacterChanged = memoize((nextActiveCharacter) => {
  const { openContainers } = getStore().getState().hud;

  const character = getStore().getState().models.characters[nextActiveCharacter.id];
  openContainers.forEach(containerItemId => {
    const container = getStore().getState().models.items[containerItemId];
    if (container.regionId !== null) {
      if (Math.abs(character.x - container.x) > 1 || Math.abs(character.y - container.y) > 1) {
        getStore().dispatch(closeContainer({
          containerItemId
        }));
      }
    }
  });
});

const handleTimeModeChanged = memoize((timeMode) => {
  if (timeMode === TimeModes.PAUSED) {
    destroyInputDirector();
    pause();
  } else if (timeMode === TimeModes.NORMAL) {
    play();
  }
});

const handleCutsceneChanged = memoize((activeCutsceneId) => {
  const { timeMode } = getStore().getState().game;
  if (activeCutsceneId === null && timeMode === TimeModes.CUTSCENE) {
    play();
  }
});

const handleHostileEnvironmentChanged = memoize((isHostileEnvironment) => {
  const { timeMode } = getStore().getState().game;
  if (!isHostileEnvironment) {
    getStore().dispatch(addMessage({ message: 'Combat ceased.' }));
  } else {
    if (timeMode === TimeModes.NORMAL) {
      pause();
    }
    getStore().dispatch(addMessage({ message: 'Combat initiated!' }));
  }
});
