import { getCutscene } from '../../../content-utils/cutsceneUtils';
import { updateActiveCutsceneId, updateActiveCutsceneStep } from '../../../data/slice';

export function cutsceneStepComplete() {
  return async (dispatch, getState) => {
    const { activeCutsceneId, activeCutsceneStep } = getState().game;
    const cutscene = getCutscene(activeCutsceneId);
    const nextStep = activeCutsceneStep + 1;
    if (nextStep < cutscene.steps.length) {
      dispatch(updateActiveCutsceneStep({ cutsceneStep: nextStep }));
    } else {
      // This function is only responsible for cleaning up the cutscene state.
      // It's the engine's responsibility to identify that the cutscene has ended
      // and choose the next state (which is probably 'play')
      dispatch(updateActiveCutsceneId({ cutsceneId: null }));
      dispatch(updateActiveCutsceneStep({ cutsceneStep: null }));
    }
  };
}
