import localforage from 'localforage';
import { useEffect, useState } from 'react';

export function useHasSave() {
  const [hasSave, setHasSave] = useState(false);
  useEffect(() => {
    localforage.length().then((length) => {
      if (length > 0) {
        setHasSave(true);
      }
    });
  }, []);
  return hasSave;
}
