import { useCharacterSkills } from '../../characters/data/hooks';

export default function Skills({ character, className }) {
  const {
    gathering,
    hunting,
    running,
    survival,
  } = useCharacterSkills(character.id);

  return (
    <div className={className}>
      <Skill name="Gathering" value={gathering} />
      <Skill name="Hunting" value={hunting} />
      <Skill name="Running" value={running} />
      <Skill name="Survival" value={survival} />
    </div>
  );
}

function Skill({ name, value }) {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div>
        {name}
      </div>
      <div className="d-flex justify-content-end">
        {value}
      </div>
    </div>
  );
}
