import featuresImage from '../../spritesheets/features.png';

const featureTemplates = [
  {
    id: 'bush-nw',
    name: 'Bush',
    image: {
      src: featuresImage,
      position: { x: 0, y: 4 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'bush-ne',
    name: 'Bush',
    image: {
      src: featuresImage,
      position: { x: 1, y: 4 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'bush-sw',
    name: 'Bush',
    image: {
      src: featuresImage,
      position: { x: 0, y: 5 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'bush-se',
    name: 'Bush',
    image: {
      src: featuresImage,
      position: { x: 1, y: 5 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
];

export default featureTemplates;
