let tilesets = {};

function assertTileset(id) {
  const tileset = tilesets[id];
  if (tileset === undefined) {
    throw new Error(`Tileset ${id} does not exist!`);
  }
}

export function getTileset(id) {
  assertTileset(id);

  return tilesets[id];
}

export function setTilesets(data) {
  tilesets = data;
}
