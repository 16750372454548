import { useGame } from '../data/hooks';
import ActivityIcon from './generic/ActivityIcon';

// https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number/57518703
function ordinalSuffixOf(i) {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export default function DateTime() {
  const {
    day,
    hour
  } = useGame();

  const season = Math.floor(day / 28);
  const weekday = (day % 28) + 1;

  let seasonDescription = null;
  switch (season) {
    case 0:
      seasonDescription = 'winter';
      break;
    case 1:
      seasonDescription = 'spring';
      break;
    case 2:
      seasonDescription = 'summer';
      break;
    case 3:
      seasonDescription = 'fall';
      break;
    default:
      seasonDescription = 'winter'; // TODO: Eh... bad default.
  }

  const hourDescriptions = [
    'Midnight', // midnight
    'Night', // 1
    'Night', // 2
    'Night', // 3
    'Predawn', // 4
    'Dawn', // 5
    'Early Morning', // 6
    'Early Morning', // 7
    'Morning', // 8
    'Morning', // 9
    'Late Morning', // 10
    'Late Morning', // 11
    'Noon', // 12
    'Early Afternoon', // 1pm
    'Afternoon', // 2pm
    'Afternoon', // 3pm
    'Late Afternoon', // 4pm
    'Dusk', // 5pm
    'Early Evening', // 6pm
    'Evening', // 7pm
    'Late Evening', // 8pm
    'Night', // 9pm
    'Night', // 10pm
    'Night', // 11pm
  ];

  const hourDescription = hourDescriptions[hour];
  return (
    <div className="d-flex flex-column align-items-center">
      <div>{hourDescription}</div>
      <div className="d-flex justify-content-center align-items-center">

        <div className="mr-2">{`${ordinalSuffixOf(weekday)} of `}</div>
        <ActivityIcon scale={2} activityId={seasonDescription} />
      </div>
    </div>
  );
}
