import featuresImage from '../../spritesheets/features.png';

const featureTemplates = [
  {
    id: 'plant-1',
    name: 'Bush',
    image: {
      src: featuresImage,
      position: { x: 2, y: 4 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'moss',
    name: 'Moss',
    image: {
      src: featuresImage,
      position: { x: 3, y: 4 }
    },
    atlas: 'features',
    moveable: false,
    determiner: 'some',
  },
  {
    id: 'roses-1',
    name: 'Roses',
    image: {
      src: featuresImage,
      position: { x: 3, y: 5 }
    },
    atlas: 'features',
    moveable: false,
    determiner: 'some',
  },
  {
    id: 'roses-2',
    name: 'Roses',
    image: {
      src: featuresImage,
      position: { x: 4, y: 5 }
    },
    atlas: 'features',
    moveable: false,
    determiner: 'some',
  },
];

export default featureTemplates;
