import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { Button, DraggablePanel } from '../../generic';
import { useCharacter, useGame } from '../../../data/hooks';
import ActivityIcon from '../../generic/ActivityIcon';
import { breakCamp, partyCamp } from './data/thunks';
import PortraitButton from '../../sheet/PortraitButton';

export default function Camp() {
  const dispatch = useDispatch();
  const {
    activeCharacterId,
    partyCharacterIds,
    hour,
  } = useGame();

  const dawn = 5;
  const dusk = 17;

  let untilDawn = dawn - hour;
  if (hour > dawn) {
    untilDawn = 23 - hour + dawn;
  }

  let untilDusk = dusk - hour;
  if (hour > dusk) {
    untilDusk = 23 - hour + dusk;
  }

  const [watcherCharacterId, setWatcherCharacterId] = useState(null);
  const [campDuration, setCampDuration] = useState(null);

  const watcher = useCharacter(watcherCharacterId);

  const handleEnd = () => {
    dispatch(breakCamp({ characterId: activeCharacterId }));
  };

  const handleCamp = () => {
    dispatch(partyCamp({ watcherId: watcherCharacterId, duration: campDuration }));
  };

  const handleSetWatch = (characterId) => {
    if (watcherCharacterId === characterId) {
      setWatcherCharacterId(null);
    } else {
      setWatcherCharacterId(characterId);
    }
  };

  const handleSetDuration = (duration) => {
    setCampDuration(duration);
  };

  let description = 'No one will keep watch';
  if (watcherCharacterId) {
    description = `${watcher.name} will keep watch`;
  }

  if (campDuration) {
    description += `, and the party will rest for ${campDuration} hours`;
  }

  description += '.';

  return (
    <DraggablePanel
      id="camp"
      title="Camping"
      onClose={handleEnd}
    >
      <section className="d-flex flex-column gap-3 align-items-center text-align-center">
        <ActivityIcon activityId="camp" scale={9}/>
        <p>How long will you rest?</p>
        <div className="d-flex gap-2 mb-2">
          <Button onClick={() => handleSetDuration(1)}>1 Hour</Button>
          <Button onClick={() => handleSetDuration(4)}>4 Hours</Button>
          <Button onClick={() => handleSetDuration(8)}>8 Hours</Button>
        </div>
        <div className="d-flex gap-2">
          <Button onClick={() => handleSetDuration(untilDawn)}>Until Dawn ({untilDawn}h)</Button>
          <Button onClick={() => handleSetDuration(untilDusk)}>Until Dusk ({untilDusk}h)</Button>
        </div>
        <p>Who will keep watch?</p>
        <div className="d-flex gap-2 mb-3">
          {partyCharacterIds.map(characterId => {
            const selected = characterId === watcherCharacterId;
            return (
              <PortraitButton
                key={characterId}
                scale={2}
                characterId={characterId}
                onClick={() => handleSetWatch(characterId)}
                selected={selected}
              />
            );
          })}
        </div>
        <p>{description}</p>
        <div className="d-flex gap-1">
          <Button onClick={handleCamp}>Camp</Button>
          <Button onClick={handleEnd}>Leave Campsite</Button>
        </div>
      </section>
    </DraggablePanel>
  );
}
