import { createSelector } from 'reselect';
import { hydrateItem } from '../../../../data/contentUtils';

export const buyPriceSelector = createSelector(
  state => state.trade,
  state => state.models.items,
  state => state.models.characters,
  (trade, itemsById, charactersById) => {
    const {
      tradePartnerId,
      buyItemIds
    } = trade;

    let totalPrice = 0;
    const tradePartner = charactersById[tradePartnerId];
    buyItemIds.forEach(itemId => {
      const item = itemsById[itemId];
      const price = tradePartner.sells[item.templateId] * item.quantity;
      totalPrice += price;
    });

    return totalPrice;
  }
);

export const sellPriceSelector = createSelector(
  state => state.trade,
  state => state.models.items,
  state => state.models.characters,
  (trade, itemsById, charactersById) => {
    const { tradePartnerId, sellItemIds } = trade;

    let totalPrice = 0;
    const tradePartner = charactersById[tradePartnerId];
    sellItemIds.forEach(itemId => {
      const item = itemsById[itemId];
      const price = tradePartner.buys[item.templateId] * item.quantity;
      totalPrice += price;
    });

    return totalPrice;
  }
);

// Sellers in shops set the price and decide what they're willing to sell - our active character doesn't.
export function createCharacterShopItemsSelector(sellerCharacterId) {
  return createSelector(
    state => state.models.items,
    state => state.models.characters[sellerCharacterId],
    (itemsById, seller) => {
      const items = [];
      if (sellerCharacterId) {
        Object.values(itemsById).forEach(item => {
          if (item.characterId === sellerCharacterId && seller.sells[item.templateId] !== undefined) {
            items.push(hydrateItem(item));
          }
        });
      }
      return items.sort((a, b) => {
        return seller.buys[a.templateId] - seller.buys[b.templateId];
      });
    }
  );
}

// Buyers set the price and decide what they want to buy - our active character doesn't.
export function createCharacterSellItemsSelector(characterId, buyerId) {
  return createSelector(
    state => state.models.items,
    state => state.models.characters[buyerId],
    (itemsById, buyer) => {
      const items = [];
      Object.values(itemsById).forEach(item => {
        if (item.characterId === characterId && buyer.buys[item.templateId] !== undefined) {
          items.push(hydrateItem(item));
        }
      });
      return items.sort((a, b) => {
        return buyer.buys[a.templateId] - buyer.buys[b.templateId];
      });
    }
  );
}
