
import characters from './characterTemplates';
import features from './featureTemplates';
import items from './itemTemplates';

const templates = {
  characters,
  features,
  items,
};

export default templates;
