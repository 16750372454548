import { FullBleed } from '../generic';

import { useWanderingPosition } from './data/hooks';

export default function Splash({ story }) {
  const [backgroundX, backgroundY] = useWanderingPosition();

  const { wander, background } = story.story.splash;

  const finalBackgroundX = wander ? backgroundX : 0;
  const finalBackgroundY = wander ? backgroundY : 0;

  return (
    <FullBleed
      style={{
        backgroundImage: `url('${background}')`,
        backgroundSize: 4800,
        imageRendering: 'pixelated',
        transition: 'background-position 0.5s',
        backgroundPosition: `${finalBackgroundX}px ${finalBackgroundY}px`,
      }}
    />
  );
}
