import classNames from 'classnames';

import { logRender } from '../../data/utils';
import BodyPart from './body/BodyPart';
import { SOUTH, REST, UNCONSCIOUS } from './constants';

export default function Character({
  className,
  scale = 1,
  character,
  style = {},
  direction = SOUTH,
  pose = REST,
  portrait = false,
}) {
  logRender('Character');
  let finalPose = pose;
  let finalDirection = direction;

  // If you're unconscious, lie down!
  if (character.health === 0) {
    finalPose = UNCONSCIOUS;
    finalDirection = SOUTH;
  }

  const {
    skins,
    feet,
    legs,
    waists,
    chests,
    hairstyles,
    eyesIrises,
    eyesWhites,
  } = character.images;

  const finalWidth = 16 * scale;
  const finalHeight = (portrait ? 16 : 32) * scale;

  return (
    <div
      className={classNames(
        className,
        'position-relative',
        'pixelated',
        'overflow-hidden',
      )}
      style={{
        width: finalWidth,
        height: finalHeight,
      }}
    >
      {skins && (
        <BodyPart
          scale={scale}
          image={skins}
          bodyPart={character.body.skin}
          direction={finalDirection}
          pose={finalPose}
          portrait={portrait}
          className="position-absolute"
        />
      )}
      {feet && (
        <BodyPart
          scale={scale}
          image={feet}
          bodyPart={character.body.feet}
          direction={finalDirection}
          pose={finalPose}
          portrait={portrait}
          className="position-absolute"
        />
      )}
      {legs && (
        <BodyPart
          scale={scale}
          image={legs}
          bodyPart={character.body.legs}
          direction={finalDirection}
          pose={finalPose}
          portrait={portrait}
          className="position-absolute"
        />
      )}
      {waists && (
        <BodyPart
          scale={scale}
          image={waists}
          bodyPart={character.body.waist}
          direction={finalDirection}
          pose={finalPose}
          portrait={portrait}
          className="position-absolute"
        />
      )}
      {chests && (
        <BodyPart
          scale={scale}
          image={chests}
          bodyPart={character.body.chest}
          direction={finalDirection}
          pose={finalPose}
          portrait={portrait}
          className="position-absolute"
        />
      )}
      {hairstyles && (
        <BodyPart
          scale={scale}
          image={hairstyles}
          bodyPart={character.body.hairstyle}
          direction={finalDirection}
          pose={finalPose}
          portrait={portrait}
          className="position-absolute"
        />
      )}
      {eyesIrises && (
        <BodyPart
          scale={scale}
          image={eyesIrises}
          bodyPart={character.body.eyesIrises}
          direction={finalDirection}
          pose={finalPose}
          portrait={portrait}
          className="position-absolute"
        />
      )}
      {eyesWhites && (
        <BodyPart
          scale={scale}
          image={eyesWhites}
          bodyPart={character.body.eyesWhites}
          direction={finalDirection}
          pose={finalPose}
          portrait={portrait}
          className="position-absolute"
        />
      )}
    </div>
  );
}
