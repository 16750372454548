
import itemsImage from '../../spritesheets/items.png';

const itemTemplates = [
  {
    id: 'short-bow',
    name: 'Short Bow',
    image: {
      src: itemsImage,
      position: { x: 2, y: 0 },
    },
    damage: 6,
    defense: 0,
    range: 7,
    value: 20,
    ammoTemplateIds: ['arrow'],
    allowances: 'primary',
  },
  {
    id: 'long-bow',
    name: 'Long Bow',
    image: {
      src: itemsImage,
      position: { x: 2, y: 1 },
    },
    damage: 8,
    defense: 0,
    range: 10,
    value: 25,
    ammoTemplateIds: ['arrow'],
    allowances: 'primary',
  },
  {
    id: 'recurve-bow',
    name: 'Recurve Bow',
    image: {
      src: itemsImage,
      position: { x: 2, y: 2 },
    },
    damage: 9,
    defense: 0,
    range: 8,
    value: 30,
    ammoTemplateIds: ['arrow'],
    allowances: 'primary',
  },
  {
    id: 'crossbow',
    name: 'Crossbow',
    image: {
      src: itemsImage,
      position: { x: 2, y: 3 },
    },
    damage: 9,
    defense: 0,
    range: 8,
    value: 30,
    ammoTemplateIds: ['bolt'],
    allowances: 'primary',
  },
  {
    id: 'arrow',
    name: 'Arrow',
    image: {
      quantities: {
        one: {
          src: itemsImage,
          position: { x: 2, y: 4 },
        },
        many: {
          src: itemsImage,
          position: { x: 2, y: 5 },
        }
      }
    },
    damage: 3,
    defense: 0,
    range: 10,
    value: 2,
    allowances: 'offHand',
    determiner: 'an',
    stackSize: 100,
  },
  {
    id: 'bolt',
    name: 'Bolt',
    image: {
      quantities: {
        one: {
          src: itemsImage,
          position: { x: 2, y: 6 },
        },
        many: {
          src: itemsImage,
          position: { x: 2, y: 7 },
        }
      }
    },
    damage: 3,
    defense: 0,
    range: 8,
    value: 2,
    allowances: 'offHand',
    stackSize: 100,
  },
];

export default itemTemplates;
