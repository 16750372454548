
import itemsImage from '../../spritesheets/items.png';

const itemTemplates = [
  {
    id: 'apple',
    name: 'Apple',
    image: {
      src: itemsImage,
      position: { x: 8, y: 0 },
    },
    value: 3,
    allowances: 'material',
    determiner: 'an',
  },
  {
    id: 'raspberries',
    name: 'Raspberries',
    image: {
      src: itemsImage,
      position: { x: 8, y: 1 },
    },
    value: 3,
    allowances: 'material',
    determiner: 'some',
  },
  {
    id: 'blueberries',
    name: 'Blueberries',
    image: {
      src: itemsImage,
      position: { x: 8, y: 2 },
    },
    value: 3,
    allowances: 'material',
    determiner: 'some',
  },
  {
    id: 'garlic',
    name: 'Garlic',
    image: {
      src: itemsImage,
      position: { x: 8, y: 3 },
    },
    value: 2,
    allowances: 'material',
    determiner: 'some',
  },
  {
    id: 'onions',
    name: 'Onions',
    image: {
      src: itemsImage,
      position: { x: 8, y: 4 },
    },
    value: 2,
    allowances: 'material',
    determiner: 'some',
  },
  {
    id: 'mushrooms',
    name: 'Mushrooms',
    image: {
      src: itemsImage,
      position: { x: 8, y: 5 },
    },
    value: 1,
    allowances: 'material',
    determiner: 'some',
  },
  {
    id: 'truffles',
    name: 'Truffles',
    image: {
      src: itemsImage,
      position: { x: 8, y: 6 },
    },
    value: 15,
    allowances: 'material',
  },
  {
    id: 'carrot',
    name: 'Carrot',
    image: {
      src: itemsImage,
      position: { x: 8, y: 7 },
    },
    value: 2,
    allowances: 'material',
  },
  {
    id: 'pork',
    name: 'Pork',
    image: {
      src: itemsImage,
      position: { x: 8, y: 8 },
    },
    value: 5,
    allowances: 'material',
    determiner: 'some',
  },
  {
    id: 'venison',
    name: 'Venison',
    image: {
      src: itemsImage,
      position: { x: 8, y: 9 },
    },
    value: 5,
    allowances: 'material',
    determiner: 'some',
  },
  {
    id: 'rabbit-meat',
    name: 'Rabbit Meat',
    image: {
      src: itemsImage,
      position: { x: 8, y: 10 },
    },
    value: 2,
    allowances: 'material',
    determiner: 'some',
  },
];

export default itemTemplates;
