import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import { SOUTH, REST } from '../character/constants';
import { addOpenSheet, removeOpenSheet } from '../data/slice';
import { useModel } from '../../models-store';
import EnduranceBar from '../sheet/EnduranceBar';
import HealthBar from '../sheet/HealthBar';
import ActivityIconButton from '../generic/ActivityIconButton';
import { PartyModes } from '../../data/types';
import { playerChangePartyMode } from '../../player/data/thunks';
import Character from '../character/Character';
import { useHud } from '../data/hooks';

export default function SheetButton({ characterId }) {
  const dispatch = useDispatch();
  const {
    openSheets,
  } = useHud();
  const character = useModel('characters', characterId);

  const openSheetCharacterIds = openSheets.map(openSheet => openSheet.characterId);

  const sheetOpen = openSheetCharacterIds.includes(character.id);

  const handleToggleSheet = useCallback(() => {
    if (sheetOpen) {
      dispatch(removeOpenSheet({ characterId: character.id }));
    } else {
      dispatch(addOpenSheet({ characterId: character.id }));
    }
  }, [sheetOpen, character, dispatch]);

  const handleSoloCharacter = useCallback(() => {
    dispatch(playerChangePartyMode({
      partyMode: PartyModes.SOLO,
      characterId
    }));
  }, [characterId, dispatch]);

  return (
    <div className={classNames(
      'd-flex align-items-start p-1',
    )}>

      <div className={classNames(
        'cursor-pointer d-flex align-items-start flex-grow-1 p-1 rounded-2',
        { 'bg-frosted-200 rounded-2': sheetOpen }
      )} onClick={handleToggleSheet} role="button">
        <Character
          portrait={false}
          scale={2}
          character={character}
          direction={SOUTH}
          pose={REST}
          className="mr-2"
        />
        <div className="p-1 flex-grow-1">
          <div className="mb-2 w-100 d-flex flex-column">
            <HealthBar height={7} characterId={character.id} barOnly className="mb-1 flex-grow-1" />
            <EnduranceBar height={7} characterId={character.id} barOnly />
          </div>
          <div>
            <ActivityIconButton
              className="p-2 mr-2"
              activityId="partyModeSolo"
              scale={1}
              onClick={handleSoloCharacter}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
