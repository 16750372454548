import { createElement } from 'react';
import classNames from 'classnames';

import { useWindowSize } from '../../data/hooks';

export default function FullBleed({ as = 'div', className, children, style = {}, ...props }) {
  const { width, height } = useWindowSize();

  return createElement(
    as,
    {
      style: {
        height,
        width,
        ...style,
      },
      className: classNames(className),
      ...props,
    },
    children
  );
}
