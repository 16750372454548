import { Item } from '../../data/types';
import { getSpritesheet } from '../spritesheetUtils';
import { getTileset } from '../tilesetUtils';
import {
  ModelsById,
  ModelsByLocation,
  RegionLink,
  RegionMap,
  Terrain,
  TerrainIdsByAreaLayer,
} from '../types';
import {
  Properties,
  RawProperty,
  RawTiledMap,
  RawObject,
  RawObjectLayer,
  RawTileLayer,
  RawTileset,
  TilesetTilesById,
  RawTileData,
  RawObjectItem
} from './types';

function processProperties(propsArray:Array<RawProperty> | undefined):Properties {
  const properties:Properties = {};
  if (propsArray) {
    propsArray.forEach((property: RawProperty) => {
      properties[property.name] = property.value;
    });
  }
  return properties;
}

function processMapTileLayer(width: number, layerId: string, data: Array<number>, areas: ModelsByLocation<TerrainIdsByAreaLayer>) {
  let x = 0;
  let y = 0;
  data.forEach((terrainId: number) => {
    if (x >= width) {
      x = 0;
      y += 1;
    }
    if (areas[`${x}&${y}`] === undefined) {
      areas[`${x}&${y}`] = {};
    }
    // Tiled sets un-specified tiles to 0
    // We'll turn that into a null for our purposes.
    if (terrainId !== 0) {
      areas[`${x}&${y}`][layerId] = terrainId;
    } else {
      areas[`${x}&${y}`][layerId] = null;
    }
    x += 1;
  });

  return areas;
}

function processTerrainTileset(firstgid: number, columns: number, rawTiles: Array<RawTileData>, srcImage: string): ModelsById<Terrain> {
  const terrains: ModelsById<Terrain> = {};
  rawTiles.forEach(tileData => {
    if (!tileData.properties) {
      throw new Error(`Tile had no properties. Probably need to set them in Tiled.`);
    }
    const properties:Properties = processProperties(tileData.properties);
    // tile IDs are based on their position.
    const x = tileData.id % columns;
    const y = Math.floor(tileData.id / columns);
    const terrainId = firstgid + tileData.id;
    terrains[terrainId] = {
      ...properties,
      id: terrainId,
      type: tileData.class,
      image: {
        src: srcImage,
        position: { x, y }
      }
    };
  });

  return terrains;
}

function processMapItems(tilesetTilesById:TilesetTilesById, regionItems: Array<RawObjectItem>): Array<Item> {
  return regionItems.map(regionItem => {
    // Here, find the item in tilesetById, and then
    // change the way that lookup object works so that it has objects
    // with both the ID and any custom properties.
    // pull the id out and put it below, and also splat out the custom properties.
    const tilesetItem = tilesetTilesById[regionItem.gid];
    const properties:any = processProperties(regionItem.properties);

    const {
      contents,
      ...otherProperties
    } = properties;
    return {
      templateId: tilesetItem.id,
      x: Math.floor(regionItem.x / 16),
      y: Math.floor(regionItem.y / 16) - 1, // The y coordinate starts at 16 for some reason in Tiled
      // This is merging the tileset item's custom properties with any on the actual region item
      // instance.
      ...tilesetItem,
      contents: contents ? JSON.parse(contents) : null,
      ...otherProperties, // Properties from the region item instance
    };
  });
}

function processMapObjects(rawObjects: Array<RawObject>) {
  const objects:any = {};
  rawObjects.forEach(rawObject => {
    if (rawObject.properties && rawObject.properties.length < 1) {
      throw new Error('Object had no properties. Probably need to add destinationId, roofId, etc. in Tiled.');
    }

    const height = Math.floor(rawObject.height / 16);
    const width = Math.floor(rawObject.width / 16);
    const left = Math.floor(rawObject.x / 16);
    const top = Math.floor(rawObject.y / 16);

    const properties:any = processProperties(rawObject.properties);

    for (let x = left; x < (left + width); x++) {
      for (let y = top; y < (top + height); y++) {
        objects[`${x}&${y}`] = properties;
      }
    }
  });

  return objects;
}

function processObjectTileset(firstgid: number, rawTiles: Array<RawTileData>):TilesetTilesById {
  const tiles:TilesetTilesById = {};
  rawTiles.forEach(object => {
    const id = firstgid + object.id;
    const properties:any = processProperties(object.properties);

    tiles[id] = {
      ...properties,
      id: object.class,
    };
  });
  return tiles;
}

export async function processMap(tiledMap: RawTiledMap) {
  const {
    height,
    layers,
    width,
  } = tiledMap;

  // Process the tilesets first
  let itemsTileset: TilesetTilesById = {};
  let featuresTileset: TilesetTilesById = {};
  let terrains: ModelsById<Terrain> = {};
  let walls: ModelsById<Terrain> = {};

  for (let i = 0; i < tiledMap.tilesets.length; i++) {
    const { firstgid, source } = tiledMap.tilesets[i];

    let tileset:(RawTileset | null) = null;
    if (source === '../../tilesets/terrains/terrains.json') {
      tileset = getTileset('terrains') as RawTileset;
    } else if (source === '../../tilesets/items/items.json') {
      tileset = getTileset('items') as RawTileset;
    } else if (source === '../../tilesets/walls/walls.json') {
      tileset = getTileset('walls') as RawTileset;
    } else if (source === '../../tilesets/features/features.json') {
      tileset = getTileset('features') as RawTileset;
    }

    if (tileset !== null) {
      if (tileset.name === 'Items') {
        itemsTileset = processObjectTileset(firstgid, tileset.tiles);
      } else if(tileset.name === 'Features') {
        featuresTileset = processObjectTileset(firstgid, tileset.tiles);
      } else if(tileset.name === 'Terrains') {
        const terrainsSpritesheet = getSpritesheet('terrains');
        terrains = processTerrainTileset(firstgid, tileset.columns, tileset.tiles, terrainsSpritesheet);
      } else if (tileset.name === 'Walls') {
        const wallsSpritesheet = getSpritesheet('walls');
        walls = processTerrainTileset(firstgid, tileset.columns, tileset.tiles, wallsSpritesheet);
      }
    }
  }

  // Finally, process the layers using the above.
  let areas: ModelsByLocation<TerrainIdsByAreaLayer> = {};
  let items: Array<Item> = [];
  let features: Array<Item> = [];
  let links: ModelsByLocation<RegionLink> = {};
  let indoors: ModelsByLocation<string> = {};
  let roofs: ModelsByLocation<TerrainIdsByAreaLayer> = {};

  layers.forEach(layer => {
    if (layer.name === 'ground') {
      const tileLayer:RawTileLayer = layer as RawTileLayer;
      areas = processMapTileLayer(width, 'ground', tileLayer.data, {});
    } else if (layer.name === 'walls') {
      const tileLayer:RawTileLayer = layer as RawTileLayer;
      areas = processMapTileLayer(width, 'walls', tileLayer.data, areas);
    } else if (layer.name === 'items') {
      const objectLayer:RawObjectLayer = layer as RawObjectLayer;
      items = processMapItems(itemsTileset, objectLayer.objects as Array<RawObjectItem>);
    } else if (layer.name === 'features') {
      const objectLayer:RawObjectLayer = layer as RawObjectLayer;
      features = processMapItems(featuresTileset, objectLayer.objects as Array<RawObjectItem>);
    } else if (layer.name === 'links') {
      const objectLayer:RawObjectLayer = layer as RawObjectLayer;
      links = processMapObjects(objectLayer.objects);
    } else if (layer.name === 'indoors') {
      const objectLayer:RawObjectLayer = layer as RawObjectLayer;
      indoors = processMapObjects(objectLayer.objects);

      // This merges 'roofId' into our areas.
      Object.entries(indoors).forEach(([key, value]: [string, any]) => {
        areas[key] = { ...areas[key], ...value };
      });
    } else {
      const properties:any = processProperties(layer.properties);
      if (properties.type === 'roof') {
        const tileLayer:RawTileLayer = layer as RawTileLayer;
        roofs = processMapTileLayer(width, properties.roofId, tileLayer.data, roofs);
      }
    }
  });

  const regionTiledMap: RegionMap = {
    height,
    width,
    terrains,
    walls,
    areas,
    items,
    features,
    links,
    roofs,
  };

  return regionTiledMap;
}
