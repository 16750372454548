import { useDispatch } from 'react-redux';
import { useHud } from '../data/hooks';

import { updateViewportActivity } from '../data/slice';
import ActivityIconButton from '../generic/ActivityIconButton';

export default function TalkButton() {
  const dispatch = useDispatch();
  const {
    viewportActivity,
  } = useHud();

  const handleTalk = () => {
    dispatch(updateViewportActivity({ activity: 'talk' }));
  };

  return (
    <ActivityIconButton
      activityId="talk"
      scale={2}
      onClick={handleTalk}
      selected={viewportActivity === 'talk'}
    />
  );
}
