export enum CombatStatuses {
  VICTORY = 'VICTORY',
  FIGHTING = 'FIGHTING',
  STALKING = 'STALKING',
  FLEEING = 'FLEEING',
  CHASING = 'CHASING',
  OPPONENT_ESCAPED = 'OPPONENT_ESCAPED',
  CHARACTER_ESCAPED = 'CHARACTER_ESCAPED',
}

export interface CombatMessage {
  id: string,
  message: string,
}

export interface CombatState {
  opponentId: string | null,
  distance: number | null,
  status: CombatStatuses | null,
  messages: Array<CombatMessage>,
}
