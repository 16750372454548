import Phaser from 'phaser';
import memoize from 'memoize-one';

import { generateItemTemplateAtlas } from '../../content-utils/itemUtils';
import {
  generateCharacterHairstylesAtlas,
  generateCharacterChestsAtlas,
  generateCharacterWaistAtlas,
  generateCharacterLegsAtlas,
  generateCharacterFeetAtlas,
  generateCharacterSkinsAtlas,
  generateAnimalsSmallAtlas,
  generateAnimalsLargeAtlas,
} from '../../content-utils/characterUtils';
import { generateEffectAtlas } from '../../content-utils/effectUtils';
import { getStore } from '../../data/configureStore';
import { gameSelector, mainViewportCharacterIdSelector } from '../../data/selectors';
import { createModelSelector } from '../../models-store/selectors';

import ProjectileSprite from '../sprites/ProjectileSprite';
import { AREA_SIZE } from '../data/constants';

import Lights from './Lights';
import Items from './Items';
import Features from './Features';
import Characters from './Characters';
import Ground from './Ground';
import { generateTerrainAtlas, generateWallsAtlas, setTerrains, setWalls } from '../../content-utils/terrainUtils';
import Walls from './Walls';
import { generateFeatureAtlas } from '../../content-utils/featureUtils';
import { AppStatus } from '../../data/types';
import { getSpritesheet } from '../../content-utils/spritesheetUtils';
import Roofs from './Roofs';

export default class RegionScene extends Phaser.Scene {
  init(region) {
    this.region = region;
    this.hasViewpointSprite = false;
    this.modelSpritesById = {};
    this.roofImages = {};
    this.store = getStore();
    this.storeUnsubscribe = this.store.subscribe(() => {
      this.stateChanged(this.store.getState());
    });
    this.stateChanged(this.store.getState());

    // Memoize update functions
    this.updateViewpointSprite = memoize(this.updateViewpointSprite);
    this.updateRoofs = memoize(this.updateRoofs);
    this.updateZoom = memoize(this.updateZoom);
  }

  destroy() {
    // This is super important - cleaning up the subscription releases the store's reference to our
    // subscription listener, which lets this sprite be garbage collected.
    this.storeUnsubscribe();
    super.destroy();
  }

  stateChanged(state) {
    if (state.status === AppStatus.UNLOADED) {
      return;
    }

    this.checkViewpointSprite(state);
    this.checkRoofs(state);
    this.checkZoom(state);
  }

  checkViewpointSprite(state) {
    const viewpointCharacterId = mainViewportCharacterIdSelector(state);
    const viewpointSprite = this.modelSpritesById[viewpointCharacterId];
    this.updateViewpointSprite(viewpointSprite);
  }

  checkRoofs(state) {
    const viewpointCharacterId = mainViewportCharacterIdSelector(state);
    const viewpointCharacter = createModelSelector('characters', viewpointCharacterId)(state);
    const area = this.region.map.areas[`${viewpointCharacter.x}&${viewpointCharacter.y}`];
    // TODO: There are situations when traveling where the character's location has updated before
    // the region has.  This means the viewpointCharacter's location is in the next region's areas,
    // but `this.region` isn't yet updated.  If the old region is smaller, for instance, than the
    // new region, then `area` will be undefined.  There's probably a better solution, but for now
    // this conditional solves it.
    if (area) {
      this.updateRoofs(area.roofId);
    }
  }

  checkZoom(state) {
    const { zoom } = gameSelector(state);
    this.updateZoom(zoom);
  }

  updateViewpointSprite(viewpointSprite) {
    if (viewpointSprite) {
      this.setViewpointSprite(viewpointSprite);
      this.viewpointSprite = viewpointSprite;
    }
  }

  updateRoofs(areaRoofId) {
    Object.entries(this.roofImages).forEach(([roofId, { visible, roof }]) => {
      if (areaRoofId === roofId) {
        this.roofImages[roofId].visible = false;
        this.tweens.add({
          targets: roof,
          duration: 100,
          alpha: 0,
          onComplete: (tween) => {
            tween.remove();
          }
        });
      } else if (!visible) {
        this.roofImages[roofId].visible = true;
        this.tweens.add({
          targets: roof,
          duration: 100,
          alpha: 1,
          onComplete: (tween) => {
            tween.remove();
          }
        });
      }
    });
  }

  updateZoom(zoom) {
    this.cameras.main.setZoom(zoom);
  }

  preload() {
    setTerrains(this.region.map.terrains);
    setWalls(this.region.map.walls);

    this.load.atlas('terrains', getSpritesheet('terrains'), generateTerrainAtlas());
    this.load.atlas('walls', getSpritesheet('walls'), generateWallsAtlas());
    this.load.atlas('items', getSpritesheet('items'), generateItemTemplateAtlas());
    this.load.atlas('features', getSpritesheet('features'), generateFeatureAtlas());
    this.load.atlas('effects', getSpritesheet('effects'), generateEffectAtlas());

    this.load.atlas('hairstyles', getSpritesheet('characterHairstyles'), generateCharacterHairstylesAtlas());
    this.load.atlas('chests', getSpritesheet('characterChests'), generateCharacterChestsAtlas());
    this.load.atlas('waist', getSpritesheet('characterWaist'), generateCharacterWaistAtlas());
    this.load.atlas('legs', getSpritesheet('characterLegs'), generateCharacterLegsAtlas());
    this.load.atlas('feet', getSpritesheet('characterFeet'), generateCharacterFeetAtlas());
    this.load.atlas('skins', getSpritesheet('characterSkins'), generateCharacterSkinsAtlas());
    this.load.atlas('animalsSmall', getSpritesheet('animalsSmall'), generateAnimalsSmallAtlas());
    this.load.atlas('animalsLarge', getSpritesheet('animalsLarge'), generateAnimalsLargeAtlas());
  }

  create() {
    const { zoom } = getStore().getState().game;

    this.cameras.main.setZoom(zoom);
    this.cameras.main.setBounds(0, 0, this.region.map.width * AREA_SIZE, this.region.map.height * AREA_SIZE);
    this.cameras.main.setRoundPixels(true);
    this.cameras.main.fadeOut(0);

    this.elevationLayers = {};

    this.createGround();
    this.createItems();
    this.createFeatures();
    this.createCharacters();
    this.createWalls();
    this.createRoofs();
    this.createLights();

    this.setViewpoint();
  }

  createGround() {
    const ground = new Ground(this.region, this.elevationLayers, this);
    this.add.existing(ground);
  }

  createWalls() {
    const walls = new Walls(this.region, this.elevationLayers, this);
    this.add.existing(walls);
  }

  createRoofs() {
    const roofs = new Roofs(this.region, this.elevationLayers, this);
    this.add.existing(roofs);
  }

  createLights() {
    const lights = new Lights(this.region, this);
    this.add.existing(lights);
    lights.setDepth((this.region.map.height * AREA_SIZE * 2) + 6);
  }

  createItems() {
    const items = new Items(this.region, this.elevationLayers, this);
    this.add.existing(items);
  }

  createFeatures() {
    const features = new Features(this.region, this.elevationLayers, this);
    this.add.existing(features);
  }

  createCharacters() {
    const characters = new Characters(this.region, this.elevationLayers, this);
    this.add.existing(characters);
  }

  setViewpoint() {
    const state = getStore().getState();
    const viewpointCharacterId = mainViewportCharacterIdSelector(state);
    const viewpointCharacter = createModelSelector('characters', viewpointCharacterId)(state);

    const viewpointX = (viewpointCharacter.x * AREA_SIZE) + 8;
    const viewpointY = (viewpointCharacter.y * AREA_SIZE) + 8;
    this.cameras.main.pan(viewpointX, viewpointY, 0, 'Linear', false);
  }

  // We use addModelSprite to get a reference to sprites that we want to use for a viewpoint.
  // If we wanted to use items or features (or anything else) for a viewpoint,
  // we'd need to start using addModelSprite with those sprites too.
  addModelSprite(id, sprite) {
    this.modelSpritesById[id] = sprite;
  }

  removeModelSprite(id) {
    delete this.modelSpritesById[id];
  }

  setViewpointSprite(sprite) {
    // This is so that if we're already following something, we gracefully disengage,
    // smoothly pan to the new target, and then start following again.
    if (this.hasViewpointSprite) {
      this.cameras.main.stopFollow();
      this.cameras.main.once(Phaser.Cameras.Scene2D.Events.PAN_COMPLETE, () => {
        this.cameras.main.startFollow(sprite, true, 1, 1, -8, 0);
      });
      this.cameras.main.pan(sprite.x + 8, sprite.y + 8, 250, 'Linear', false);
    } else {
      // This is for when we haven't yet started a follow, so we immediately point
      // at whatever we're following.  It should generally happen on startup.
      // Note how create() pans to this sprite's location with a duration of 0
      // to get us there right away.
      this.hasViewpointSprite = true;
      this.cameras.main.startFollow(sprite, true, 1, 1, -8, 0);
    }
  }

  async showEffect(x, y, type, duration) {
    return new Promise((resolve, reject) => {
      const effectSprite = this.add.sprite(x, y, 'effects', type);
      const depth = (this.region.map.height * AREA_SIZE * 2) + 1;
      effectSprite.setDepth(depth);
      setTimeout(() => {
        effectSprite.destroy();
        resolve();
      }, duration);
    });
  }

  async fireProjectile(item, attacker, defender) {
    return new Promise((resolve, reject) => {
      const startX = attacker.x * AREA_SIZE;
      const startY = attacker.y * AREA_SIZE;
      const endX = defender.x * AREA_SIZE;
      const endY = defender.y * AREA_SIZE;

      const sprite = new ProjectileSprite(this, startX, startY, 'items', `${item.templateId}@one`);
      sprite.setDepth(10); // Arbitrarily high depth so its above everything else.
      this.add.existing(sprite);
      sprite.setTargetPosition(endX, endY, () => {
        resolve();
      });
    });
  }
}
