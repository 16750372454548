let cutscenes = {};

function assertCutscene(id) {
  const cutscene = cutscenes[id];
  if (cutscene === undefined) {
    throw new Error(`Cutscene ${id} does not exist!`);
  }
}

export function getCutscene(id) {
  assertCutscene(id);

  return cutscenes[id];
}

export function setCutscenes(data) {
  cutscenes = data;
}
