import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../generic';

import OpponentCard from './OpponentCard';
import { attack, chase, flee, leave, stalk, strike } from './data/thunks';
import Victory from './Victory';
import CombatStatus from './CombatStatus';
import { useGame } from '../../../data/hooks';
import { CombatStatuses } from './data/types';

export default function Combat() {
  const dispatch = useDispatch();
  const {
    activeCharacterId,
    actingCharacterId,
  } = useGame();
  const {
    opponentId,
    status,
    distance,
  } = useSelector(state => state.combat);

  const handleFlee = () => {
    dispatch(flee());
  };

  const handleLeave = () => {
    dispatch(leave());
  };

  const handleAttack = () => {
    if (status === CombatStatuses.FIGHTING) {
      dispatch(attack({
        activeCharacterId,
        opponentId,
      }));
    } else if (status === CombatStatuses.CHASING) {
      dispatch(strike({
        opponentId,
        characterId: activeCharacterId,
        ambush: false,
      }));
    }
  };

  const handleStalk = () => {
    dispatch(stalk());
  };

  const handleStrike = () => {
    dispatch(strike({
      opponentId,
      characterId: activeCharacterId,
      ambush: true,
    }));
  };

  const handleChase = () => {
    dispatch(chase({
      opponentId,
      characterId: activeCharacterId,
    }));
  };

  const isCharacterTurn = actingCharacterId === activeCharacterId;
  const weaponRange = 1;
  const canAttack = distance <= weaponRange;
  const canFight =  canAttack && (status === CombatStatuses.FIGHTING || status === CombatStatuses.CHASING);
  const canStalk = distance > 1 && status === CombatStatuses.STALKING;
  const canStrike = canAttack && status === CombatStatuses.STALKING;
  const canChase = distance > 1 && status === CombatStatuses.CHASING;
  const hasOpponent = status !== CombatStatuses.OPPONENT_ESCAPED && status !== CombatStatuses.CHARACTER_ESCAPED;
  const canLeave = !hasOpponent;

  if (status === CombatStatuses.VICTORY) {
    return (
      <Victory />
    );
  }

  return (
    <>
      {hasOpponent && (
        <OpponentCard characterId={opponentId} className="mb-3" />
      )}

      <CombatStatus characterId={activeCharacterId} />

      <div className="d-flex gap-2">
        {canFight && (
          <Button disabled={!isCharacterTurn} onClick={handleAttack}>
            Attack
          </Button>
        )}
        {canStrike && (
          <Button disabled={!isCharacterTurn} onClick={handleStrike}>
            Strike
          </Button>
        )}
        {canStalk && (
          <Button onClick={handleStalk}>Stalk Closer</Button>
        )}
        {canChase && (
          <Button onClick={handleChase}>Chase</Button>
        )}
        {!canLeave && (
          <Button disabled={!isCharacterTurn} onClick={handleFlee}>
            Flee
          </Button>
        )}
        {canLeave && (
          <Button onClick={handleLeave}>
            Leave
          </Button>
        )}
      </div>
    </>
  );
}
