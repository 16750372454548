import { Item } from '../data/types';
import { createFeatureWithChildren } from './featureUtils';
import { createItemWithChildren } from './itemUtils';
import { processMap } from './tiled/tiledUtils';
import {
  Feature,
  NormalizedItem,
  ModelsById,
  Region,
  RegionData,
  RegionMap,
} from './types';

async function createRegion({
  id,
  name,
  placeIconId,
  activities = [],
  prey = {},
  gatherables = {},
  wilderness = false,
  rawMap,
}: RegionData) : Promise<[Region, ModelsById<NormalizedItem>, ModelsById<Feature>]> {

  if (!id) {
    throw new Error('createRegion: id is a required field');
  }
  if (!name) {
    throw new Error('createRegion: name is a required field');
  }
  if (!placeIconId) {
    throw new Error('createRegion: placeIconId is a required field');
  }

  let finalItems = {};
  let finalFeatures = {};

  const map:RegionMap = await processMap(rawMap);

  // Create items from map items.
  map.items.forEach(({
    templateId,
    x,
    y,
    status,
    contents,
    uniqueName,
    keyId,
    ...properties
  }: Item, index) => {
    const createdItems = createItemWithChildren({
      ...properties,
      templateId,
      regionId: id,
      x,
      y,
      order: index,
      status,
      contents,
      uniqueName,
      keyId,
    });
    finalItems = { ...finalItems, ...createdItems };
  });

  // Create features from map features.
  map.features.forEach(({
    id: templateId,
    x,
    y,
    status,
    contents,
    ...properties
  }, index) => {
    const createdFeatures = createFeatureWithChildren({
      ...properties,
      templateId,
      regionId: id,
      x,
      y,
      order: index,
      status,
      contents,
    });
    finalFeatures = { ...finalFeatures, ...createdFeatures };
  });

  const response: [Region, ModelsById<NormalizedItem>, ModelsById<Feature>] = [
    {
      id,
      name,
      placeIconId,
      activities,
      prey,
      gatherables,
      wilderness,
      map,
    }, finalItems, finalFeatures
  ];

  return response;
}


// TODO: Yikes, we create all the regions at once?  What will I do when there are 100 of them?
export async function createRegions(dataList: Array<RegionData>) : Promise<{
  regions: ModelsById<Region>,
  items: ModelsById<NormalizedItem>,
  features: ModelsById<Feature>,
}> {
  const regions: ModelsById<Region> = {};
  let items: ModelsById<NormalizedItem> = {};
  let features:ModelsById<Feature> = {};
  for (let i = 0; i < dataList.length; i++) {
    const data:RegionData = dataList[i];

    const [region, regionItems, regionFeatures] = await createRegion(data);
    regions[region.id] = region;

    items = {
      ...items,
      ...regionItems,
    };
    features = {
      ...features,
      ...regionFeatures,
    };
  }

  return {
    regions,
    items,
    features,
  };
}
