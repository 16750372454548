
import itemsImage from '../../spritesheets/items.png';

const itemTemplates = [
  {
    id: 'chest',
    name: 'Chest',
    status: 'closed',
    container: true,
    lockable: true,
    walkable: false,
    image: {
      statuses: {
        closed: {
          src: itemsImage,
          position: { x: 12, y: 0 },
        },
        open: {
          src: itemsImage,
          position: { x: 12, y: 1 },
        },
        locked: {
          src: itemsImage,
          position: { x: 12, y: 2 },
        }
      }
    },
    value: 40,
  },
  {
    id: 'bag',
    name: 'Bag',
    status: 'closed',
    container: true,
    image: {
      statuses: {
        closed: {
          src: itemsImage,
          position: { x: 12, y: 3 },
        },
        open: {
          src: itemsImage,
          position: { x: 12, y: 4 },
        },
      }
    },
    value: 40,
    allowances: 'inventory',
  },
  {
    id: 'backpack',
    name: 'Backpack',
    status: 'closed',
    container: true,
    image: {
      statuses: {
        closed: {
          src: itemsImage,
          position: { x: 12, y: 5 },
        },
        open: {
          src: itemsImage,
          position: { x: 12, y: 6 },
        },
      }
    },
    value: 60,
    allowances: 'inventory',
  },
  {
    id: 'key',
    name: 'Key',
    image: {
      src: itemsImage,
      position: { x: 12, y: 10 },
    }
  }
];

export default itemTemplates;
