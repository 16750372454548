import classNames from 'classnames';
import AbilityPoint from './AbilityPoint';

export default function AbilityPoints({ className, count, variant, name }) {

  const points = [];

  for (let i = 0; i < count; i++) {
    const marginRight = i < count - 1 ? '2px' : 0;
    points.push(
      <AbilityPoint
        key={i}
        variant={variant}
        style={{ marginRight }}
        className="flex-grow-0 flex-shrink-0"
      />
    );
  }

  return (
    <div className={classNames(
      className,
      'd-flex',
      'align-items-center justify-content-between'
    )}>
      <div>
        {name}
      </div>
      <div className="d-flex" style={{
        padding: 1
      }}>
        {points}
      </div>
    </div>
  );

}
