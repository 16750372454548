import pineTree from './featureTemplates/pineTree';
import mapleTree from './featureTemplates/mapleTree';
import peachTree from './featureTemplates/peachTree';
import bush from './featureTemplates/bush';
import smallFoliage from './featureTemplates/smallFoliage';
import stoneBridge from './featureTemplates/stoneBridge';
import streetLight from './featureTemplates/streetLight';
import sconce from './featureTemplates/sconce';
import woodenTable from './featureTemplates/woodenTable';
import woodenCounter from './featureTemplates/woodenCounter';
import forge from './featureTemplates/forge';

const featureTemplates = [
  ...pineTree,
  ...mapleTree,
  ...peachTree,
  ...bush,
  ...smallFoliage,
  ...stoneBridge,
  ...streetLight,
  ...sconce,
  ...woodenTable,
  ...woodenCounter,
  ...forge,
];

export default featureTemplates;
