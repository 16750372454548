let conversations = {};

function assertConversation(id) {
  const conversation = conversations[id];
  if (conversation === undefined) {
    throw new Error(`Conversation ${id} does not exist!`);
  }
}

export function getConversation(id) {
  assertConversation(id);

  return conversations[id];
}

export function setConversations(data) {
  conversations = data;
}
