import Mousetrap from 'mousetrap';

import { getStore } from '../../../data/configureStore';
import { activeCharacterSelector, leaderCharacerSelector } from '../../../data/selectors';
import { PartyModes, TimeModes } from '../../../data/types';
import { playerChangePartyMode, playerWalk } from '../../../player/data/thunks';
import { updateShowChronicle, updateShowMenu, updateViewportActivity } from '../../../ui/data/slice';

import { pause, play } from '../../controls';

export function createKeyboardControls() {
  const { dispatch, getState } = getStore();

  const activeCharacter = activeCharacterSelector(getState());
  const leaderCharacter = leaderCharacerSelector(getState());

  const { partyCharacterIds, timeMode } = getState().game;
  const { showChronicle, showMenu } = getState().hud;

  Mousetrap.bind(['up', 'w'], (event) => {
    if (!event.repeat) {
      dispatch(playerWalk({
        characterId: activeCharacter.id,
        walkNorth: true,
      }));
    }
  }, 'keydown');
  Mousetrap.bind(['up', 'w'], (event) => {
    if (!event.repeat) {
      dispatch(playerWalk({
        characterId: activeCharacter.id,
        walkNorth: false,
      }));
    }
  }, 'keyup');

  Mousetrap.bind(['down', 's'], (event) => {
    if (!event.repeat) {
      dispatch(playerWalk({
        characterId: activeCharacter.id,
        walkSouth: true,
      }));
    }
  }, 'keydown');
  Mousetrap.bind(['down', 's'], (event) => {
    if (!event.repeat) {
      dispatch(playerWalk({
        characterId: activeCharacter.id,
        walkSouth: false,
      }));
    }
  }, 'keyup');

  Mousetrap.bind(['left', 'a'], (event) => {
    if (!event.repeat) {
      dispatch(playerWalk({
        characterId: activeCharacter.id,
        walkWest: true,
      }));
    }
  }, 'keydown');
  Mousetrap.bind(['left', 'a'], (event) => {
    if (!event.repeat) {
      dispatch(playerWalk({
        characterId: activeCharacter.id,
        walkWest: false,
      }));
    }
  }, 'keyup');

  Mousetrap.bind(['right', 'd'], (event) => {
    if (!event.repeat) {
      dispatch(playerWalk({
        characterId: activeCharacter.id,
        walkEast: true,
      }));
    }
  }, 'keydown');
  Mousetrap.bind(['right', 'd'], (event) => {
    if (!event.repeat) {
      dispatch(playerWalk({
        characterId: activeCharacter.id,
        walkEast: false,
      }));
    }
  }, 'keyup');

  Mousetrap.bind(['1', '2', '3', '4', '5', '6', '7', '8'], (event) => {
    const characterIndex = event.key;

    if (partyCharacterIds.length >= characterIndex) {
      const partyCharacterId = partyCharacterIds[characterIndex - 1];

      dispatch(playerChangePartyMode({
        partyMode: PartyModes.SOLO,
        characterId: partyCharacterId
      }));
    }
  }, 'keyup');

  Mousetrap.bind(['0'], (event) => {
    dispatch(playerChangePartyMode({
      partyMode: PartyModes.ALL,
      characterId: leaderCharacter.id
    }));
  }, 'keyup');

  Mousetrap.bind(['l'], (event) => {
    dispatch(updateViewportActivity({ activity: 'look' }));
  });

  Mousetrap.bind(['u'], (event) => {
    dispatch(updateViewportActivity({ activity: 'use' }));
  });

  Mousetrap.bind(['t'], (event) => {
    dispatch(updateViewportActivity({ activity: 'talk' }));
  });

  Mousetrap.bind(['c'], (event) => {
    dispatch(updateShowChronicle({ showChronicle: !showChronicle }));
  });

  Mousetrap.bind(['space'], (event) => {
    if (timeMode === TimeModes.PAUSED) {
      play();
    } else if (timeMode === TimeModes.NORMAL) {
      pause();
    }
  });

  Mousetrap.bind(['esc'], (event) => {
    dispatch(updateShowMenu({ showMenu: !showMenu }));
  });
}

export function destroyKeyboardControls() {
  Mousetrap.unbind([
    'up',
    'down',
    'left',
    'right',
    'a',
    'w',
    's',
    'd',
    '1', '2', '3', '4', '5', '6', '7', '8', '0',
    'l', 'u', 't', 'space', 'esc',
  ]);
}
