export function calculateFinalViewpoint(
  viewpoint,
  zoom,
  frameWidth,
  frameHeight,
  mapWidth,
  mapHeight,
  region,
) {
  const { x, y } = viewpoint;

  // Lower bound - the x or minimum left viewpoint, whichever is higher.
  let finalX = Math.max(
    x,
    Math.ceil(
      // pixels: percentage of half the frame width to the total map width
      (frameWidth / 2 / zoom) / mapWidth
      // tiles: multiply by tile width of region to get percentage in tiles.
        * region.map.width
    )
  );
  // Same as above
  let finalY = Math.max(
    y,
    Math.floor(
      (frameHeight / 2 / zoom) / mapHeight
       * region.map.height
    )
  );

  // Upper bound - the x or maximum right viewpoint, whichever is lower.
  finalX = Math.min(
    finalX,
    Math.floor(
      // pixels: percentage of the width of the map minus half a frame width
      ((mapWidth - (frameWidth / 2 / zoom)) / mapWidth)
      // tiles: multiply by tile width of the region to get percentage in tiles.
        * region.map.width
    )
  );
  finalY = Math.min(
    finalY,
    Math.floor(
      ((mapHeight - (frameHeight / 2 / zoom)) / mapHeight)
        * region.map.height
    )
  );

  return {
    x: finalX,
    y: finalY,
  };
}

// Need to make this actually focus on the viewpoint.
export function calculateTranslation(viewpoint, zoom, areaSize, frameWidth, frameHeight) {
  let x = -1 * ((viewpoint.x * areaSize * zoom) - (frameWidth / 2) + (areaSize * zoom / 2));
  let y = -1 * ((viewpoint.y * areaSize * zoom) - (frameHeight / 2) + (areaSize * zoom / 2));

  if (x > (-1 * areaSize * zoom)) {
    x = 0;
  }
  if (y > (-1 * areaSize * zoom)) {
    y = 0;
  }
  return {
    x,
    y,
  };
}

export function parseColorInt(str) {
  return parseInt(str.replace('#', ''), 16);
}
