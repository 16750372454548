import { useCallback, useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import Mousetrap from 'mousetrap';
import { useDispatch } from 'react-redux';

import { Fader, FullBleed } from './generic';
import { getNarrative } from '../content-utils/narrativeUtils';
import { cutsceneStepComplete } from '../engine/directors/cutscene/stepComplete';

export default function Narrative({ narrativeId }) {
  const narrative = getNarrative(narrativeId);

  const [contentIndex, setContentIndex] = useState(0);
  const [show, setShow] = useState(true);

  const dispatch = useDispatch();

  const advanceText = () => {
    if (contentIndex === narrative.content.length - 1) {
      setShow(false);
      setTimeout(() => {
        dispatch(cutsceneStepComplete());
      }, 2000);
    } else {
      setContentIndex(contentIndex + 1);
    }
  };

  useEffect(() => {
    Mousetrap.bind(['space'], (event) => {
      advanceText();
    }, 'keyup');

    return () => {
      Mousetrap.unbind([
        'space'
      ]);
    };
  });

  const handleClick = useCallback(advanceText, [contentIndex, narrative.content, dispatch]);

  return (
    <FullBleed
      as="main"
      className="d-flex flex-column justify-content-center align-items-center bg-parchment-500 narrative overflow-hidden"
      onClick={handleClick}
      style={{
        transition: `opacity 1s linear`,
        transitionDelay: '1s',
        opacity: show ? 1 : 0,
      }}
    >
      {show && (
        <Fader className="d-inline-block m-3" speed="0.75s" delay={0.25} key={contentIndex} ready>
          <Markdown components={{
            p: 'span',
          }}>
            {narrative.content[contentIndex]}
          </Markdown>
        </Fader>
      )}
    </FullBleed>
  );
}
