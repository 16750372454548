import { useDispatch } from 'react-redux';
import { useHasSave } from '../../saves/data/hooks';
import { loadSavedGame } from '../../saves/data/thunks';
import { Button } from '../generic';

export default function LoadButton({ className }) {
  const dispatch = useDispatch();
  const hasSave = useHasSave();

  const handleLoad = () => {
    if (hasSave) {
      dispatch(loadSavedGame());
    }
  };

  return (
    <Button style={{ width: 160 }} className={className} onClick={handleLoad}>Load</Button>
  );
}
