
import itemsImage from '../../spritesheets/items.png';

const itemTemplates = [
  {
    id: 'deer-hide',
    name: 'Deer Hide',
    image: {
      src: itemsImage,
      position: { x: 10, y: 0 },
    },
    value: 8,
    allowances: 'material',
  },
  {
    id: 'wolf-pelt',
    name: 'Wolf Pelt',
    image: {
      src: itemsImage,
      position: { x: 10, y: 1 },
    },
    value: 50,
    allowances: 'material',
  },
  {
    id: 'rabbit-pelt',
    name: 'Rabbit Pelt',
    image: {
      src: itemsImage,
      position: { x: 10, y: 2 },
    },
    value: 3,
    allowances: 'material',
  },
  {
    id: 'horn',
    name: 'Horn',
    image: {
      src: itemsImage,
      position: { x: 10, y: 3 },
    },
    value: 20,
    allowances: 'material',
  },
  {
    id: 'tusk',
    name: 'Tusk',
    image: {
      src: itemsImage,
      position: { x: 10, y: 4 },
    },
    value: 12,
    allowances: 'material',
  },
  {
    id: 'antler',
    name: 'Antler',
    image: {
      src: itemsImage,
      position: { x: 10, y: 5 },
    },
    value: 15,
    allowances: 'material',
    determiner: 'an',
  },
];

export default itemTemplates;
