import { createSelector } from '@reduxjs/toolkit';

import { createRegionCharactersSelector } from '../../../world/data/selectors';
import { createCharacterEquipmentBySlotSelector } from '../../items/data/selectors';

export function createCharacterAttackRangeSelector(characterId) {
  return createSelector(
    createCharacterEquipmentBySlotSelector(characterId),
    (equipment) => {
      return equipment.mainHand ? equipment.mainHand.range : 1;
    }
  );
}

export function createIsAmmoAvailableSelector(characterId) {
  return createSelector(
    createCharacterEquipmentBySlotSelector(characterId),
    (equipment) => {
      const mainHandItem = equipment.mainHand || null;
      const offHandItem = equipment.offHand || null;
      if (mainHandItem && mainHandItem.ammoTemplateIds) {
        if (offHandItem && mainHandItem.ammoTemplateIds.includes(offHandItem.templateId)) {
          return true;
        }
        return false;
      }
      return true;
    }
  );
}

export function createCharacterIdsByInitiativeSelector(regionId) {
  return createSelector(
    createRegionCharactersSelector(regionId),
    (characters) => {
      return [...characters].filter(character => character.health > 0).sort((a, b) => {
        return (a.agility + a.running) - (b.agility + b.running);
      }).map(character => character.id);
    }
  );
}
