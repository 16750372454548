import classNames from 'classnames';

export default function Panel({ children, className, style, ...props }) {
  return (
    <section
      style={style}
      className={classNames(
        'border bg-frosted-100 rounded-2 border-box overflow-hidden',
        className,
      )}
      {...props}
    >
      {children}
    </section>
  );
}
