/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { InnState } from './types';

export const initialState: InnState = {
  activeInnkeeperId: null,
};

const slice = createSlice({
  name: 'inn',
  initialState,
  reducers: {
    startInn: (state: InnState, action) => {
      state.activeInnkeeperId = action.payload.innkeeperId;
    },
    endInn: (state: InnState) => {
      state.activeInnkeeperId = null;
    },
  },
});

export const {
  startInn,
  endInn,
} = slice.actions;

export const { reducer } = slice;
