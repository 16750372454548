import { getFeatureTemplate } from '../content-utils/featureUtils';
import { getItemTemplate } from '../content-utils/itemUtils';
import { Feature } from '../content-utils/types';
import { HydratedFeature, HydratedItem, Item } from './types';

export function hydrateItem(item:Item): HydratedItem | null {
  if (!item) {
    return null;
  }
  return {
    ...getItemTemplate(item.templateId),
    ...item,
  };
}

export function hydrateFeature(feature:Feature): HydratedFeature | null {
  if (!feature) {
    return null;
  }
  return {
    ...getFeatureTemplate(feature.templateId),
    ...feature,
  };
}
