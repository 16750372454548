import { useDispatch } from 'react-redux';
import { useHud } from '../data/hooks';
import { updateShowChronicle } from '../data/slice';
import ActivityIconButton from '../generic/ActivityIconButton';

export default function ChronicleButton() {
  const dispatch = useDispatch();

  const {
    showChronicle
  } = useHud();

  const handleToggleChronicle = () => {
    dispatch(updateShowChronicle({ showChronicle: !showChronicle }));
  };

  return (
    <ActivityIconButton
      activityId="chronicle"
      scale={2}
      onClick={handleToggleChronicle}
      selected={showChronicle}
    />
  );
}
