import { createSelector } from '@reduxjs/toolkit';
import memoize from 'memoize-one';
import { createCharacterItemsSelector } from '../../../../characters/items/data/selectors';
import { activeStorySelector, gameSelector } from '../../../../data/selectors';

export const activeConversationCharacterSelector = createSelector(
  state => state.conversation.activeConversationCharacterId,
  state => state.models.characters,
  (activeConversationCharacterId, charactersById) => {
    return charactersById[activeConversationCharacterId];
  }
);

export const createFilteredConversationChoicesSelector = memoize((activeConversationCharacterId, playerCharacterId, choices) => {
  return createSelector(
    activeConversationCharacterSelector,
    activeStorySelector,
    gameSelector,
    createCharacterItemsSelector(activeConversationCharacterId),
    createCharacterItemsSelector(playerCharacterId),
    (activeConversationCharacter, activeStory, game, activeConversationCharacterItems, playerCharacterItems) => {
      const { partyCharacterIds, activeCharacterId } = game;

      const relationship = activeConversationCharacter.relationships[activeCharacterId] || {
        conversationStatus: 'NEUTRAL',
        level: 0,
        knownName: false,
      };

      return choices.filter((choice) => {
        const {
          storyMilestoneStatus,
          relationshipStatus,
          playerItemStatus,
          characterItemStatus,
          partyStatus,
        } = choice;

        if (storyMilestoneStatus) {
          for (let i = 0; i < storyMilestoneStatus.milestones.length; i++) {
            const requiredMilestone = storyMilestoneStatus.milestones[i];
            if (activeStory.milestones.indexOf(requiredMilestone) < 0) {
              return false;
            }
          }
        }
        if (relationshipStatus) {
          if (relationshipStatus.levelAbove && relationship.level <= relationshipStatus.levelAbove) {
            return false;
          }
          if (relationshipStatus.levelBelow && relationship.level >= relationshipStatus.levelBelow) {
            return false;
          }
        }
        if (playerItemStatus) {
          const playerTemplateIds = playerCharacterItems.map(item => item.templateId);
          if (playerItemStatus.has) {
            for (let i = 0; i < playerItemStatus.has.length; i++) {
              const templateId = playerItemStatus.has[i];
              if (playerTemplateIds.indexOf(templateId) < 0) {
                return false;
              }
            }
          }
          if (playerItemStatus.lacks) {
            for (let i = 0; i < playerItemStatus.lacks.length; i++) {
              const templateId = playerItemStatus.lacks[i];
              if (playerTemplateIds.indexOf(templateId) > -1) {
                return false;
              }
            }
          }
        }
        if (characterItemStatus) {
          const characterTemplateIds = activeConversationCharacterItems.map(item => item.templateId);
          if (characterItemStatus.has) {
            for (let i = 0; i < characterItemStatus.has.length; i++) {
              const templateId = characterItemStatus.has[i];
              if (characterTemplateIds.indexOf(templateId) < 0) {
                return false;
              }
            }
          }
          if (characterItemStatus.lacks) {
            for (let i = 0; i < characterItemStatus.lacks.length; i++) {
              const templateId = characterItemStatus.lacks[i];
              if (characterTemplateIds.indexOf(templateId) > -1) {
                return false;
              }
            }
          }
        }
        if (partyStatus) {
          const canCharacterJoin = partyCharacterIds.length < 8;
          const inPartySatisfied = partyStatus.inParty === !!activeConversationCharacter.movement.type === 'PARTY';
          const canJoinSatisfied = partyStatus.canJoin === undefined || partyStatus.canJoin === canCharacterJoin;
          if (!inPartySatisfied || !canJoinSatisfied) {
            return false;
          }
        }
        return true;
      });
    }
  );
});
