import { destroyDirectors } from './controls';

import { createKeyboardControls, destroyKeyboardControls } from './directors/input/keyboard';

import { createListeners, destroyListeners } from './listeners';

export function startEngine() {
  createKeyboardControls();
  createListeners();
}

export function stopEngine() {
  destroyKeyboardControls();
  destroyListeners();
  destroyDirectors();
}
