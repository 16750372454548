import { useDispatch } from 'react-redux';
import { useGame } from '../../data/hooks';
import { TimeModes } from '../../data/types';
import { pause } from '../../engine/controls';
import { useHud } from '../data/hooks';
import { updateShowMenu } from '../data/slice';
import ActivityIconButton from '../generic/ActivityIconButton';

export default function MenuButton() {
  const dispatch = useDispatch();

  const {
    timeMode
  } = useGame();
  const {
    showMenu
  } = useHud();

  const handleToggle = () => {
    // TODO: This is imperfect - paused is still a state where the user can interact with the game - we want a new timeMode like SUSPENDED which means the game and its input are truly turned off.  Paused just means sequenced.
    if (!showMenu && timeMode === TimeModes.NORMAL) {
      pause();
    }
    dispatch(updateShowMenu({ showMenu: !showMenu }));
  };

  return (
    <ActivityIconButton
      activityId="menu"
      scale={2}
      onClick={handleToggle}
      selected={showMenu}
    />
  );
}
