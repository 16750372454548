import { useDispatch } from 'react-redux';

import { Button, DraggablePanel } from '../../generic';
import { useActiveCharacter } from '../../../data/hooks';

import { useActiveInnkeeper } from './data/hooks';
import { partyStayAtInn, stopTalkingToInnkeeper } from './data/thunks';

export default function Inn() {
  const dispatch = useDispatch();

  const innkeeper = useActiveInnkeeper();
  const { price, quality } = innkeeper.inn;

  const activeCharacter = useActiveCharacter();

  const handleEnd = () => {
    dispatch(stopTalkingToInnkeeper());
  };

  const handleRest = () => {
    dispatch(partyStayAtInn({ price, quality }));
  };

  const canAfford = activeCharacter.gold >= price;

  return (
    <DraggablePanel
      id="inn"
      title={`${innkeeper.name}'s Inn`}
      onClose={handleEnd}
    >
      <h2>Resting</h2>

      <p>You decide to rest here. The price is {price}.</p>

      {!canAfford && (
        <p>Unfortunately, you can't afford it.  Does your party have more money?</p>
      )}

      {canAfford && (
        <Button onClick={handleRest}>Rest</Button>
      )}
      <Button onClick={handleEnd}>Leave</Button>
    </DraggablePanel>
  );
}
