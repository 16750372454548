import memoize from 'memoize-one';

import { hydrateItem } from '../../data/contentUtils';
import { grabbedItemIdSelector } from '../../data/selectors';
import createSelectorListener from '../../engine/createSelectorListener';
import GameSprite from './GameSprite';

const THROW_VELOCITY = 400; // 400 pixels per second
const SPIN_VELOCITY = 720; // 720 degrees per second

export default class ItemSprite extends GameSprite {
  constructor(itemId, ...args) {
    super(itemId, 'items', ...args);

    this.targetX = null;
    this.targetY = null;
    this.originalX = null;
    this.originalY = null;

    this.updateVisible = memoize(this.updateVisible);
    this.handleGrabbedItemIdChanged = this.handleGrabbedItemIdChanged.bind(this);

    const { remove, data } = createSelectorListener(
      grabbedItemIdSelector,
      this.handleGrabbedItemIdChanged
    );

    this.handleGrabbedItemIdChanged(data);
    this.removeGrabbedItemIdListener = remove;

    this.initialize();
  }

  setTargetPosition(x, y) {
    // Maybe limit how far away this target position can be, but do it in the thunk where we assign the new location.  That way characters are limited in how far they can throw.
    this.targetX = x + 8;
    this.targetY = y + 8;
    this.originalX = this.x;
    this.originalY = this.y;
  }

  handleGrabbedItemIdChanged(grabbedItemId) {
    this.updateVisible(grabbedItemId);
  }

  updateVisible(grabbedItemId) {
    if (grabbedItemId === this.modelId) {
      this.setVisible(false);
    } else {
      this.setVisible(true);
    }
  }

  setModelFrame() {
    const item = hydrateItem(this.model);
    let frame = item.templateId;
    if (item.status) {
      frame += `@${item.status}`;
    } else if (item.image.quantities) {
      frame += `@${item.quantity > 1 ? 'many' : 'one'}`;
    }
    this.setFrame(frame);
  }

  setModelDepth() {
    const depth = this.model.order !== null ? this.model.order : 0;
    this.setDepth(depth);
  }

  preUpdate(time, delta) {
    // The partial velocity for this delta
    const dV = delta / 1000 * THROW_VELOCITY;

    if (this.targetX !== null && this.targetY !== null) {
      const dx = this.targetX - this.originalX;
      const dy = this.targetY - this.originalY;
      const magnitudeX = Math.abs(dx);
      const magnitudeY = Math.abs(dy);

      const normalX = dx / (magnitudeX + magnitudeY);
      const normalY = dy / (magnitudeX + magnitudeY);

      const nextX = this.x + (normalX * dV);
      const nextY = this.y + (normalY * dV);

      // See if the next X or Y is 'beyond' the target.  If so, stop.
      const stop = (
        (this.targetX < this.x && nextX < this.targetX) ||
        (this.targetX > this.x && nextX > this.targetX) ||
        (this.targetY < this.y && nextY < this.targetY) ||
        (this.targetY > this.y && nextY > this.targetY)
      );

      this.setPosition(
        nextX,
        nextY,
      );

      this.setAngle(this.angle + (delta / 1000 * SPIN_VELOCITY));
      this.setOrigin(0.5, 0.5);

      if (stop) {
        this.setPosition(this.targetX - 8, this.targetY - 8);
        this.targetX = null;
        this.targetY = null;
        this.originalX = null;
        this.originalY = null;
        this.setRotation(0);
        this.setOrigin(0, 0);
      }
    }
  }

  destroy() {
    this.removeGrabbedItemIdListener();
    super.destroy();
  }
}
