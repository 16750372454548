import { createItemWithChildren } from '../../../content-utils/itemUtils';
import { createCombatTargetSelector } from '../../../data/selectors';
import { endJourneyTheHardWay, updateActingCharacter } from '../../../data/slice';
import { getDistance } from '../../../data/utils';

import { addModels, removeModel, updateModel } from '../../../models-store';
import { createModelSelector } from '../../../models-store/selectors';
import { addMessage } from '../../../ui/chronicle';
import { AREA_SIZE } from '../../../world/data/constants';
import { getRegionScene } from '../../../world/data/phaserGame';
import { createCharacterEquipmentSlotSelector, createCharacterInventorySelector } from '../../items/data/selectors';
import { walkPath } from '../../movement/data/thunks';
import { updateRelationship } from '../../relationships/data/thunks';
import { createCharacterCombatStatsSelector } from '../../stats/data/selectors';
import { decreaseEndurance } from '../../status/data/thunks';
import { createCharacterAttackRangeSelector, createIsAmmoAvailableSelector } from './selectors';

export function combatTactics({ characterId }) {
  return async (dispatch, getState) => {
    const actingCharacter = getState().models.characters[characterId];
    const defenderId = createCombatTargetSelector(actingCharacter.id)(getState());
    const defender = createModelSelector('characters', defenderId)(getState());

    const dx = actingCharacter.x - defender.x;
    const dy = actingCharacter.y - defender.y;
    const distance = getDistance(dx, dy);

    const range = createCharacterAttackRangeSelector(actingCharacter.id)(getState());
    const isAmmoAvailable = createIsAmmoAvailableSelector(actingCharacter.id)(getState());
    if (distance <= range && isAmmoAvailable) {
      await dispatch(attackCharacter({
        attackerId: actingCharacter.id,
        defenderId: defender.id,
      }));
    } else {
      dispatch(walkPath({
        characterId: actingCharacter.id,
        x: defender.x,
        y: defender.y,
      }));
    }
  };
}

/**
 * Find out the percentage of damage to damage plus defense, and then apply that much damage.
 * Then round down.
 *
 * Examples:
 *   10 damage + 10 defense = 50%, so 5 damage applied.
 *   5 damage + 10 defense = 33%, so 1 damage applied.
 *   10 damage + 5 defense = 66%, so 6 damage applied.
 *   1 damage + 10 defense = ~9% so 0 damage applied.
 */
export function tallyDamage(damage, defense, str) {
  return Math.floor(damage / (damage + defense) * damage) + Math.floor(Math.random() * str);
}

export function attackCharacter({ attackerId, defenderId, bonusDamage = 0 }) {
  return async (dispatch, getState) => {
    const attacker = getState().models.characters[attackerId];
    const defender = getState().models.characters[defenderId];
    const attackerStats = createCharacterCombatStatsSelector(attackerId)(getState());
    const defenderStats = createCharacterCombatStatsSelector(defenderId)(getState());
    const damage = tallyDamage(attackerStats.damage, defenderStats.defense, attacker.str);
    const remainingHealth = Math.max(0, defender.health - damage);

    dispatch(decreaseEndurance({ characterId: attackerId, amount: 1 }));

    if (damage < 1) {
      dispatch(addMessage({
        message: `${attacker.name} hits ${defender.name}, but is unable to deal any damage!`
      }));
      dispatch(updateActingCharacter({ characterId: defenderId }));
      return;
    }

    let previousLevel = 0;
    if (defender.relationships[attackerId]) {
      previousLevel = defender.relationships[attackerId].level;
    }

    // Get the offhand equipment here so we have the ammo, then update it below.
    const mainHandItem = createCharacterEquipmentSlotSelector(attackerId, 'mainHand')(getState());
    const offHandItem = createCharacterEquipmentSlotSelector(attackerId, 'offHand')(getState());
    // Only mess with ammo if the weapon uses it.
    if (mainHandItem && offHandItem && mainHandItem.ammoTemplateIds !== null && mainHandItem.ammoTemplateIds.includes(offHandItem.templateId)) {
      const nextQuantity = offHandItem.quantity - 1;

      if (nextQuantity < 1) {
        dispatch(removeModel({
          modelType: 'items',
          id: offHandItem.id,
        }));
      } else {
        dispatch(updateModel({
          modelType: 'items',
          model: {
            id: offHandItem.id,
            quantity: nextQuantity
          }
        }));
      }
      const regionScene = getRegionScene(attacker.regionId);

      await regionScene.fireProjectile(offHandItem, attacker, defender);
    }

    dispatch(updateRelationship({
      characterId: defenderId,
      relatedCharacterId: attackerId,
      // TODO: Is this right?  IGNORE?  Is that what we decided?
      conversationStatus: 'IGNORE',
      level: previousLevel - damage
    }));

    dispatch(updateModel({
      modelType: 'characters',
      model: {
        id: defenderId,
        health: remainingHealth,
      },
    }));

    const regionScene = getRegionScene(defender.regionId);
    const x = (defender.x * AREA_SIZE) + (Math.floor(Math.random() * 8));
    const y = (defender.y * AREA_SIZE) + (Math.floor(Math.random() * 8) - 4);
    await regionScene.showEffect(x, y, 'attacked', 250);

    dispatch(addMessage({
      message: `${attacker.name} hits ${defender.name} for ${damage} damage.`,
    }));

    if (remainingHealth < 1) {
      // TODO: This probably wants to be different and to be based on the leader and/or all the party members being defeated.
      if (defender.active) {
        dispatch(endJourneyTheHardWay());
      } else {
        dispatch(defeatCharacter({ characterId: defender.id }));
      }
    }
  };
}

export function defeatCharacter({ characterId }) {
  return async (dispatch, getState) => {
    const materialItems = [];
    const character = getState().models.characters[characterId];
    const inventoryItems = createCharacterInventorySelector(characterId)(getState());
    const materials = character.materials;

    Object.entries(materials).forEach(([materialTemplateId, probability], index) => {
      const shouldGenerateMaterial = Math.random() < probability;

      if (shouldGenerateMaterial) {
        const createdItems = createItemWithChildren({
          templateId: materialTemplateId,
          characterId: characterId,
          order: inventoryItems.length + index,
        });
        Object.values(createdItems).forEach(createdItem => {
          materialItems.push(createdItem);
        });
      }
    });

    dispatch(updateModel({
      modelType: 'characters',
      model: {
        id: characterId,
        health: 0,
        relationships: {} // Remove all the character's relationships.  They're dead.
      }
    }));

    if (materialItems.length > 0) {
      dispatch(addModels({ modelType: 'items', models: materialItems }));
    }
  };
}
