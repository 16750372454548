
import itemsImage from '../../spritesheets/items.png';

const itemTemplates = [
  {
    id: 'steel-helmet',
    name: 'Steel Helmet',
    image: {
      src: itemsImage,
      position: { x: 6, y: 0 },
    },
    damage: 0,
    defense: 3,
    value: 35,
    allowances: 'head',
  },
  {
    id: 'steel-breastplate',
    name: 'Steel Breastplate',
    image: {
      src: itemsImage,
      position: { x: 6, y: 1 },
    },
    damage: 0,
    defense: 6,
    value: 55,
    allowances: 'chest',
  },
  {
    id: 'steel-leggings',
    name: 'Steel Leggings',
    image: {
      src: itemsImage,
      position: { x: 6, y: 2 },
    },
    damage: 0,
    defense: 4,
    value: 45,
    allowances: 'legs',
    determiner: 'some',
  },
  {
    id: 'steel-boots',
    name: 'Steel Boots',
    image: {
      src: itemsImage,
      position: { x: 6, y: 3 },
    },
    damage: 0,
    defense: 2,
    value: 32,
    allowances: 'feet',
    determiner: 'a pair of'
  },
  {
    id: 'steel-belt',
    name: 'Steel Belt',
    image: {
      src: itemsImage,
      position: { x: 6, y: 4 },
    },
    damage: 0,
    defense: 2,
    value: 28,
    allowances: 'waist',
  },
  {
    id: 'steel-gauntlets',
    name: 'Steel Gauntlets',
    image: {
      src: itemsImage,
      position: { x: 6, y: 5 },
    },
    damage: 0,
    defense: 2,
    value: 32,
    allowances: 'hands',
    determiner: 'a pair of',
  },
  {
    id: 'steel-buckler',
    name: 'Steel Buckler',
    image: {
      src: itemsImage,
      position: { x: 6, y: 6 },
    },
    damage: 0,
    defense: 3,
    value: 30,
    allowances: 'secondary',
  },
];

export default itemTemplates;
