import { getStore } from '../../data/configureStore';
import { updateActingCharacter, updateTimeMode } from '../../data/slice';
import { TimeModes } from '../../data/types';
import { destroyCutsceneDirector } from '../directors/cutscene/director';
import { destroyLiveDirector } from '../directors/live/director';
import { createSequenceDirector } from '../directors/sequence/director';

export function pause(options = {}) {
  const actingCharacterId = options.actingCharacterId !== undefined ? options.actingCharacterId : null;
  // If we've hit pause, the only performers we can clean up are the 'play' ones - this should never happen if we're in the middle of a cutscene.
  destroyLiveDirector();
  destroyCutsceneDirector();

  const { dispatch, getState } = getStore();

  // Set up the state.
  dispatch(updateTimeMode({ timeMode: TimeModes.PAUSED }));

  // Now that we have our paused state setup, create the performers.
  createSequenceDirector();

  // We set the acting character after creating the paused performers so that the
  // performer's store listener fires correctly.
  let finalActingCharacterId = actingCharacterId;
  if (finalActingCharacterId === null) {
    finalActingCharacterId = getState().game.activeCharacterId;
  }
  dispatch(updateActingCharacter({ characterId: finalActingCharacterId }));
}
