import { useDispatch } from 'react-redux';
import { saveGame } from '../../saves/data/thunks';
import { Button } from '../generic';

export default function SaveButton({ className }) {
  const dispatch = useDispatch();

  const handleSave = () => {
    dispatch(saveGame());
  };

  return (
    <Button style={{ width: 160 }} className={className} onClick={handleSave}>Save</Button>
  );
}
