import featuresImage from '../../spritesheets/features.png';

const itemTemplates = [
  {
    id: 'sconce',
    name: 'Sconce',
    status: 'cold',
    image: {
      statuses: {
        lit: {
          src: featuresImage,
          position: { x: 3, y: 6 }
        },
        cold: {
          src: featuresImage,
          position: { x: 2, y: 6 }
        }
      }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
    light: {
      lit: { rgb: 0xffa500, intensity: 5 },
      cold: { rgb: 0x000000, intensity: 0 }
    },
  },
];

export default itemTemplates;
