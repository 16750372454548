import { createSelector } from '@reduxjs/toolkit';

import {
  activeCharacterSelector,
  activeRegionSelector,
  gameSelector
} from '../../../data/selectors';
import { PartyModes } from '../../../data/types';
import { getCampfirePosition } from '../../../ui/activities/camp/data/utils';
import { NORTH, EAST, SOUTH, WEST, } from '../../../ui/character/constants';

export const partySelector = createSelector(
  gameSelector,
  state => state.models.characters,
  (game, charactersById) => {
    const party = [];
    const { partyCharacterIds } = game;
    partyCharacterIds.forEach(characterId => {
      party.push(charactersById[characterId]);
    });
    return party.sort((a, b) => (a.movement.position - b.movement.position));
  }
);

export const partyGoldSelector = createSelector(
  partySelector,
  (party) => {
    return party.reduce((previousGold, character) => {
      return previousGold + character.gold;
    }, 0);
  }
);

export function calculatePartyPreferredPositions(partyMode, leaderX, leaderY, direction) {
  if (partyMode === PartyModes.ALL) {
    if (direction === NORTH) {
      return [
        { x: leaderX + 1, y: leaderY + 1, direction },
        { x: leaderX - 1, y: leaderY + 1, direction },
        { x: leaderX,     y: leaderY + 2, direction },
        { x: leaderX + 2, y: leaderY + 2, direction },
        { x: leaderX - 2, y: leaderY + 2, direction },
        { x: leaderX + 1, y: leaderY + 3, direction },
        { x: leaderX - 1, y: leaderY + 3, direction },
      ];
    } else if (direction === EAST) {
      return [
        { x: leaderX - 1, y: leaderY + 1, direction },
        { x: leaderX - 1, y: leaderY - 1, direction },
        { x: leaderX - 2, y: leaderY, direction },
        { x: leaderX - 2, y: leaderY + 2, direction },
        { x: leaderX - 2, y: leaderY - 2, direction },
        { x: leaderX - 3, y: leaderY + 1, direction },
        { x: leaderX - 3, y: leaderY - 1, direction },
      ];
    } else if (direction === SOUTH) {
      return [
        { x: leaderX + 1, y: leaderY - 1, direction },
        { x: leaderX - 1, y: leaderY - 1, direction },
        { x: leaderX,     y: leaderY - 2, direction },
        { x: leaderX + 2, y: leaderY - 2, direction },
        { x: leaderX - 2, y: leaderY - 2, direction },
        { x: leaderX + 1, y: leaderY - 3, direction },
        { x: leaderX - 1, y: leaderY - 3, direction },
      ];
    } else if (direction === WEST) {
      return [
        { x: leaderX + 1, y: leaderY + 1, direction },
        { x: leaderX + 1, y: leaderY - 1, direction },
        { x: leaderX + 2, y: leaderY, direction },
        { x: leaderX + 2, y: leaderY + 2, direction },
        { x: leaderX + 2, y: leaderY - 2, direction },
        { x: leaderX + 3, y: leaderY + 1, direction },
        { x: leaderX + 3, y: leaderY - 1, direction },
      ];
    }
  } else if (partyMode === PartyModes.CAMPING) {
    const { x, y } = getCampfirePosition({ x: leaderX, y: leaderY, direction });
    if (direction === NORTH) {
      return [
        { x: x - 1, y: y, direction: EAST }, // 1
        { x: x + 1, y: y, direction: WEST }, // 2
        { x: x,     y: y - 1, direction: SOUTH }, // 3
        { x: x - 1, y: y - 1, direction: SOUTH }, // 4
        { x: x + 1, y: y - 1, direction: SOUTH }, // 5
        { x: x - 1, y: y + 1, direction: NORTH }, // 6
        { x: x + 1, y: y + 1, direction: NORTH }, // 7
      ];
    } else if (direction === EAST) {
      return [
        { x: x,     y: y - 1, direction: SOUTH }, // 1
        { x: x,     y: y + 1, direction: NORTH }, // 2
        { x: x + 1, y: y, direction: WEST }, // 3
        { x: x + 1, y: y - 1, direction: WEST }, // 4
        { x: x + 1, y: y + 1, direction: WEST }, // 5
        { x: x - 1, y: y - 1, direction: EAST }, // 6
        { x: x - 1, y: y + 1, direction: EAST }, // 7
      ];
    } else if (direction === SOUTH) {
      return [
        { x: x + 1, y: y, direction: WEST }, // 1
        { x: x - 1, y: y, direction: EAST }, // 2
        { x: x,     y: y + 1, direction: NORTH }, // 3
        { x: x + 1, y: y + 1, direction: NORTH }, // 4
        { x: x - 1, y: y + 1, direction: NORTH }, // 5
        { x: x + 1, y: y - 1, direction: SOUTH }, // 6
        { x: x - 1, y: y - 1, direction: SOUTH }, // 7
      ];
    } else if (direction === WEST) {
      return [
        { x: x,     y: y + 1, direction: NORTH }, // 1
        { x: x,     y: y - 1, direction: SOUTH }, // 2
        { x: x - 1, y: y, direction: EAST }, // 3
        { x: x - 1, y: y + 1, direction: EAST }, // 4
        { x: x - 1, y: y - 1, direction: EAST }, // 5
        { x: x + 1, y: y + 1, direction: WEST }, // 6
        { x: x + 1, y: y - 1, direction: WEST }, // 7
      ];
    }
  }
  return null;
}

export const partyPreferredPositionsSelector = createSelector(
  activeCharacterSelector,
  gameSelector,
  (playerCharacter, { partyMode }) => {
    return calculatePartyPreferredPositions(
      partyMode,
      playerCharacter.x,
      playerCharacter.y,
      playerCharacter.direction,
    );
  }
);

export function createPartyMemberPreferredPositionSelector(characterId) {
  return createSelector(
    state => state.models.characters[characterId],
    activeCharacterSelector,
    partyPreferredPositionsSelector,
    activeRegionSelector,
    (character, playerCharacter, positions, region) => {
      // Positions are 0-6, so if your original party position is below the player character, use
      // it as is, otherwise decrement by 1 to avoid the position of the player character.
      // So if the player character is in party position 3, then characters with positions 0, 1, and
      // 2 will keep those positions, but the ones higher will get 3, 4, 5, and 6 - rather than 4,
      // 5, 6, and 7.
      if (!character.movement || !character.movement.type === 'PARTY') {
        throw new Error('createPartyMemberPreferredPositionSelector called for a non-party character.  How did this happen?');
      }
      const {
        position
      } = character.movement;
      const finalPosition = position < playerCharacter.movement.position ? position : position - 1;

      let { x, y, direction } = positions[finalPosition];

      // Constrain the position bounds to the region's dimensions.
      x = Math.max(0, Math.min(region.map.width - 1, x));
      y = Math.max(0, Math.min(region.map.height - 1, y));

      return { x, y, direction };
    }
  );
}
