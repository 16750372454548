import classNames from 'classnames';

import { getSpritesheet } from '../../content-utils/spritesheetUtils';

export enum ActivityIconTypes {
  // First row
  // Note, the first slot is empty intentionally.
  TALK = 'talk',
  LOOK = 'look',
  WALK = 'walk',
  USE = 'use',
  CHRONICLE = 'chronicle',
  BACKPACK = 'backpack',
  BACKPACK_OPEN = 'backpackOpen',
  DEFENSE = 'defense',
  DAMAGE = 'damage',
  GOLD = 'gold',
  CLOSE = 'close',
  PAUSE = 'pause',
  PLAY = 'play',
  COMBAT_PAUSE = 'combatPause',
  CAMP_PAUSE = 'campPause',

  // Second row
  HUNT = 'hunt',
  GATHER = 'gather',
  CAMP = 'camp',
  COMBAT = 'combat',
  EQUIPMENT = 'equipment',
  STATS = 'stats',
  GOLD_LOST = 'goldLost',
  GOLD_GAINED = 'goldGained',
  SPRING = 'spring',
  SUMMER = 'summer',
  FALL = 'fall',
  WINTER = 'winter',
  MINIMIZE = 'minimize',
  MAXIMIZE = 'maximize',
  CONTAINER_SEPARATOR = 'containerSeparator',

  // Third row
  PUSH = 'push',
  PICK_UP = 'pickUp',
  DROP = 'drop',
  EXPAND_VIEWPORT = 'expandViewport',
  PARTY_MODE_SOLO = 'partyModeSolo',
  PARTY_MODE_ALL = 'partyModeAll',
  PLUS_ONE = 'plusOne',
  MINUS_ONE = 'minusOne',
  PLUS_FIVE = 'plusFive',
  MINUS_FIVE = 'minusFive',
  HALF = 'half',
  MINUS_HALF = 'minusHalf',
  MENU = 'menu',
  CUTSCENE_PAUSE = 'cutscenePause',
}

interface ActivityIconProps {
  activityId: ActivityIconTypes,
  scale: number,
  style: object | undefined,
  className: string,
}

export default function ActivityIcon({
  activityId,
  scale = 4,
  style,
  className,
}: ActivityIconProps) {
  let positionX = 0;
  let positionY = 0;

  switch(activityId) {
    // First row

    case ActivityIconTypes.TALK:
      positionX = 1;
      break;
    case ActivityIconTypes.LOOK:
      positionX = 2;
      break;
    case ActivityIconTypes.WALK:
      positionX = 3;
      break;
    case ActivityIconTypes.USE:
      positionX = 4;
      break;
    case ActivityIconTypes.CHRONICLE:
      positionX = 5;
      break;
    case ActivityIconTypes.BACKPACK:
      positionX = 6;
      break;
    case ActivityIconTypes.BACKPACK_OPEN:
      positionX = 7;
      break;
    case ActivityIconTypes.DEFENSE:
      positionX = 8;
      break;
    case ActivityIconTypes.DAMAGE:
      positionX = 9;
      break;
    case ActivityIconTypes.GOLD:
      positionX = 10;
      break;
    case ActivityIconTypes.CLOSE:
      positionX = 11;
      break;
    case ActivityIconTypes.PAUSE:
      positionX = 12;
      break;
    case ActivityIconTypes.PLAY:
      positionX = 13;
      break;
    case ActivityIconTypes.COMBAT_PAUSE:
      positionX = 14;
      break;
    case ActivityIconTypes.CAMP_PAUSE:
      positionX = 15;
      break;

    // SECOND ROW
    case ActivityIconTypes.HUNT:
      positionX = 0;
      positionY = 1;
      break;
    case ActivityIconTypes.GATHER:
      positionX = 1;
      positionY = 1;
      break;
    case ActivityIconTypes.CAMP:
      positionX = 2;
      positionY = 1;
      break;
    case ActivityIconTypes.COMBAT:
      positionX = 3;
      positionY = 1;
      break;
    case ActivityIconTypes.EQUIPMENT:
      positionX = 4;
      positionY = 1;
      break;
    case ActivityIconTypes.STATS:
      positionX = 5;
      positionY = 1;
      break;
    case ActivityIconTypes.GOLD_LOST:
      positionX = 6;
      positionY = 1;
      break;
    case ActivityIconTypes.GOLD_GAINED:
      positionX = 7;
      positionY = 1;
      break;
    case ActivityIconTypes.SPRING:
      positionX = 8;
      positionY = 1;
      break;
    case ActivityIconTypes.SUMMER:
      positionX = 9;
      positionY = 1;
      break;
    case ActivityIconTypes.FALL:
      positionX = 10;
      positionY = 1;
      break;
    case ActivityIconTypes.WINTER:
      positionX = 11;
      positionY = 1;
      break;
    case ActivityIconTypes.MINIMIZE:
      positionX = 12;
      positionY = 1;
      break;
    case ActivityIconTypes.MAXIMIZE:
      positionX = 13;
      positionY = 1;
      break;
    case ActivityIconTypes.CONTAINER_SEPARATOR:
      positionX = 14;
      positionY = 1;
      break;

    // Third row
    case ActivityIconTypes.PUSH:
      positionX = 0;
      positionY = 2;
      break;
    case ActivityIconTypes.PICK_UP:
      positionX = 1;
      positionY = 2;
      break;
    case ActivityIconTypes.DROP:
      positionX = 2;
      positionY = 2;
      break;
    case ActivityIconTypes.EXPAND_VIEWPORT:
      positionX = 3;
      positionY = 2;
      break;
    case ActivityIconTypes.PARTY_MODE_SOLO:
      positionX = 4;
      positionY = 2;
      break;
    case ActivityIconTypes.PARTY_MODE_ALL:
      positionX = 5;
      positionY = 2;
      break;
    case ActivityIconTypes.PLUS_ONE:
      positionX = 6;
      positionY = 2;
      break;
    case ActivityIconTypes.MINUS_ONE:
      positionX = 7;
      positionY = 2;
      break;
    case ActivityIconTypes.PLUS_FIVE:
      positionX = 8;
      positionY = 2;
      break;
    case ActivityIconTypes.MINUS_FIVE:
      positionX = 9;
      positionY = 2;
      break;
    case ActivityIconTypes.HALF:
      positionX = 10;
      positionY = 2;
      break;
    case ActivityIconTypes.MINUS_HALF:
      positionX = 11;
      positionY = 2;
      break;
    case ActivityIconTypes.MENU:
      positionX = 12;
      positionY = 2;
      break;
    case ActivityIconTypes.CUTSCENE_PAUSE:
      positionX = 13;
      positionY = 2;
      break;
    default:
  }

  const x = ((positionX * 16) + 1 + (positionX * 2)) * -1 * scale;
  const y = ((positionY * 16) + 1 + (positionY * 2)) * -1 * scale;
  const size = 16 * scale;
  const backgroundSize = 288 * scale;

  const activityImage = getSpritesheet('activityIcons');

  return (
    <div
      className={classNames('pixelated', className)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundImage: `url('${activityImage}')`,
        backgroundSize: `${backgroundSize}px ${backgroundSize}px`,
        backgroundPosition: `${x}px ${y}px`,
        ...style,
      }}
    />
  );
}
