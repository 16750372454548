import { RelationshipConversationStatuses } from '../../../../characters/relationships/data/types';

export interface ConversationState {
  activeConversationCharacterId: string | null,
  activeConversationTopicId: string | null,
}

export enum ChoiceActions {
  LINK = 'LINK', // Link to another topic
  TRADE = 'TRADE',
  INN = 'INN',
  END = 'END',
  COMBAT = 'COMBAT', // TODO: Am I getting rid of this?
  JOIN_PARTY = 'JOIN_PARTY',
  LEAVE_PARTY = 'LEAVE_PARTY',
  ADD_STORY_MILESTONE = 'ADD_STORY_MILESTONE',
  UPDATE_RELATIONSHIP = 'UPDATE_RELATIONSHIP',
  UPDATE_MOVEMENT = 'UPDATE_MOVEMENT',
  GIVE_PLAYER_ITEMS = 'GIVE_PLAYER_ITEMS',
  TAKE_PLAYER_ITEMS = 'TAKE_PLAYER_ITEMS',
  LEARN_NAME = 'LEARN_NAME',
}

export interface StoryMilestoneStatusCondition {
  milestones: Array<string>,
}

export interface RelationshipStatusCondition {
  levelAbove?: number,
  levelBelow?: number,
}

// ItemStatusCondition arrays are lists of template IDs.
export interface ItemStatusCondition {
  has?: Array<string>,
  lacks?: Array<string>,
}

export interface PartyStatusCondition {
  inParty: boolean,
  canJoin?: boolean,
}

export interface ConversationChoice {
  response: string,
  action: ChoiceActions
  storyMilestoneStatus?: StoryMilestoneStatusCondition,
  relationshipStatus?: RelationshipStatusCondition,
  playerItemStatus?: ItemStatusCondition,
  characterItemStatus?: ItemStatusCondition,
  partyStatus?: PartyStatusCondition,
}

export interface AddStoryMilestoneConversationChoice extends ConversationChoice {
  action: ChoiceActions.ADD_STORY_MILESTONE,
  milestone: string,
}

export interface UpdateRelationshipConversationChoice extends ConversationChoice {
  action: ChoiceActions.UPDATE_RELATIONSHIP,
  linkedTopicId: string,
  levelChange?: number,
  conversationStatus?: RelationshipConversationStatuses,
}

export interface LinkedTopicConversationChoice extends ConversationChoice {
  action: ChoiceActions.LINK,
  linkedTopicId: string,
}

export interface GiveItemsConversationChoice extends ConversationChoice {
  action: ChoiceActions.TAKE_PLAYER_ITEMS | ChoiceActions.GIVE_PLAYER_ITEMS,
  templateIds: Array<string>,
  linkedTopicId: string,
}

export interface PartyConversationChoice extends ConversationChoice {
  action: ChoiceActions.JOIN_PARTY | ChoiceActions.LEAVE_PARTY,
  linkedTopicId: string,
}

export interface ConversationTopic {
  content: string,
  choices: Array<
    ConversationChoice |
    AddStoryMilestoneConversationChoice |
    UpdateRelationshipConversationChoice |
    GiveItemsConversationChoice |
    LinkedTopicConversationChoice |
    PartyConversationChoice
  >,
}

export interface Conversation {
  [conversationTopicId: string]: ConversationTopic,
}

export interface Conversations {
  [conversationId: string]: Conversation
}
