import classNames from 'classnames';

export default function Grid({ children }) {
  return (
    <div className={classNames(
      'd-flex gap-2 grid-gap-2 flex-wrap justify-content-start align-items-start'
    )}>
      {children}
    </div>
  );
}
