import Phaser from 'phaser';
import memoize from 'memoize-one';
import tinycolor from 'tinycolor2';

import { getStore } from '../../data/configureStore';
import { WALK_1, WALK_2, SOUTH, WEST, EAST } from '../../ui/character/constants';
import { parseColorInt } from '../data/utils';
import { createModelSelector } from '../../models-store/selectors';
import createSelectorListener from '../../engine/createSelectorListener';

export default class CharacterEyes extends Phaser.GameObjects.Graphics {

  constructor(characterId, ...args) {
    super(...args);

    this.characterId = characterId;
    this.store = getStore();

    this.updatePoseAndDirection = memoize(this.updatePoseAndDirection);
    // This is important since we're calling the function from createSelectorListener
    this.handleModelChanged = this.handleModelChanged.bind(this);

    const { remove, data } = createSelectorListener(
      createModelSelector('characters', characterId),
      this.handleModelChanged
    );

    this.removeModelListener = remove;
    this.character = data;

    this.initialize();
  }

  initialize() {
    this.checkPoseAndDirection();
  }

  destroy() {
    // This is super important - cleaning up the subscription releases the store's reference to our
    // subscription listener, which lets this sprite be garbage collected.
    this.removeModelListener();
    super.destroy();
  }

  handleModelChanged(model) {
    this.character = model;
    this.checkPoseAndDirection();
  }

  checkPoseAndDirection() {
    const { pose, direction, body } = this.character;

    const { eyesIrises } = body;

    this.updatePoseAndDirection(pose, direction, eyesIrises);
  }

  updatePoseAndDirection(pose, direction, eyesIrises) {
    let yOffset = 0;
    if (pose === WALK_1 || pose === WALK_2) {
      yOffset = 1;
    }

    this.clear();

    const eyeColor = parseColorInt(eyesIrises.tint);
    const darkerEyeColor = parseColorInt(tinycolor(eyesIrises.tint).darken(10).toString());

    if (direction === SOUTH) {
      this.fillStyle(darkerEyeColor);
      this.fillPoint(1, yOffset);
      this.fillPoint(4, yOffset);
      this.fillStyle(eyeColor);
      this.fillPoint(1, yOffset + 1);
      this.fillPoint(4, yOffset + 1);
      this.fillStyle(0xffffff);
      this.fillPoint(0, yOffset);
      this.fillPoint(5, yOffset);
      this.fillPoint(0, yOffset + 1);
      this.fillPoint(5, yOffset + 1);
    } else if (direction === EAST) {
      this.fillStyle(darkerEyeColor);
      this.fillPoint(3, yOffset);
      this.fillStyle(eyeColor);
      this.fillPoint(3, yOffset + 1);
      this.fillStyle(0xffffff);
      this.fillPoint(2, yOffset);
      this.fillPoint(2, yOffset + 1);
    } else if (direction === WEST) {
      this.fillStyle(darkerEyeColor);
      this.fillPoint(2, yOffset);
      this.fillStyle(eyeColor);
      this.fillPoint(2, yOffset + 1);
      this.fillStyle(0xffffff);
      this.fillPoint(3, yOffset);
      this.fillPoint(3, yOffset + 1);
    }
  }
}
