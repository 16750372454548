import { useSelector } from 'react-redux';

import { hudSelector } from './selectors';

export function useHud() {
  return useSelector(hudSelector);
}

export function useOpenSheet(characterId) {
  const {
    openSheets
  } = useHud();

  let openSheet = null;
  openSheets.every(element => {
    if (element.characterId === characterId) {
      openSheet = element;
      return false;
    }
    return true;
  });
  return openSheet;
}
