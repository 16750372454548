import Button from './Button';
import ActivityIcon from './ActivityIcon';

export default function ActivityIconButton({
  className,
  activityId,
  onClick,
  scale = 2,
  style,
  selected = false,
  ...props
}) {
  return (
    <Button onClick={onClick} selected={selected} style={style} className={className} {...props}>
      <ActivityIcon activityId={activityId} scale={scale} />
    </Button>
  );
}
