import { useDispatch } from 'react-redux';
import { quitGame } from '../../saves/data/thunks';
import { Button } from '../generic';

export default function QuitButton({ className }) {
  const dispatch = useDispatch();

  const handleQuit = () => {
    dispatch(quitGame());
  };

  return (
    <Button style={{ width: 160 }} className={className} onClick={handleQuit}>Quit</Button>

  );
}
