import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { updateShowChronicle } from '../data/slice';
import { DraggablePanel } from '../generic';

export default function Chronicle({ className }) {
  const {
    messages,
  } = useSelector(state => state.chronicle);
  const dispatch = useDispatch();

  const handleChronicleClose = () => {
    dispatch(updateShowChronicle({ showChronicle: false }));
  };

  return (
    <DraggablePanel
      id="chronicle"
      className="d-flex"
      onClose={handleChronicleClose}
      closeStyle={{
        right: 30,
        top: 2,
      }}
      title="Chronicle"
    >
      <section
        className={classNames(className, 'pixelated d-flex overflow-scroll flex-column-reverse')}
        style={{
          width: 300,
          height: 160,
        }}
      >
        {[...messages].reverse().map(({ id, message }) => {
          return (
            <p className="mb-1" key={id}><li>{message}</li></p>
          );
        })}
      </section>
    </DraggablePanel>
  );
}
