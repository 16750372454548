let narratives = {};

function assertNarrative(id) {
  const narrative = narratives[id];
  if (narrative === undefined) {
    throw new Error(`Narrative ${id} does not exist!`);
  }
}

export function getNarrative(id) {
  assertNarrative(id);

  return narratives[id];
}

export function setNarratives(data) {
  narratives = data;
}
