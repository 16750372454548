import classNames from 'classnames';

import { useCombatStats } from '../../characters/data/hooks';
import Statistic from './Statistic';

export default function Statistics({ character, className }) {
  const {
    gold
  } = character;

  const { damage, defense } = useCombatStats(character.id);

  return (
    <div className={classNames('d-flex flex-row justify-content-between', className)}>
      <Statistic className="mb-1" statId="defense">{defense}</Statistic>
      <Statistic className="mb-1" statId="damage">{damage}</Statistic>
      <Statistic className="mb-1" statId="gold">{gold}</Statistic>
    </div>
  );
}
