import { useSelector } from 'react-redux';
import { useCharacter } from '../../../data/hooks';
import { CombatStatuses } from './data/types';

export default function CombatStatus({ characterId }) {

  const {
    opponentId,
    status,
    messages,
  } = useSelector(state => state.combat);

  const character = useCharacter(characterId);
  const opponent = useCharacter(opponentId);
  let statusDescription = null;

  const hasOpponent = status !== CombatStatuses.OPPONENT_ESCAPED && status !== CombatStatuses.CHARACTER_ESCAPED;
  const canLeave = !hasOpponent;

  switch (status) {
    case CombatStatuses.STALKING:
      statusDescription = `The ${opponent.name} hasn't seen ${character.name}.`;
      break;
    case CombatStatuses.FIGHTING:
      statusDescription = `The ${opponent.name} has turned to fight ${character.name}!`;
      break;
    case CombatStatuses.CHASING:
      statusDescription = `The ${opponent.name} is fleeing from ${character.name}.`;
      break;
    case CombatStatuses.FLEEING:
      statusDescription = `${character.name} is fleeing from ${opponent.name}.`;
      break;
    case CombatStatuses.VICTORY:
      statusDescription = `${character.name} has defeated ${opponent.name}.`;
      break;
    case CombatStatuses.OPPONENT_ESCAPED:
      statusDescription = `The ${opponent.name} has escaped from ${character.name}.`;
      break;
    case CombatStatuses.CHARACTER_ESCAPED:
      statusDescription = `${character.name} has escaped from the ${opponent.name}.`;
      break;
    default:
      statusDescription = null;
      break;
  }

  return (
    <>
      <p>{statusDescription}</p>
      {!canLeave && messages.length > 0 && (
        <p>{messages[messages.length - 1].message}</p>
      )}
    </>
  );
}
