import { EAST, NORTH, SOUTH, WEST } from '../../../ui/character/constants';

export function getDirectionFromWalks(walkNorth, walkSouth, walkEast, walkWest) {
  let dx = 0;
  let dy = 0;

  if (walkNorth) {
    dy -= 1;
  }
  if (walkSouth) {
    dy += 1;
  }
  if (walkEast) {
    dx += 1;
  }
  if (walkWest) {
    dx -= 1;
  }
  return { dx, dy };
}

let characterWalks = {};

export function getCharacterWalk(id) {
  return characterWalks[id];
}

export function setCharacterWalk(id, walk) {
  characterWalks[id] = walk;
}

export function getPreferredCharacterDirection(characterId, walkNorth, walkSouth, walkEast, walkWest) {
  const walks = characterWalks[characterId];

  if (walkNorth) {
    walks.walkNorthCount += 1;
  } else {
    walks.walkNorthCount = 0;
  }
  if (walkSouth) {
    walks.walkSouthCount += 1;
  } else {
    walks.walkSouthCount = 0;
  }
  if (walkEast) {
    walks.walkEastCount += 1;
  } else {
    walks.walkEastCount = 0;
  }
  if (walkWest) {
    walks.walkWestCount += 1;
  } else {
    walks.walkWestCount = 0;
  }

  let lastDirection = SOUTH;
  let highestCount = walks.walkSouthCount;
  if (walks.walkEastCount > highestCount) {
    lastDirection = EAST;
    highestCount = walks.walkEastCount;
  }
  if (walks.walkWestCount > highestCount) {
    lastDirection = WEST;
    highestCount = walks.walkWestCount;
  }
  if (walks.walkNorthCount > highestCount) {
    lastDirection = NORTH;
    highestCount = walks.walkNorthCount;
  }
  return lastDirection;
}
