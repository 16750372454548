import Markdown from 'react-markdown';
import { useClickAttributes } from '../../../data/hooks';

export default function ConversationChoice({ choice, onChoiceClick }) {

  const clickAttributes = useClickAttributes(() => {
    onChoiceClick(choice);
  });

  return (
    <li
      {...clickAttributes}
      className="cursor-pointer"
    >
      <Markdown components={{
        p: 'span',
      }}>
        {choice.response}
      </Markdown>
    </li>
  );
}
