let game = null;

export function getGame() {
  return game;
}

export function setGame(newGame) {
  game = newGame;
}

export function getRegionScene(regionId) {
  return getGame().scene.getScene(regionId);
}
