import featuresImage from '../../spritesheets/features.png';

const itemTemplates = [
  {
    id: 'street-light',
    name: 'Street Light',
    status: 'lit',
    image: {
      statuses: {
        lit: {
          src: featuresImage,
          position: { x: 1, y: 6 }
        },
        cold: {
          src: featuresImage,
          position: { x: 0, y: 6 }
        }
      }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
    light: {
      lit: { rgb: 0xffa500, intensity: 8 },
      cold: { rgb: 0x000000, intensity: 0 }
    },
  },
  {
    id: 'street-light-pole',
    name: 'Street Light Pole',
    image: {
      src: featuresImage,
      position: { x: 0, y: 7 }
    },
    atlas: 'features',
    moveable: false,
    walkable: true,
  },
  {
    id: 'street-light-base',
    name: 'Street Light Base',
    image: {
      src: featuresImage,
      position: { x: 0, y: 8 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
];

export default itemTemplates;
