import classNames from 'classnames';

import { getSpritesheet } from '../content-utils/spritesheetUtils';

export enum PlaceIconTypes {
  ROAD = 'road',
  SMITH = 'smith',
  INN = 'inn',
  TAVERN = 'tavern',
  TOWN = 'town',
}

export interface PlaceIconProps {
  placeId: PlaceIconTypes,
  scale: number,
  style: object | undefined,
  className: string,
}

export default function PlaceIcon({
  placeId,
  scale = 4,
  style,
  className,
}: PlaceIconProps) {
  let positionX = 0;
  let positionY = 0;

  switch (placeId) {
    case PlaceIconTypes.ROAD:
      positionX = 0;
      break;
    case PlaceIconTypes.SMITH:
      positionX = 1;
      break;
    case PlaceIconTypes.INN:
      positionX = 2;
      break;
    case PlaceIconTypes.TAVERN:
      positionX = 3;
      break;
    case PlaceIconTypes.TOWN:
      positionX = 4;
      break;
    default:
  }

  const x = ((positionX * 16) + 1 + (positionX * 2)) * -1 * scale;
  const y = ((positionY * 16) + 1 + (positionY * 2)) * -1 * scale;
  const size = 16 * scale;
  const backgroundSize = 288 * scale;

  const placeIconImage = getSpritesheet('placeIcons');

  return (
    <div
      className={classNames('pixelated', className)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundImage: `url('${placeIconImage}')`,
        backgroundSize: `${backgroundSize}px ${backgroundSize}px`,
        backgroundPosition: `${x}px ${y}px`,
        ...style,
      }}
    />
  );
}
