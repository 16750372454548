
import itemsImage from '../../spritesheets/items.png';

const itemTemplates = [
  {
    id: 'leather-helmet',
    name: 'Leather Helmet',
    image: {
      src: itemsImage,
      position: { x: 4, y: 0 },
    },
    damage: 0,
    defense: 2,
    value: 20,
    allowances: 'head',
  },
  {
    id: 'leather-chest',
    name: 'Leather Chest',
    image: {
      src: itemsImage,
      position: { x: 4, y: 1 },
    },
    damage: 0,
    defense: 4,
    value: 27,
    allowances: 'chest',
  },
  {
    id: 'leather-leggings',
    name: 'Leather Leggings',
    image: {
      src: itemsImage,
      position: { x: 4, y: 2 },
    },
    damage: 0,
    defense: 3,
    value: 21,
    allowances: 'legs',
    determiner: 'some',
  },
  {
    id: 'leather-boots',
    name: 'Leather Boots',
    image: {
      src: itemsImage,
      position: { x: 4, y: 3 },
    },
    damage: 0,
    defense: 2,
    value: 14,
    allowances: 'feet',
    determiner: 'a pair of'
  },
  {
    id: 'belt',
    name: 'Belt',
    image: {
      src: itemsImage,
      position: { x: 4, y: 4 },
    },
    damage: 0,
    defense: 1,
    value: 8,
    allowances: 'waist',
  },
  {
    id: 'leather-gloves',
    name: 'Leather Gloves',
    image: {
      src: itemsImage,
      position: { x: 4, y: 5 },
    },
    damage: 0,
    defense: 1,
    value: 12,
    allowances: 'hands',
    determiner: 'a pair of',
  },
  {
    id: 'wooden-shield',
    name: 'Wooden Shield',
    image: {
      src: itemsImage,
      position: { x: 4, y: 6 },
    },
    damage: 0,
    defense: 2,
    value: 15,
    allowances: 'secondary',
  },
];

export default itemTemplates;
