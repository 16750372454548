import classNames from 'classnames';

export default function EmptySlot({ scale, className, itemIsSelected, isEquippable }) {
  const size = 16 * scale;

  return (
    <div className={
      classNames(
        'border border-primary rounded-circle',
        className,
        { 'border-dashed bg-white': !itemIsSelected },
        { 'bg-parchment-400': itemIsSelected || isEquippable },
        { 'border-dashed bg-white': itemIsSelected && !isEquippable },
      )}
    style={{
      width: `${size}px`,
      height: `${size}px`,
      boxSizing: 'border-box',
    }} />
  );
}
