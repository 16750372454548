import { createSelector } from '@reduxjs/toolkit';
import { hudSelector } from '../ui/data/selectors';

export const activeCharacterSelector = createSelector(
  gameSelector,
  (state) => state.models.characters,
  (game, charactersById) => {
    const { activeCharacterId } = game;
    return charactersById[activeCharacterId];
  }
);

export const activeRegionSelector = createSelector(
  activeCharacterSelector,
  (state) => state.models.regions,
  (activeCharacter, regionsById) => {
    return regionsById[activeCharacter.regionId];
  }
);

export const leaderCharacerSelector = createSelector(
  gameSelector,
  (state) => state.models.characters,
  (game, charactersById) => {
    const { partyCharacterIds } = game;

    let leaderCharacter = null;
    for (let i = 0; i < partyCharacterIds.length; i++) {
      const characterId = partyCharacterIds[i];
      const character = charactersById[characterId];
      if (character.movement.position === 0) {
        leaderCharacter = character;
      }
    }
    if (leaderCharacter === null) {
      throw new Error('leaderCharacterSelector did not find a leader. How can there be no character with party position 0?');
    }
    return leaderCharacter;
  }
);

export function gameSelector(state) {
  return state.game;
}

export const activeStorySelector = createSelector(
  gameSelector,
  state => state.models.stories,
  (game, storiesById) => {
    const { activeStoryId } = game;
    return storiesById[activeStoryId];
  }
);

export const grabbedItemIdSelector = createSelector(
  hudSelector,
  (hud) => {
    return hud.grabbedItemId;
  }
);

export const mainViewportCharacterIdSelector = createSelector(
  gameSelector,
  state => state.conversation.activeConversationCharacterId,
  state => state.trade.tradePartnerId,
  state => state.game.overrideViewpointCharacterId,
  (game, activeConversationCharacterId, tradePartnerId, overrideViewpointCharacterId) => {
    let viewpointCharacterId = game.activeCharacterId;
    if (overrideViewpointCharacterId !== null) {
      viewpointCharacterId = overrideViewpointCharacterId;
    } else if (activeConversationCharacterId !== null) {
      viewpointCharacterId = activeConversationCharacterId;
    } else if (tradePartnerId !== null) {
      viewpointCharacterId = tradePartnerId;
    }

    return viewpointCharacterId;
  }
);

export function createCombatTargetSelector(characterId) {
  return createSelector(
    state => state.models.characters[characterId],
    (character) => {
      const relationshipCharacterIds = Object.keys(character.relationships);
      let attackRelationship = null;
      let attackCharacterId = null;
      for (let j = 0; j < relationshipCharacterIds.length; j++) {
        const relationshipCharacterId = relationshipCharacterIds[j];
        const relationship = character.relationships[relationshipCharacterId];
        if (relationship.level < 0) {
          if (attackRelationship === null || relationship.attention > attackRelationship.attention) {
            attackRelationship = relationship;
            attackCharacterId = relationshipCharacterId;
          }
        }
      }
      return attackCharacterId;
    }
  );
}

export function createContainerItemsSelector(containerItemId) {
  return createSelector(
    state => state.models.items,
    (itemsById) => {
      return Object.values(itemsById).filter(item => item.containerItemId === containerItemId).sort((a, b) => {
        return a.order - b.order;
      });
    }
  );
}
