/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { GameState, PartyModes, TimeModes } from './types';

export const initialState: GameState = {
  // Active Characters
  activeStoryId: null,
  activeCutsceneId: null,
  activeCutsceneStep: null,
  activeCharacterId: null,
  actingCharacterId: null,
  overrideViewpointCharacterId: null,
  partyCharacterIds: [],
  partyMode: PartyModes.ALL,
  activity: null,
  year: 0,
  day: 0,
  hour: 6,
  defeated: false,
  walkNorth: false,
  walkSouth: false,
  walkEast: false,
  walkWest: false,
  timeMode: TimeModes.PAUSED,
  zoom: 3,
};

const slice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    clearActivity: (state) => {
      state.activity = null;
    },
    updateActivity: (state: GameState, action) => {
      state.activity = action.payload.activity;
    },
    endJourneyTheHardWay: (state) => {
      state.defeated = true;
      state.timeMode = TimeModes.NORMAL;
    },
    updateDate: (state, action) => {
      const { year, day, hour } = action.payload;
      if (year !== undefined) {
        state.year = year;
      }
      if (day !== undefined) {
        state.day = day;
      }
      if (hour !== undefined) {
        state.hour = hour;
      }
    },
    updateWalkDirections: (state: GameState, action) => {
      const { payload } = action;
      const { walkNorth, walkSouth, walkEast, walkWest } = payload;

      if (walkNorth !== undefined) {
        state.walkNorth = walkNorth;
      }
      if (walkSouth !== undefined) {
        state.walkSouth = walkSouth;
      }
      if (walkEast !== undefined) {
        state.walkEast = walkEast;
      }
      if (walkWest !== undefined) {
        state.walkWest = walkWest;
      }
    },
    updateTimeMode: (state: GameState, action) => {
      state.timeMode = action.payload.timeMode;
    },
    updateActingCharacter: (state: GameState, action) => {
      state.actingCharacterId = action.payload.characterId;
    },
    updateActiveCharacter: (state: GameState, action) => {
      state.activeCharacterId = action.payload.characterId;
    },
    updatePartyMode: (state: GameState, action) => {
      state.partyMode = action.payload.partyMode;
    },
    addPartyMember: (state: GameState, action) => {
      state.partyCharacterIds = [...state.partyCharacterIds, action.payload.characterId];
    },
    removePartyMember: (state: GameState, action) => {
      state.partyCharacterIds = state.partyCharacterIds.filter(
        characterId => characterId !== action.payload.characterId
      );
    },
    updateActiveCutsceneId: (state: GameState, action) => {
      state.activeCutsceneId = action.payload.cutsceneId;
    },
    updateActiveCutsceneStep: (state: GameState, action) => {
      state.activeCutsceneStep = action.payload.cutsceneStep;
    },
    updateOverrideViewpointCharacterId: (state: GameState, action) => {
      state.overrideViewpointCharacterId = action.payload.characterId;
    }
  },
});

export const {
  // Activities
  clearActivity,
  updateActivity,

  // Dead
  endJourneyTheHardWay,

  // Date / Time
  updateDate,

  // Input
  updateWalkDirections,
  updateTimeMode,
  updateActingCharacter,
  updateActiveCharacter,
  updatePartyMode,
  addPartyMember,
  removePartyMember,
  updateActiveCutsceneId,
  updateActiveCutsceneStep,
  updateOverrideViewpointCharacterId,
} = slice.actions;

export const { reducer } = slice;
