import classNames from 'classnames';

export default function Card({ children, className, style }) {
  return (
    <section
      style={style}
      className={classNames(
        'border-box border rounded bg-white p-3 shadow-1',
        className,
      )}
    >{children}</section>
  );
}
