import { useEffect, useState } from 'react';

export function useWanderingPosition() {
  const [x, setX] = useState(Math.floor(Math.random() * 4800));
  const [y, setY] = useState(Math.floor(Math.random() * 4800));
  const [vX] = useState(Math.floor(Math.random() * 8) - 4);
  const [vY] = useState(Math.floor(Math.random() * 8) - 4);

  useEffect(() => {
    const id = setInterval(() => {
      setX(value => value + vX);
      setY(value => value + vY);
    }, 50);
    return () => {
      clearInterval(id);
    };
  }, [vX, vY]);

  return [x, y];
}
