import classNames from 'classnames';

export default function AbilityPoint({ style, className, variant }) {
  return (
    <div
      style={{
        height: '12px',
        width: '6px',
        ...style,
      }}
      className={classNames(
        `bg-${variant}`,
        'border border-primary',
        className,
      )}>
    </div>
  );
}
