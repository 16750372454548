import { updateModels } from '../../../../models-store';
import { endActivity, startActivity } from '../../data/thunks';
import { startInn, endInn } from './slice';
import { ActivityTypes } from '../../data/types';
import { rest } from '../../../../characters/status/data/thunks';
import { advanceTime } from '../../../../saves/data/thunks';
import { sleep } from '../../../../data/utils';

export function partyStayAtInn() {
  return async (dispatch, getState) => {
    const { partyCharacterIds, hour } = getState().game;

    partyCharacterIds.forEach(characterId => {
      dispatch(stayAtInn({ characterId }));
    });

    const dawn = 5;
    let untilDawn = dawn - hour;
    if (hour > dawn) {
      untilDawn = 23 - hour + dawn;
    }

    for (let i = 1; i <= untilDawn; i++) {
      dispatch(advanceTime());
      await sleep(500);
    }

    dispatch(stopTalkingToInnkeeper());
  };
}

export function stayAtInn({ characterId }) {
  return async (dispatch, getState) => {
    const {
      activeCharacterId,
    } = getState().game;

    const {
      activeInnkeeperId
    } = getState().inn;

    const character = getState().models.characters[characterId];
    const innkeeper = getState().models.characters[activeInnkeeperId];

    const { price, quality } = innkeeper.inn;

    if (character.gold < price) {
      throw new Error ('rest: character did not have enough gold to cover inn price.');
    }

    dispatch(rest({
      characterId,
      healthQuality: quality,
      enduranceQuality: quality * 2,
    }));

    dispatch(updateModels({
      modelType: 'characters',
      models: [
        {
          id: activeCharacterId,
          gold: character.gold - price,
        },
        {
          id: activeInnkeeperId,
          gold: innkeeper.gold + price,
        }
      ]
    }));
  };
}

export function talkToInnkeeper({ innkeeperId }) {
  return async (dispatch, getState) => {

    dispatch(startInn({ innkeeperId }));
    dispatch(startActivity({ activity: ActivityTypes.INN }));
  };
}

export function stopTalkingToInnkeeper() {
  return async (dispatch, getState) => {
    dispatch(endActivity());
    dispatch(endInn());
  };
}
