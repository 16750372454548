import { clearActivity, updateActivity } from '../../../data/slice';

export function startActivity({ activity }) {
  return async (dispatch, getState) => {
    dispatch(updateActivity({ activity }));
  };
}

export function endActivity() {
  return async (dispatch, getState) => {
    dispatch(clearActivity());
  };
}
