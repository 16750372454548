import { useCallback, useContext, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { getAngle } from '../../data/utils';
import { useActiveCharacter, useGame, useItem } from '../../data/hooks';
import { updateWalkDirections } from '../../data/slice';
import { clearGrabbedItem } from '../../ui/data/slice';
import { dropItem } from '../../characters/items/data/thunks';

import MousePositionContext from '../MousePositionContext';

import { playerAttack, playerLook, playerTalk, playerUse, playerUseKey, playerWalk } from './thunks';
import { useHud } from '../../ui/data/hooks';

function calculateWalkDirectionsFromLocation(activeCharacter, x, y) {
  const angle = getAngle(activeCharacter.x - x, activeCharacter.y - y);

  let walkNorth = false;
  let walkSouth = false;
  let walkWest = false;
  let walkEast = false;
  if (angle >= 337.5 || angle < 22.5) {
    walkWest = true;
  } else if (angle >= 22.5 && angle < 67.5) {
    walkWest = true;
    walkNorth = true;
  } else if (angle >= 67.5 && angle < 112.5) {
    walkNorth = true;
  } else if (angle >= 112.5 && angle < 157.5) {
    walkNorth = true;
    walkEast = true;
  } else if (angle >= 157.5 && angle < 202.5) {
    walkEast = true;
  } else if (angle >= 202.5 && angle < 247.5) {
    walkEast = true;
    walkSouth = true;
  } else if (angle >= 247.5 && angle < 292.5) {
    walkSouth = true;
  } else if (angle >= 292.5 && angle < 337.5) {
    walkSouth = true;
    walkWest = true;
  }

  return {
    walkNorth,
    walkSouth,
    walkWest,
    walkEast,
  };
}

export function useViewportMouseHandlers() {
  const dispatch = useDispatch();
  const {
    actingCharacterId,
  } = useGame();
  const {
    grabbedItemId,
    viewportActivity,
  } = useHud();
  const activeCharacter = useActiveCharacter();
  const grabbedItem = useItem(grabbedItemId);

  const { setDragPosition } = useContext(MousePositionContext);

  const directionsRef = useRef({
    walkNorth: false,
    walkSouth: false,
    walkWest: false,
    walkEast: false
  });

  const handleMouseDown = useCallback((location) => {
    const { x, y } = location;

    if (actingCharacterId && actingCharacterId !== activeCharacter.id) {
      return;
    }
    if (viewportActivity === 'walk') {
      const {
        walkNorth,
        walkSouth,
        walkWest,
        walkEast,
      } = calculateWalkDirectionsFromLocation(activeCharacter, x, y);

      dispatch(playerWalk({
        characterId: activeCharacter.id,
        walkNorth,
        walkSouth,
        walkWest,
        walkEast,
      }));
    } else if (viewportActivity === 'talk') {
      dispatch(playerTalk({ characterId: activeCharacter.id, x, y }));
    } else if (viewportActivity === 'look') {
      dispatch(playerLook({ characterId: activeCharacter.id, x, y }));
    } else if (viewportActivity === 'use') {
      if (grabbedItemId !== null && grabbedItem.keyId === null) {
        dispatch(dropItem({
          itemId: grabbedItemId,
          regionId: activeCharacter.regionId,
          characterId: activeCharacter.id,
          x: location.x,
          y: location.y
        }));
        dispatch(clearGrabbedItem());
        setDragPosition({ x: null, y: null });
      } else if (grabbedItemId !== null && grabbedItem.keyId !== null) {
        // This item is a key.  See if there's a container here, and if there is, see if our keyId
        // matches it.  If it does, open the container, otherwise print a message that says it
        // doesn't.
        dispatch(playerUseKey({
          characterId: activeCharacter.id,
          keyItemId: grabbedItemId,
          x,
          y
        }));
      } else {
        dispatch(playerUse({ characterId: activeCharacter.id, x, y }));
        // TODO: This is so that the item appears immediately on click.  I don't like
        // that it's not in the thunk, but we can't set a value from context in there.
        setDragPosition({ x: location.clientX, y: location.clientY });
      }
    } else if (viewportActivity === 'attack') {
      dispatch(playerAttack({ characterId: activeCharacter.id, x, y }));
    }
  }, [
    actingCharacterId,
    viewportActivity,
    activeCharacter,
    dispatch,
    setDragPosition,
    grabbedItemId,
    grabbedItem,
  ]);

  const handleMouseUp = useCallback((location) => {
    if (viewportActivity === 'walk') {
      dispatch(playerWalk({
        characterId: activeCharacter.id,
        walkNorth: false,
        walkSouth: false,
        walkWest: false,
        walkEast: false,
      }));
    }
  }, [dispatch, viewportActivity, activeCharacter.id]);

  const handleMouseMove = useCallback((location) => {
    if (viewportActivity === 'walk') {
      const { x, y } = location;
      const {
        walkNorth,
        walkSouth,
        walkWest,
        walkEast,
      } = calculateWalkDirectionsFromLocation(activeCharacter, x, y);

      if (
        directionsRef.current.walkNorth !== walkNorth ||
        directionsRef.current.walkSouth !== walkSouth ||
        directionsRef.current.walkWest !== walkWest ||
        directionsRef.current.walkEast !== walkEast
      ) {

        directionsRef.current = {
          walkNorth,
          walkSouth,
          walkWest,
          walkEast
        };
        dispatch(updateWalkDirections({
          walkNorth,
          walkSouth,
          walkWest,
          walkEast
        }));
      }
    } else if (viewportActivity === 'use') {
      // TODO: So this behavior needs to be on a mouse move handler on the Hud or above it, rather than here, attached to the ViewportInputOverlay.  This is because that overlay is below the hud, so it ceases to get inputs once we hit a panel.
      setDragPosition({ x: location.clientX, y: location.clientY });
    }
  }, [dispatch, activeCharacter, viewportActivity, setDragPosition]);

  return useMemo(
    () => ({
      handleMouseDown,
      handleMouseUp,
      handleMouseMove
    }),
    [handleMouseDown, handleMouseUp, handleMouseMove]
  );
}
