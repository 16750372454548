let spritesheets = {};

function assertSpritesheet(id) {
  const spritesheet = spritesheets[id];
  if (spritesheet === undefined) {
    throw new Error(`Spritesheet ${id} does not exist!`);
  }
}

export function getSpritesheet(id) {
  assertSpritesheet(id);

  return spritesheets[id];
}

export function setSpritesheets(data) {
  spritesheets = data;
}
