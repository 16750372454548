import classNames from 'classnames';
import { useClickAttributes } from '../data/hooks';

import Item from './Item';
import ItemQuantity from './ItemQuantity';

export default function ItemTile({ item, onClick = null, className, scale = 4 }) {
  const _onClick = onClick || (() => {});
  const clickAttributes = useClickAttributes(_onClick);

  return (
    <div
      className={classNames(
        className,
        { 'cursor-pointer': onClick !== null },
        'border border-secondary rounded p-1 d-inline-block position-relative'
      )}
      role="button"
      {...clickAttributes}
    >
      <Item scale={scale} item={item} className="position-relative" />
      {item.quantity > 1 && (
        <ItemQuantity quantity={item.quantity} />
      )}
    </div>
  );
}
