export const ATTACKED = 'attacked';
export const SLASHED = 'slashed';
export const PROJECTILE = 'projectile';

export function generateEffectAtlas() {

  const effects = [
    ATTACKED, SLASHED, PROJECTILE
  ];

  const height = 16;
  const width = 16;
  const margin = 0;
  const spacing = 0;
  const frames = [];

  const frameDefaults = {
    rotated: false,
    trimmed: false,
    spriteSourceSize: { x: 0, y: 0, w: width, h: height },
    sourceSize: { w: width, h: height },
    pivot: { x: 0.5, y: 0.5 }
  };

  effects.forEach((effect, effectIndex) => {
    frames.push({
      filename: effect,
      frame: {
        x: margin + (effectIndex * width) + (effectIndex * spacing),
        y: margin,
        w: width,
        h: height,
      },
      ...frameDefaults,
    });
  });

  return {
    frames
  };
}
