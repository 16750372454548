import { useDispatch } from 'react-redux';

import { Button } from '../../generic';
import { endGathering } from './data/thunks';
import { pickUpItem } from '../../../characters/items/data/thunks';
import { useGatherItems } from './data/hooks';
import ItemTile from '../../ItemTile';
import { useGame } from '../../../data/hooks';

export default function Gather() {
  const dispatch = useDispatch();
  const {
    activeCharacterId
  } = useGame();

  const gatherableItems = useGatherItems();

  const handleEnd = () => {
    dispatch(endGathering());
  };

  const handleClick = (item) => {
    dispatch(pickUpItem({
      characterId: activeCharacterId,
      itemId: item.id,
    }));
  };

  return (
    <section>
      <h2>Gathering</h2>
      <p>You decide to gather some resources and find:</p>

      <div className="mb-3 gap-2">
        {gatherableItems.map(gatherableItem => {
          return (
            <ItemTile
              className="bg-parchment-300"
              key={gatherableItem.id}
              onClick={() => handleClick(gatherableItem)}
              item={gatherableItem}
              scale={3}
            />
          );
        })}
      </div>

      <Button onClick={handleEnd}>End</Button>
    </section>
  );
}
