import { getStore } from '../data/configureStore';

export default function createSelectorListener(selector, callback) {
  const store = getStore();

  let data = selector(store.getState());

  const stateChanged = (state) => {
    if (state.status === 'UNLOADED') {
      return;
    }
    const nextData = selector(state) || null;
    if (nextData !== data) {
      data = nextData;
      callback(data);
    }
  };

  const storeUnsubscribe = store.subscribe(() => {
    stateChanged(store.getState());
  });
  stateChanged(store.getState());

  const remove = () => {
    storeUnsubscribe();
  };

  return { remove, data };
}
