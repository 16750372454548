import localforage from 'localforage';
import Phaser from 'phaser';

import { setItemTemplates } from '../../content-utils/itemUtils';
import { setConversations } from '../../content-utils/conversationUtils';
import { createCharacters, resetCharacterIdsIntegrityCheck, setCharacterTemplates } from '../../content-utils/characterUtils';
import { createRegions } from '../../content-utils/regionUtils';
import { setCutscenes } from '../../content-utils/cutsceneUtils';
import { setNarratives } from '../../content-utils/narrativeUtils';

import { getGame, setGame } from '../../world/data/phaserGame';
import { PhaserStatuses } from '../../world/data/types';
import { updateGameStatus } from '../../world/data/slice';
import { loadActiveRegion, unloadActiveRegion } from '../../world/data/thunks';

import { startEngine, stopEngine } from '../../engine';

import { initialState as rootInitialState, loadGame, unloadGame } from '../../data/rootReducer';
import { initialState as gameInitialState, updateDate, updateTimeMode } from '../../data/slice';
import { playCutscene } from '../../engine/controls';
import { activeRegionSelector } from '../../data/selectors';
import { setFeatureTemplates } from '../../content-utils/featureUtils';
import { TimeModes } from '../../data/types';
import { setTilesets } from '../../content-utils/tilesetUtils';
import { setTemplates } from '../../content-utils/templateUtils';
import { setSpritesheets } from '../../content-utils/spritesheetUtils';

export function loadSavedGame() {
  return async (dispatch, getState) => {
    const save = await localforage.getItem('myth-save');
    dispatch(loadGame(save));
  };
}

export function loadNewGame(newStory) {
  return async (dispatch, getState) => {
    const {
      characterData,
      regionData,
      conversations,
      cutscenes,
      narratives,
      story,
      tilesets,
      templates,
      spritesheets,
    } = newStory;

    setFeatureTemplates(templates.features);
    setItemTemplates(templates.items);
    setConversations(conversations);
    setCutscenes(cutscenes);
    setNarratives(narratives);
    setTilesets(tilesets);
    setTemplates(templates);
    setSpritesheets(spritesheets);
    setCharacterTemplates(templates.characters);

    resetCharacterIdsIntegrityCheck();
    const {
      activeCharacterId,
      partyCharacterIds,
      characters,
      items: characterItems
    } = createCharacters(characterData);

    const { regions, items: regionItems, features } = await createRegions(regionData);

    const items = {
      ...characterItems,
      ...regionItems,
    };

    const storeState = {
      ...rootInitialState,
      game: {
        ...gameInitialState,
        activeStoryId: story.id,
        activeCharacterId,
        partyCharacterIds,
        date: 49, // 7 weeks * 7 days - the seventh week, nearly through spring.
      },
      models: {
        characters,
        items,
        features,
        regions,
        stories: {
          [story.id]: story,
        },
      },
    };

    dispatch(loadGame(storeState));
    if (story.startCutsceneId) {
      playCutscene(story.startCutsceneId);
    } else {
      dispatch(updateTimeMode({ timeMode: TimeModes.NORMAL }));
    }

    dispatch(createGame({
      parent: 'phaser-root',
    }));
  };
}

export function createGame({ parent }) {
  return async (dispatch, getState) => {
    const region = activeRegionSelector(getState());
    const game = new Phaser.Game({
      type: Phaser.AUTO,
      pixelArt: true,
      scale: {
        parent,
        mode: Phaser.Scale.RESIZE,
      },
      backgroundColor: '#000000',
      callbacks: {
        postBoot: () => {
          setGame(game);
          // Yes this is currently 'booted' according to phaser, not 'loaded', but we're treating
          // this like an anti-corruption layer and using the language I want in the game.
          dispatch(updateGameStatus({
            gameStatus: PhaserStatuses.LOADED,
          }));

          startEngine();

          dispatch(loadActiveRegion({ regionId: region.id }));
        }
      },
    });
  };
}

export function saveGame() {
  return async (dispatch, getState) => {
    const { status, ...state } = getState();
    await localforage.setItem('myth-save', state);
  };
}

export function quitGame() {
  return async (dispatch, getState) => {
    stopEngine();

    await dispatch(unloadActiveRegion());
    dispatch(updateGameStatus({
      gameStatus: PhaserStatuses.UNLOADED,
    }));

    getGame().events.once('destroy', () => {
      setGame(null);
      dispatch(unloadGame());
    });

    getGame().destroy(true);
    setGame(null);
  };
}

export function advanceTime() {
  return async (dispatch, getState) => {
    const { year, day, hour } = getState().game;
    let newYear = year;
    let newDay = day;

    let newHour = hour + 1;
    if (newHour >= 24) {
      newHour = 0;
      newDay = day + 1;
    }
    if (newDay >= 112) {
      newDay = 0;
      newYear = year + 1;
    }

    dispatch(updateDate({
      year: newYear,
      day: newDay,
      hour: newHour,
    }));
  };
}
