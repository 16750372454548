import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import EmptySlot from './EmptySlot';
import Item from '../../Item';
import { useClickAttributes, useItem } from '../../../data/hooks';
import { equipItem, replaceEquippedItem, unequipItem } from '../../../characters/items/data/thunks';
import ItemQuantity from '../../ItemQuantity';
import { useHud } from '../../data/hooks';

const scale = 3;

export default function EquipmentSlot({ characterId, slot, name, item }) {
  const {
    grabbedItemId
  } = useHud();

  const grabbedItem = useItem(grabbedItemId);

  const isEquippable = grabbedItem !== null && grabbedItem.allowances[slot];

  let background = 'bg-parchment-200';
  if (grabbedItem !== null && isEquippable) {
    background = 'bg-parchment-300 cursor-pointer';
  } else if (grabbedItem !== null) {
    background = 'bg-white';
  }

  const dispatch = useDispatch();
  const handleClick = () => {
    if (isEquippable && item) {
      dispatch(replaceEquippedItem({
        characterId,
        slot,
        equippedItemId: item.id,
        replacementItemId: grabbedItemId,
      }));
    } else if (isEquippable) {
      dispatch(equipItem({
        characterId,
        slot,
        itemId: grabbedItemId,
      }));
    } else if (item) {
      dispatch(unequipItem({
        characterId,
        equippedItemId: item.id,
      }));
    }
  };

  const clickAttributes = useClickAttributes(handleClick);

  return (
    <div className="mb-2">
      <div>{name}</div>
      <div
        className={classNames(
          background,
          'border border-secondary rounded p-1 position-relative',
          { 'cursor-pointer': !!item }
        )}
        role="button"
        {...clickAttributes}
      >
        {item ? (
          <Item scale={scale} item={item} />
        ) : (
          <EmptySlot
            scale={scale}
            itemIsSelected={grabbedItem !== null}
            isEquippable={isEquippable}
          />
        )}
        {item && item.quantity > 1 && (
          <ItemQuantity quantity={item.quantity} />
        )}
      </div>
    </div>
  );
}
