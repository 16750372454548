/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ConversationState } from './types';

export const initialState: ConversationState = {
  // Conversations
  activeConversationCharacterId: null,
  activeConversationTopicId: 'start',
};

const slice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    startConversation: (state: ConversationState, action) => {
      const { payload } = action;
      const { characterId } = payload;

      state.activeConversationTopicId = 'start';
      state.activeConversationCharacterId = characterId;
    },
    endConversation: (state: ConversationState) => {
      state.activeConversationCharacterId = null;
      state.activeConversationTopicId = 'start';
    },
    changeConversationTopic: (state: ConversationState, action) => {
      const { payload } = action;
      const { linkedTopicId } = payload;
      state.activeConversationTopicId = linkedTopicId;
    },
  },
});

export const {
  startConversation,
  endConversation,
  changeConversationTopic,
} = slice.actions;

export const { reducer } = slice;
