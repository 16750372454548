import { updateRegionStatus } from './slice';
import RegionScene from '../scenes/RegionScene';
import { getGame, getRegionScene } from './phaserGame';
import { PhaserStatuses } from './types';
import { sleep } from '../../data/utils';
import { activeCharacterSelector } from '../../data/selectors';

export function loadActiveRegion({ regionId }) {
  return async (dispatch, getState) => {
    const region = getState().models.regions[regionId];

    const scene = getGame().scene.add(regionId, RegionScene, true, region);

    const ready = () => {
      dispatch(updateRegionStatus({
        regionStatus: PhaserStatuses.LOADED,
      }));

      scene.cameras.main.fadeIn(300);
    };

    // If a scene with a certain key (regionId) has been created once before, it appears the
    // 'create' event is not fired on subsequent adds of a scene with the same key, and "isActive"
    // will be true immediately instead of after 'create'.
    if (getGame().scene.isActive(regionId)) {
      ready();
    } else {
      scene.events.on('create', ready);
    }
  };
}

export function unloadActiveRegion() {
  return async (dispatch, getState) => {
    const activeCharacter = activeCharacterSelector(getState());

    const regionScene = getRegionScene(activeCharacter.regionId);
    regionScene.cameras.main.fadeOut(300);
    await sleep(300);
    dispatch(updateRegionStatus({ regionStatus: PhaserStatuses.UNLOADED }));
    getGame().scene.remove(activeCharacter.regionId);
  };
}
