import { createRoot } from 'react-dom/client';

import './index.scss';
import VentureApp from './VentureApp';
import reportWebVitals from './reportWebVitals';

export default function initialize({ story, rootId }) {
  const container = document.getElementById(rootId);
  const root = createRoot(container);
  root.render(
    <VentureApp story={story} />
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
