import { Provider } from 'react-redux';

import store from '../data/configureStore';
import AppContent from './AppContent';

function VentureApp({ story }) {
  return (
    <Provider store={store}>
      <AppContent story={story} />
    </Provider>
  );
}

export default VentureApp;
