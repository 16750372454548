import featuresImage from '../../spritesheets/features.png';

const featureTemplates = [
  {
    id: 'stone-bridge-w',
    name: 'Stone Bridge',
    image: {
      src: featuresImage,
      position: { x: 13, y: 1 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'stone-bridge',
    name: 'Stone Bridge',
    image: {
      src: featuresImage,
      position: { x: 14, y: 1 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'stone-bridge-e',
    name: 'Stone Bridge',
    image: {
      src: featuresImage,
      position: { x: 15, y: 1 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
  {
    id: 'stone-bridge-rail-w',
    name: 'Stone Bridge Railing',
    image: {
      src: featuresImage,
      position: { x: 13, y: 0 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'stone-bridge-rail',
    name: 'Stone Bridge Railing',
    image: {
      src: featuresImage,
      position: { x: 14, y: 0 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'stone-bridge-rail-e',
    name: 'Stone Bridge Railing',
    image: {
      src: featuresImage,
      position: { x: 15, y: 0 }
    },
    atlas: 'features',
    moveable: false,
  },
];

export default featureTemplates;
