import featuresImage from '../../spritesheets/features.png';

const featureTemplates = [
  {
    id: 'maple-nw',
    name: 'Maple Tree',
    image: {
      src: featuresImage,
      position: { x: 3, y: 0 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'maple-n',
    name: 'Maple Tree',
    image: {
      src: featuresImage,
      position: { x: 4, y: 0 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'maple-ne',
    name: 'Maple Tree',
    image: {
      src: featuresImage,
      position: { x: 5, y: 0 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'maple-w',
    name: 'Maple Tree',
    image: {
      src: featuresImage,
      position: { x: 3, y: 1 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'maple-center',
    name: 'Maple Tree',
    image: {
      src: featuresImage,
      position: { x: 4, y: 1 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'maple-e',
    name: 'Maple Tree',
    image: {
      src: featuresImage,
      position: { x: 5, y: 1 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'maple-sw',
    name: 'Maple Tree',
    image: {
      src: featuresImage,
      position: { x: 3, y: 2 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'maple-s',
    name: 'Maple Tree',
    image: {
      src: featuresImage,
      position: { x: 4, y: 2 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'maple-se',
    name: 'Maple Tree',
    image: {
      src: featuresImage,
      position: { x: 5, y: 2 }
    },
    atlas: 'features',
    moveable: false,
  },
  {
    id: 'maple-trunk',
    name: 'Maple Tree',
    image: {
      src: featuresImage,
      position: { x: 4, y: 3 }
    },
    atlas: 'features',
    moveable: false,
    walkable: false,
  },
];

export default featureTemplates;
