/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { HudState } from './types';

export const initialState: HudState = {
  grabbedItemId: null,
  selectedInventoryItem: null,

  viewportActivity: 'use',
  showChronicle: false,
  showMenu: false,
  showSettingsMenu: false,

  openSheets: [],
  openContainers: [],
};

const slice = createSlice({
  name: 'hud',
  initialState,
  reducers: {
    selectInventoryItem: (state: HudState, action) => {
      const { payload } = action;
      const { characterId, itemId, index } = payload;

      state.selectedInventoryItem = { characterId, itemId, index };
    },
    unselectInventoryItem: (state: HudState, action) => {
      state.selectedInventoryItem = null;
    },
    updateViewportActivity: (state: HudState, action) => {
      const { payload } = action;
      const { activity } = payload;
      state.viewportActivity = activity;
    },
    updateShowChronicle: (state: HudState, action) => {
      state.showChronicle = action.payload.showChronicle;
    },
    updateShowMenu: (state: HudState, action) => {
      state.showMenu = action.payload.showMenu;
    },
    updateShowSettingsMenu: (state: HudState, action) => {
      state.showSettingsMenu = action.payload.showSettingsMenu;
    },
    addOpenSheet: (state: HudState, action) => {
      state.openSheets = [
        ...state.openSheets.filter(element => element.characterId !== action.payload.characterId),
        { characterId: action.payload.characterId, minimized: false }
      ];
    },
    minimizeOpenSheet: (state: HudState, action) => {
      state.openSheets = state.openSheets.map(({ characterId, minimized }) => {
        let nextMinimized = minimized;
        if (action.payload.characterId === characterId) {
          nextMinimized = true;
        }
        return {
          characterId,
          minimized: nextMinimized,
        };
      });
    },
    maximizeOpenSheet: (state: HudState, action) => {
      state.openSheets = state.openSheets.map(({ characterId, minimized }) => {
        let nextMinimized = minimized;
        if (action.payload.characterId === characterId) {
          nextMinimized = false;
        }
        return {
          characterId,
          minimized: nextMinimized,
        };
      });
    },
    removeOpenSheet: (state: HudState, action) => {
      state.openSheets = state.openSheets.filter(element => element.characterId !== action.payload.characterId);
    },
    addOpenContainer: (state: HudState, action) => {
      state.openContainers = [
        ...state.openContainers.filter(element => element !== action.payload.containerItemId),
        action.payload.containerItemId
      ];
    },
    removeOpenContainer: (state: HudState, action) => {
      state.openContainers = state.openContainers.filter(element => element !== action.payload.containerItemId);
    },
    updateGrabbedItem: (state: HudState, action) => {
      state.grabbedItemId = action.payload.itemId;
    },
    clearGrabbedItem: (state: HudState, action) => {
      state.grabbedItemId = null;
    }
  },
});

export const {
  // Inventory
  selectInventoryItem,
  unselectInventoryItem,

  // Input
  updateViewportActivity,
  updateShowChronicle,
  updateShowMenu,
  updateShowSettingsMenu,

  // Lists
  addOpenSheet,
  removeOpenSheet,
  minimizeOpenSheet,
  maximizeOpenSheet,
  addOpenContainer,
  removeOpenContainer,

  // Grabbed Item
  updateGrabbedItem,
  clearGrabbedItem,
} = slice.actions;

export const { reducer } = slice;
