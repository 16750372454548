import classNames from 'classnames';
import { useClickAttributes } from '../../data/hooks';

export default function Button({ children, className, selected, onClick, ...props }) {

  const clickAttributes = useClickAttributes(onClick);

  return (
    <button
      className={classNames(
        'btn',
        { 'btn-selected': selected },
        className
      )}
      {...clickAttributes}
      {...props}
    >
      {children}
    </button>
  );
}
