import Phaser from 'phaser';
import memoize from 'memoize-one';

import { getStore } from '../../data/configureStore';
import { AREA_SIZE } from '../data/constants';
import createSelectorListener from '../../engine/createSelectorListener';
import { createModelSelector } from '../../models-store/selectors';

export default class GameSprite extends Phaser.GameObjects.Image {
  constructor(modelId, modelType, ...args) {
    super(...args);

    this.modelId = modelId;
    this.modelType = modelType;
    this.store = getStore();

    this.updateSpritePosition = memoize(this.updateSpritePosition);
    // This is important since we're calling the function from createSelectorListener
    this.handleModelChanged = this.handleModelChanged.bind(this);

    const { remove, data } = createSelectorListener(
      createModelSelector(modelType, modelId),
      this.handleModelChanged
    );

    this.removeModelListener = remove;
    this.model = data;
  }

  initialize() {
    this.initializePosition();
  }

  initializePosition() {
    this.setModelFrame();
    this.setModelDepth();
    this.setOrigin(0, 0);
    this.setPosition(this.model.x * AREA_SIZE, this.model.y * AREA_SIZE);
  }

  destroy() {
    // This is super important - cleaning up the subscription releases the store's reference to our
    // subscription listener, which lets this sprite be garbage collected.
    this.removeModelListener();
    super.destroy();
  }

  handleModelChanged(model) {
    this.model = model;
    this.setModelFrame();
    this.setModelDepth();
    this.checkSpritePosition();
  }

  checkSpritePosition() {
    const { x, y } = this.model;
    if (this.x !== x * AREA_SIZE || this.y !== y * AREA_SIZE) {
      this.updateSpritePosition(x * AREA_SIZE, y * AREA_SIZE);
    }
  }

  updateSpritePosition(x, y) {
    this.setTargetPosition(x, y);
  }
}
