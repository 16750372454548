import { useSelector } from 'react-redux';

import { useActiveCharacter, useGame } from '../../data/hooks';
import { PartyModes, TimeModes } from '../../data/types';
import { pause, play } from '../../engine/controls';
import { createHostileEnvironmentSelector } from '../../world/data/selectors';

import ActivityIconButton from '../generic/ActivityIconButton';

export default function TimeModeButton() {
  const {
    timeMode,
    partyMode
  } = useGame();

  const activeCharacter = useActiveCharacter();
  const isHostileEnvironment = useSelector(createHostileEnvironmentSelector(activeCharacter.regionId, activeCharacter.id));

  const handlePause = () => {
    pause();
  };

  const handlePlay = () => {
    play();
  };

  if (timeMode === TimeModes.NORMAL) {
    return (
      <ActivityIconButton
        key="pause"
        activityId="pause"
        scale={2}
        onClick={handlePause}
      />
    );
  }

  if (timeMode === TimeModes.CUTSCENE) {
    return (
      <ActivityIconButton
        key="cutscenePause"
        activityId="cutscenePause"
        scale={2}
        onClick={handlePause}
      />
    );
  }

  if (timeMode === TimeModes.PAUSED && partyMode === PartyModes.CAMPING) {
    return (
      <ActivityIconButton
        key="campPause"
        activityId="campPause"
        scale={2}
      />
    );
  }

  if (timeMode === TimeModes.PAUSED && isHostileEnvironment) {
    return (
      <ActivityIconButton
        key="combatPause"
        activityId="combatPause"
        scale={2}
      />
    );
  }

  return (
    <ActivityIconButton
      key="play"
      activityId="play"
      scale={2}
      onClick={handlePlay}
    />
  );
}
