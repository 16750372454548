import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../generic';
import { useCharacter, useGame } from '../../../data/hooks';
import { useCharacterItems } from '../../../characters/data/hooks';
import { transferItem } from '../../../characters/items/data/thunks';
import ItemTile from '../../ItemTile';
import { leave } from './data/thunks';

export default function Victory() {
  const dispatch = useDispatch();
  const {
    activeCharacterId,
  } = useGame();
  const {
    opponentId
  } = useSelector(state => state.combat);

  const opponent = useCharacter(opponentId);
  const opponentItems = useCharacterItems(opponentId);

  const handleLeave = () => {
    dispatch(leave());
  };

  const handleItemClick = (item) => {
    dispatch(transferItem({
      giverCharacterId: opponentId,
      receiverCharacterId: activeCharacterId,
      itemId: item.id
    }));
  };

  return (
    <>
      <p>You have defeated the {opponent.name}!</p>

      {opponentItems.length > 0 && (
        <>
          <p>You find some things worth taking. What do you want?</p>
          <div className="mb-3 gap-2">
            {opponentItems.map((item) => {
              return (
                <ItemTile
                  className="bg-parchment-300"
                  key={item.id}
                  item={item}
                  onClick={() => handleItemClick(item)}
                  scale={3}
                />
              );
            })}
          </div>
        </>
      )}

      <Button onClick={handleLeave}>Leave</Button>
    </>
  );
}
