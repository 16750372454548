import AbilityPoints from './AbilityPoints';

export default function Abilities({ character, className }) {
  const { agi, cha, int, str } = character;
  return (
    <div className={className}>
      <AbilityPoints count={agi} name="Agi" variant="agi" className="d-block" />
      <AbilityPoints count={cha} name="Cha" variant="cha" className="d-block" />
      <AbilityPoints count={int} name="Int" variant="int" className="d-block" />
      <AbilityPoints count={str} name="Str" variant="str" className="d-block" />
    </div>
  );
}
