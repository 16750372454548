import isTouchDevice from 'is-touch-device';
import { useActiveRegion, useWindowSize } from '../data/hooks';

import TimeModeButton from './buttons/TimeModeButton';
import ChronicleButton from './buttons/ChronicleButton';
import { Floater } from './generic';
import MenuButton from './buttons/MenuButton';
import UseButton from './buttons/UseButton';
import TalkButton from './buttons/TalkButton';
import LookButton from './buttons/LookButton';
import AttackButton from './buttons/AttackButton';
import WalkButton from './buttons/WalkButton';
import CampButton from './buttons/CampButton';

export default function GeneralButtons() {
  const { width, height } = useWindowSize();
  const region = useActiveRegion();

  let buttons = [];
  if (isTouchDevice()) {
    buttons.push({
      key: 'walk', element: (<WalkButton />)
    });
  }

  if (region.wilderness) {
    buttons.push({
      key: 'camp', element: (<CampButton />)
    });
  }

  const commonButtons = [
    { key: 'attack', element: (<AttackButton />) },
    { key: 'look', element: (<LookButton />) },
    { key: 'talk', element: (<TalkButton />) },
    { key: 'use', element: (<UseButton />) },
  ];

  buttons = [
    { key: 'menu-button', element: (<MenuButton />) },
    { key: 'timeMode-button', element: (<TimeModeButton />) },
    { key: 'chronicle-button', element: (<ChronicleButton />) },
    ...commonButtons,
    ...buttons,
  ];

  const maxAcross = 5;

  return buttons.map(({ key, element }, index) => {
    let y = index >= maxAcross ? (height - 72) : (height - 8);
    return (
      <Floater key={key} y={y} x={width - 8 - ((index % maxAcross) * 64)} alignY="bottom" alignX="right">
        {element}
      </Floater>
    );
  });
}
