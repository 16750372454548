import { Narratives } from '../platform/content-utils/types';

const narratives: Narratives = {
  introduction: {
    id: 'introduction',
    content: [
      'This is a tech demo...',
      'Of all the features in Venture.',
      'Once, in a small pixelated town...',
    ]
  }
};

export default narratives;
