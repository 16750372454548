import classNames from 'classnames';

import Character from '../character/Character';
import { SOUTH, REST } from '../character/constants';
import { useCharacter, useClickAttributes } from '../../data/hooks';

export default function PortraitButton({
  direction = SOUTH,
  className,
  characterId,
  scale = 4,
  onClick = null,
  selected = false,
}) {
  const character = useCharacter(characterId);

  const _onClick = onClick || (() => {});
  const clickAttributes = useClickAttributes(_onClick);

  const props = {};
  if (onClick) {
    props.role = 'button';
  }

  return (
    <div
      className={classNames(
        { 'border rounded bg-white shadow-1': selected },
        { 'border rounded bg-parchment-700 border-secondary': !selected },
        'overflow-hidden flex-grow-0 flex-shrink-0 cursor-pointer',
        className
      )}
      {...clickAttributes}
      {...props}
    >
      <Character
        portrait
        scale={scale}
        character={character}
        direction={direction}
        pose={REST}
      />
    </div>
  );
}
