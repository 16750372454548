import { useSelector } from 'react-redux';

import { ProgressBar } from '../generic';
import { createCharacterStatusSelector } from '../../characters/status/data/selectors';
import { useCharacter } from '../../data/hooks';

export default function HealthBar({ characterId, className = null, barOnly = false, height = 14 }) {
  const character = useCharacter(characterId);
  const { health, maxHealth } = character;
  const { healthStatus } = useSelector(createCharacterStatusSelector(characterId));

  return (
    <div className={className}>
      <ProgressBar height={height} min={0} max={maxHealth} current={health} className="w-100" barClassName="bg-str" />
      {!barOnly && (
        <div className="d-flex justify-content-between">
          <div className="small">{healthStatus}</div>
          <div className="small">{`${health} / ${maxHealth}`}</div>
        </div>
      )}
    </div>
  );
}
