import { destroyCutsceneDirector } from '../directors/cutscene/director';
import { destroyLiveDirector } from '../directors/live/director';
import { destroySequenceDirector } from '../directors/sequence/director';

/**
 * Cleans up the character action controls.
 */
export function destroyDirectors() {
  destroyLiveDirector();
  destroySequenceDirector();
  destroyCutsceneDirector();
}
