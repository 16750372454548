import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { addItemToContainer, pickUpItem } from '../../characters/items/data/thunks';
import { useGrabbedItemId, useItem } from '../../data/hooks';
import ItemTile from '../ItemTile';
import { combineStacks, openContainer } from '../../player/data/thunks';
import { clearGrabbedItem, updateGrabbedItem } from '../data/slice';

export default function ContainerItem({ item, scale = 2, isGrabbed, characterId = null, containerItemId = null,  }) {
  const dispatch = useDispatch();

  const grabbedItemId = useGrabbedItemId();
  const grabbedItem = useItem(grabbedItemId);

  const handleClick = () => {
    if (item.container) {
      dispatch(openContainer({ containerItemId: item.id }));
    } else if (grabbedItem && grabbedItem.id === item.id) {
      dispatch(clearGrabbedItem());
    } else if (grabbedItem && grabbedItem.templateId === item.templateId && grabbedItem.stackSize > 1) {
      dispatch(combineStacks({
        destinationItemId: grabbedItemId,
        sourceItemId: item.id,
      }));
    } else {
      dispatch(updateGrabbedItem({ itemId: item.id }));
    }
  };

  const handleEmptyClick = () => {
    if (grabbedItemId !== null) {
      if (characterId) {
        dispatch(pickUpItem({ characterId, itemId: grabbedItemId }));
      } else if (containerItemId) {
        dispatch(addItemToContainer({
          containerItemId,
          itemId: grabbedItemId
        }));
      }
      dispatch(clearGrabbedItem());
    }
  };

  if (item.empty) {
    return (
      <div
        className={classNames(
          'border border-dashed border-secondary bg-parchment-100 rounded p-1 d-inline-block position-relative'
        )}
        style={{
          width: 32,
          height: 32,
        }}
        role="button"
        onClick={handleEmptyClick}
      ></div>
    );
  }

  return (
    <ItemTile
      item={item}
      onClick={handleClick}
      className={classNames(
        'bg-parchment-100',
        { 'border-dashed shadow-1': isGrabbed }

      )}
      scale={scale}
    />
  );
}
