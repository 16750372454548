import { useHud } from '../data/hooks';
import Sheet from './Sheet';

export default function OpenSheets() {
  const {
    openSheets
  } = useHud();

  return openSheets.map(({ characterId }) => {
    return (
      <Sheet
        key={characterId}
        characterId={characterId}
      />
    );
  });
}
