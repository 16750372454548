import { useSelector } from 'react-redux';
import { Card } from '../../generic';

import { createCharacterStatusSelector } from '../../../characters/status/data/selectors';
import { useCharacter } from '../../../data/hooks';

export default function OpponentCard({ characterId, className }) {
  const character = useCharacter(characterId);
  const { distance } = useSelector(state => state.combat);
  const { healthStatus, enduranceStatus } = useSelector(createCharacterStatusSelector(characterId));

  let distanceDescription = 'almost completely out of sight';
  if (distance === 3) {
    distanceDescription = 'far away';
  } else if (distance === 2) {
    distanceDescription = 'almost within reach';
  }  else if (distance === 1) {
    distanceDescription = 'within melee range';
  }

  let statusDescription = (
    <p>
      The {character.name} is <strong>{healthStatus}</strong>.
    </p>
  );
  if (character.health > 0) {
    statusDescription = (
      <p>
        The {character.name} is <strong>{healthStatus}</strong> and <strong>{enduranceStatus}</strong>.
      </p>
    );
  }

  return (
    <Card className={className}>
      <h2>{character.name}</h2>
      {statusDescription}
      <p>The {character.name} is {distanceDescription}.</p>
    </Card>
  );
}
