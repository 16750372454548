import Phaser from 'phaser';

import createSelectorListener from '../../engine/createSelectorListener';
import { createRegionCharactersSelector } from '../data/selectors';

import CharacterLowerContainer from '../sprites/CharacterLowerContainer';
import CharacterUpperContainer from '../sprites/CharacterUpperContainer';

export default class Characters extends Phaser.GameObjects.Group {

  constructor(region, elevationLayers, ...args) {
    super(...args);

    this.region = region;
    this.elevationLayers = elevationLayers;
    this.handleCharactersChanged = this.handleCharactersChanged.bind(this);
    this.currentCharactersById = {};

    const { remove, data } = createSelectorListener(
      createRegionCharactersSelector(this.region.id),
      this.handleCharactersChanged
    );
    this.handleCharactersChanged(data);

    this.removeModelListener = remove;
  }

  destroy() {
    this.removeModelListener();
  }

  handleCharactersChanged(characters) {
    const nextCurrentCharactersById = {};
    const remainingCurrentCharactersById = { ...this.currentCharactersById };
    const newCharactersData = [];

    for (let i = 0; i < characters.length; i++) {
      const character = characters[i];
      if (remainingCurrentCharactersById[character.id] !== undefined) {
        // Pass the sprite to the new list
        nextCurrentCharactersById[character.id] = remainingCurrentCharactersById[character.id];
        // Delete it in the old list so we can filter down to what's left, which should be retired
        delete remainingCurrentCharactersById[character.id];
      } else {
        newCharactersData.push(character);
      }
    }

    const retiredCharacters = Object.values(remainingCurrentCharactersById);

    newCharactersData.forEach(character => {
      // create a new CharacterContainer
      const characterUpperContainer = new CharacterUpperContainer(
        character.id,
        'characters',
        this.elevationLayers,
        this.scene,
      );
      const characterLowerContainer = new CharacterLowerContainer(
        character.id,
        'characters',
        this.elevationLayers,
        this.scene,
      );

      nextCurrentCharactersById[character.id] = {
        id: character.id,
        upper: characterUpperContainer,
        lower: characterLowerContainer,
      };

      const elevation = 2;
      if (this.elevationLayers[elevation] === undefined) {
        this.elevationLayers[elevation] = this.scene.add.layer();
        this.elevationLayers[elevation].setDepth(elevation);
      }

      this.add(characterLowerContainer);
      this.add(characterUpperContainer);

      // We still use addModelSprite here so that the scene can get a reference to this character
      // for use in setting the viewport.
      this.scene.addModelSprite(character.id, characterLowerContainer);
    });

    retiredCharacters.forEach(retiredCharacter => {
      // destroy existing character sprite
      this.scene.removeModelSprite(retiredCharacter.id);
      retiredCharacter.upper.destroy();
      retiredCharacter.lower.destroy();
    });

    this.currentCharactersById = nextCurrentCharactersById;
  }

  preUpdate(time, delta) {
    this.children.each((child) => {
      child.preUpdate(time, delta);
    });
  }
}
