import { walk } from '../../../../characters/movement/data/thunks';
import { getStore } from '../../../../data/configureStore';
import { getCharacterWalk, getDirectionFromWalks, getPreferredCharacterDirection, setCharacterWalk } from '../utils';

export function performMovementInput() {
  const {
    walkNorth,
    walkSouth,
    walkEast,
    walkWest,
    activeCharacterId,
  } = getStore().getState().game;

  const characterWalk = getCharacterWalk(activeCharacterId);
  if (!characterWalk) {
    setCharacterWalk(activeCharacterId, {
      walkNorthCount: 0,
      walkSouthCount: 0,
      walkEastCount: 0,
      walkWestCount: 0,
      cancel: null,
    });
  }

  if (walkNorth || walkSouth || walkEast || walkWest) {
    const { dx, dy } = getDirectionFromWalks(walkNorth, walkSouth, walkEast, walkWest);
    const preferredDirection = getPreferredCharacterDirection(
      activeCharacterId,
      walkNorth,
      walkSouth,
      walkEast,
      walkWest
    );

    getStore().dispatch(walk({
      characterId: activeCharacterId,
      dx,
      dy,
      preferredDirection,
      canTravel: true,
    }));
  }
}
