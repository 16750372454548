import { createSelector } from 'reselect';

export function createCharacterHealthStatusSelector(characterId) {
  return createSelector(
    state => state.models.characters[characterId],
    (character) => {
      const percent = character.health / character.maxHealth;
      if (percent > 0.9) {
        return 'Healthy';
      }
      if (percent > 0.8) {
        return 'Bruised';
      }
      if (percent > 0.6) {
        return 'Lightly Wounded';
      }
      if (percent > 0.4) {
        return 'Wounded';
      }
      if (percent > 0.2) {
        return 'Heavily Wounded';
      }
      if (percent > 0) {
        return 'Critical';
      }
      return 'Defeated';
    }
  );
}

export function createCharacterEnduranceStatusSelector(characterId) {
  return createSelector(
    state => state.models.characters[characterId],
    (character) => {
      const percent = character.endurance / character.maxEndurance;
      if (percent > 0.7) {
        return 'Well Rested';
      }
      if (percent > 0.5) {
        return 'Rested';
      }
      if (percent > 0.35) {
        return 'Tired';
      }
      if (percent > 0.10) {
        return 'Exhausted';
      }
      if (percent > 0) {
        return 'Debilitated';
      }
      return 'Spent';
    }
  );
}

export function createCharacterStatusSelector(characterId) {
  return createSelector(
    createCharacterHealthStatusSelector(characterId),
    createCharacterEnduranceStatusSelector(characterId),
    (healthStatus, enduranceStatus) => {
      return {
        healthStatus,
        enduranceStatus,
      };
    }
  );
}
