import {
  ChoiceActions,
  Conversations
} from '../platform/ui/activities/conversation/data/types';

const conversations: Conversations = {
  dao: {
    start: {
      content: 'Dao looks up with a smile, "Hi, how are you today?"',
      choices: [
        { action: ChoiceActions.LINK, linkedTopicId: 'happy', response: `"I'm doing okay."` },
        { action: ChoiceActions.LINK, linkedTopicId: 'sad', response: `"Truthfully, I'm a bit bummed."` },
        { action: ChoiceActions.TRADE, response: `Let's trade!` },
      ]
    },
    happy: {
      content: `His smile broadens. "Well that's good to hear."`,
      choices: [
        { action: ChoiceActions.LINK, linkedTopicId: 'end', response: `"Yeah - I guess I'll be going then."` }
      ]
    },
    sad: {
      content: `He frowns.  "Oh, I'm sorry to hear that."`,
      choices: [
        { action: ChoiceActions.LINK, linkedTopicId: 'end', response: `"Yeah, bummer - I should get going."` }
      ]
    },
    end: {
      content: `"Ah, well, by then."`,
      choices: [
        { action: ChoiceActions.END, response: `"So long."` }
      ]
    }
  },
  horace: {
    start: {
      content: 'Welcome to the Shameful Dog.',
      choices: [
        { action: ChoiceActions.INN, response: 'Do you have a room?' },
        { action: ChoiceActions.TRADE, response: 'Want to buy any meat for the inn?' }
      ]
    }
  },
  horaceIntroduction: {
    start: {
      content: `You're here!  Thank goodness.`,
      choices: [
        { action: ChoiceActions.END, response: 'Sure am.' }
      ]
    }
  },
  tim: {
    start: {
      content: `Hi, I'm a raccoon.  The name's Tim.`,
      choices: [
        { action: ChoiceActions.END, response: '"Cool. Bye, Tim."' }
      ]
    }
  },
  mue: {
    start: {
      content: `Huph!  You no welcome here.  Go 'way.`,
      choices: [
        { action: ChoiceActions.COMBAT, response: `No, let's fight!` },
        { action: ChoiceActions.END, response: `Go 'way.` }
      ]
    }
  },
  aen: {
    start: {
      content: 'The woman notices you trying to get her attention and perks up expectantly.  She looks tricksy.',
      choices: [
        {
          action: ChoiceActions.LINK,
          linkedTopicId: 'secret',
          relationshipStatus: { levelAbove: 4 },
          characterItemStatus: {
            has: ['halberd']
          },
          response: 'Hey... have anything you can give me?  For a friend?',
        },
        {
          action: ChoiceActions.UPDATE_RELATIONSHIP,
          levelChange: 5,
          relationshipStatus: { levelBelow: 5 },
          response: `"You're really nice. Be my friend!"`,
          linkedTopicId: 'friendsNow',
        },
        {
          action: ChoiceActions.TAKE_PLAYER_ITEMS,
          playerItemStatus: {
            has: ['apple'],
          },
          response: 'Do you want an apple?',
          templateIds: ['apple'],
          linkedTopicId: 'acceptApple',
        },
        {
          action: ChoiceActions.LINK,
          linkedTopicId: 'joinParty',
          partyStatus: {
            inParty: false,
            canJoin: true,
          },
          response: 'Would you like to join our party?',
        },
        {
          action: ChoiceActions.LINK,
          linkedTopicId: 'cannotJoinParty',
          partyStatus: {
            inParty: false,
            canJoin: false,
          },
          response: 'Would you like to join our party?',
        },
        {
          action: ChoiceActions.LINK,
          linkedTopicId: 'leaveParty',
          partyStatus: {
            inParty: true,
          },
          response: 'I think maybe you should leave the party.',
        },
        {
          action: ChoiceActions.END,
          response: 'Quietly walk away.'
        }
      ]
    },
    friendsNow: {
      content: `"Aw, that's such a nice thing to say.  Let's be friends!"`,
      choices: [
        {
          action: ChoiceActions.LINK,
          response: `"For ever and ever."`,
          linkedTopicId: 'start',
        }
      ]
    },
    acceptApple: {
      content: '"Oh, wow, thank you!  This looks delicious.',
      choices: [
        {
          action: ChoiceActions.LINK,
          response: `"You're wecome."`,
          linkedTopicId: 'start'
        }
      ]
    },
    joinParty: {
      content: 'The woman perks up.  "I would love to!  Can I?"',
      choices: [
        {
          action: ChoiceActions.JOIN_PARTY,
          linkedTopicId: 'joinedParty',
          response: 'Yes, join us!',
        },
        {
          action: ChoiceActions.LINK,
          linkedTopicId: 'nevermind',
          response: `"Forget it, I've changed my mind."`
        }
      ]
    },
    joinedParty: {
      content: `"I'm so excited! We're going to have so many adventures together."`,
      choices: [
        {
          action: ChoiceActions.LINK,
          linkedTopicId: 'start',
          response: `"Me too!  Journey onward!"`
        }
      ]
    },
    cannotJoinParty: {
      content: 'She looks excited for a moment, and then frowns. "Oh, well, I think your group is a bit big. Maybe if there were fewer of you."',
      choices: [
        {
          action: ChoiceActions.LINK,
          linkedTopicId: 'start',
          response: `"Oh, that's too bad.`
        }
      ]
    },
    leaveParty: {
      content: '"Really? You want me to leave? I really enjoying traveling with you!"',
      choices: [
        {
          action: ChoiceActions.LEAVE_PARTY,

          response: '"Yes, sorry. We need to part."',
          linkedTopicId: 'leftParty',
        },
        {
          action: ChoiceActions.LINK,
          linkedTopicId: 'start',
          response: '"Nevermind, please stay."'
        }
      ]
    },
    leftParty: {
      content: `"Okay then... I guess I'll go home."`,
      choices: [
        {
          action: ChoiceActions.LINK,
          linkedTopicId: 'start',
          response: `"It's been fun. So long."`
        }
      ]
    },
    nevermind: {
      content: 'The woman looks crestfallen. "Oh... okay then."',
      choices: [
        {
          action: ChoiceActions.LINK,
          linkedTopicId: 'joinParty',
          partyStatus: { inParty: false },
          response: 'Just kidding. Would you like to join our party?',
        },
        {
          action: ChoiceActions.END,
          response: 'Quietly walk away.'
        }
      ]
    },
    secret: {
      content: `"Well... Since we're friends.  Here's a halberd that I don't need.  Enjoy!"`,
      choices: [
        {
          action: ChoiceActions.GIVE_PLAYER_ITEMS,
          characterItemStatus: {
            has: ['halberd']
          },
          response: `"Thanks, I'll take it!  You're a great friend."`,
          linkedTopicId: 'start',
          templateIds: ['halberd']
        },
        {
          action: ChoiceActions.LINK,
          characterItemStatus: {
            has: ['halberd']
          },
          response: `"You know, I don't need it.  Keep it!"`,
          linkedTopicId: 'start',
        },
      ]

    }
  }
};

export default conversations;
