import memoize from 'memoize-one';

import { takeCharacterTurn } from '../../../characters/data/thunks';
import { getStore } from '../../../data/configureStore';
import { TimeModes } from '../../../data/types';

import createSelectorListener from '../../createSelectorListener';

let removeStateListener = null;

export function createSequenceDirector() {
  const {
    remove,
  } = createSelectorListener(state => state.game.actingCharacterId, handleActingCharacterIdChanged);

  removeStateListener = remove;
}

export function destroySequenceDirector() {
  if (removeStateListener !== null) {
    removeStateListener();
  }
}

const handleActingCharacterIdChanged = memoize((actingCharacterId) => {
  const { timeMode, activeCharacterId } = getStore().getState().game;
  const isNonActiveCharacter = actingCharacterId !== null && actingCharacterId !== activeCharacterId;

  if ((timeMode === TimeModes.PAUSED) && isNonActiveCharacter) {
    getStore().dispatch(takeCharacterTurn({ characterId: actingCharacterId }));
  }
});
