import { useDispatch } from 'react-redux';
import { useHud } from '../data/hooks';

import { updateViewportActivity } from '../data/slice';
import ActivityIconButton from '../generic/ActivityIconButton';

export default function LookButton() {
  const dispatch = useDispatch();
  const {
    viewportActivity,
  } = useHud();

  const handleLook = () => {
    dispatch(updateViewportActivity({ activity: 'look' }));
  };

  return (
    <ActivityIconButton
      activityId="look"
      scale={2}
      onClick={handleLook}
      selected={viewportActivity === 'look'}
    />
  );
}
